import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import {
  isLoggedIn,
  getLoggedInUserRole,
} from "../../../Services/LocalStorageService";
import { accessGroups, isAuthorized } from "./AccessGroups";

export function AuthGuard({ route = "" }) {
  const auth = isLoggedIn(); // determine if authorized, from context or however you're doing it

  let role = false;
  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  const routeUrl = `/login?reroute=${route}`;

  if (!auth) return <Navigate to="/login" />;

  if (route == "") return <Outlet />;

  role = isAuthorized(getLoggedInUserRole(), "routes", route);

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return role ? <Outlet /> : <Navigate to="/" />;
}
