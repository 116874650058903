// @ts-nocheck
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
  },

  fullScrnCard: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    // Responsive styling for the modal
    width: "75vw", // Default width for larger screens
    height: "85vh", // Default height for larger screens
    overflowY: "auto",
    // Media query for mobile devices
    [theme.breakpoints.down("sm")]: {
      width: "99vw", // Full viewport width on mobile
      height: "99vh", // Full viewport height on mobile
    },
  },
  fullScrnCardContent: { fontWeight: "bold", fontSize: "2em" },
  fullScrnCardActions: {},
}));

export { useStyles };
