// @ts-nocheck
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    modal: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
    },
    fullScrnCard: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        overflowY: 'auto',
    },
    fullScrnCardContent: { fontWeight: 'bold', fontSize: '2em' },
}));

export { useStyles }
