// @ts-nocheck

import React, { useEffect, useState, useRef, createRef } from "react";
import { useStyles } from "./Styles";
import "../../../../../../index.scss";
import Header from "../../../../../Layout/Header/Header";
import Sider from "../../../../../Layout/Sider/Sider";
import { getAll, post } from "../../../../../../Services/GenericApiService";
import { useTranslation } from "react-i18next";
import AlertM from "../../../../../Helpers/AlertM/AlertM";
import { log } from "../../../../../../Services/LoggerService";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  FormHelperText,
  TextField,
  Divider,
  Typography,
  Modal,
  Fade,
  Backdrop,
  Select,
  MenuItem,
  InputLabel,
  Grid,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { CircularProgress } from "@material-ui/core";
import {
  setHadithTranslationData,
  retrieveHadithTranslationData,
} from "../../../../../../Services/LocalStorageService";
import ImageCapture from "../../../../Camera/ImageCapture";
import { getLanguageLabel, languages } from "./languagesMeta";
import { franc } from "franc-min";

// font awesome stuff
const iconList = Object.keys(Icons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon) => Icons[icon]);

library.add(...iconList);

function HadithTranslationMain() {
  // css for module
  const classes = useStyles();

  // translation
  const { t } = useTranslation();

  // child ref for alert
  const notifications = useRef();

  // module(s) for api
  const moduleHadith = "hadith/bulk";
  const moduleBooks = "hadithbook";
  const moduleTranslate = "translation/translate";

  // ui handlers
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [allHadithBooks, setAllHadithBooks] = React.useState([]);
  const [userTimezone, setUserTimeZone] = useState("");

  // ... other component logic
  const inputRefs = useRef([]);

  const [controls, setControls] = useState({
    hasBeenTranslated: false,
    isTranslating: false,
  });

  const defaultHadithDataObj = {
    conventionalId: "",
    text: "",
    translatedText: "",
    secondTranslationText: "",
  };

  const {
    control,
    handleSubmit,
    watch,
    trigger,
    setValue,
    getValues,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      hadithBookId: "",
      sourceLanguageCode: "",
      targetLanguageCode: "",
      secondTargetLanguageCode: "",
      isSecondTranslationLanguage: false,
      hadiths: [{ ...defaultHadithDataObj }],
    },
  });

  const hadithBookIdValue = watch("hadithBookId");
  const sourceLanguageCodeValue = watch("sourceLanguageCode");
  const targetLanguageCodeValue = watch("targetLanguageCode");
  const secondTargetLanguageCodeValue = watch("secondTargetLanguageCode");
  const isSecondTranslationLanguageValue = watch("isSecondTranslationLanguage");

  const { fields, append, remove } = useFieldArray({
    control,
    name: "hadiths",
  });

  const resetFields = () => {
    reset();
  };

  useEffect(() => {
    const savedData = retrieveHadithTranslationData();
    if (savedData) {
      reset(savedData);
    }
  }, [reset]);

  useEffect(() => {
    const subscription = watch((values) => {
      setHadithTranslationData(values);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    fetch();
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setUserTimeZone(timeZone);
  }, []);

  useEffect(() => {
    // Re-run effect when translatedText changes
    const subscription = watch((value) => {
      if (value.hadiths.some((hadith) => hadith.translatedText)) {
        setControls((prevState) => ({
          ...prevState,
          hasBeenTranslated: true,
        }));
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const fetch = () => {
    fetchHadithBooks();
  };

  const fetchHadithBooks = () => {
    setLoading(true); // show loader
    getAll(moduleBooks)
      .then((_res: any) => {
        setAllHadithBooks(_res);
        setLoading(false); // hide loader
      })
      .catch((_err) => {
        log(_err);
        setLoading(false); // hide loader
      });
  };

  const onSubmit = (_data) => {
    console.log("data submitted: ", _data);
  };

  // Function to handle translation
  const handleTranslate = async () => {
    clearAllEmptyEntries();
    try {
      setControls((prevState) => ({ ...prevState, isTranslating: true }));

      const formValues = getValues();
      let hadithFormData = formValues.hadiths;

      let body = {
        sourceLanguageCode: sourceLanguageCodeValue,
        targetLanguageCode: targetLanguageCodeValue,
        contents: hadithFormData
          ?.map((element) => element.text)
          .filter((text) => text !== ""),
        isSecondTranslationLanguage: false,
      };

      if (isSecondTranslationLanguageValue) {
        body = {
          ...body,
          isSecondTranslationLanguage: true,
          secondTargetLanguageCode: secondTargetLanguageCodeValue,
        };
      }

      // translation API call
      post(moduleTranslate, body)
        .then((_res: any) => {
          let translationCount = 0;
          let hadithsData = hadithFormData?.map((element, index) => {
            if (element.text !== "") {
              translationCount++;
              return {
                ...element,
                translatedText:
                  _res.firstTranslation[translationCount - 1].translatedText,
              };
            } else return element;
          });

          if (isSecondTranslationLanguageValue) {
            translationCount = 0;
            hadithsData = hadithFormData?.map((element, index) => {
              if (element.text !== "") {
                translationCount++;
                return {
                  ...hadithsData[index],
                  secondTranslationText:
                    _res.secondTranslation[translationCount - 1].translatedText,
                };
              } else return element;
            });
          }

          setValue("hadiths", hadithsData);
          setControls((prevState) => ({
            ...prevState,
            isTranslating: false,
            hasBeenTranslated: true,
          }));
        })
        .catch((_err) => {
          log(_err);
          setControls((prevState) => ({
            ...prevState,
            isTranslating: false,
          }));
          notifications.current.errorAlert(
            "Translation Error",
            _err?.message || "Error translating text"
          );
        });
    } catch (error) {
      console.error("Error translating text:", error);
      notifications.current.errorAlert("Translation Error", error?.message);
    }
  };

  // Function to add a new hadith
  const addHadith = () => {
    if (fields?.length < 20) {
      append({ ...defaultHadithDataObj });
    } else {
      alert("Maximum of 20 hadiths can be added.");
    }
  };

  const resetAllHadithEntries = () => {
    const currentValues = getValues("hadiths");
    for (let index = currentValues.length - 1; index >= 0; index--) {
      remove(index);
    }
  };

  const clearAllEmptyEntries = () => {
    const currentValues = getValues("hadiths");
    for (let index = currentValues.length - 1; index >= 0; index--) {
      const field = currentValues[index];
      if (
        field.conventionalId === "" &&
        field.text === "" &&
        field.translatedText === ""
      ) {
        remove(index);
      }
    }
  };

  useEffect(() => {
    // Adjust the refs array to match the number of hadiths
    inputRefs.current = fields?.map(
      (_, i) => inputRefs.current[i] ?? createRef()
    );
  }, [fields]);

  // ui controls
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    if (hadithBookIdValue) handleBookSelect(hadithBookIdValue);
  }, [hadithBookIdValue]);

  const handleBookSelect = (value) => {
    if (isNaN(value)) return;
    const hadithBook = allHadithBooks.find((book) => book.id == value);

    if (hadithBook) {
      setControls((prevState) => ({ ...prevState, hadithBookId: value }));
    }
  };

  useEffect(() => {
    if (sourceLanguageCodeValue)
      handleSourceLanguageSelect(sourceLanguageCodeValue);
  }, [sourceLanguageCodeValue]);

  const handleSourceLanguageSelect = (value) => {
    if (isNaN(value)) return;
    const language = languages.find((lang) => lang.key == value);

    if (language) {
      setControls((prevState) => ({
        ...prevState,
        sourceLanguageCode: language.key,
      }));
    }
  };

  useEffect(() => {
    if (targetLanguageCodeValue)
      handleTargetLanguageSelect(targetLanguageCodeValue);
  }, [targetLanguageCodeValue]);

  const handleTargetLanguageSelect = (value) => {
    if (isNaN(value)) return;
    const language = languages.find((lang) => lang.key == value);

    if (language) {
      setControls((prevState) => ({
        ...prevState,
        targetLanguageCode: language.key,
      }));
    }
  };

  useEffect(() => {
    if (secondTargetLanguageCodeValue)
      handleSecondTargetLanguageSelect(secondTargetLanguageCodeValue);
  }, [secondTargetLanguageCodeValue]);

  const handleSecondTargetLanguageSelect = (value) => {
    const language = languages.find((lang) => lang.key === value);

    if (language) {
      setControls((prevState) => ({
        ...prevState,
        secondTargetLanguageCode: language.key,
      }));
    }
  };

  const handleReview = async () => {
    const isValid = await trigger(); // This will validate all fields in the form

    if (isValid) {
      handleOpen();
      return;
    }

    notifications.current.errorAlert(
      "Fields Missing",
      "Make sure you have selected Book, Source Language, Target Language, all Hadith IDs and Hadith Text for all entries."
    );
  };

  /**
   * Method for Hadith Submission
   * @returns
   */
  const submitData = async () => {
    clearAllEmptyEntries();
    const isValid = await trigger(); // This will validate all fields in the form

    if (!isValid || !sourceLanguageCodeValue || !targetLanguageCodeValue) {
      notifications.current.errorAlert(
        "Fields Missing",
        "Make sure to check Languages, Hadith ID, and Hadith Translation before submission."
      );
      return;
    }

    setLoading(true); // show loader

    let body = {
      hadiths: [], // array to store hadith objects
      userTimezone, // user timezone
    };

    fields.forEach((hadith) => {
      if (hadith.text !== "" && hadith.translatedText !== "") {
        const translationsData = [];

        // Add translations to the translations array

        // Add source language translation
        translationsData.push({
          conventionalId: hadith.conventionalId, // conventional id of the hadith
          language: sourceLanguageCodeValue, // source language code
          translation: hadith.text, // translation in the source language
        });

        // Add target language translation
        translationsData.push({
          conventionalId: hadith.conventionalId, // conventional id of the hadith
          language: targetLanguageCodeValue, // target language code
          translation: hadith.translatedText, // translation in the target language
        });

        // Add second target language translation
        if (hadith.secondTranslationText !== "") {
          translationsData.push({
            conventionalId: hadith.conventionalId, // conventional id of the hadith
            language: secondTargetLanguageCodeValue, // second target language code
            translation: hadith.secondTranslationText, // translation in the second target language
          });
        }

        // Add hadith to the hadiths payload
        body.hadiths.push({
          hadithbookId: hadithBookIdValue, // hadith book id
          conventionalId: hadith.conventionalId, // conventional id of the hadith
          translations: translationsData, // translations of the hadith
        });
      }
    });

    post(moduleHadith, body)
      .then((_res) => {
        setLoading(false); // hide loader
        handleClose();
        // @ts-ignore
        notifications.current.successAlert(t("common.createSuccess"), "");

        setControls((prevState) => ({
          ...prevState,
          hasBeenTranslated: false,
        }));

        // resets all hadith entries after submission
        resetAllHadithEntries();
      })
      .catch((_err) => {
        log(_err);
        setLoading(false); // hide loader
        // @ts-ignore
        notifications.current.errorAlert(
          t("common.somethingWentWrong"),
          t("common.tryAgain")
        );
      });
  };

  const handleScanCompleteEvent = (_data) => {
    try {
      const { text, hadithIndex } = _data;

      if (hadithIndex !== null) {
        const hadithsCopy = [...fields];
        hadithsCopy[hadithIndex].text = hadithsCopy[hadithIndex].text + text;
        // setData(prevState => ({ ...prevState, hadiths: hadithsCopy }))
        setControls((prevState) => ({ ...prevState, hadiths: hadithsCopy }));

        notifications.current.successAlert(
          t("common.success"),
          "Text Extracted Successfully"
        );
      }
    } catch (_err) {
      console.log(_err.message);
      notifications.current.errorAlert(
        t("common.somethingWentWrong"),
        "Error extracting text: " + _err.message
      );
    }
  };

  const handleTextAdd = (textToAdd, index, fieldName) => {
    const fieldId = `hadiths.${index}.${fieldName}`;
    const input = document.querySelector(`[name="${fieldId}"]`);
    if (input) {
      const startPos = input.selectionStart;
      const endPos = input.selectionEnd;
      const currentText = input.value;
      const newText =
        currentText.substring(0, startPos) +
        textToAdd +
        currentText.substring(endPos, currentText.length);

      // Update the value in the form state
      setValue(fieldId, newText);

      // Set the cursor position after the inserted text
      input.focus();
      input.setSelectionRange(
        startPos + textToAdd.length,
        startPos + textToAdd.length
      );
    }
  };

  const [direction, setDirection] = useState("ltr");

  // Function to determine the direction based on language code
  const getDirection = (lang) => {
    const rtlLanguages = ["urd", "arb"];
    return rtlLanguages.includes(lang) ? "rtl" : "ltr";
  };

  const handleInputChange = (value) => {
    const lang = franc(value);
    const newDirection = getDirection(lang);
    setDirection(newDirection);
  };

  return (
    <Box
      className=""
      sx={{
        mt: 6,
        display: "flex",
        minHeight: "98vh",
        backgroundColor: "rgba(222, 223, 223, 0.3)",
      }}
    >
      <CssBaseline />

      {/* Header */}
      <Header />

      {/* Sider */}
      <Sider />

      {/* Content */}
      <Box component="main" sx={{ flexGrow: 1, mt: 5, px: 4 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>
                Translation Submission
              </Typography>
              <Typography variant="overline">
                Submit Hadith by filling out the information below.
              </Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Controller
                name="hadithBookId"
                control={control}
                rules={{ required: "Hadith book is required" }}
                render={({ field, fieldState }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    error={!!fieldState.error}
                  >
                    <InputLabel>Hadith Book</InputLabel>
                    <Select {...field} label="Hadith Book">
                      <MenuItem value="">
                        <em>Select Hadith Book</em>
                      </MenuItem>
                      {allHadithBooks?.map((book) => (
                        <MenuItem key={book.id} value={book.id}>
                          {book.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {fieldState.error && (
                      <FormHelperText>
                        {fieldState.error.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Controller
                name="sourceLanguageCode"
                control={control}
                rules={{ required: "Source Language is required" }}
                render={({ field, fieldState }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    error={!!fieldState.error}
                  >
                    <InputLabel>Source Language</InputLabel>
                    <Select {...field} label="Source Language">
                      <MenuItem value="">
                        <em>Source Language</em>
                      </MenuItem>
                      {languages?.map((el) => (
                        <MenuItem key={el.key} value={el.key}>
                          {el.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {fieldState.error && (
                      <FormHelperText>
                        {fieldState.error.message}
                      </FormHelperText>
                    )}
                    {watch("targetLanguageCode") === field.value && (
                      <FormHelperText error>
                        This should be different from Target Language
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Controller
                name="targetLanguageCode"
                control={control}
                rules={{
                  required: controls?.hasBeenTranslated
                    ? "Target Language is required"
                    : false,
                }}
                render={({ field, fieldState }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    error={!!fieldState.error}
                  >
                    <InputLabel>Target Translation Language</InputLabel>
                    <Select {...field} label="Target Translation Language">
                      <MenuItem value="">
                        <em>Target Translation Language</em>
                      </MenuItem>
                      {languages?.map((el) => (
                        <MenuItem key={el.key} value={el.key}>
                          {el.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {fieldState.error && (
                      <FormHelperText>
                        {fieldState.error.message}
                      </FormHelperText>
                    )}
                    {watch("sourceLanguageCode") === field.value && (
                      <FormHelperText error>
                        This should be different from Source Language
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>

            {/* <Grid item xs={12}>
              <FormControlLabel
                label="Translate Second Language"
                control={
                  <Checkbox
                  checked={isSecondTranslationLanguageValue}
                  onChange={(_event) => {
                      setValue("isSecondTranslationLanguage", _event.target.checked); // Set the value using setValue
                    }}
                  />
                }
              />
            </Grid> */}

            {isSecondTranslationLanguageValue && (
              <Grid item xs={12} md={4}>
                <Controller
                  name="secondTargetLanguageCode"
                  control={control}
                  rules={{
                    required: controls?.hasBeenTranslated
                      ? "Second Target Language is required"
                      : false,
                  }}
                  render={({ field, fieldState }) => (
                    <FormControl
                      fullWidth
                      variant="outlined"
                      error={!!fieldState.error}
                    >
                      <InputLabel>
                        Second Target Translation Language
                      </InputLabel>
                      <Select
                        {...field}
                        label="Second Target Translation Language"
                      >
                        <MenuItem value="">
                          <em>Second Target Translation Language</em>
                        </MenuItem>
                        {languages?.map((el) => (
                          <MenuItem key={el.key} value={el.key}>
                            {el.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {fieldState.error && (
                        <FormHelperText>
                          {fieldState.error.message}
                        </FormHelperText>
                      )}
                      {sourceLanguageCodeValue === field.value && (
                        <FormHelperText error>
                          This should be different from Source Language
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
            )}

            {hadithBookIdValue &&
              sourceLanguageCodeValue &&
              targetLanguageCodeValue && (
                <>
                  <Grid item xs={12}>
                    <Typography className="mt-4 text-muted">
                      Type Hadiths in the given Text Boxes below
                    </Typography>
                  </Grid>

                  {fields.map((field, index) => (
                    <Grid item xs={12} key={field.id}>
                      <Paper variant="outlined" className="p-md-3 p-sm-1">
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography variant="subtitle2">
                              # {index + 1}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} md={2}>
                            <Controller
                              control={control}
                              name={`hadiths.${index}.conventionalId`}
                              rules={{
                                required: "This field is required",
                                pattern: {
                                  value: /^\d+$/,
                                  message:
                                    "This field should only contain numbers",
                                },
                                validate: (value) =>
                                  value.trim() !== "" ||
                                  "This field cannot be an empty string",
                              }}
                              render={({ field, fieldState }) => (
                                <TextField
                                  {...field}
                                  label="Hadith ID"
                                  error={!!fieldState.error}
                                  helperText={
                                    fieldState.error
                                      ? fieldState.error.message
                                      : null
                                  }
                                  fullWidth
                                />
                              )}
                            />
                          </Grid>

                          <Grid item xs={12} md={4} className="ms-auto">
                            <ImageCapture
                              hadithIndex={index}
                              scanCompleteEvent={handleScanCompleteEvent}
                            />
                          </Grid>
                        </Grid>

                        {watch(`hadiths.${index}.conventionalId`) && (
                          <>
                            <Grid item xs={12} className="mt-4">
                              <Controller
                                control={control}
                                name={`hadiths.${index}.text`}
                                rules={{ required: "This field is required" }}
                                render={({ field, fieldState }) => (
                                  <TextField
                                    {...field}
                                    value={field?.value}
                                    label={`Text in ${getLanguageLabel(
                                      sourceLanguageCodeValue
                                    )}`}
                                    multiline
                                    minRows={4}
                                    error={!!fieldState.error}
                                    helperText={
                                      fieldState.error
                                        ? fieldState.error.message
                                        : null
                                    }
                                    fullWidth
                                    onChange={(e) => {
                                      field.onChange(e);
                                      handleInputChange(e.target.value);
                                    }}
                                    InputProps={{
                                      style: { direction },
                                    }}
                                  />
                                )}
                              />
                              <Button
                                variant="text"
                                size="small"
                                disableRipple
                                onClick={() =>
                                  handleTextAdd("ﷺ", index, "text")
                                }
                              >
                                ﷺ
                              </Button>

                              <Button
                                variant="text"
                                size="small"
                                disableRipple
                                onClick={() =>
                                  handleTextAdd("رحمة الله عليه", index, "text")
                                }
                              >
                                رحمة الله عليه
                              </Button>

                              <Button
                                variant="text"
                                size="small"
                                disableRipple
                                onClick={() =>
                                  handleTextAdd(
                                    "رضي الله عنها  ",
                                    index,
                                    "text"
                                  )
                                }
                              >
                                رضي الله عنها
                              </Button>

                              <Button
                                variant="text"
                                size="small"
                                disableRipple
                                onClick={() =>
                                  handleTextAdd("رضي الله عنه", index, "text")
                                }
                              >
                                رضي الله عنه
                              </Button>

                              <Button
                                variant="text"
                                size="small"
                                disableRipple
                                onClick={() =>
                                  handleTextAdd("عَلَيْهِ ٱلسَّلَامُ", index, "text")
                                }
                              >
                                عَلَيْهِ ٱلسَّلَامُ
                              </Button>
                            </Grid>
                            <Grid item xs={12} className="mt-4">
                              <Controller
                                control={control}
                                name={`hadiths.${index}.translatedText`}
                                rules={{
                                  required: controls?.hasBeenTranslated
                                    ? "This field is required"
                                    : false,
                                }}
                                render={({ field, fieldState }) => (
                                  <TextField
                                    {...field}
                                    value={field?.value}
                                    label={`Text in ${getLanguageLabel(
                                      targetLanguageCodeValue
                                    )}`}
                                    disabled={!controls.hasBeenTranslated}
                                    multiline
                                    minRows={4}
                                    error={!!fieldState.error}
                                    helperText={
                                      fieldState.error
                                        ? fieldState.error.message
                                        : null
                                    }
                                    fullWidth
                                  />
                                )}
                              />
                              <Button
                                variant="text"
                                size="small"
                                disableRipple
                                onClick={() =>
                                  handleTextAdd("ﷺ", index, "translatedText")
                                }
                              >
                                ﷺ
                              </Button>

                              <Button
                                variant="text"
                                size="small"
                                disableRipple
                                onClick={() =>
                                  handleTextAdd("رحمة الله عليه", index, "translatedText")
                                }
                              >
                                رحمة الله عليه
                              </Button>

                              <Button
                                variant="text"
                                size="small"
                                disableRipple
                                onClick={() =>
                                  handleTextAdd("رضي الله عنها  ", index, "translatedText")
                                }
                              >
                                رضي الله عنها
                              </Button>

                              <Button
                                variant="text"
                                size="small"
                                disableRipple
                                onClick={() =>
                                  handleTextAdd("رضي الله عنه", index, "translatedText")
                                }
                              >
                                رضي الله عنه
                              </Button>

                              <Button
                                variant="text"
                                size="small"
                                disableRipple
                                onClick={() =>
                                  handleTextAdd("عَلَيْهِ ٱلسَّلَامُ", index, "translatedText")
                                }
                              >
                                عَلَيْهِ ٱلسَّلَامُ
                              </Button>
                            </Grid>

                            {secondTargetLanguageCodeValue &&
                              isSecondTranslationLanguageValue && (
                                <Grid item xs={12} className="mt-4">
                                  <Controller
                                    control={control}
                                    name={`hadiths.${index}.secondTranslationText`}
                                    rules={{
                                      required: "This field is required",
                                    }}
                                    render={({ field, fieldState }) => (
                                      <TextField
                                        {...field}
                                        label={`Text in ${secondTargetLanguageCodeValue}`}
                                        disabled={!controls.hasBeenTranslated}
                                        multiline
                                        minRows={4}
                                        error={!!fieldState.error}
                                        helperText={
                                          fieldState.error
                                            ? fieldState.error.message
                                            : null
                                        }
                                        fullWidth
                                      />
                                    )}
                                  />
                                </Grid>
                              )}
                          </>
                        )}
                      </Paper>
                      <div className="d-flex mt-1 mb-2">
                        <Button
                          className="ms-auto"
                          variant="outlined"
                          color="error"
                          onClick={() => remove(index)}
                          startIcon={<FontAwesomeIcon icon="trash" />}
                        >
                          Remove
                        </Button>
                      </div>
                    </Grid>
                  ))}

                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      type="button"
                      onClick={addHadith}
                      disabled={fields?.length >= 20}
                      startIcon={<FontAwesomeIcon icon="plus" />}
                    >
                      Add Hadith
                    </Button>
                  </Grid>
                </>
              )}
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12}>
              {hadithBookIdValue &&
                sourceLanguageCodeValue &&
                targetLanguageCodeValue && (
                  <Box
                    sx={{
                      zIndex: 1000,
                      position: "sticky",
                      bottom: 0,
                      left: 0,
                      right: 0,
                      // boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.1)",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      p: 2,
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="medium"
                      onClick={handleTranslate}
                      sx={{
                        mx: 1,
                        textTransform: "none",
                        borderRadius: "8px",
                        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                        transition: "transform 0.2s ease-in-out",
                        "&:hover": {
                          transform: "scale(1.05)",
                          boxShadow: "0 6px 16px rgba(0, 0, 0, 0.15)",
                        },
                      }}
                      disabled={
                        controls?.isTranslating ||
                        !sourceLanguageCodeValue ||
                        !targetLanguageCodeValue ||
                        (isSecondTranslationLanguageValue &&
                          !secondTargetLanguageCodeValue) ||
                        watch("sourceLanguageCode") ===
                          watch("targetLanguageCode") ||
                        watch("sourceLanguageCode") ===
                          watch("secondTargetLanguageCode") ||
                        watch("targetLanguageCode") ===
                          watch("secondTargetLanguageCode")
                      }
                      endIcon={
                        controls?.isTranslating ? (
                          <CircularProgress
                            size="1em"
                            sx={{ color: "white !important" }}
                          />
                        ) : null
                      }
                    >
                      {controls?.isTranslating ? "Translating" : "Translate"}
                    </Button>

                    {controls?.hasBeenTranslated && (
                      <Button
                        variant="contained"
                        color="secondary"
                        size="medium"
                        onClick={handleReview}
                        disabled={
                          watch("sourceLanguageCode") ===
                            watch("targetLanguageCode") ||
                          watch("sourceLanguageCode") ===
                            watch("secondTargetLanguageCode") ||
                          watch("targetLanguageCode") ===
                            watch("secondTargetLanguageCode")
                        }
                        sx={{
                          mx: 1,
                          textTransform: "none",
                          borderRadius: "8px",
                          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                          transition: "transform 0.2s ease-in-out",
                          "&:hover": {
                            transform: "scale(1.05)",
                            boxShadow: "0 6px 16px rgba(0, 0, 0, 0.15)",
                          },
                        }}
                      >
                        Review
                      </Button>
                    )}
                  </Box>
                )}
            </Grid>
          </Grid>

          {/* Alerts */}
          <AlertM ref={notifications} />
        </form>
      </Box>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 0,
        }}
      >
        <Fade in={open}>
          <Box>
            <Card
              className="px-md-4 px-sm-1"
              className={classes.fullScrnCard}
              variant="outlined"
            >
              <CardContent
                className={classes.fullScrnCardConten + " px-sm-0 px-md-2"}
                sx={{ overflowY: "auto", flexGrow: 1 }}
              >
                <div className="col-12 px-sm-2">
                  <Typography style={{ fontWeight: "bold", fontSize: "2em" }}>
                    Review
                  </Typography>
                  <Typography className="mb-2 text-muted">
                    Please review before confirming
                  </Typography>
                  <Divider light />

                  <div className="mt-3 p-2">
                    <div className="mb-2 d-flex">
                      <Typography
                        className="my-auto"
                        sx={{ fontWeight: "bold", fontSize: "0.8em" }}
                      >
                        Book
                      </Typography>
                      <span className="mx-2">{fields?.hadithBookName}</span>
                    </div>

                    <div className="mb-2 d-flex">
                      <Typography
                        className="my-auto"
                        sx={{ fontWeight: "bold", fontSize: "0.8em" }}
                      >
                        Language
                      </Typography>
                      <span className="mx-2">{sourceLanguageCodeValue}</span>
                    </div>
                  </div>
                </div>

                <div className="my-3">
                  {fields?.map((hadithData: any, hadithIndex: any) => (
                    <>
                      {hadithData?.text !== "" && (
                        <div className="rounded p-3 mb-3">
                          <div className="">
                            <Typography
                              className="my-auto"
                              sx={{ fontWeight: "bold", fontSize: "0.8em" }}
                            >
                              Hadith ID
                            </Typography>

                            <div className="">{hadithData?.conventionalId}</div>
                          </div>
                          <Divider />

                          <div className="mt-2">
                            <div className="mb-2">
                              <Typography
                                className="my-auto"
                                sx={{ fontWeight: "bold", fontSize: "0.8em" }}
                              >
                                Language
                              </Typography>

                              <div className="" style={{ fontSize: "0.9em" }}>
                                {sourceLanguageCodeValue}
                              </div>
                            </div>

                            <div className="mb-4">
                              <Typography
                                className="my-auto"
                                sx={{ fontWeight: "bold", fontSize: "0.8em" }}
                              >
                                Hadith
                              </Typography>

                              <div className="">{hadithData?.text}</div>
                            </div>
                          </div>

                          <Divider />

                          <div className="mt-2">
                            <div className="mb-2">
                              <Typography
                                className="my-auto"
                                sx={{ fontWeight: "bold", fontSize: "0.8em" }}
                              >
                                Language
                              </Typography>

                              <div className="" style={{ fontSize: "0.9em" }}>
                                {targetLanguageCodeValue}
                              </div>
                            </div>

                            <div className="mb-4">
                              <div>
                                <Typography
                                  className="my-auto"
                                  sx={{ fontWeight: "bold", fontSize: "0.8em" }}
                                >
                                  Hadith
                                </Typography>
                              </div>

                              <div className="">
                                {hadithData?.translatedText}
                              </div>
                            </div>
                          </div>

                          {secondTargetLanguageCodeValue && (
                            <div>
                              <Divider />

                              <div className="mt-2">
                                <div className="mb-2">
                                  <Typography
                                    className="my-auto"
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: "0.8em",
                                    }}
                                  >
                                    Language
                                  </Typography>

                                  <div
                                    className=""
                                    style={{ fontSize: "0.9em" }}
                                  >
                                    {secondTargetLanguageCodeValue}
                                  </div>
                                </div>

                                <div className="mb-4">
                                  <div>
                                    <Typography
                                      className="my-auto"
                                      sx={{
                                        fontWeight: "bold",
                                        fontSize: "0.8em",
                                      }}
                                    >
                                      Hadith
                                    </Typography>
                                  </div>

                                  <div className="">
                                    {hadithData?.secondTranslationText}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  ))}
                </div>
              </CardContent>

              <CardActions
                className={
                  classes.fullScrnCardContent + " bordered mt-auto d-flex"
                }
              >
                <div
                  className="py-2 px-md-4 px-2 "
                  style={{ width: "100%", textAlign: "right" }}
                >
                  <Button
                    onClick={handleClose}
                    variant="outlined"
                    className={"secondary"}
                  >
                    Cancel
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    variant="contained"
                    color="success"
                    disabled={loading}
                    onClick={submitData}
                    className={""}
                  >
                    {loading ? "Submitting..." : "Submit"}
                  </Button>
                </div>
              </CardActions>
            </Card>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
}

export default HadithTranslationMain;
