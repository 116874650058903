// @ts-nocheck
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useStyles } from "./Styles";
import "../../../../../index.scss";
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import {
  getAll,
  getAllFiltered,
} from "../../../../../Services/GenericApiService";
import { useTranslation } from "react-i18next";
import AlertM from "../../../../Helpers/AlertM/AlertM";
import { log } from "../../../../../Services/LoggerService";
import {
  CssBaseline,
  Box,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  FormHelperText,
  TextField,
  Tooltip,
  IconButton,
  useMediaQuery,
  useTheme,
  Drawer,
  Typography,
  Chip,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import { Button, Skeleton } from "@mui/material";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HadithView from "../HadithView/HadithView";
import HadithUpdate from "../HadithUpdate/HadithUpdate";
import { Tabs, Tab } from "@mui/material";
import {
  getLoggedInUserRole,
  getUserData,
} from "../../../../../Services/LocalStorageService";
import { useNavigate, useLocation } from "react-router-dom";
import FilterListIcon from "@mui/icons-material/FilterList";
import CloseIcon from "@mui/icons-material/Close";

// font awesome stuff
const iconList = Object.keys(Icons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon) => Icons[icon]);

library.add(...iconList);

// Function to truncate text
const TruncatedText = ({ text, maxLength = 100 }) => {
  const truncated =
    text?.length > maxLength ? text.slice(0, maxLength) + "..." : text;

  return (
    <Tooltip
      title={
        <div style={{ whiteSpace: "pre-wrap", maxWidth: "400px" }}>
          {text || "N/A"}
        </div>
      }
      arrow
      placement="top"
    >
      <span>{truncated || "N/A"}</span>
    </Tooltip>
  );
};

function HadithReviewMain() {
  // css for module
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));

  // Router
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // translation
  const { t } = useTranslation();

  // child ref for alert
  const notifications = useRef();

  // module(s) for api
  const moduleMain = "hadith";
  const moduleBooks = "hadithbook";
  const moduleDataEntryClerks = "user/allclerks";

  // data vars
  const [allData, setAllData] = useState([]);
  const [dataToEdit, setDataToEdit] = useState({});
  const [tabValue, setTabValue] = useState("");
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [allHadithBooks, setAllHadithBooks] = useState([]);
  const [controls, setControls] = useState({
    isRejectEvent: false,
    isConfirmEvent: false,
    isResolveEvent: false,
    editMode: false,
  });

  const currentUser = getUserData();

  // Get page and size from URL query params, or use defaults
  const initialPage = parseInt(queryParams.get("page")) || 0;
  const initialSize = parseInt(queryParams.get("size")) || 25;
  const initialTab = queryParams.get("tab") || "";

  // table ui controls
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [pageSize, setPageSize] = useState(initialSize);
  const [narratorLoading, setNarratorLoading] = useState(false);

  const [params, setParams] = useState({
    toBeReviewed: false,
    toBeApproved: false,
    toBeQC: false,
    verified: false,
    qcRejected: false,
    reviewRejected: false,
    hadithStatus: null,
    addedByUser: null,
    hadithbookId: queryParams.get("hadithbookId") || null,
    order: queryParams.get("order") || "ASC",
    startId: queryParams.get("startId") || null,
    endId: queryParams.get("endId") || null,
    systemId: queryParams.get("systemId") || null,
  });

  // ui controls
  const [loading, setLoading] = useState(true);
  const [userRole, setUserRole] = useState("");
  const [dataEntryClerks, setAllDataEntryClerks] = useState([]);
  const [selectedDataEntryClerks, setSelectedDataEntryClerks] = useState([]);
  const [debouncedParams, setDebouncedParams] = useState(params);

  const orders = ["ASC", "DESC"];

  const roleTabMappings = {
    admin: [
      { value: 0, key: "verified", label: "Verified" },
      { value: 1, key: "toBeQc", label: "To be QC" },
      { value: 2, key: "qcRejected", label: "QC Rejected" },
      { value: 3, key: "toBeReviewed", label: "To be Reviewed" },
      { value: 4, key: "reviewRejected", label: "Review Rejected" },
      { value: 5, key: "toBeApproved", label: "To Be Approved" },
      { value: 6, key: "zaeefHadith", label: "Zaeef Hadith" },
    ],
    manager: [
      { value: 0, key: "verified", label: "Verified" },
      { value: 1, key: "toBeQc", label: "To be QC" },
      { value: 2, key: "qcRejected", label: "QC Rejected" },
      { value: 3, key: "toBeReviewed", label: "To be Reviewed" },
      { value: 4, key: "reviewRejected", label: "Review Rejected" },
      { value: 5, key: "toBeApproved", label: "To Be Approved" },
    ],
    qc: [
      { value: 0, key: "verified", label: "Verified" },
      { value: 1, key: "toBeQc", label: "To be QC" },
      { value: 6, key: "zaeefHadith", label: "Zaeef Hadith" },
    ],
    teacher: [
      { value: 0, key: "verified", label: "Verified" },
      { value: 2, key: "qcRejected", label: "QC Rejected" },
      { value: 3, key: "toBeReviewed", label: "To be Reviewed" },
      { value: 6, key: "zaeefHadith", label: "Zaeef Hadith" },
    ],
    student: [
      { value: 4, key: "reviewRejected", label: "Review Rejected" },
      { value: 5, key: "toBeApproved", label: "To Be Approved" },
      { value: 6, key: "zaeefHadith", label: "Zaeef Hadith" },
    ],
    dataentryclerk: [
      { value: 7, key: "last24HoursHadith", label: "Added in last 24 hours" },
    ],
  };

  // Update URL with current state
  const updateURL = useCallback(() => {
    const newParams = new URLSearchParams();

    // Add pagination params
    newParams.set("page", currentPage.toString());
    newParams.set("size", pageSize.toString());

    // Add tab param
    if (tabValue) {
      newParams.set("tab", tabValue);
    }

    // Add filter params
    if (params.hadithbookId) newParams.set("hadithbookId", params.hadithbookId);
    if (params.order) newParams.set("order", params.order);
    if (params.startId) newParams.set("startId", params.startId);
    if (params.endId) newParams.set("endId", params.endId);
    if (params.systemId) newParams.set("systemId", params.systemId);

    navigate(`?${newParams.toString()}`, { replace: true });
  }, [navigate, currentPage, pageSize, tabValue, params]);

  const handleTabChange = (event, selectedTab) => {
    setTabValue(selectedTab);

    if (selectedTab) {
      let updatedControls = {
        toBeReviewed: false,
        toBeApproved: false,
        toBeQC: false,
        verified: false,
        qcRejected: false,
        reviewRejected: false,
        hadithStatus: "",
        addedByUser: null,
      };

      switch (selectedTab) {
        case "verified":
          updatedControls.verified = true;
          break;
        case "toBeQc":
          updatedControls.toBeQC = true;
          break;
        case "qcRejected":
          updatedControls.qcRejected = true;
          break;
        case "toBeReviewed":
          updatedControls.toBeReviewed = true;
          break;
        case "reviewRejected":
          updatedControls.reviewRejected = true;
          break;
        case "toBeApproved":
          updatedControls.toBeApproved = true;
          break;
        case "zaeefHadith":
          updatedControls.hadithStatus = "zaeef";
          break;
        case "last24HoursHadith":
          updatedControls.toBeApproved = true;
          updatedControls.addedByUser = currentUser.id;
          break;
        default:
          break;
      }
      setParams((prev) => ({ ...prev, ...updatedControls }));

      // Reset pagination when changing tabs
      setCurrentPage(0);
    }
  };

  let columns = [
    {
      name: "System ID",
      options: {
        filterOptions: { fullWidth: true },
        setCellProps: () => ({
          style: {
            maxWidth: "80px",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        }),
      },
    },
    {
      name: "Hadith #",
      options: {
        filterOptions: { fullWidth: true },
        setCellProps: () => ({
          style: {
            maxWidth: "100px",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        }),
      },
    },
    {
      name: "Translation",
      options: {
        filterOptions: { fullWidth: true },
        customBodyRender: (value) => (
          <TruncatedText text={value} maxLength={isMobile ? 50 : 100} />
        ),
        setCellProps: () => ({
          style: { maxWidth: isMobile ? "150px" : "300px", overflow: "hidden" },
        }),
      },
    },
    {
      name: "Book",
      options: {
        filterOptions: { fullWidth: true },
        customBodyRender: (value) => (
          <TruncatedText text={value} maxLength={30} />
        ),
        setCellProps: () => ({
          style: {
            maxWidth: "120px",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        }),
      },
    },
    {
      name: "Added By",
      options: {
        filterOptions: { fullWidth: true },
        display: !isMobile,
        setCellProps: () => ({
          style: {
            maxWidth: "120px",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        }),
      },
    },
    {
      name: "Approved By",
      options: {
        filterOptions: { fullWidth: true },
        display: !isMobile && !isTablet,
        setCellProps: () => ({
          style: {
            maxWidth: "120px",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        }),
      },
    },
    {
      name: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellHeaderProps: () => {
          return { align: "center" };
        },
        setCellProps: () => ({
          align: "left",
          style: { minWidth: isMobile ? "120px" : "250px" },
        }),
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div
              className={
                isMobile ? "d-flex flex-column gap-2" : "d-inline-flex"
              }
            >
              <div className={isMobile ? "mt-2" : "ms-2 mt-2"}>
                {(tabValue == "toBeReviewed" ||
                  tabValue == "toBeQc" ||
                  tabValue == "toBeApproved" ||
                  tabValue == "zaeefHadith") && (
                  <Button
                    variant="contained"
                    color="success"
                    size="small"
                    style={{
                      height: "40px",
                      width: isMobile ? "100%" : "100px",
                      fontSize: "0.75rem",
                    }}
                    onClick={() => {
                      onEditClick(dataIndex, rowIndex, tabValue);
                    }}
                  >
                    Review
                  </Button>
                )}
              </div>

              <div className={isMobile ? "mt-2" : "ms-2 mt-2"}>
                {(tabValue == "toBeReviewed" ||
                  tabValue == "toBeQc" ||
                  (tabValue == "verified" && userRole == "admin")) && (
                  <Button
                    variant="contained"
                    color="error"
                    size="small"
                    style={{
                      height: "40px",
                      width: isMobile ? "100%" : "100px",
                      fontSize: "0.75rem",
                    }}
                    onClick={() => {
                      onRejectClick(dataIndex, rowIndex, tabValue);
                    }}
                  >
                    Reject
                  </Button>
                )}
              </div>
              <div className={isMobile ? "mt-2" : "ms-2 mt-2"}>
                {tabValue == "verified" && userRole == "admin" && (
                  <div className="px-2">
                    {allData && <HadithView data={allData[rowIndex]} />}
                  </div>
                )}
              </div>

              <div className={isMobile ? "mt-2" : "ms-2 mt-2"}>
                {(tabValue == "qcRejected" ||
                  tabValue == "reviewRejected" ||
                  tabValue == "last24HoursHadith") && (
                  <Button
                    variant="contained"
                    size="small"
                    style={{
                      height: "40px",
                      width: isMobile ? "100%" : "100px",
                      fontSize: "0.75rem",
                    }}
                    onClick={() => {
                      onResolveClick(dataIndex, rowIndex, tabValue);
                    }}
                  >
                    {tabValue == "last24HoursHadith" ? "Update" : "Resolve"}
                  </Button>
                )}
              </div>
            </div>
          );
        },
      },
    },
  ];

  // fetch dependent data
  const fetch = useCallback(() => {
    setTableData([]); // reset table data
    setLoading(true); // show loader

    const {
      toBeReviewed,
      toBeQC,
      reviewRejected,
      qcRejected,
      verified,
      toBeApproved,
      hadithStatus,
      addedByUser,
      hadithbookId,
      order,
      startId,
      endId,
      systemId,
    } = params;

    let url = `${moduleMain}?page=${currentPage}&size=${pageSize}`;

    url = toBeReviewed ? url + "&isToBeReviewed=" + toBeReviewed : url;
    url = toBeQC ? url + "&isToBeQC=" + toBeQC : url;
    url = reviewRejected ? url + "&isReviewRejected=" + reviewRejected : url;
    url = qcRejected ? url + "&isQcRejected=" + qcRejected : url;
    url = verified ? url + "&isVerified=" + verified : url;
    url = toBeApproved ? url + "&isToBeApproved=" + toBeApproved : url;
    url = hadithStatus ? url + "&hadithStatus=" + hadithStatus : url;
    url = addedByUser ? url + "&addedBy=" + addedByUser : url;
    url = hadithbookId ? url + "&hadithbookId=" + hadithbookId : url;
    url =
      selectedDataEntryClerks?.length > 0
        ? url + "&dataentryclerks=" + selectedDataEntryClerks.join(",")
        : url;
    url = url + "&order=" + order;
    url = startId ? url + "&startId=" + startId : url;
    url = endId ? url + "&endId=" + endId : url;
    url = systemId ? url + "&systemId=" + systemId : url;

    getAllFiltered(url)
      .then((_res) => {
        // set total count
        setTotalRowCount(_res.count);

        const rows = [];

        _res.rows.forEach((translationRecord) => {
          rows.push([
            translationRecord?.id,
            translationRecord.conventionalId || "-",
            translationRecord.translation || "-",
            translationRecord?.Hadiths?.book?.name || "-",
            (translationRecord.translationAddedBy?.firstName ?? "") +
              " " +
              (translationRecord.translationAddedBy?.lastName ?? ""),
            (translationRecord.translationApprovedBy?.firstName ?? "") +
              " " +
              (translationRecord.translationApprovedBy?.lastName ?? ""),
          ]);
        });

        const allTranslations = _res.rows.map((hadithRecord) => {
          return {
            ...hadithRecord,
            conventionalId: hadithRecord?.conventionalId,
            status: hadithRecord?.Hadiths?.status,
            hadithbookId: hadithRecord?.Hadiths?.hadithbookId,
            narratorId: hadithRecord?.Hadiths?.narratorId,
          };
        });

        setAllData(allTranslations);
        setTableData(rows);
        setLoading(false); // hide loader

        // Update URL with current state
        updateURL();
      })
      .catch((_err) => {
        log(_err);
        setLoading(false); // hide loader
        notifications.current?.errorAlert(
          "Error fetching data",
          "Please try again or contact support if the problem persists."
        );
      });
  }, [currentPage, pageSize, params, selectedDataEntryClerks, updateURL]);

  // Debounce params
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedParams(params);
    }, 500);

    return () => clearTimeout(timer);
  }, [params]);

  useEffect(() => {
    const currentUser = getUserData();
    setUserRole(currentUser.roleKey);

    // Set tab from URL or default to "toBeReviewed"
    const defaultTabKey = getDefaultTabForRole(currentUser.roleKey);
    const tabFromUrl = initialTab || defaultTabKey;

    // Only set tab if not already set
    if (!tabValue) {
      setTabValue(tabFromUrl);
      handleTabChange(null, tabFromUrl);
    }
  }, []);

  useEffect(() => {
    if (tabValue) {
      fetch();
    }
  }, [
    debouncedParams,
    currentPage,
    pageSize,
    selectedDataEntryClerks,
    tabValue,
    fetch,
  ]);

  useEffect(() => {
    fetchHadithBooks();
    fetchAllDataEntryClerks();
  }, []);

  // Find the tab key that corresponds to "toBeReviewed" or the first available tab
  const getDefaultTabForRole = (role) => {
    const roleTabs = roleTabMappings[role] || [];

    // Try to find "toBeReviewed" tab
    const toBeReviewedTab = roleTabs.find((tab) => tab.key === "toBeReviewed");

    // Return "toBeReviewed" if available, otherwise the first tab
    return toBeReviewedTab
      ? toBeReviewedTab.key
      : roleTabs.length > 0
      ? roleTabs[0].key
      : "";
  };

  const fetchAllDataEntryClerks = () => {
    getAll(moduleDataEntryClerks)
      .then((_res) => {
        setAllDataEntryClerks(_res);
      })
      .catch((_err) => {
        log(_err);
        notifications.current?.errorAlert(
          "Error fetching data entry clerks",
          "Please try again later."
        );
      });
  };

  const fetchHadithBooks = () => {
    getAll(moduleBooks)
      .then((_res) => {
        setAllHadithBooks(_res);
      })
      .catch((_err) => {
        log(_err);
        notifications.current?.errorAlert(
          "Error fetching hadith books",
          "Please try again later."
        );
      });
  };

  const [visitedRecords, setVisitedRecords] = useState([]);

  const handleNextRecord = () => {
    setVisitedRecords((prevVisitedRecords) => {
      const updatedVisitedRecords = [...prevVisitedRecords, dataToEdit.id];
      return updatedVisitedRecords;
    });

    setVisitedRecords((prevVisitedRecords) => {
      const remainingdata = allData.filter(
        (record) => !prevVisitedRecords.includes(record.id)
      );

      if (remainingdata.length > 0) {
        setDataToEdit(remainingdata[0]);
        setControls((prevControls) => ({
          ...prevControls,
          editMode: true,
          isConfirmEvent: true,
          isRejectEvent: false,
          isResolveEvent: false,
        }));
      } else {
        fetch();
      }
      return prevVisitedRecords;
    });
  };

  // event handlers
  const onEditClick = (_dataIndex, _rowIndex, tabValue) => {
    const dataToEdit = allData[_dataIndex];
    setDataToEdit(dataToEdit);
    setControls((prevState) => ({
      ...prevState,
      editMode: true,
      isConfirmEvent: true,
      isRejectEvent: false,
      isResolveEvent: false,
    }));
  };

  const onRejectClick = (_dataIndex, _rowIndex, tabValue) => {
    setDataToEdit(allData[_dataIndex]);

    setControls((prevState) => ({
      ...prevState,
      editMode: true,
      isConfirmEvent: false,
      isRejectEvent: true,
      isResolveEvent: false,
    }));
  };

  const onResolveClick = (_dataIndex, _rowIndex, tabValue) => {
    setDataToEdit(allData[_dataIndex]);

    setControls((prevState) => ({
      ...prevState,
      editMode: true,
      isConfirmEvent: false,
      isRejectEvent: false,
      isResolveEvent: true,
    }));
  };

  const resetEdit = () => {
    setControls((prevState) => ({
      ...prevState,
      editMode: false,
    }));
  };

  const clearAllFilters = () => {
    setParams((prevState) => ({
      ...prevState,
      hadithbookId: null,
      order: "ASC",
      startId: null,
      endId: null,
      systemId: null,
    }));

    setSelectedDataEntryClerks([]);

    if (isMobile) {
      setFilterDrawerOpen(false);
    }
  };

  const options = {
    search: true,
    download: false,
    print: false,
    viewColumns: !isMobile,
    filter: !isMobile,
    filterType: "dropdown",
    responsive: "standard",
    selectableRowsHideCheckboxes: true,
    sort: true,
    onChangePage: (currentPage) => {
      setCurrentPage(currentPage);
    },
    onChangeRowsPerPage: (numberOfRows) => {
      setPageSize(numberOfRows);
      setCurrentPage(0); // Reset to first page when changing page size
    },
    page: currentPage,
    count: totalRowCount,
    serverSide: true,
    loading: loading,
    rowsPerPageOptions: [25, 50, 75, 100],
    rowsPerPage: pageSize,
    fixedHeader: true,
    elevation: 1,
    textLabels: {
      body: {
        noMatch: loading ? (
          <Box>
            <Skeleton className="my-4" />
            <Skeleton className="my-4" />
            <Skeleton className="my-4" />
          </Box>
        ) : (
          "No records found"
        ),
      },
      pagination: {
        rowsPerPage: isMobile ? "Rows:" : "Rows per page:",
      },
    },
    customToolbar: () => {
      return isMobile ? (
        <IconButton onClick={() => setFilterDrawerOpen(true)}>
          <FilterListIcon />
        </IconButton>
      ) : null;
    },
  };

  // Filter drawer for mobile
  const FilterDrawer = () => (
    <Drawer
      anchor="right"
      open={filterDrawerOpen}
      onClose={() => setFilterDrawerOpen(false)}
    >
      <Box sx={{ width: 300, p: 3 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h6">Filters</Typography>
          <IconButton onClick={() => setFilterDrawerOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box sx={{ mb: 3 }}>
          <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
            <InputLabel id="hadith-book-label">Hadith Book</InputLabel>
            <Select
              labelId="hadith-book-label"
              value={params?.hadithbookId || ""}
              label="Hadith Book"
              onChange={(event) =>
                setParams((prev) => ({
                  ...prev,
                  hadithbookId: event.target.value,
                }))
              }
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {allHadithBooks.map((el) => (
                <MenuItem value={el.id} key={el.id}>
                  {el.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
            <InputLabel id="order-label">Order</InputLabel>
            <Select
              labelId="order-label"
              value={params?.order || "ASC"}
              label="Order"
              onChange={(event) =>
                setParams((prev) => ({
                  ...prev,
                  order: event.target.value,
                }))
              }
            >
              {orders.map((el) => (
                <MenuItem value={el} key={el}>
                  {el}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            label="System ID"
            variant="outlined"
            fullWidth
            sx={{ mb: 2 }}
            value={params?.systemId || ""}
            onChange={(event) =>
              setParams((prev) => ({
                ...prev,
                systemId: event.target.value,
              }))
            }
          />

          <TextField
            label="Start ID"
            variant="outlined"
            fullWidth
            sx={{ mb: 2 }}
            value={params?.startId || ""}
            onChange={(event) =>
              setParams((prev) => ({
                ...prev,
                startId: event.target.value,
              }))
            }
          />

          <TextField
            label="End ID"
            variant="outlined"
            fullWidth
            sx={{ mb: 2 }}
            value={params?.endId || ""}
            onChange={(event) =>
              setParams((prev) => ({
                ...prev,
                endId: event.target.value,
              }))
            }
          />

          {userRole === "student" && (
            <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
              <InputLabel id="data-clerks-label">Data Entry Clerks</InputLabel>
              <Select
                labelId="data-clerks-label"
                multiple
                value={selectedDataEntryClerks || []}
                label="Data Entry Clerks"
                onChange={(event) => {
                  setSelectedDataEntryClerks(event.target.value);
                }}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => {
                      const clerk = dataEntryClerks.find((c) => c.id === value);
                      return clerk ? (
                        <Chip
                          key={value}
                          label={`${clerk.firstName} ${clerk.lastName}`}
                          size="small"
                        />
                      ) : null;
                    })}
                  </Box>
                )}
              >
                {dataEntryClerks.map((el) => (
                  <MenuItem value={el.id} key={el.id}>
                    {el.firstName} {el.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
          <Button
            variant="outlined"
            onClick={clearAllFilters}
            startIcon={<CloseIcon />}
          >
            Clear All
          </Button>
          <Button
            variant="contained"
            onClick={() => setFilterDrawerOpen(false)}
            color="primary"
          >
            Apply Filters
          </Button>
        </Box>
      </Box>
    </Drawer>
  );

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "99vh",
        backgroundColor: "rgba(222, 223, 223, 0.3)",
      }}
    >
      <CssBaseline />

      {/* Header */}
      <Header />

      {/* Sider */}
      <Sider />

      {/* Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 2,
          p: isMobile ? 1 : 3,
          overflow: "hidden",
        }}
        mt={7}
      >
        <Box
          sx={{
            maxWidth: "100%",
            overflowX: "hidden",
          }}
        >
          {!narratorLoading && (
            <div className={isMobile ? "px-1" : "px-1 px-md-5"}>
              {/* Upper Section */}
              <div className="row">
                <div className="col-md-8 col-xs-12">
                  <h2 className="mt-3">{t("module.hadith")}</h2>
                </div>
                {!isMobile && (
                  <div className="col-md-4 col-xs-12 d-flex justify-content-end align-items-center">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={clearAllFilters}
                      startIcon={<CloseIcon />}
                      size={isMobile ? "small" : "medium"}
                      sx={{ mt: 3 }}
                    >
                      Clear Filters
                    </Button>
                  </div>
                )}
              </div>

              {/* Filters Section - Desktop/Tablet */}
              {!isMobile && (
                <div className="row">
                  <div className="col-md-3 mt-2">
                    <FormControl variant="outlined" fullWidth size="small">
                      <InputLabel id="hadith-book-label">
                        Hadith Book
                      </InputLabel>
                      <Select
                        labelId="hadith-book-label"
                        value={params?.hadithbookId || ""}
                        label="Hadith Book"
                        onChange={(event) =>
                          setParams((prev) => ({
                            ...prev,
                            hadithbookId: event.target.value,
                          }))
                        }
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {allHadithBooks.map((el) => (
                          <MenuItem value={el.id} key={el.id}>
                            {el.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  <div className="col-md-2 mt-2">
                    <FormControl variant="outlined" fullWidth size="small">
                      <InputLabel id="order-label">Order</InputLabel>
                      <Select
                        labelId="order-label"
                        value={params?.order || "ASC"}
                        label="Order"
                        onChange={(event) =>
                          setParams((prev) => ({
                            ...prev,
                            order: event.target.value,
                          }))
                        }
                      >
                        {orders.map((el) => (
                          <MenuItem value={el} key={el}>
                            {el}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  <div className="col-md-2 mt-2">
                    <TextField
                      label="System ID"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={params?.systemId || ""}
                      onChange={(event) =>
                        setParams((prev) => ({
                          ...prev,
                          systemId: event.target.value,
                        }))
                      }
                    />
                  </div>

                  <div className="col-md-2 mt-2">
                    <TextField
                      label="Start ID"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={params?.startId || ""}
                      onChange={(event) =>
                        setParams((prev) => ({
                          ...prev,
                          startId: event.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className="col-md-2 mt-2">
                    <TextField
                      label="End ID"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={params?.endId || ""}
                      onChange={(event) =>
                        setParams((prev) => ({
                          ...prev,
                          endId: event.target.value,
                        }))
                      }
                    />
                  </div>

                  {userRole == "student" && (
                    <div className="col-md-3 mt-2">
                      <FormControl fullWidth variant="outlined" size="small">
                        <InputLabel id="data-clerks-label">
                          Data Entry Clerks
                        </InputLabel>
                        {dataEntryClerks.length > 0 && (
                          <Select
                            labelId="data-clerks-label"
                            multiple
                            value={selectedDataEntryClerks || []}
                            label="Data Entry Clerks"
                            onChange={(event) => {
                              setSelectedDataEntryClerks(event.target.value);
                            }}
                            renderValue={(selected) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {selected.map((value) => {
                                  const clerk = dataEntryClerks.find(
                                    (c) => c.id === value
                                  );
                                  return clerk ? (
                                    <Chip
                                      key={value}
                                      label={`${clerk.firstName} ${clerk.lastName}`}
                                      size="small"
                                    />
                                  ) : null;
                                })}
                              </Box>
                            )}
                          >
                            {dataEntryClerks.map((el) => (
                              <MenuItem value={el.id} key={el.id}>
                                {el.firstName} {el.lastName}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                        {dataEntryClerks.length == 0 && (
                          <>
                            <Select disabled label={"Data Entry Clerks"}>
                              <MenuItem value={""}>No Data</MenuItem>
                              <FormHelperText>No Data</FormHelperText>
                            </Select>
                          </>
                        )}
                      </FormControl>
                    </div>
                  )}
                </div>
              )}

              {/* Tabs */}
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  mt: 2,
                  overflowX: "auto",
                }}
              >
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="hadith tabs"
                >
                  {roleTabMappings[userRole]?.map((tab) => (
                    <Tab key={tab.value} value={tab.key} label={tab.label} />
                  ))}
                </Tabs>
              </Box>

              {/* Table Container */}
              <div className="mt-3">
                <Paper elevation={1} sx={{ overflow: "hidden" }}>
                  <MUIDataTable
                    title={
                      <Typography variant="h6" component="div">
                        {tabValue === "verified" && "Verified Hadiths"}
                        {tabValue === "toBeQc" && "Hadiths To Be QC'd"}
                        {tabValue === "qcRejected" && "QC Rejected Hadiths"}
                        {tabValue === "toBeReviewed" &&
                          "Hadiths To Be Reviewed"}
                        {tabValue === "reviewRejected" &&
                          "Review Rejected Hadiths"}
                        {tabValue === "toBeApproved" &&
                          "Hadiths To Be Approved"}
                        {tabValue === "zaeefHadith" && "Zaeef Hadiths"}
                        {tabValue === "last24HoursHadith" &&
                          "Hadiths Added in Last 24 Hours"}
                      </Typography>
                    }
                    data={tableData}
                    columns={columns}
                    options={options}
                  />
                </Paper>
              </div>
            </div>
          )}

          {narratorLoading && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center", // Center horizontally
                alignItems: "center", // Center vertically
                height: "90vh", // Take up full viewport height
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Box>

        {/* Mobile Filter Drawer */}
        {isMobile && <FilterDrawer />}

        {/* Display edit modal */}
        {controls.editMode && (
          <div>
            <HadithUpdate
              refreshDataEvent={fetch}
              resetEditState={resetEdit}
              recordToUpdate={dataToEdit}
              hadithBookData={allHadithBooks}
              selectedTabValue={tabValue}
              IsRejectEvet={controls.isRejectEvent}
              IsConfirmEvent={controls.isConfirmEvent}
              IsResolveEvent={controls.isResolveEvent}
              userRole={userRole}
              allHadithRecords={allData}
              handleNextRecord={handleNextRecord}
            />
          </div>
        )}

        {/* Alerts */}
        <AlertM ref={notifications} />
      </Box>
    </Box>
  );
}

export default HadithReviewMain;
