import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          register: {
            tagline: "Register with email",
            haveAccount: "Already have an account?",
            createSuccess: "Account Created",
            creationMailSent: "An activation email has been sent to you,",
          },
          login: {
            tagline: "Sign in to your account",
            loginFailed: "Login Failed",
            invalidCredentials: "Invalid email or password",
            dontHaveAccount: "Don't have an account?",
            sessionExpired:
              "Your session has expired. Please login again to continue working.",
          },
          form: {
            email: "Email",
            password: "Password",
          },
          common: {
            add: "Add",
            edit: "Update",
            delete: "Delete",
            cancel: "Cancel",
            view: "View",
            success: "Success",
            allRecords: "All Records",
            recordDetails: "Record Details",
            tryAgain: "Please try again later",
            somethingWentWrong: "Something went wrong",
            createSuccess: "Record created successfully",
            editSuccess: "Record updated successfully",
            deleteSuccess: "Record deleted successfully",
            genericSuccess: "Operation completed successfully",
            isReviewed: "Review",
            confirm: "Confirm",
            search: "Search",
            save: "Save",
            update: "Update",
            create: "Create",
            back: "Back",
            noData: "No data available",
            noHadithsAttached: "No hadiths are attached to this group.",
            hadiths: "Hadiths",
            name: "Name",
            submit: "Submit",
            addHadith: "Add Hadith",
            searchHadith: "Search Hadith",
            viewGroups: "View Groups",
            linkSuccess: "Successfully linked",
            verses: "Verses",
            tafseer: "Tafseer",
          },
          module: {
            register: "Register",
            login: "Login",
            role: "Roles",
            user: "Users",
            hadithbook: "Hadith Book",
            hadith: "Hadith Review",
            search: "Search",
            addHadithGroup: "Add Hadith Group",
            keyword: "Hadith Root Word",
            keywordGroups: "Hadith RootWord Groups",
          },
          search: {
            title: "Search",
          },
          role: {
            title: "Title",
          },
          hadithbook: {
            name: "Title",
            author: "Author",
          },
          hadith: {
            translation: "Translation",
            hadithId: "Hadith ID",
            isReviewed: "Is Reviewed",
          },
          user: {
            firstName: "First Name",
            middleName: "Middle Name",
            lastName: "Last Name",
            email: "Email",
            contact: "Contact",
            roleId: "Role",
            isActive: "Active",
            isMicrosoftLinked: "nan",
            isGoogleLinked: "nan",
            password: "Password",
            token: "nan",
            lastLogin: "Last Login",
          },
          hadithgroups: {
            hadiths: "Grouped Hadiths",
            linkFail: "Failed to link hadith",
          },
          keywordGroups: {
            hadiths: "Hadiths",
            verses: "Verses",
            tafseer: "Tafseer",
            noHadiths: "No Hadiths found for this keyword.",
            noVerses: "No Verses found for this keyword.",
            noTafseer: "No Tafseer found for this keyword.",
          },
        },
      },
      de: {
        translation: {
          description: {
            part1: "Ändere <1>src/App.js</1> und speichere um neu zu laden.",
            part2: "Lerne React",
          },
        },
      },
    },
  });

export default i18n;
