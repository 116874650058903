import { retrieveJWT } from "./LocalStorageService";

const axios = require("axios");

const generateHeaders = (blobResponse: Boolean = false) => {
  const JWT = retrieveJWT();
  const headerData: any = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${JWT}`,
    },
  };

  if (blobResponse) headerData.headers.responseType = "blob";
  return headerData;
};

export async function postImage(
  _module: string,
  _data: any,
  blobResponse: Boolean = false
) {
  const url = process.env.REACT_APP_REST_ENDPOINT;
  const response = await axios.post(
    url + _module,
    _data,
    generateHeaders(blobResponse)
  );
  return response.data;
}
