// @ts-nocheck

import React, { useState, useEffect } from "react";
import { useStyles } from "./Styles";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { collapse, updateMenuItems } from "./Actions";
import { expand } from "../Header/Actions";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import Collapse from "@mui/material/Collapse";
import { getAll } from "../../../Services/GenericApiService";
import { getLoggedInUserRole } from "../../../Services/LocalStorageService";
import { getUserData } from "../../../Services/LocalStorageService";
import { isAuthorized } from "../../../Routes/Guards/AuthGuard/AccessGroups";

// font awesome stuff
const iconList = Object.keys(Icons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon) => Icons[icon]);

library.add(...iconList);

const drawerWidth = 200;

interface menuItem {
  title: "";
  url: "";
  icon: "";
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function Sider() {
  // Set Theme
  const theme = useTheme();

  // Css for module
  const classes = useStyles();

  // Router object
  const navigate = useNavigate();

  // current location
  const location = useLocation();

  // Redux
  const sider = useSelector((state: RootStateOrAny) => state.sider);

  const header = useSelector((state: RootStateOrAny) => state.header);

  // Dispatcher
  const dispatch = useDispatch();

  // UI vars
  const [open, setOpen] = React.useState(sider.state.isExpanded);
  const [menu, setMenu] = React.useState(sider.state.menuItems);
  const [loading, setLoading] = React.useState(true);
  const [currentUser, setCurrentUser] = useState([]);

  // Data Vars
  const [allClients, setAllClients] = React.useState(sider.state.menuItems);

  //get user role
  const roleId = getLoggedInUserRole();

  // Menu Items
  // const defaultMenuItems: any

  // linked components API modules (endpoints)
  const moduleClient = "client";

  useEffect(() => {
    const data = getUserData();
    if (Object.values(data).length > 0) {
      setCurrentUser(data);
      setLoading(false);
    }
  }, []);

  const [menuItems, setMenuItems] = React.useState([
    {
      title: "Logout",
      url: "logout",
      icon: "exclamation-triangle",
      subMenu: false,
    },
    {
      title: "Dashboard",
      url: "dashboard",
      icon: "house",
      subMenu: false,
    },
    {
      title: "Roles",
      url: "role",
      icon: "user-lock",
      subMenu: false,
    },
    {
      title: "Users",
      url: "user",
      icon: "user-plus",
      subMenu: false,
    },
    {
      title: "HadithBooks",
      url: "hadithbooks",
      icon: "book",
      subMenu: false,
    },
    {
      title: "Search",
      url: "search",
      icon: "search",
      subMenu: false,
    },
    {
      title: "Verification",
      url: "hadith",
      icon: "check",
      subMenu: false,
    },
    {
      title: "Submission",
      url: "translate",
      icon: "keyboard",
      subMenu: false,
    },
    {
      title: "Narrator ",
      url: "narrator",
      icon: "language",
      subMenu: false,
    },
    {
      title: "Quran",
      url: "quran",
      icon: "language",
      subMenu: false,
    },
    {
      title: "Books",
      url: "book",
      icon: "book",
      subMenu: false,
    },
    {
      title: "Keywords",
      url: "keyword",
      icon: "key",
      subMenu: false,
    },
    // {
    //   title: "Keyword Groups",
    //   url: "keywordgroup",
    //   icon: "object-group",
    //   subMenu: false,
    // },
  ]);

  useEffect(() => {
    setOpen(sider.state.isExpanded);
    setMenu(sider.state.menuItems);
  }, [sider]);

  useEffect(() => {
    // setOpen(!header.state.isExpanded)
  }, [header]);

  useEffect(() => {
    if (sider.state.menuItems.length == 0) dispatch(updateMenuItems(menuItems));
  }, []);

  // UI toggles
  const handleDrawerClose = () => {
    dispatch(collapse()); //sider state collapses
    dispatch(expand()); //header state expands
  };

  const navigateToComponent = (index, subItemIndex, isSubMenuItem = false) => {
    let url = "";
    url = "/" + menu[index].url;
    // dispatch(updateMenuItems(updatedMenu))
    navigate(url);
  };

  // Deselects All other menu items
  const handleSubMenuChange = (index) => {
    const updatedState = [...menu];
    updatedState[index] = {
      ...updatedState[index],
      // subMenuOpen: true
      subMenuOpen: !updatedState[index].subMenuOpen,
    };
    setMenu(updatedState);
    dispatch(updateMenuItems(updatedState));
    // console.log('subMenu for ', menu[index].title, 'changed to: ', !menu[index].subMenuOpen)
  };

  return (
    <>
      {!loading && (
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            {/* Brand Image */}
            {/* <img className="img-fluid mx-auto" style={{ maxWidth: '150px' }}
                    src={require('../../../Assets/img/aep-fullname-logo.png')} /> */}

            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          {menu.length > 0 && (
            <List sx={{}}>
              {menu.map((item: any, index: any) => (
                <>
                  {currentUser &&
                    isAuthorized(currentUser?.roleId, "routes", item.url) && (
                      <Tooltip title={item.title} placement="right">
                        <ListItemButton
                          className={
                            location.pathname == "/" + item.url
                              ? "bg-custom-primary-faded"
                              : ""
                          }
                          onClick={() => {
                            navigateToComponent(index);
                            handleDrawerClose();
                            if (item.subMenu) handleSubMenuChange(index);
                          }}
                          key={item.title}
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                            border: "1 px",
                            borderColor: "black",
                            borderWidth: 2,
                          }}
                          disableRipple
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                            }}
                          >
                            <FontAwesomeIcon icon={item.icon} />
                          </ListItemIcon>
                          <ListItemText
                            primary={item.title}
                            sx={{ opacity: open ? 1 : 0 }}
                          />
                          {item.subMenu && (
                            <>
                              {item.subMenuOpen ? (
                                <ExpandLess />
                              ) : (
                                <ExpandMore />
                              )}
                            </>
                          )}
                        </ListItemButton>
                      </Tooltip>
                    )}
                </>
              ))}
            </List>
          )}
        </Drawer>
      )}
    </>
  );
}

export default Sider;
