// @ts-nocheck
import React, { useRef, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
  Box,
  FormControl,
  InputAdornment,
  IconButton,
  CircularProgress,
  useTheme,
  useMediaQuery,
  Paper,
  Container,
  Divider,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import "../../../../index.scss";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { post } from "../../../../Services/GenericApiService";
import AlertM from "../../../Helpers/AlertM/AlertM";
import { encrypt } from "../../../../Services/CryptoService";
import {
  getUserData,
  storeJWT,
} from "../../../../Services/LocalStorageService";
import { login } from "./Actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Login({ reAuthMode = false, returnUrl = "", onLoginSuccess = null }) {
  // theme and responsive design
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // router
  const navigate = useNavigate();
  const location = useLocation();

  // translation
  const { t } = useTranslation();

  // child ref for alert
  const notifications = useRef();

  // module(s) for api
  const moduleMain = "user/auth/email";

  // state in redux
  const authentication = useSelector((state) => state.authentication);

  // Dispatcher
  const dispatch = useDispatch();

  // form data with react-hook-form
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    mode: "onChange",
  });

  // state management
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  // UI controls
  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const handleRoute = () => {
    // Extract the current URL to potentially redirect back after login
    const searchParams = new URLSearchParams(location.search);
    const rerouteUrl = searchParams.get("reroute") || returnUrl;

    // If in re-auth mode, handle the return to the specified URL
    if (reAuthMode && rerouteUrl) {
      // Call the login success callback if provided
      if (onLoginSuccess) {
        onLoginSuccess();
      }
      navigate("/" + rerouteUrl);
      return;
    }

    // Standard login flow
    if (rerouteUrl) {
      navigate("/" + rerouteUrl);
    } else {
      navigate("/dashboard");
    }
  };

  // authentication method
  const onLogin = (data) => {
    setLoading(true);

    // encrypt data
    const encryptedPassword = encrypt(data.password);
    const loginData = {
      email: data.email,
      password: encryptedPassword,
    };

    post(moduleMain, loginData)
      .then((response) => {
        setLoading(false);
        storeJWT(response.token);
        const userdata = getUserData();
        dispatch(login(true, response.token, userdata));
        handleRoute();
      })
      .catch((error) => {
        setLoading(false);
        notifications.current.errorAlert(
          t("login.loginFailed"),
          t("login.invalidCredentials")
        );
      });
  };

  return (
    <Container
      className={`${reAuthMode ? "" : "py-5 h-100"} fade-in`}
      maxWidth="sm"
      sx={{ animation: "fadeIn 0.5s ease-in-out" }}
    >
      <Paper
        elevation={3}
        sx={{
          borderRadius: 4,
          overflow: "hidden",
          backgroundColor: "background.paper",
          transition: "all 0.3s ease-in-out",
          "&:hover": {
            boxShadow: 6,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            p: { xs: 3, md: 5 },
          }}
        >
          <Typography
            variant="h4"
            component="h1"
            sx={{
              fontWeight: 700,
              textAlign: "center",
              mb: 1,
              color: theme.palette.primary.main,
            }}
          >
            Hadith Translation
          </Typography>

          <Box sx={{ mt: 2, mb: 4, textAlign: "center" }}>
            {reAuthMode ? (
              <Typography
                variant="subtitle1"
                color="error"
                sx={{ fontWeight: 500 }}
              >
                {t("login.sessionExpired")}
              </Typography>
            ) : (
              <Typography variant="body1" color="text.secondary">
                {t("login.tagline")}
              </Typography>
            )}
          </Box>

          <form onSubmit={handleSubmit(onLogin)}>
            <Box sx={{ mb: 3 }}>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="emailInput"
                    label={t("form.email")}
                    variant="outlined"
                    fullWidth
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    InputProps={{
                      sx: { borderRadius: 2 },
                    }}
                  />
                )}
              />
            </Box>

            <Box sx={{ mb: 4 }}>
              <Controller
                name="password"
                control={control}
                rules={{ required: "Password is required" }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="passwordInput"
                    label={t("form.password")}
                    type={showPassword ? "text" : "password"}
                    variant="outlined"
                    fullWidth
                    error={!!errors.password}
                    helperText={errors.password?.message}
                    InputProps={{
                      sx: { borderRadius: 2 },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={togglePasswordVisibility}
                            edge="end"
                          >
                            {showPassword ? (
                              <FontAwesomeIcon width={"1em"} icon="eye" />
                            ) : (
                              <FontAwesomeIcon width={"1em"} icon="eye-slash" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Box>

            <Box sx={{ mb: 2 }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={loading}
                sx={{
                  py: 1.5,
                  borderRadius: 2,
                  textTransform: "none",
                  fontWeight: 600,
                  boxShadow: 2,
                  transition: "all 0.2s ease-in-out",
                  "&:hover": {
                    boxShadow: 4,
                    transform: "translateY(-2px)",
                  },
                }}
                startIcon={
                  loading ? null : <FontAwesomeIcon icon="sign-in-alt" />
                }
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  t("module.login")
                )}
              </Button>
            </Box>
          </form>
        </Box>
      </Paper>

      <style jsx>{`
        @keyframes fadeIn {
          from {
            opacity: 0;
            transform: translateY(10px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
        .fade-in {
          animation: fadeIn 0.5s ease-in-out;
        }
      `}</style>

      <AlertM ref={notifications} />
    </Container>
  );
}

export default Login;
