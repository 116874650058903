// @ts-nocheck
import React, { useState, useEffect } from "react";
import { useStyles } from "./Styles";
import {
  styled,
  useTheme,
  Theme,
  CSSObject,
  alpha,
} from "@mui/material/styles";
import {
  Drawer as MuiDrawer,
  List,
  Divider,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Collapse,
  Box,
  Typography,
  Avatar,
  useMediaQuery,
  Badge,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { collapse, updateMenuItems } from "./Actions";
import { expand } from "../Header/Actions";
import { useNavigate } from "react-router-dom";
import {
  getLoggedInUserRole,
  getUserData,
} from "../../../Services/LocalStorageService";
import { isAuthorized } from "../../../Routes/Guards/AuthGuard/AccessGroups";

// font awesome stuff
const iconList = Object.keys(Icons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon) => Icons[icon]);

library.add(...iconList);

const drawerWidth = 240;

// Enhanced drawer styling
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.standard,
  }),
  overflowX: "hidden",
  backgroundColor:
    theme.palette.mode === "dark"
      ? alpha(theme.palette.background.paper, 0.95)
      : alpha(theme.palette.background.paper, 0.98),
  borderRight: `1px solid ${theme.palette.divider}`,
  boxShadow:
    theme.palette.mode === "dark"
      ? "1px 0 5px rgba(0,0,0,0.15)"
      : "1px 0 5px rgba(0,0,0,0.05)",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.standard,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  backgroundColor:
    theme.palette.mode === "dark"
      ? alpha(theme.palette.background.paper, 0.95)
      : alpha(theme.palette.background.paper, 0.98),
  borderRight: `1px solid ${theme.palette.divider}`,
  boxShadow:
    theme.palette.mode === "dark"
      ? "1px 0 5px rgba(0,0,0,0.15)"
      : "1px 0 5px rgba(0,0,0,0.05)",
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1),
  height: 64, // Match header height
  borderBottom: `1px solid ${theme.palette.divider}`,
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

// Custom styled components for menu items
const StyledListItemButton = styled(ListItemButton)(({ theme, active }) => ({
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  transition: "all 0.2s ease",
  position: "relative",
  "&:before": active
    ? {
        content: '""',
        position: "absolute",
        left: -8,
        height: "60%",
        width: 4,
        backgroundColor: theme.palette.primary.main,
        borderRadius: "0 4px 4px 0",
      }
    : {},
  "&:hover": {
    backgroundColor:
      theme.palette.mode === "dark"
        ? alpha(theme.palette.primary.main, 0.15)
        : alpha(theme.palette.primary.main, 0.08),
  },
  ...(active && {
    backgroundColor:
      theme.palette.mode === "dark"
        ? alpha(theme.palette.primary.main, 0.2)
        : alpha(theme.palette.primary.main, 0.1),
    "&:hover": {
      backgroundColor:
        theme.palette.mode === "dark"
          ? alpha(theme.palette.primary.main, 0.25)
          : alpha(theme.palette.primary.main, 0.15),
    },
  }),
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme, active }) => ({
  minWidth: 0,
  color: active ? theme.palette.primary.main : theme.palette.text.secondary,
  transition: "all 0.2s ease",
  fontSize: "1.1rem",
}));

const CategoryLabel = styled(Typography)(({ theme }) => ({
  fontSize: "0.75rem",
  fontWeight: 600,
  textTransform: "uppercase",
  color: theme.palette.text.secondary,
  padding: theme.spacing(1.5, 2, 0.5, 3),
  letterSpacing: "0.5px",
}));

// Groupings for menu items
const MENU_CATEGORIES = {
  MAIN: "Main",
  CONTENT: "Content",
  MANAGEMENT: "Management",
  SYSTEM: "System",
};

// Function to categorize menu items
const categorizeMenuItems = (items) => {
  return items.map((item) => {
    let category = MENU_CATEGORIES.CONTENT;

    if (["dashboard", "search", "chat"].includes(item.url)) {
      category = MENU_CATEGORIES.MAIN;
    } else if (["role", "user", "log", "logout"].includes(item.url)) {
      category = MENU_CATEGORIES.SYSTEM;
    } else if (["hadith", "translate", "hadithbooks"].includes(item.url)) {
      category = MENU_CATEGORIES.MANAGEMENT;
    }

    return { ...item, category };
  });
};

function Sider() {
  // Set Theme
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Css for module
  const classes = useStyles();

  // Router object
  const navigate = useNavigate();

  // current location
  const location = useLocation();

  // Redux
  const sider = useSelector((state: RootStateOrAny) => state.sider);
  const header = useSelector((state: RootStateOrAny) => state.header);

  // Dispatcher
  const dispatch = useDispatch();

  // UI vars
  const [open, setOpen] = useState(sider.state.isExpanded);
  const [menu, setMenu] = useState(sider.state.menuItems);
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const [categorizedMenu, setCategorizedMenu] = useState({});

  //get user role
  const roleId = getLoggedInUserRole();

  useEffect(() => {
    const data = getUserData();
    if (Object.values(data).length > 0) {
      setCurrentUser(data);
      setLoading(false);
    }
  }, []);

  const [menuItems, setMenuItems] = useState([
    {
      title: "Dashboard",
      url: "dashboard",
      icon: "house",
      subMenu: false,
    },
    {
      title: "Search",
      url: "search",
      icon: "search",
      subMenu: false,
    },
    {
      title: "Chat",
      url: "chat",
      icon: "message",
      subMenu: false,
    },
    {
      title: "Verification",
      url: "hadith",
      icon: "check",
      subMenu: false,
    },
    {
      title: "Submission",
      url: "translate",
      icon: "keyboard",
      subMenu: false,
    },
    {
      title: "HadithBooks",
      url: "hadithbooks",
      icon: "book",
      subMenu: false,
    },
    {
      title: "Narrator",
      url: "narrator",
      icon: "user-tie",
      subMenu: false,
    },
    {
      title: "Quran",
      url: "quran",
      icon: "book-open",
      subMenu: false,
    },
    {
      title: "Books",
      url: "book",
      icon: "book",
      subMenu: false,
    },
    {
      title: "Quran Root Words",
      url: "keyword",
      icon: "language",
      subMenu: false,
    },
    {
      title: "Hadith Keywords",
      url: "label",
      icon: "tag",
      subMenu: false,
    },
    {
      title: "Roles",
      url: "role",
      icon: "user-shield",
      subMenu: false,
    },
    {
      title: "Users",
      url: "user",
      icon: "users",
      subMenu: false,
    },
    {
      title: "Logs",
      url: "log",
      icon: "history",
      subMenu: false,
    },
    {
      title: "Logout",
      url: "logout",
      icon: "sign-out-alt",
      subMenu: false,
    },
  ]);

  useEffect(() => {
    setOpen(sider.state.isExpanded);
    setMenu(sider.state.menuItems);

    // Categorize menu items when they change
    if (sider.state.menuItems.length > 0) {
      const categorized = categorizeMenuItems(sider.state.menuItems);

      // Group items by category
      const groupedItems = categorized.reduce((groups, item) => {
        const category = item.category;
        if (!groups[category]) {
          groups[category] = [];
        }
        groups[category].push(item);
        return groups;
      }, {});

      // Sort categories in a specific order
      const orderedGroups = {};
      [
        MENU_CATEGORIES.MAIN,
        MENU_CATEGORIES.MANAGEMENT,
        MENU_CATEGORIES.CONTENT,
        MENU_CATEGORIES.SYSTEM,
      ].forEach((category) => {
        if (groupedItems[category]) {
          orderedGroups[category] = groupedItems[category];
        }
      });

      setCategorizedMenu(orderedGroups);
    }
  }, [sider]);

  useEffect(() => {
    if (sider.state.menuItems.length === 0)
      dispatch(updateMenuItems(menuItems));
  }, []);

  // UI toggles
  const handleDrawerClose = () => {
    dispatch(collapse()); //sider state collapses
    dispatch(expand()); //header state expands
  };

  const navigateToComponent = (url) => {
    navigate("/" + url);
    if (isMobile) {
      handleDrawerClose();
    }
  };

  // Deselects All other menu items
  const handleSubMenuChange = (index) => {
    const updatedState = [...menu];
    updatedState[index] = {
      ...updatedState[index],
      subMenuOpen: !updatedState[index].subMenuOpen,
    };
    setMenu(updatedState);
    dispatch(updateMenuItems(updatedState));
  };

  // Get initials for logo placeholder
  const getAppInitials = () => {
    const appName = process.env.REACT_APP_PROJECT_NAME || "App Name";
    return appName
      .split(" ")
      .map((word) => word[0])
      .join("")
      .substring(0, 2)
      .toUpperCase();
  };

  // Check if a menu item is active
  const isActive = (itemUrl) => {
    return location.pathname === "/" + itemUrl;
  };

  return (
    <>
      {!loading && (
        <Drawer variant="permanent" open={open}>
          {/* Drawer Header with Logo/Brand */}
          <DrawerHeader>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                ml: 1,
                opacity: open ? 1 : 0,
                transition: "opacity 0.3s ease",
                overflow: "hidden",
              }}
            >
              <Avatar
                sx={{
                  width: 36,
                  height: 36,
                  bgcolor: theme.palette.primary.main,
                  fontSize: "1rem",
                  fontWeight: "bold",
                  mr: 2,
                }}
              >
                {getAppInitials()}
              </Avatar>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 600,
                  letterSpacing: "0.5px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {process.env.REACT_APP_PROJECT_NAME || "Hadith Translation"}
              </Typography>
            </Box>
            <IconButton
              onClick={handleDrawerClose}
              sx={{
                transition: "all 0.2s ease",
                "&:hover": {
                  backgroundColor:
                    theme.palette.mode === "dark"
                      ? alpha(theme.palette.primary.main, 0.15)
                      : alpha(theme.palette.primary.main, 0.08),
                },
              }}
            >
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>

          {/* Menu Items by Category */}
          <Box sx={{ overflowY: "auto", overflowX: "hidden", flexGrow: 1 }}>
            {Object.entries(categorizedMenu).map(([category, items]) => (
              <React.Fragment key={category}>
                {/* Category Label - only show when drawer is open */}
                {open && <CategoryLabel>{category}</CategoryLabel>}

                <List component="nav" disablePadding>
                  {items.map(
                    (item, index) =>
                      currentUser &&
                      isAuthorized(currentUser?.roleId, "routes", item.url) && (
                        <Tooltip
                          key={item.url}
                          title={open ? "" : item.title}
                          placement="right"
                          arrow
                        >
                          <StyledListItemButton
                            active={isActive(item.url)}
                            onClick={() => navigateToComponent(item.url)}
                            sx={{
                              justifyContent: open ? "initial" : "center",
                              px: 2.5,
                              py: 1.2,
                            }}
                          >
                            <StyledListItemIcon
                              active={isActive(item.url)}
                              sx={{
                                mr: open ? 2 : "auto",
                                justifyContent: "center",
                              }}
                            >
                              <FontAwesomeIcon icon={item.icon} />
                            </StyledListItemIcon>
                            <ListItemText
                              primary={item.title}
                              primaryTypographyProps={{
                                fontSize: "0.9rem",
                                fontWeight: isActive(item.url) ? 600 : 500,
                                color: isActive(item.url)
                                  ? theme.palette.primary.main
                                  : theme.palette.text.primary,
                              }}
                              sx={{
                                opacity: open ? 1 : 0,
                                transition: "opacity 0.3s ease",
                              }}
                            />
                            {item.subMenu && open && (
                              <>
                                {item.subMenuOpen ? (
                                  <ExpandLess />
                                ) : (
                                  <ExpandMore />
                                )}
                              </>
                            )}
                          </StyledListItemButton>
                        </Tooltip>
                      )
                  )}
                </List>

                {/* Category divider */}
                {category !== Object.keys(categorizedMenu).pop() && (
                  <Divider
                    sx={{
                      my: 1.5,
                      mx: open ? 2 : 1.5,
                      opacity: 0.6,
                    }}
                  />
                )}
              </React.Fragment>
            ))}
          </Box>

          {/* Bottom section - could add user info here */}
          <Box
            sx={{
              p: 1.5,
              borderTop: `1px solid ${theme.palette.divider}`,
              display: "flex",
              alignItems: "center",
              justifyContent: open ? "space-between" : "center",
              bgcolor:
                theme.palette.mode === "dark"
                  ? alpha(theme.palette.background.default, 0.4)
                  : alpha(theme.palette.background.default, 0.7),
            }}
          >
            {open && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                }}
              >
                <Typography
                  variant="caption"
                  noWrap
                  sx={{
                    fontWeight: 500,
                    color: theme.palette.text.secondary,
                    fontSize: "0.7rem",
                  }}
                >
                  Logged in as
                </Typography>
                <Typography
                  variant="body2"
                  noWrap
                  sx={{
                    fontWeight: 600,
                  }}
                >
                  {currentUser?.firstName} {currentUser?.lastName}
                </Typography>
              </Box>
            )}

            <Avatar
              sx={{
                width: 32,
                height: 32,
                fontSize: "0.8rem",
                bgcolor: theme.palette.primary.main,
              }}
            >
              {currentUser?.firstName?.[0]}
              {currentUser?.lastName?.[0]}
            </Avatar>
          </Box>
        </Drawer>
      )}
    </>
  );
}

export default Sider;
