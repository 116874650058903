// @ts-nocheck
import React, { useState, useRef, useEffect } from "react";
import { useStyles } from "./Styles";
import "../../../../../index.scss";
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import { post } from "../../../../../Services/GenericApiService";
import { useTranslation } from "react-i18next";
import AlertM from "../../../../Helpers/AlertM/AlertM";
import { log } from "../../../../../Services/LoggerService";

import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import {
  Button,
  TextField,
  Typography,
  CircularProgress,
  Avatar,
  Divider,
  IconButton,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";

// Font Awesome setup
const iconList = Object.keys(Icons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon) => Icons[icon]);
library.add(...iconList);

// Component for individual reference card with tooltip functionality
const ReferenceCard = ({ reference }) => {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Tooltip
      title={
        <React.Fragment>
          <Typography variant="subtitle2">{reference.text}</Typography>
          <Typography variant="body2">{reference.description}</Typography>
        </React.Fragment>
      }
      open={open}
      onClose={handleClose}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      arrow
      interactive
      placement="top"
    >
      <Chip
        label={`Ref ${reference.id}`}
        size="small"
        onClick={handleToggle}
        sx={{ cursor: "pointer" }}
      />
    </Tooltip>
  );
};

function QueryMain() {
  // CSS for module
  const classes = useStyles();

  // Translation
  const { t } = useTranslation();

  // Ref for alert component
  const notifications = useRef(null);
  const messagesEndRef = useRef(null);

  // State for chat
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(false);

  // Scroll to bottom of chat
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, isTyping]);

  // Handle message submission
  const handleSendMessage = async () => {
    if (!inputMessage.trim()) return;

    // Add user message to chat
    const userMessage = {
      content: inputMessage,
      sender: "user",
      timestamp: new Date(),
    };

    setMessages((prev) => [...prev, userMessage]);
    setInputMessage("");
    setIsLoading(true);
    setIsTyping(true);

    try {
      // Call API
      const response = await post("llm/query", { userQuery: inputMessage });

      // Add AI response to chat; note that the response now includes assistantMessage and references
      const aiMessage = {
        content:
          response.assistantMessage || "I couldn't process that request.",
        references: response.references,
        sender: "ai",
        timestamp: new Date(),
      };

      setMessages((prev) => [...prev, aiMessage]);
    } catch (error) {
      log.error("Error sending message:", error);
      notifications.current.showAlert(
        "error",
        "Failed to get response from AI"
      );

      // Add error message
      const errorMessage = {
        content: "Sorry, I encountered an error processing your request.",
        sender: "ai",
        timestamp: new Date(),
        isError: true,
      };

      setMessages((prev) => [...prev, errorMessage]);
    } finally {
      setIsLoading(false);
      setIsTyping(false);
    }
  };

  // Handle Enter key press
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  // Format message content with basic text formatting
  const formatMessage = (content) => {
    // Replace URLs with clickable links
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const textWithLinks = content.replace(
      urlRegex,
      (url) =>
        `<a href="${url}" target="_blank" rel="noopener noreferrer" style="color: #64ffda;">${url}</a>`
    );

    // Replace ** for bold
    const boldRegex = /\*\*(.*?)\*\*/g;
    const textWithBold = textWithLinks.replace(
      boldRegex,
      "<strong>$1</strong>"
    );

    // Replace * for italic
    const italicRegex = /\*(.*?)\*/g;
    const textWithItalic = textWithBold.replace(italicRegex, "<em>$1</em>");

    // Replace backticks for code
    const codeRegex = /`(.*?)`/g;
    const textWithCode = textWithItalic.replace(
      codeRegex,
      '<code style="background-color: #2d3748; padding: 2px 4px; border-radius: 4px; font-family: monospace;">$1</code>'
    );

    // Convert line breaks
    return textWithCode.replace(/\n/g, "<br />");
  };

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "100vh",
        backgroundColor: "#121212", // Dark background
      }}
    >
      <CssBaseline />

      {/* Header */}
      <Header />

      {/* Sider */}
      <Sider />

      {/* Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 2,
          p: 3,
          py: 1,
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#121212", // Dark background
        }}
        mt={9}
      >
        {/* Alerts */}
        <AlertM ref={notifications} />

        {/* Hero Section (smaller) */}
        <Box
          sx={{
            mb: 2,
            textAlign: "center",
            py: 1,
            px: 2,
            borderRadius: 4,
            background: "linear-gradient(135deg, #1a1a2e 0%, #16213e 100%)",
            boxShadow: "0 8px 32px rgba(0, 0, 0, 0.3)",
            transition: "all 0.3s ease",
            // Make the hero section collapse when there are messages
            maxHeight: messages.length > 0 ? "70px" : "300px",
            overflow: "hidden",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: 600,
              color: "#ffffff",
              mb: messages.length > 0 ? 1 : 2,
              textShadow: "0 2px 10px rgba(0,0,0,0.3)",
              fontSize: messages.length > 0 ? "1.4rem" : "2rem",
            }}
          >
            Hadith AI Assistant
          </Typography>
          {messages.length === 0 && (
            <>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 400,
                  color: "#90caf9",
                  mb: 2,
                  maxWidth: "800px",
                  mx: "auto",
                }}
              >
                Explore the wealth of Islamic knowledge through our advanced AI
                system
              </Typography>
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon
                  icon="brain"
                  style={{
                    fontSize: "3rem",
                    color: "#64ffda",
                    filter: "drop-shadow(0 0 10px rgba(100, 255, 218, 0.4))",
                  }}
                />
              </Box>
            </>
          )}
        </Box>

        {/* Chat Container */}
        <Paper
          elevation={4}
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            borderRadius: 3,
            overflow: "hidden",
            boxShadow: "0 10px 30px rgba(0, 0, 0, 0.2)",
            mb: 3,
            // Expand the chat container to fill available space
            maxHeight:
              messages.length > 0
                ? "calc(100vh - 180px)" // More space when hero is collapsed
                : "calc(100vh - 380px)", // Less space when hero is expanded
            backgroundColor: "#1e1e1e", // Dark chat container
            border: "1px solid #333",
            transition: "all 0.3s ease",
          }}
        >
          {/* Chat Messages Area */}
          <Box
            sx={{
              p: 3,
              flexGrow: 1,
              overflow: "auto",
              backgroundColor: "#1e1e1e", // Dark background for messages area
              scrollbarWidth: "thin",
              scrollbarColor: "#444 #1e1e1e",
              "&::-webkit-scrollbar": {
                width: "8px",
              },
              "&::-webkit-scrollbar-track": {
                background: "#1e1e1e",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#444",
                borderRadius: "4px",
              },
            }}
          >
            {messages.length === 0 ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  opacity: 0.8,
                }}
              >
                <FontAwesomeIcon
                  icon="comment-dots"
                  style={{
                    fontSize: "4rem",
                    color: "#64ffda",
                    marginBottom: "1.5rem",
                    filter: "drop-shadow(0 0 8px rgba(100, 255, 218, 0.3))",
                  }}
                />
                <Typography variant="h5" sx={{ color: "#e0e0e0" }}>
                  Start a conversation
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ mt: 1, color: "#aaa", textAlign: "center" }}
                >
                  Ask questions about Hadith or Islamic knowledge
                </Typography>
              </Box>
            ) : (
              <>
                {messages.map((message, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent:
                        message.sender === "user" ? "flex-end" : "flex-start",
                      mb: 3,
                    }}
                  >
                    {message.sender === "ai" && (
                      <Avatar
                        sx={{
                          bgcolor: message.isError ? "#ff5252" : "#64ffda",
                          mr: 1.5,
                          width: 42,
                          height: 42,
                          boxShadow: "0 2px 8px rgba(0,0,0,0.3)",
                          animation: message.isError ? "" : "pulse 2s infinite",
                          "@keyframes pulse": {
                            "0%": {
                              boxShadow: "0 0 0 0 rgba(100, 255, 218, 0.4)",
                            },
                            "70%": {
                              boxShadow: "0 0 0 10px rgba(100, 255, 218, 0)",
                            },
                            "100%": {
                              boxShadow: "0 0 0 0 rgba(100, 255, 218, 0)",
                            },
                          },
                        }}
                      >
                        <FontAwesomeIcon
                          icon={
                            message.isError ? "exclamation-triangle" : "robot"
                          }
                          style={{ color: "#1e1e1e" }}
                        />
                      </Avatar>
                    )}

                    <Paper
                      elevation={2}
                      sx={{
                        p: 2.5,
                        borderRadius: 2,
                        maxWidth: "75%",
                        backgroundColor:
                          message.sender === "user"
                            ? "#3949ab" // User message background (deep blue)
                            : message.isError
                            ? "#470000" // Error message background (dark red)
                            : "#263238", // AI message background (dark blue-grey)
                        color:
                          message.sender === "user"
                            ? "#e6e6e6" // User message text (off-white)
                            : message.isError
                            ? "#ff9e80" // Error message text (light orange)
                            : "#e0e0e0", // AI message text (light grey)
                        ml: message.sender === "user" ? 1 : 0,
                        mr: message.sender === "user" ? 0 : 1,
                        borderTopLeftRadius: message.sender === "ai" ? 0 : 2,
                        borderTopRightRadius: message.sender === "user" ? 0 : 2,
                        position: "relative",
                        "&::before":
                          message.sender === "ai"
                            ? {
                                content: '""',
                                position: "absolute",
                                top: 15,
                                left: -10,
                                width: 0,
                                height: 0,
                                borderTop: "10px solid transparent",
                                borderBottom: "10px solid transparent",
                                borderRight: message.isError
                                  ? "10px solid #470000"
                                  : "10px solid #263238",
                              }
                            : message.sender === "user"
                            ? {
                                content: '""',
                                position: "absolute",
                                top: 15,
                                right: -10,
                                width: 0,
                                height: 0,
                                borderTop: "10px solid transparent",
                                borderBottom: "10px solid transparent",
                                borderLeft: "10px solid #3949ab",
                              }
                            : {},
                        boxShadow: "0 2px 10px rgba(0,0,0,0.2)",
                        transition: "all 0.3s ease",
                        "&:hover": {
                          boxShadow: "0 4px 12px rgba(0,0,0,0.3)",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          whiteSpace: "pre-wrap",
                          "& a": {
                            color: "#64ffda",
                            textDecoration: "underline",
                          },
                          "& code": {
                            backgroundColor: "#37474f",
                            color: "#e0e0e0",
                            padding: "2px 4px",
                            borderRadius: 1,
                            fontFamily: "monospace",
                          },
                        }}
                        dangerouslySetInnerHTML={{
                          __html: formatMessage(message.content),
                        }}
                      />

                      {/* Render reference cards if this AI message includes references */}
                      {message.sender === "ai" &&
                        message.references &&
                        message.references.length > 0 && (
                          <Box
                            sx={{
                              mt: 1,
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 1,
                            }}
                          >
                            {message.references.map((ref, idx) => (
                              <ReferenceCard
                                key={ref.id || idx}
                                reference={ref}
                              />
                            ))}
                          </Box>
                        )}

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          mt: 1.5,
                          pt: 1,
                          borderTop: "1px solid rgba(255,255,255,0.1)",
                        }}
                      >
                        <Typography
                          variant="caption"
                          sx={{
                            opacity: 0.7,
                            color:
                              message.sender === "user" ? "#bbdefb" : "#90caf9",
                            fontStyle: "italic",
                          }}
                        >
                          {message.sender === "user" ? "You" : "Hadith AI"}
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{
                            opacity: 0.7,
                            color:
                              message.sender === "user" ? "#bbdefb" : "#90caf9",
                          }}
                        >
                          {new Date(message.timestamp).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </Typography>
                      </Box>
                    </Paper>

                    {message.sender === "user" && (
                      <Avatar
                        sx={{
                          bgcolor: "#7986cb",
                          ml: 1.5,
                          width: 42,
                          height: 42,
                          boxShadow: "0 2px 8px rgba(0,0,0,0.3)",
                        }}
                      >
                        <FontAwesomeIcon
                          icon="user"
                          style={{ color: "#e8eaf6" }}
                        />
                      </Avatar>
                    )}
                  </Box>
                ))}

                {/* Typing indicator */}
                {isTyping && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      mb: 3,
                    }}
                  >
                    <Avatar
                      sx={{
                        bgcolor: "#64ffda",
                        mr: 1.5,
                        width: 42,
                        height: 42,
                        boxShadow: "0 2px 8px rgba(0,0,0,0.3)",
                        animation: "pulse 2s infinite",
                      }}
                    >
                      <FontAwesomeIcon
                        icon="robot"
                        style={{ color: "#1e1e1e" }}
                      />
                    </Avatar>

                    <Paper
                      elevation={2}
                      sx={{
                        p: 2.5,
                        borderRadius: 2,
                        maxWidth: "75%",
                        backgroundColor: "#263238",
                        color: "#e0e0e0",
                        borderTopLeftRadius: 0,
                        position: "relative",
                        "&::before": {
                          content: '""',
                          position: "absolute",
                          top: 15,
                          left: -10,
                          width: 0,
                          height: 0,
                          borderTop: "10px solid transparent",
                          borderBottom: "10px solid transparent",
                          borderRight: "10px solid #263238",
                        },
                        boxShadow: "0 2px 10px rgba(0,0,0,0.2)",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="body1" sx={{ mr: 2 }}>
                          Generating response
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              width: 8,
                              height: 8,
                              borderRadius: "50%",
                              backgroundColor: "#64ffda",
                              animation:
                                "bounce 1.4s infinite ease-in-out both",
                              animationDelay: "0s",
                              mr: 0.5,
                              "@keyframes bounce": {
                                "0%, 80%, 100%": {
                                  transform: "scale(0)",
                                },
                                "40%": {
                                  transform: "scale(1)",
                                },
                              },
                            }}
                          />
                          <Box
                            sx={{
                              width: 8,
                              height: 8,
                              borderRadius: "50%",
                              backgroundColor: "#64ffda",
                              animation:
                                "bounce 1.4s infinite ease-in-out both",
                              animationDelay: "0.2s",
                              mr: 0.5,
                            }}
                          />
                          <Box
                            sx={{
                              width: 8,
                              height: 8,
                              borderRadius: "50%",
                              backgroundColor: "#64ffda",
                              animation:
                                "bounce 1.4s infinite ease-in-out both",
                              animationDelay: "0.4s",
                            }}
                          />
                        </Box>
                      </Box>
                    </Paper>
                  </Box>
                )}
              </>
            )}
            <div ref={messagesEndRef} />
          </Box>

          {/* Input Area */}
          <Box
            sx={{
              px: 2.5,
              py: 2,
              borderTop: "1px solid #333",
              backgroundColor: "#252525", // Dark input area
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                placeholder={t("Ask about Hadith or Islamic knowledge...")}
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                onKeyPress={handleKeyPress}
                multiline
                maxRows={4}
                disabled={isLoading}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                    backgroundColor: "#333",
                    color: "#e0e0e0",
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#64ffda",
                      borderWidth: 2,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#555",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#777",
                    },
                    "& .MuiInputBase-input::placeholder": {
                      color: "#aaa",
                      opacity: 0.8,
                    },
                  },
                }}
              />
              <Button
                variant="contained"
                onClick={handleSendMessage}
                disabled={isLoading || !inputMessage.trim()}
                sx={{
                  ml: 1.5,
                  height: 56,
                  borderRadius: 2,
                  minWidth: 56,
                  backgroundColor: "#64ffda",
                  color: "#1e1e1e",
                  fontWeight: "bold",
                  boxShadow: "0 4px 12px rgba(100, 255, 218, 0.3)",
                  "&:hover": {
                    backgroundColor: "#4cdfb2",
                    boxShadow: "0 6px 14px rgba(100, 255, 218, 0.4)",
                  },
                  "&:disabled": {
                    backgroundColor: "#2d3748",
                    color: "#9e9e9e",
                  },
                  transition: "all 0.3s ease",
                }}
              >
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <FontAwesomeIcon icon="paper-plane" />
                )}
              </Button>
            </Box>
            {/* Footer */}
            <Typography
              variant="caption"
              sx={{
                position: "fixed",
                bottom: "10px",
                left: "50%",
                transform: "translateX(-50%)",
                color: "#90caf9",
              }}
            >
              © {new Date().getFullYear()} • Powered by Hadith LLM Technology
            </Typography>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}

export default QueryMain;
