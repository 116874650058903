// styles.ts
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    container: {
        direction: 'rtl',
        textAlign: 'right',
    },
    verse: {
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginBottom: '10px',
        fontSize: '2em',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(245, 245, 245, 0.1)', // Using RGBA for subtle highlight with opacity
            color: 'yellow', // Ensure text color remains readable
        },
    },
    verseIssue: {
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginBottom: '10px',
        fontSize: '2em',
        color: 'red',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(245, 245, 245, 0.1)', // Using RGBA for subtle highlight with opacity
            color: 'red', // Ensure text color remains readable
        },
    },
    circle: {
        marginLeft: '5px',
        marginRight: '5px',
    },
    tooltipStyles: {
        color: 'white',
        maxWidth: 220,
        fontSize: '1em',
        fontWeight: 'bold', 
        // border: '1px solid #dadde9',
        marginLeft: '10px',
        marginRight: '10px', 
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(245, 245, 245, 0.1)', 
            color: 'yellow', 
        },
    },
    chip: {
        marginRight: "7px",
        fontSize: '0.6rem',
    },
    tafseer: 
    {
        marginLeft: '10px',
        marginRight: '10px', 
        cursor: 'pointer',
        fontWeight: 'bold', 
        color: 'white', 
        fontSize: '16px',
        '&:hover': {
            backgroundColor: 'rgba(245, 245, 245, 0.1)', 
            color: 'yellow', 
        },
    },
    modal: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: 'black',
        padding: '30px',
        borderRadius: '5px'
    },
});

export { useStyles };
