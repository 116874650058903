// @ts-nocheck

import React, { useEffect, useState, useRef } from "react";
import { useStyles } from "./Styles";
import "../../../../../index.scss";
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import {
  getAll,
  getById,
  post,
  patch,
  deleteById,
  getAllFiltered,
} from "../../../../../Services/GenericApiService";
import { formatData } from "../../../../../Services/DataFormatService";
import { useTranslation } from "react-i18next";
import AlertM from "../../../../Helpers/AlertM/AlertM";
import { log } from "../../../../../Services/LoggerService";

import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import MUIDataTable from "mui-datatables";
import { Button, IconButton, Skeleton, Tabs, Tab } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import Tooltip from "@mui/material/Tooltip";
import ConfirmationDialog from "../../../../Helpers/ConfirmationDialog/ConfirmationDialog";
import KeywordCreate from "../KeywordCreate/KeywordCreate";
import KeywordView from "../KeywordView/KeywordView";
import KeywordUpdate from "../KeywordUpdate/KeywordUpdate";
import { useNavigate } from "react-router";
import { getUserData } from "../../../../../Services/LocalStorageService";

// font awesome stuff
const iconList = Object.keys(Icons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon) => Icons[icon]);

library.add(...iconList);

function KeywordMain() {
  const navigate = useNavigate();
  // css for module
  const classes = useStyles();

  // translation
  const { t } = useTranslation();

  // child ref for alert
  const notifications = useRef();

  // module(s) for api
  const moduleMain = "keyword";

  const roleTabMappings = {
    admin: [
      { value: 0, key: "verified", label: "Verified" },
      { value: 1, key: "level1Approved", label: "Level 1 Approved" },
      { value: 2, key: "unapproved", label: "Unapproved" },
    ],
    manager: [
      { value: 0, key: "verified", label: "Verified" },
      { value: 1, key: "level1Approved", label: "Level 1 Approved" },
      { value: 2, key: "unapproved", label: "Unapproved" },
    ],
    qc: [{ value: 0, key: "verified", label: "Verified" }],
    teacher: [
      { value: 0, key: "verified", label: "Verified" },
      { value: 1, key: "level1Approved", label: "Level 1 Approved" },
      { value: 2, key: "unapproved", label: "Unapproved" },
    ],
    student: [
      { value: 0, key: "verified", label: "Verified" },
      { value: 1, key: "level1Approved", label: "Level 1 Approved" },
      { value: 2, key: "unapproved", label: "Unapproved" },
    ],
    dataentryclerk: [{ value: 0, key: "verified", label: "Verified" }],
  };

  // data vars
  const [allData, setAllData] = React.useState([]);
  const [dataToEdit, setDataToEdit] = React.useState({});
  const [userRole, setUserRole] = React.useState("");
  const [tabValue, setTabValue] = useState("");
  const [params, setParams] = useState({
    level1Approved: false,
    level2Approved: false,
    level1ApprovedBy: false,
    level2ApprovedBy: false,
  });

  const handleTabChange = (event, selectedTab) => {
    setTabValue(selectedTab);

    if (selectedTab) {
      let updatedControls = {
        level1Approved: false,
        level2Approved: false,
      };

      switch (selectedTab) {
        case "verified":
          updatedControls.level1Approved = true;
          updatedControls.level2Approved = true;
          break;
        case "level1Approved":
          updatedControls.level1Approved = true;
          updatedControls.level2Approved = false;
          break;
        case "unapproved":
          updatedControls.level1Approved = false;
          updatedControls.level2Approved = false;
          break;
        default:
          break;
      }
      setParams((prev) => ({ ...prev, ...updatedControls }));
    }
  };

  useEffect(() => {
    const currentUser: any = getUserData();
    setUserRole(currentUser.roleKey);
  }, []);

  let columns = [
    { name: "Root Word", options: { filterOptions: { fullWidth: true } } },
    { name: "Letter", options: { filterOptions: { fullWidth: true } } },
    {
      name: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellHeaderProps: () => {
          return { align: "right" };
        },
        setCellProps: () => ({
          align: "right",
        }),
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          return (
            <div className="d-inline-flex">
              <div className="px-2">
                {tabValue == "verified" && (
                  <Tooltip title={t("common.viewGroups")}>
                    <IconButton
                      aria-label="group"
                      size="small"
                      onClick={() => {
                        navigate(`/keyword/group/${allData[dataIndex].id}`); // Navigate to KeywordGroups
                      }}
                    >
                      <FontAwesomeIcon className="group-btn" icon="sitemap" />
                    </IconButton>
                  </Tooltip>
                )}
              </div>

              <div className="px-2">
                {tabValue == "verified" && (
                  <Tooltip title={t("common.edit")}>
                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={() => {
                        onEditClick(dataIndex, rowIndex, tabValue);
                      }}
                    >
                      <FontAwesomeIcon className="edit-btn" icon="edit" />
                    </IconButton>
                  </Tooltip>
                )}
              </div>

              <div className="px-2">
                {/*  delete confirmation dialog  */}
                {userRole === "admin" && (
                  <ConfirmationDialog
                    title={"Delete Confirmation"}
                    body={"Are you sure you want to delete this record?"}
                    type={"delete"}
                    isLoading={loading}
                    dataItemIdx={dataIndex}
                    confirmEvent={handleDelete}
                  />
                )}
              </div>

              <div className="px-2">
                {allData && tabValue == "verified" && (
                  <KeywordView data={allData[rowIndex]} />
                )}
              </div>

              <div className="ms-2 mt-2">
                {(tabValue == "unapproved" || tabValue == "level1Approved") && (
                  <Button
                    variant="contained"
                    color="success"
                    size="small"
                    style={{
                      height: "40px",
                      width: "100px",
                      fontSize: "0.75rem",
                    }}
                    onClick={() => {
                      onReviewClick(dataIndex, rowIndex, tabValue);
                    }}
                  >
                    Review
                  </Button>
                )}
              </div>
            </div>
          );
        },
      },
    },
  ];

  const [loading, setLoading] = React.useState(true);
  const [editMode, setEditMode] = React.useState(false);
  const [tableData, setTableData] = React.useState([]);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [isReviewMode, setIsReviewMode] = useState(false);

  // fetch dependent data
  const fetch = () => {
    setTableData([]); // reset table data
    setLoading(true); // show loader

    const {
      level1Approved,
      level1ApprovedBy,
      level2Approved,
      level2ApprovedBy,
    } = params;

    let url = `${moduleMain}?page=${currentPage}&size=${pageSize}`;

    url = url + "&level1Approved=" + level1Approved;
    url = url + "&level2Approved=" + level2Approved;

    getAllFiltered(url)
      .then((_res: any) => {
        // set all data
        setAllData(_res.rows);

        // set total count
        setTotalRowCount(_res.count);

        const rows: any = [];
        _res.rows.forEach((_record: any) => {
          rows.push(formatData([_record?.rootWord, _record?.letter]));
        });

        setTableData(rows);
        setLoading(false); // hide loader
      })
      .catch((_err) => {
        log(_err);
        setLoading(false); // hide loader
      });
  };

  useEffect(() => {
    const currentUser = getUserData();
    setUserRole(currentUser.roleKey);
    const defaultTabValue = getDefaultTabValue(currentUser.roleKey);

    if (!tabValue) {
      setTabValue(defaultTabValue);
      handleTabChange(null, defaultTabValue);
    }

    fetch();
  }, [params, currentPage, pageSize]);

  const getDefaultTabValue = (role) => {
    const roleTabs = roleTabMappings[role] || [];
    return roleTabs.length > 0 ? roleTabs[0].key : "";
  };

  // event handlers
  const onEditClick = (_dataIndex: any, _rowIndex: any, tabValue: any) => {
    setEditMode(true);
    setIsReviewMode(false);
    setDataToEdit(allData[_dataIndex]);
  };

  const onReviewClick = (_dataIndex: any, _rowIndex: any, tabValue: any)  => {
    setIsReviewMode(true);
    setEditMode(false);  
    setDataToEdit(allData[_dataIndex]);
  }

  const resetEdit = (_state) => {
    setEditMode(false);
  };

  const handleDelete = (_dataItemIndex: number) => {
    setLoading(true); // show spinner
    deleteById(moduleMain, allData[_dataItemIndex].id)
      .then(async (_res) => {
        setLoading(false); // hide loader
        // @ts-ignore
        notifications.current.successAlert(t("common.deleteSuccess"), "");
        fetch(); // refresh data
      })
      .catch((_err) => {
        log(_err);
        // @ts-ignore
        notifications.current.errorAlert(
          t("common.somethingWentWrong"),
          t("common.tryAgain")
        );
        setLoading(false); // hide loader
      });
  };

  let options = {
    search: true,
    download: true,
    print: true,
    viewColumns: true,
    filter: true,
    filterType: "textField",
    responsive: "simple",
    selectableRowsHideCheckboxes: true,
    sort: true,
    onChangePage: (currentPage) => {
      setCurrentPage(currentPage);
    },
    onChangeRowsPerPage: (numberOfRows) => {
      setPageSize(numberOfRows);
    },
    page: currentPage,
    count: totalRowCount,
    loading: true,
    rowsPerPageOptions: [25, 50, 75, 100],
    rowsPerPage: pageSize,
    onTableChange: (action: any, state: any) => {},
  };

  if (tableData.length === 0) {
    options = {
      textLabels: {
        body: {
          noMatch: loading ? (
            <Box>
              <Skeleton className="my-4" />
              <Skeleton className="my-4" />
              <Skeleton className="my-4" />
            </Box>
          ) : (
            "Sorry, there is no matching data to display"
          ),
        },
      },
    };
  }

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "99vh",
        backgroundColor: "rgba(222, 223, 223, 0.3)",
      }}
    >
      <CssBaseline />

      {/* Header */}
      <Header />

      {/* Sider */}
      <Sider />

      {/* Content */}
      <Box component="main" sx={{ flexGrow: 2, p: 3 }} mt={10}>
        <div className="px-1 px-md-5">
          {/* Upper Section */}
          <div className="row">
            <div className="col-md-10">
              <h2 className="mt-3">{"Hadith Root Word"}s</h2>
            </div>
            <div className="col-md-2">
              <KeywordCreate refreshDataEvent={fetch} />
            </div>
          </div>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              centered
              variant="scrollable"
              scrollButtons="auto"
            >
              {roleTabMappings[userRole]?.map((tab) => (
                <Tab key={tab.value} value={tab.key} label={tab.label} />
              ))}
            </Tabs>
          </Box>

          {tabValue === "verified" && (
            <div className="mt-5">
              <Paper elevation={12}>
                <MUIDataTable
                  title={t("common.allRecords")}
                  data={tableData}
                  columns={columns}
                  options={options}
                />
              </Paper>
            </div>
          )}
          {tabValue === "level1Approved" && (
            <div className="mt-5">
              <Paper elevation={12}>
                <MUIDataTable
                  title={t("common.allRecords")}
                  data={tableData}
                  columns={columns}
                  options={options}
                />
              </Paper>
            </div>
          )}
          {tabValue === "unapproved" && (
            <div className="mt-5">
              <Paper elevation={12}>
                <MUIDataTable
                  title={t("common.allRecords")}
                  data={tableData}
                  columns={columns}
                  options={options}
                />
              </Paper>
            </div>
          )}
        </div>

        {/* display edit modal */}
        {editMode && (
          <div>
            <KeywordUpdate
              refreshDataEvent={fetch}
              resetEditState={resetEdit}
              recordToUpdate={dataToEdit}
              isReviewMode={false}
              EditMode={editMode}
            />
          </div>
        )}

        {/* display review modal */}
        {isReviewMode && (
          <div>
            <KeywordUpdate
              refreshDataEvent={fetch}
              resetEditState={resetEdit}
              recordToUpdate={dataToEdit}
              isReviewMode={isReviewMode}
              EditMode={editMode}
              currentTabValue={tabValue}
            />
          </div>
        )}

        {/* Alerts */}
        <AlertM ref={notifications} />
      </Box>
    </Box>
  );
}

export default KeywordMain;
