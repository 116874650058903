//@ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import { useStyles } from "./Styles";
import { patch } from "../../../../../Services/GenericApiService";
import { useTranslation } from "react-i18next";
import { log } from "../../../../../Services/LoggerService";
import { useNavigate, useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import AlertM from "../../../../Helpers/AlertM/AlertM";
import HadithReviewForm from "../HadithReviewForm/HadithReviewForm";
import {
  Card,
  CardContent,
  CircularProgress,
  Typography,
  Paper,
  useTheme,
  useMediaQuery,
} from "@mui/material";

// @ts-ignore
function HadithUpdate({
  refreshDataEvent,
  recordToUpdate,
  resetEditState,
  narratorsData = [],
  hadithBookData = [],
  selectedTabValue,
  IsRejectEvet,
  IsConfirmEvent,
  IsResolveEvent,
  userRole,
  allHadithRecords,
  handleNextRecord,
}) {
  // css for module
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  // module(s) for api
  const moduleMain = "hadith";

  // translation
  const { t } = useTranslation();

  // navigation
  const navigate = useNavigate();
  const location = useLocation();

  // child ref for alert
  const notifications = useRef();

  // ui handlers
  const [open, setOpen] = useState(true);
  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    resetEdit();
  };
  const [loading, setLoading] = useState(false);

  // delay before closing update screen
  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  // Function to navigate to the same URL but without systemId parameter
  const navigateToCleanUrl = () => {
    try {
      const searchParams = new URLSearchParams(location.search);

      // Check if systemId parameter exists
      if (searchParams.has("systemId")) {
        // Remove systemId parameter
        searchParams.delete("systemId");

        // Construct new URL with the remaining parameters
        const newUrl = `${location.pathname}?${searchParams.toString()}`;

        // Navigate to the new URL
        console.log("Navigating to clean URL:", newUrl);
        navigate(newUrl, { replace: true });
      }
    } catch (error) {
      console.error("Error navigating to clean URL:", error);
    }
  };

  // handle edit record event
  const handleEditEvent = (_formData) => {
    setLoading(true); // show loader
    patch(moduleMain, _formData.id, _formData)
      .then(async (_res) => {
        // Show success notification
        notifications.current.successAlert(t("common.editSuccess"), "");

        // Wait before any navigation or state changes
        await sleep(500);

        // Navigate to the clean URL (without systemId)
        navigateToCleanUrl();

        // Small delay before closing modal
        await sleep(
          process.env.REACT_APP_UPDATE_NOTIFICATION_TIMEOUT_MS || 1000
        );

        setLoading(false); // hide loader
        refreshDataEvent(); // refresh data
        handleClose();
        resetEdit();
      })
      .catch((_err) => {
        log(_err);
        setLoading(false); // hide loader
        notifications.current.errorAlert(
          t("common.somethingWentWrong"),
          t("common.tryAgain")
        );
        resetEdit();
      });
  };

  // handle cancel event
  const handleCancelEvent = () => {
    handleClose();
    resetEdit();
  };

  // reset isEdit to false in main component
  const resetEdit = () => {
    resetEditState(true);
  };

  //Confirm Final Review for the Hadith
  const handleUpdateHadith = (_formData) => {
    setLoading(true); // show loader
    patch(moduleMain, _formData.id, _formData)
      .then(async (_res) => {
        // Show success notification
        notifications.current.successAlert(t("common.editSuccess"), "");

        // Wait briefly before any navigation
        await sleep(500);

        // Navigate to the clean URL (without systemId)
        navigateToCleanUrl();

        // Allow the URL to update and notification to be seen
        await sleep(
          process.env.REACT_APP_UPDATE_NOTIFICATION_TIMEOUT_MS || 1000
        );

        // If this is a confirm event, go to next record
        if (IsConfirmEvent) {
          handleNextRecord();
        } else {
          handleClose();
        }

        setLoading(false); // hide loader
        resetEdit();
      })
      .catch((_err) => {
        log(_err);
        setLoading(false); // hide loader
        notifications.current.errorAlert(
          t("common.somethingWentWrong"),
          t("common.tryAgain")
        );
        resetEdit();
      });
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={loading ? null : handleClose} // Prevent closing while loading
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 0,
        }}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor:
              theme.palette.mode === "dark"
                ? "rgba(0, 0, 0, 0.8)"
                : "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              boxShadow: 24,
              width: isMobile ? "100%" : "75%", // 75% width on desktop, 95% on mobile
            }}
          >
            {/* Always show the form, even during loading */}
            <Box sx={{ position: "relative", height: "100%", width: "100%" }}>
              <HadithReviewForm
                handleCancelEvent={handleCancelEvent}
                handleAddEvent={() => {}}
                handleUpdateHadith={handleUpdateHadith}
                isConfirmEvent={IsConfirmEvent}
                narratorsData={narratorsData}
                hadithBookData={hadithBookData}
                handleEditEvent={handleEditEvent}
                recordToUpdate={recordToUpdate}
                isLoading={loading}
                currentTabValue={selectedTabValue}
                isRejectEvent={IsRejectEvet}
                isResolveEvent={IsResolveEvent}
                userRole={userRole}
              />
            </Box>
          </Box>
        </Fade>
      </Modal>

      {/* Alerts */}
      <AlertM ref={notifications} />
    </div>
  );
}

export default HadithUpdate;
