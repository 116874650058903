// @ts-nocheck
import React, { useState, useEffect, useCallback, useRef } from "react";
import _ from "lodash";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import AlertM from "../../../../Helpers/AlertM/AlertM";
import {
  post,
  getAllFiltered,
  patchData,
} from "../../../../../Services/GenericApiService";
import {
  Card,
  CardContent,
  CardActions,
  Button,
  FormControl,
  TextField,
  Chip,
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Divider,
  Paper,
  Alert,
  Grid,
  IconButton,
  Tooltip,
  useTheme,
  Snackbar,
} from "@mui/material";

// @ts-ignore
function LabelForm({
  handleAddEvent,
  handleCancelEvent,
  isLoading,
  isEditMode,
  recordToUpdate,
  handleEditEvent,
}) {
  // translation
  const { t } = useTranslation();
  const theme = useTheme();

  // form data
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  // States
  const [labelData, setLabelData] = useState({
    nameEnglish: [],
    nameUrdu: [],
    nameArabic: [],
  });

  const [currentKeyword, setCurrentKeyword] = useState({
    nameEnglish: "",
    nameUrdu: "",
    nameArabic: "",
  });

  const [searchResults, setSearchResults] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [showResultDialog, setShowResultDialog] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [noMatchFound, setNoMatchFound] = useState(false);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);

  // Handle search for keywords
  const handleSearch = async () => {
    // Check if at least one field has a value
    if (
      !currentKeyword.nameEnglish &&
      !currentKeyword.nameUrdu &&
      !currentKeyword.nameArabic
    ) {
      return;
    }

    const searchValue =
      currentKeyword.nameEnglish ||
      currentKeyword.nameUrdu ||
      currentKeyword.nameArabic;

    setIsFetching(true);
    setSearchPerformed(false);

    try {
      const response = await getAllFiltered(
        `label?searchedLabel=${encodeURIComponent(searchValue)}`
      );

      if (
        response &&
        response.length > 0 &&
        (response[0].nameEnglish.length > 0 ||
          response[0].nameUrdu.length > 0 ||
          response[0].nameArabic.length > 0)
      ) {
        // Found matching keywords
        setSearchResults({
          ...response[0],
          nameEnglish: [...new Set(response[0].nameEnglish)],
          nameUrdu: [...new Set(response[0].nameUrdu)],
          nameArabic: [...new Set(response[0].nameArabic)],
        });
        setNoMatchFound(false);
      } else {
        // No matches found
        setNoMatchFound(true);
        setSearchResults(null);
      }

      // Show results dialog in both cases
      setShowResultDialog(true);
      setSearchPerformed(true);
    } catch (error) {
      console.error("Error searching labels:", error);
      setErrorMessage("Error searching for keywords. Please try again.");
      setShowSnackbar(true);
    } finally {
      setIsFetching(false);
    }
  };

  const handleInputChange = (e, field) => {
    const value = e.target.value;
    setCurrentKeyword({ ...currentKeyword, [field]: value });
  };

  const handleKeyDown = (e, field) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearch();
    }
  };

  // Create a new keyword list
  const handleCreateNewKeywords = async () => {
    // Create a new keywords list with values from all three languages
    const newKeywords = {
      nameEnglish: currentKeyword.nameEnglish
        ? [currentKeyword.nameEnglish]
        : [],
      nameUrdu: currentKeyword.nameUrdu ? [currentKeyword.nameUrdu] : [],
      nameArabic: currentKeyword.nameArabic ? [currentKeyword.nameArabic] : [],
      isDeleted: false,
    };

    setIsCreating(true);

    try {
      // Make API call to create new keyword list
      const response = await post("label", newKeywords);

      if (response && response.id) {
        // Get the created label with its ID
        const createdLabel = {
          ...newKeywords,
          id: response.id,
        };

        // Set success message and update state
        setSuccessMessage("New keyword list created successfully!");
        setLabelData(createdLabel);
        setShowResultDialog(false);
        setShowSuccessDialog(true);
      }
    } catch (error) {
      console.error("Error creating keywords:", error);
      setErrorMessage("Error creating new keyword list. Please try again.");
      setShowSnackbar(true);
    } finally {
      setIsCreating(false);
    }
  };

  // Update existing keywords list
  const handleUpdateExistingKeywords = async () => {
    if (!searchResults || !searchResults.id) {
      setErrorMessage("Cannot update: No existing keyword list found.");
      setShowSnackbar(true);
      return;
    }

    setIsUpdating(true);

    // Prepare updated data
    const updatedKeywords = {
      nameEnglish: [...new Set([...searchResults.nameEnglish])],
      nameUrdu: [...new Set([...searchResults.nameUrdu])],
      nameArabic: [...new Set([...searchResults.nameArabic])],
    };

    // Add current keywords to each field if they exist
    if (currentKeyword.nameEnglish) {
      updatedKeywords.nameEnglish = [
        ...new Set([
          ...updatedKeywords.nameEnglish,
          currentKeyword.nameEnglish,
        ]),
      ];
    }
    if (currentKeyword.nameUrdu) {
      updatedKeywords.nameUrdu = [
        ...new Set([...updatedKeywords.nameUrdu, currentKeyword.nameUrdu]),
      ];
    }
    if (currentKeyword.nameArabic) {
      updatedKeywords.nameArabic = [
        ...new Set([...updatedKeywords.nameArabic, currentKeyword.nameArabic]),
      ];
    }

    try {
      // Make API call to update
      await patchData(`label/${searchResults.id}`, updatedKeywords);

      // Fetch the updated label to get the latest data
      const response = await getAllFiltered(
        `label?searchedLabel=${encodeURIComponent(
          currentKeyword.nameEnglish ||
            currentKeyword.nameUrdu ||
            currentKeyword.nameArabic
        )}`
      );

      if (response && response.length > 0) {
        setLabelData(response[0]);
        setSuccessMessage("Keywords added to existing list successfully!");
        setShowResultDialog(false);
        setShowSuccessDialog(true);
      }
    } catch (error) {
      console.error("Error updating keywords:", error);
      setErrorMessage("Error updating keyword list. Please try again.");
      setShowSnackbar(true);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleAddAnotherKeyword = () => {
    // Close any open dialogs
    setShowResultDialog(false);
    setShowSuccessDialog(false);

    // Reset to initial state
    setCurrentKeyword({
      nameEnglish: "",
      nameUrdu: "",
      nameArabic: "",
    });
    setNoMatchFound(false);
    setSearchResults(null);
    setSearchPerformed(false);
    setLabelData({
      nameEnglish: [],
      nameUrdu: [],
      nameArabic: [],
    });
  };

  const handleCloseAll = () => {
    setShowResultDialog(false);
    setShowSuccessDialog(false);
    handleCancelEvent();
  };

  const onSubmit = () => {
    const submitData = {
      ...labelData,
      isDeleted: false,
    };

    if (!isEditMode && !labelData.id) {
      handleAddEvent(submitData);
    } else {
      handleEditEvent(submitData);
    }
  };

  const onCancel = () => {
    handleCancelEvent();
  };

  // Check if all three language fields have been filled
  const isCreateEnabled =
    !!currentKeyword.nameEnglish &&
    !!currentKeyword.nameUrdu &&
    !!currentKeyword.nameArabic;

  useEffect(() => {
    if (isEditMode && recordToUpdate) {
      setLabelData(recordToUpdate);
    }
  }, [isEditMode, recordToUpdate]);

  // Dark mode background and text colors
  const paperBgColor = theme.palette.mode === "dark" ? "#2d3748" : "#ffffff";
  const cardBgColor = theme.palette.mode === "dark" ? "#1a202c" : "#ffffff";
  const highlightBgColor =
    theme.palette.mode === "dark" ? "#2d3748" : "#f5f5f5";
  const successBgColor = theme.palette.mode === "dark" ? "#1b4332" : "#e8f5e9";
  const infoBgColor = theme.palette.mode === "dark" ? "#172a45" : "#e3f2fd";
  const borderColor = theme.palette.mode === "dark" ? "#4a5568" : "#e2e8f0";

  return (
    <Box sx={{ display: "flex" }}>
      <Card
        sx={{
          width: "60vw",
          maxHeight: "90vh",
          overflowY: "auto",
          bgcolor: cardBgColor,
          color: theme.palette.text.primary,
        }}
      >
        <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
          <CardContent>
            <div className="py-2 px-md-4 px-2">
              <div className="row mx-2">
                <div className="col-md-12 my-2">
                  <h4 className="pb-2">
                    {isEditMode ? t("common.edit") : t("common.add")}{" "}
                    {"Hadith Keywords"}
                  </h4>
                </div>

                <div className="col-md-12 mt-2">
                  <Paper
                    elevation={1}
                    sx={{
                      p: 3,
                      mb: 3,
                      bgcolor: paperBgColor,
                      borderRadius: 1,
                      border: `1px solid ${borderColor}`,
                    }}
                  >
                    <Typography variant="subtitle1" gutterBottom>
                      Enter keywords in all three languages
                    </Typography>

                    {["nameEnglish", "nameUrdu", "nameArabic"].map((field) => (
                      <div key={field} className="col-md-12 mt-3">
                        <FormControl fullWidth>
                          <TextField
                            label={
                              field === "nameEnglish"
                                ? "Name English"
                                : field === "nameUrdu"
                                ? "Name Urdu"
                                : "Name Arabic"
                            }
                            type="text"
                            value={currentKeyword[field]}
                            onChange={(e) => handleInputChange(e, field)}
                            onKeyDown={(e) => handleKeyDown(e, field)}
                            variant="outlined"
                            className="full-width"
                            InputProps={{
                              endAdornment: searchPerformed && (
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  {!noMatchFound ? (
                                    <Tooltip title="Match found in database">
                                      <CheckCircleIcon color="success" />
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title="No match found in database">
                                      <InfoIcon color="info" />
                                    </Tooltip>
                                  )}
                                </Box>
                              ),
                            }}
                          />
                        </FormControl>
                      </div>
                    ))}

                    <Box
                      sx={{ mt: 3, display: "flex", justifyContent: "center" }}
                    >
                      <LoadingButton
                        loading={isFetching}
                        variant="contained"
                        startIcon={<SearchIcon />}
                        onClick={handleSearch}
                        disabled={
                          !currentKeyword.nameEnglish &&
                          !currentKeyword.nameUrdu &&
                          !currentKeyword.nameArabic
                        }
                        className="bg-custom-gradient"
                        loadingPosition="start"
                      >
                        Search Existing Keywords
                      </LoadingButton>
                    </Box>
                  </Paper>
                </div>
              </div>
            </div>
          </CardContent>
          <CardActions>
            <div
              className="pb-4 pt-2 px-md-4 px-2"
              style={{ width: "100%", textAlign: "right" }}
            >
              <Button variant="outlined" onClick={onCancel}>
                {t("common.cancel")}
              </Button>
            </div>
          </CardActions>
        </form>
      </Card>

      {/* Results Dialog (used for both matching and no-match scenarios) */}
      <Dialog
        open={showResultDialog}
        onClose={() => setShowResultDialog(false)}
        aria-labelledby="result-dialog-title"
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: cardBgColor,
            color: theme.palette.text.primary,
          },
        }}
      >
        <DialogTitle
          id="result-dialog-title"
          sx={{ display: "flex", alignItems: "center", gap: 1 }}
        >
          {!noMatchFound ? (
            <>
              <CheckCircleIcon color="success" />
              Matching Keywords Found
            </>
          ) : (
            <>
              <InfoIcon color="info" />
              No Matching Keywords Found
            </>
          )}
        </DialogTitle>
        <DialogContent>
          {!noMatchFound && searchResults ? (
            <Box>
              <DialogContentText sx={{ color: theme.palette.text.secondary }}>
                We found existing keywords that match your search. Your new
                keywords will be added to this existing group.
              </DialogContentText>

              <Box
                sx={{
                  mt: 2,
                  p: 2,
                  bgcolor: successBgColor,
                  borderRadius: 1,
                  border: "1px solid",
                  borderColor:
                    theme.palette.mode === "dark"
                      ? "rgba(72, 187, 120, 0.3)"
                      : "rgba(72, 187, 120, 0.2)",
                }}
              >
                {["nameEnglish", "nameUrdu", "nameArabic"].map((field) =>
                  searchResults[field] && searchResults[field].length > 0 ? (
                    <div key={field} className="mb-2">
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: "bold" }}
                      >
                        {field === "nameEnglish"
                          ? "English Keywords"
                          : field === "nameUrdu"
                          ? "Urdu Keywords"
                          : "Arabic Keywords"}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 0.5,
                          mt: 0.5,
                        }}
                      >
                        {searchResults[field].map((value, index) => (
                          <Chip
                            key={index}
                            label={value}
                            size="small"
                            sx={{
                              bgcolor:
                                theme.palette.mode === "dark"
                                  ? "rgba(72, 187, 120, 0.2)"
                                  : undefined,
                              "& .MuiChip-label": {
                                color:
                                  theme.palette.mode === "dark"
                                    ? "white"
                                    : undefined,
                              },
                            }}
                          />
                        ))}
                      </Box>
                    </div>
                  ) : null
                )}
              </Box>
            </Box>
          ) : (
            <Box>
              <DialogContentText sx={{ color: theme.palette.text.secondary }}>
                No existing keywords match your search. You can create a new
                keywords list with the provided keywords.
              </DialogContentText>
            </Box>
          )}

          <Box
            sx={{
              mt: 3,
              p: 2,
              bgcolor: highlightBgColor,
              borderRadius: 1,
              border: "1px solid",
              borderColor:
                theme.palette.mode === "dark"
                  ? "rgba(160, 174, 192, 0.3)"
                  : "rgba(160, 174, 192, 0.2)",
            }}
          >
            <Typography variant="subtitle2" sx={{ fontWeight: "bold", mb: 1 }}>
              Your keywords:
            </Typography>
            {["nameEnglish", "nameUrdu", "nameArabic"].map((field) => (
              <Typography
                key={field}
                variant="body2"
                color={
                  currentKeyword[field]
                    ? theme.palette.mode === "dark"
                      ? "primary.light"
                      : "primary.main"
                    : theme.palette.mode === "dark"
                    ? "error.light"
                    : "error.main"
                }
              >
                <strong>
                  {field === "nameEnglish"
                    ? "English"
                    : field === "nameUrdu"
                    ? "Urdu"
                    : "Arabic"}
                  :
                </strong>{" "}
                {currentKeyword[field] || "Not provided"}
              </Typography>
            ))}
          </Box>

          {noMatchFound && !isCreateEnabled && (
            <Alert
              severity="warning"
              sx={{
                mt: 2,
                bgcolor:
                  theme.palette.mode === "dark"
                    ? "rgba(237, 137, 54, 0.2)"
                    : undefined,
                color: theme.palette.mode === "dark" ? "white" : undefined,
                "& .MuiAlert-icon": {
                  color: theme.palette.mode === "dark" ? "orange" : undefined,
                },
              }}
            >
              Please provide keywords in all three languages to create a new
              list.
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowResultDialog(false)}
            sx={{ color: theme.palette.text.primary }}
          >
            Cancel
          </Button>

          {!noMatchFound && (
            <LoadingButton
              loading={isUpdating}
              onClick={handleUpdateExistingKeywords}
              variant="contained"
              loadingPosition="start"
              startIcon={<AddIcon />}
            >
              Add to Existing List
            </LoadingButton>
          )}

          {noMatchFound && (
            <LoadingButton
              loading={isCreating}
              onClick={handleCreateNewKeywords}
              disabled={!isCreateEnabled}
              variant="contained"
              loadingPosition="start"
              startIcon={<AddIcon />}
            >
              Create New List
            </LoadingButton>
          )}
        </DialogActions>
      </Dialog>

      {/* Success Dialog */}
      <Dialog
        open={showSuccessDialog}
        aria-labelledby="success-dialog-title"
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: cardBgColor,
            color: theme.palette.text.primary,
          },
        }}
      >
        <DialogTitle
          id="success-dialog-title"
          sx={{ display: "flex", alignItems: "center", gap: 1 }}
        >
          <CheckCircleIcon color="success" />
          Operation Successful
        </DialogTitle>
        <DialogContent>
          <Alert
            severity="success"
            sx={{
              mb: 3,
              bgcolor:
                theme.palette.mode === "dark"
                  ? "rgba(72, 187, 120, 0.2)"
                  : undefined,
              color: theme.palette.mode === "dark" ? "white" : undefined,
            }}
          >
            {successMessage}
          </Alert>

          <Typography variant="subtitle1" gutterBottom>
            Keywords List:
          </Typography>

          <Box
            sx={{
              mt: 1,
              p: 2,
              bgcolor: successBgColor,
              borderRadius: 1,
              border: "1px solid",
              borderColor:
                theme.palette.mode === "dark"
                  ? "rgba(72, 187, 120, 0.3)"
                  : "rgba(72, 187, 120, 0.2)",
            }}
          >
            {["nameEnglish", "nameUrdu", "nameArabic"].map((field) =>
              labelData[field] && labelData[field].length > 0 ? (
                <div key={field} className="mb-2">
                  <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                    {field === "nameEnglish"
                      ? "English Keywords"
                      : field === "nameUrdu"
                      ? "Urdu Keywords"
                      : "Arabic Keywords"}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 0.5,
                      mt: 0.5,
                    }}
                  >
                    {labelData[field].map((value, index) => (
                      <Chip
                        key={index}
                        label={value}
                        size="small"
                        sx={{
                          bgcolor:
                            theme.palette.mode === "dark"
                              ? "rgba(72, 187, 120, 0.2)"
                              : undefined,
                          "& .MuiChip-label": {
                            color:
                              theme.palette.mode === "dark"
                                ? "white"
                                : undefined,
                          },
                        }}
                      />
                    ))}
                  </Box>
                </div>
              ) : null
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseAll}
            sx={{ color: theme.palette.text.primary }}
          >
            Close
          </Button>
          <Button
            onClick={handleAddAnotherKeyword}
            variant="contained"
            startIcon={<AddIcon />}
          >
            Add Another Keyword
          </Button>
        </DialogActions>
      </Dialog>

      {/* Error Snackbar */}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setShowSnackbar(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default LabelForm;
