// @ts-nocheck
import { SESSION_EXPIRED, SESSION_RESTORED } from "./Types";

const initialState = {
  sessionExpired: false,
  currentPath: "",
};

const AuthSessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SESSION_EXPIRED:
      console.log("Reducer handling SESSION_EXPIRED action");
      return {
        ...state,
        sessionExpired: true,
        currentPath: window.location.pathname.replace("/", ""),
      };
    case SESSION_RESTORED:
      console.log("Reducer handling SESSION_RESTORED action");
      return {
        ...state,
        sessionExpired: false,
        currentPath: "",
      };
    default:
      return state;
  }
};

export default AuthSessionReducer;
