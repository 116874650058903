// @ts-nocheck
// HadithTranslationText.tsx
import React, { useState, useRef, useEffect, useMemo } from "react";
import {
  Card,
  Box,
  Typography,
  Button,
  FormControl,
  TextField,
  Alert,
  AlertTitle,
  Collapse,
  CircularProgress,
  Chip,
  Tooltip,
  Fade,
  IconButton,
  ButtonGroup,
  Badge,
  useTheme,
} from "@mui/material";
import { patch, post } from "../../../../../Services/GenericApiService";
import { log } from "../../../../../Services/LoggerService";
import { franc } from "franc-min";
import FeedbackIcon from "@mui/icons-material/Feedback";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CheckIcon from "@mui/icons-material/Check";
import InsertTextIcon from "@mui/icons-material/LibraryBooks";
import CloseIcon from "@mui/icons-material/Close";

const HadithTranslationText = ({
  recordToUpdate,
  isEditMode,
  toggleEditMode,
  isAnyEditModeActive,
  validationStatus,
  setValidationStatus,
  register,
  errors,
  watch,
  setValue,
  getValues,
  setLoader,
  notifications,
  t,
  theme,
  toggleSection,
  isActive,
  isMobile,
  currentTabValue,
  AudioPlayerComponent,
}) => {
  const [aiSuggestion, setAiSuggestion] = useState(null);
  const [direction, setDirection] = useState("ltr");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFetchingAI, setIsFetchingAI] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [aiApplied, setAiApplied] = useState(false);
  const textFieldRef = useRef(null);
  const localTheme = useTheme();

  // Function to determine the direction based on language code
  const getDirection = (lang) => {
    const rtlLanguages = ["urd", "arb"];
    return rtlLanguages.includes(lang) ? "rtl" : "ltr";
  };

  const handleInputChange = (value) => {
    const lang = franc(value);
    const newDirection = getDirection(lang);
    setDirection(newDirection);
  };

  useEffect(() => {
    if (recordToUpdate?.translation) {
      handleInputChange(recordToUpdate.translation);
    }
  }, [recordToUpdate]);

  // Function to update translation text
  const updateHadithTranslationText = async () => {
    const data = getValues();

    let translationData = {
      translation: data.translation,
    };

    setIsSubmitting(true);
    setLoader((prevState) => ({ ...prevState, modal: true }));

    try {
      await patch("hadith/translationText", data.id, translationData);
      setLoader((prevState) => ({ ...prevState, modal: false }));
      toggleEditMode("translation");

      // Show success indicator
      setSaveSuccess(true);
      setTimeout(() => setSaveSuccess(false), 3000);

      notifications.current.successAlert(t("common.editSuccess"), "");
    } catch (error) {
      log(error);
      setLoader((prevState) => ({ ...prevState, modal: false }));
      notifications.current.errorAlert(
        t("common.somethingWentWrong"),
        t("common.tryAgain")
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleTextAdd = (textToAdd) => {
    const input = textFieldRef.current;

    if (input) {
      const startPos = input.selectionStart;
      const endPos = input.selectionEnd;
      const currentText = input.value;
      const newText =
        currentText.substring(0, startPos) +
        textToAdd +
        currentText.substring(endPos, currentText.length);

      setValue("translation", newText);

      // Set the cursor position after the inserted text
      setTimeout(() => {
        input.focus();
        input.setSelectionRange(
          startPos + textToAdd.length,
          startPos + textToAdd.length
        );
      }, 0);
    }
  };

  // Calls the AI suggestion endpoint and stores the result.
  const getAiSuggestion = async () => {
    try {
      setIsFetchingAI(true);
      setLoader((prevState) => ({ ...prevState, modal: true }));
      const body = { hadithTranslationId: recordToUpdate?.id };
      const res = await post(`hadith/quality-check`, body);
      setLoader((prevState) => ({ ...prevState, modal: false }));
      setAiSuggestion(res);
    } catch (err) {
      console.error(err);
      setLoader((prevState) => ({ ...prevState, modal: false }));
      notifications.current.errorAlert(
        t("common.somethingWentWrong"),
        t("common.tryAgain")
      );
    } finally {
      setIsFetchingAI(false);
    }
  };

  const handleApplySuggestion = () => {
    setValue("translation", aiSuggestion.correctedText);
    setAiApplied(true);
    setTimeout(() => setAiApplied(false), 3000);
  };

  // Handle edit mode toggle with accordion sync
  const handleEditToggle = () => {
    const newEditMode = !isEditMode;

    // If turning on edit mode and accordion is closed, open it
    if (newEditMode && !isActive) {
      toggleSection("translation");
    }

    // If turning off edit mode and accordion is open, close it
    if (!newEditMode && isActive) {
      toggleSection("translation");
    }

    toggleEditMode("translation");
  };

  // Memoize the formatted phrases to prevent unnecessary re-renders
  const commonPhrases = useMemo(
    () => [
      { id: 1, text: "صلى الله عليه وسلم" },
      { id: 2, text: "رحمة الله عليه" },
      { id: 3, text: "رضي الله عنها" },
      { id: 4, text: "رضي الله عنه" },
      { id: 5, text: "عَلَيْهِ ٱلسَّلَامُ" },
    ],
    []
  );

  // Function to get alert severity based on AI suggestion
  const getAlertSeverity = (alertLevel) => {
    if (!alertLevel) return "info";
    if (alertLevel === "critical issues") return "error";
    if (alertLevel === "medium level issues") return "warning";
    return "success";
  };

  return (
    <Card
      variant="outlined"
      sx={{
        mb: 2,
        boxShadow: "0 4px 6px rgba(0,0,0,0.05)",
        borderColor: validationStatus.translation
          ? "success.light"
          : "error.light",
        borderWidth: 1,
        position: "relative",
        transition: "all 0.2s ease",
        "&:hover": {
          boxShadow: "0 6px 10px rgba(0,0,0,0.1)",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          p: 2,
          borderBottom: "1px solid",
          borderColor: "divider",
          cursor: "pointer",
          bgcolor:
            localTheme.palette.mode === "dark"
              ? "rgba(255,255,255,0.05)"
              : "rgba(0,0,0,0.03)",
          transition: "background-color 0.2s ease",
          "&:hover": {
            bgcolor:
              localTheme.palette.mode === "dark"
                ? "rgba(255,255,255,0.08)"
                : "rgba(0,0,0,0.05)",
          },
        }}
        onClick={() => toggleSection("translation")}
      >
        <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
          {validationStatus.translation ? (
            <CheckCircleOutlineIcon color="success" sx={{ mr: 1 }} />
          ) : (
            <ErrorOutlineIcon color="error" sx={{ mr: 1 }} />
          )}
          <Typography variant="h6">Translation</Typography>

          {isEditMode && (
            <Chip
              size="small"
              label="Editing Mode"
              color="primary"
              variant="outlined"
              sx={{ ml: 2 }}
            />
          )}

          {saveSuccess && (
            <Fade in={saveSuccess} timeout={300}>
              <Chip
                label="Saved successfully"
                color="success"
                size="small"
                icon={<CheckIcon />}
                sx={{ ml: 2 }}
              />
            </Fade>
          )}
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          {currentTabValue === "toBeQc" &&
            recordToUpdate?.id &&
            AudioPlayerComponent && (
              <AudioPlayerComponent hadithTranslationId={recordToUpdate.id} />
            )}

          {isEditMode ? (
            <ButtonGroup
              variant="contained"
              size={isMobile ? "small" : "medium"}
              sx={{ ml: 1 }}
            >
              <Button
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  updateHadithTranslationText();
                }}
                disabled={isSubmitting}
                startIcon={
                  isSubmitting ? (
                    <CircularProgress size={16} color="inherit" />
                  ) : (
                    <SaveIcon />
                  )
                }
              >
                {isSubmitting ? "Saving..." : "Save"}
              </Button>
              <Button
                color="secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  handleEditToggle();
                }}
                // startIcon={<CheckIcon />}
              >
                Cancel
              </Button>
            </ButtonGroup>
          ) : (
            <Button
              variant="outlined"
              disabled={isAnyEditModeActive && !isEditMode}
              onClick={(e) => {
                e.stopPropagation();
                handleEditToggle();
              }}
              color="primary"
              size={isMobile ? "small" : "medium"}
              startIcon={<EditIcon />}
            >
              Edit
            </Button>
          )}

          {isActive ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Box>
      </Box>

      <Collapse in={isActive} timeout={300}>
        <Box sx={{ p: 3 }}>
          {!validationStatus.translation && (
            <Alert severity="warning" sx={{ mb: 2 }}>
              Please enter the hadith translation text
            </Alert>
          )}

          <FormControl fullWidth>
            <TextField
              disabled={!isEditMode}
              id="translationInput"
              {...register("translation", { required: true })}
              label={t("hadith.translation")}
              type="text"
              error={!!errors.translation}
              multiline
              rows={5}
              variant="outlined"
              className="full-width"
              inputRef={textFieldRef}
              onChange={(e) => {
                handleInputChange(e.target.value);
              }}
              InputProps={{
                style: { direction },
                sx: {
                  backgroundColor: isEditMode
                    ? localTheme.palette.mode === "dark"
                      ? "rgba(255,255,255,0.03)"
                      : "rgba(0,0,0,0.01)"
                    : "transparent",
                },
              }}
              sx={{ mb: 2 }}
              placeholder={isEditMode ? "Enter translation text here..." : ""}
              helperText={
                errors.translation ? "Translation text is required" : ""
              }
            />
          </FormControl>

          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 1,
              mb: 2,
              p: 2,
              borderRadius: 1,
              bgcolor:
                localTheme.palette.mode === "dark"
                  ? "rgba(255,255,255,0.03)"
                  : "rgba(0,0,0,0.01)",
              border: "1px solid",
              borderColor: "divider",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                width: "100%",
                mb: 1,
                display: "flex",
                alignItems: "center",
              }}
            >
              <InsertTextIcon fontSize="small" sx={{ mr: 0.5 }} /> Common
              Phrases
            </Typography>

            {commonPhrases.map((phrase) => (
              <Tooltip
                key={phrase.id}
                title="Click to insert at cursor position"
              >
                <Button
                  disabled={!isEditMode}
                  variant="outlined"
                  size="small"
                  onClick={() => handleTextAdd(phrase.text)}
                  sx={{
                    transition: "all 0.2s ease",
                    "&:hover": {
                      transform: isEditMode ? "scale(1.02)" : "none",
                    },
                  }}
                >
                  {phrase.text}
                </Button>
              </Tooltip>
            ))}
          </Box>

          {isEditMode && (
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={getAiSuggestion}
                disabled={isFetchingAI || true}
                startIcon={
                  isFetchingAI ? (
                    <CircularProgress size={16} color="inherit" />
                  ) : (
                    <FeedbackIcon />
                  )
                }
              >
                {isFetchingAI ? "Fetching suggestions..." : "AI Suggestions"}
              </Button>
            </Box>
          )}

          {/* AI Suggestion Display */}
          {aiSuggestion && (
            <Fade in={!!aiSuggestion}>
              <Box
                sx={{
                  mt: 3,
                  p: 2,
                  bgcolor:
                    localTheme.palette.mode === "dark"
                      ? "rgba(255,255,255,0.05)"
                      : "rgba(0,0,0,0.02)",
                  borderRadius: 1,
                  border: "1px solid",
                  borderColor: "divider",
                  position: "relative",
                }}
              >
                <Box sx={{ position: "absolute", top: 8, right: 8 }}>
                  <IconButton
                    size="small"
                    onClick={() => setAiSuggestion(null)}
                    color="default"
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Box>

                <Alert
                  severity={getAlertSeverity(aiSuggestion.alertLevel)}
                  sx={{ mb: 2 }}
                  variant="outlined"
                >
                  <AlertTitle>AI Suggestion</AlertTitle>
                  {aiSuggestion.issueDescription ||
                    "No significant issues found."}
                </Alert>

                <Typography
                  variant="subtitle1"
                  sx={{ mt: 2, mb: 1, fontWeight: "medium" }}
                >
                  Corrected Text:
                </Typography>

                <TextField
                  disabled={true}
                  type="text"
                  multiline
                  rows={3}
                  variant="outlined"
                  fullWidth
                  value={aiSuggestion.correctedText}
                  InputProps={{
                    style: { direction },
                    sx: {
                      bgcolor:
                        localTheme.palette.mode === "dark"
                          ? "rgba(255,255,255,0.02)"
                          : "rgba(255,255,255,0.7)",
                    },
                  }}
                  sx={{ mb: 2 }}
                />

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleApplySuggestion}
                  disabled={!isEditMode || aiApplied}
                  startIcon={aiApplied ? <CheckIcon /> : null}
                >
                  {aiApplied ? "Applied" : "Apply Suggestion"}
                </Button>
              </Box>
            </Fade>
          )}
        </Box>
      </Collapse>

      {/* Edit mode indicator */}
      {isEditMode && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "4px",
            height: "100%",
            bgcolor: "primary.main",
            borderTopLeftRadius: "4px",
            borderBottomLeftRadius: "4px",
          }}
        />
      )}
    </Card>
  );
};

export default HadithTranslationText;
