// @ts-nocheck
// HadithComments.tsx
import React, { useState } from "react";
import {
  Card,
  Box,
  Typography,
  Button,
  TextField,
  Collapse,
} from "@mui/material";
import { patch } from "../../../../../Services/GenericApiService";
import { log } from "../../../../../Services/LoggerService";
import FeedbackIcon from "@mui/icons-material/Feedback";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const HadithComments = ({
  recordToUpdate,
  isEditMode,
  toggleEditMode,
  isAnyEditModeActive,
  setLoader,
  notifications,
  t,
  theme,
  toggleSection,
  isActive,
  isMobile,
}) => {
  const [newComment, setNewComment] = useState("");

  const handleInputCommentChange = (event) => {
    setNewComment(event.target.value);
  };

  const handleAddComment = () => {
    if (!newComment.trim()) return;

    const prevComments = recordToUpdate?.comments;
    let updatedComments;

    if (prevComments) {
      updatedComments = `${prevComments}\n${newComment}`;
    } else {
      updatedComments = newComment;
    }

    let updatedData = {
      hadithId: recordToUpdate.hadithId,
      comments: updatedComments,
    };

    setLoader((prevState) => ({ ...prevState, modal: true }));
    patch("hadith/comments", recordToUpdate.id, updatedData)
      .then(async (_res) => {
        // Update local state to show the comment immediately
        recordToUpdate.comments = updatedComments;

        setNewComment("");
        setLoader((prevState) => ({ ...prevState, modal: false }));
        toggleEditMode("comments");
        notifications.current.successAlert(t("common.editSuccess"), "");
      })
      .catch((_err) => {
        log(_err);
        setLoader((prevState) => ({ ...prevState, modal: false }));
        notifications.current.errorAlert(
          t("common.somethingWentWrong"),
          t("common.tryAgain")
        );
      });
  };

  return (
    <Card
      variant="outlined"
      sx={{
        mb: 2,
        boxShadow: "0 4px 6px rgba(0,0,0,0.05)",
        mt: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          p: 2,
          borderBottom: "1px solid",
          borderColor: "divider",
          cursor: "pointer",
          bgcolor:
            theme.palette.mode === "dark"
              ? "rgba(255,255,255,0.05)"
              : "rgba(0,0,0,0.03)",
        }}
        onClick={() => toggleSection("comments")}
      >
        <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
          <FeedbackIcon sx={{ mr: 1 }} />
          <Typography variant="h6">Comments</Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Button
            variant={isEditMode ? "contained" : "outlined"}
            disabled={isAnyEditModeActive && !isEditMode}
            onClick={() => toggleEditMode("comments")}
            color={isEditMode ? "success" : "primary"}
            size={isMobile ? "small" : "medium"}
          >
            {isEditMode ? "Editing" : "Edit"}
          </Button>

          {isActive ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Box>
      </Box>

      <Collapse in={isActive}>
        <Box sx={{ p: 3 }}>
          <Typography
            variant="body1"
            className="mb-3"
            sx={{
              p: 2,
              bgcolor:
                theme.palette.mode === "dark"
                  ? "rgba(255,255,255,0.05)"
                  : "rgba(0,0,0,0.02)",
              borderRadius: 1,
              whiteSpace: "pre-line",
              mb: 2,
            }}
          >
            {recordToUpdate?.comments || "No comments yet."}
          </Typography>

          <TextField
            disabled={!isEditMode}
            label="New Comment"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={newComment}
            onChange={handleInputCommentChange}
            sx={{ mb: 2 }}
          />

          {isEditMode && (
            <Button
              disabled={!isEditMode || !newComment.trim()}
              variant="contained"
              color="primary"
              onClick={handleAddComment}
            >
              Submit Comment
            </Button>
          )}
        </Box>
      </Collapse>
    </Card>
  );
};

export default HadithComments;
