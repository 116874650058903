// @ts-nocheck
// KeywordsManager.tsx
import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Card,
  CardContent,
  Button,
  Typography,
  FormControl,
  Autocomplete,
  TextField,
  Chip,
  IconButton,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  Alert,
  Collapse,
  useTheme,
  useMediaQuery,
  Paper,
  Tooltip,
  Fade,
  Skeleton,
  Badge,
  ButtonGroup,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import SearchIcon from "@mui/icons-material/Search";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import _ from "lodash";
import {
  post,
  getAllFiltered,
} from "../../../../../Services/GenericApiService";
import AlertM from "../../../../Helpers/AlertM/AlertM";

// Arabic keyboard layout
const arabicKeyboardLayout = [
  ["ض", "ص", "ث", "ق", "ف", "غ", "ع", "ه", "خ", "ح", "ج", "د"],
  ["ش", "س", "ي", "ب", "ل", "ا", "ت", "ن", "م", "ك", "ط"],
  ["ئ", "ء", "ؤ", "ر", "لا", "ى", "ة", "و", "ز", "ظ"],
];

const KeywordsManager = ({
  hadithId,
  isAnyEditModeActive,
  onEditModeChange,
  linkedKeywords,
  setLinkedKeywords,
  hadithTranslationId,
  setNoRelativeKeywords,
  noRelativeKeywords,
  validationStatus,
  theme: parentTheme,
  toggleSection,
  activeSections,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [allKeywords, setAllKeywords] = useState([]);
  const [isKeywordsEditMode, setIsKeywordsEditMode] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [showArabicKeyboard, setShowArabicKeyboard] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isOperationLoading, setIsOperationLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [confirmationDialog, setConfirmationDialog] = useState({
    open: false,
    type: "",
    keyword: null,
  });
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const alertRef = useRef(null);
  const searchInputRef = useRef(null);

  // State to track original values for change detection
  const [originalValues, setOriginalValues] = useState({
    linkedKeywords: [],
    noRelativeKeywords: false,
  });

  // State to track if there are unsaved changes
  const [hasChanges, setHasChanges] = useState(false);

  // Check validation status
  const isValid = () => {
    return noRelativeKeywords || linkedKeywords.length > 0;
  };

  // Store original values when entering edit mode
  useEffect(() => {
    if (isKeywordsEditMode) {
      setOriginalValues({
        linkedKeywords: JSON.parse(JSON.stringify(linkedKeywords)),
        noRelativeKeywords: noRelativeKeywords,
      });
    }
  }, [isKeywordsEditMode]);

  // Check for changes whenever relevant values change
  useEffect(() => {
    if (isKeywordsEditMode) {
      // Simple length check first for linkedKeywords
      if (linkedKeywords.length !== originalValues.linkedKeywords.length) {
        setHasChanges(true);
        return;
      }

      // Check if no relative keywords flag has changed
      if (noRelativeKeywords !== originalValues.noRelativeKeywords) {
        setHasChanges(true);
        return;
      }

      // Check if any keyword has changed by comparing IDs
      const originalIds = originalValues.linkedKeywords
        .map((kw) => kw.id)
        .sort();
      const currentIds = linkedKeywords.map((kw) => kw.id).sort();

      const changed = !_.isEqual(originalIds, currentIds);
      setHasChanges(changed);
    }
  }, [linkedKeywords, noRelativeKeywords, isKeywordsEditMode]);

  const fetchKeywords = async (query) => {
    if (!query) return;

    try {
      setIsFetching(true);
      const params = query ? `searchedKeyword=${query}` : "";
      const url = `keyword?${params}`;

      const _res = await getAllFiltered(url);
      const keywordData = _res.map((keyword) => ({
        id: keyword.id,
        label: keyword.rootWord,
      }));
      setAllKeywords(keywordData);
    } catch (error) {
      console.error("Error fetching keywords:", error);
      alertRef.current?.errorAlert("Error", "Failed to fetch keywords.");
    } finally {
      setIsFetching(false);
    }
  };

  const fetchLinkedKeywords = async () => {
    try {
      setInitialLoading(true);
      const url = `keyword?linkedHadithId=${hadithId}`;
      const _res = await getAllFiltered(url);
      const linkedKeywordData = _res.map((keyword) => ({
        id: keyword.id,
        label: keyword.rootWord,
      }));
      setLinkedKeywords(linkedKeywordData);
    } catch (error) {
      console.error("Error fetching linked keywords:", error);
      alertRef.current?.errorAlert("Error", "Failed to fetch linked keywords.");
    } finally {
      setInitialLoading(false);
    }
  };

  const addKeyword = (event, value) => {
    if (value != null) {
      // Check if keyword is already linked
      const isDuplicate = linkedKeywords.some(
        (keyword) => keyword.id === value.id
      );

      if (isDuplicate) {
        alertRef.current?.warningAlert(
          "Warning",
          "This root word is already linked."
        );
        return;
      }

      setConfirmationDialog({
        open: true,
        type: "link",
        keyword: value,
      });

      // Clear input after selection
      setInputValue("");
      setSearchTerm("");
    }
  };

  const confirmAction = async () => {
    const { type, keyword } = confirmationDialog;
    setIsOperationLoading(true);
    try {
      if (type === "link") {
        // Get current keyword IDs to check for duplicates
        const currentKeywordIds = linkedKeywords.map((kw) => kw.id);

        // Only proceed if not already linked
        if (!currentKeywordIds.includes(keyword.id)) {
          await post("hadithkeyword/link", {
            hadithId,
            keywordId: keyword.id,
            hadithTranslationId: hadithTranslationId,
          });

          // Optimistic update
          setLinkedKeywords((prev) => [...prev, keyword]);

          alertRef.current?.successAlert(
            "Success",
            "Root Word linked successfully."
          );
        } else {
          alertRef.current?.warningAlert(
            "Warning",
            "This Root Word is already linked."
          );
        }
      } else if (type === "delete") {
        await post("hadithkeyword/unlink", {
          hadithId,
          keywordId: keyword.id,
          hadithTranslationId: hadithTranslationId,
        });

        // Optimistic update
        setLinkedKeywords((prev) =>
          prev.filter((item) => item.id !== keyword.id)
        );

        alertRef.current?.successAlert(
          "Success",
          "Root Word unlinked successfully."
        );
      }
    } catch (error) {
      console.error("Error during operation:", error);
      alertRef.current?.errorAlert(
        "Error",
        "Operation failed. Please try again."
      );
      // Refresh to ensure UI is in sync with backend
      await fetchLinkedKeywords();
    } finally {
      setTimeout(() => {
        setIsOperationLoading(false);
        setConfirmationDialog({ open: false, type: "", keyword: null });
      }, 500); // Short delay to ensure the loader is visible
    }
  };

  const cancelAction = () => {
    setConfirmationDialog({ open: false, type: "", keyword: null });
  };

  const handleInputChange = (event, newInputValue) => {
    // Check if this is a direct keyboard input or a programmatic change
    // For keyboard input, keep the value as is
    setInputValue(newInputValue);
    setSearchTerm(newInputValue);
    debounceSearch(newInputValue);
  };

  const handleArabicLetterClick = (letter) => {
    const updatedTerm = inputValue + letter;
    setInputValue(updatedTerm);
    setSearchTerm(updatedTerm);
    // Focus the search input after clicking a letter
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  };

  const handleDeleteKeyword = (keyword) => {
    setConfirmationDialog({
      open: true,
      type: "delete",
      keyword,
    });
  };

  const debounceSearch = useCallback(
    _.debounce((query) => {
      fetchKeywords(query);
    }, 500),
    []
  );

  useEffect(() => {
    fetchLinkedKeywords(); // Fetch linked keywords on component mount
  }, [hadithId]);

  useEffect(() => {
    if (searchTerm) {
      debounceSearch(searchTerm);
    }
  }, [searchTerm, debounceSearch]);

  // Save changes
  const saveChanges = async () => {
    setIsSaving(true);

    try {
      // Actual save logic would go here if needed
      // In this component, it seems like changes are saved immediately
      // through the confirmAction function for each operation

      // Finalize edit mode and show success
      setSaveSuccess(true);
      setTimeout(() => setSaveSuccess(false), 3000);
      setHasChanges(false);

      // Exit edit mode and close accordion
      exitEditMode();
    } catch (error) {
      console.error("Error saving changes:", error);
      alertRef.current?.errorAlert("Error", "Failed to save changes.");
    } finally {
      setIsSaving(false);
    }
  };

  // Cancel editing and restore original values
  const cancelEditing = () => {
    // Restore original values
    setLinkedKeywords(JSON.parse(JSON.stringify(linkedKeywords)));
    setNoRelativeKeywords(originalValues.noRelativeKeywords);

    // Exit edit mode
    exitEditMode();
  };

  // Common function to exit edit mode
  const exitEditMode = () => {
    setIsKeywordsEditMode(false);
    onEditModeChange(false);

    // Close the accordion
    if (toggleSection && activeSections) {
      toggleSection("keywords");
    }

    setHasChanges(false);
  };

  // Toggle edit mode
  const toggleEditMode = () => {
    const newEditMode = !isKeywordsEditMode;
    setIsKeywordsEditMode(newEditMode);
    onEditModeChange(newEditMode);

    if (newEditMode) {
      // When entering edit mode (clicking "Edit")
      setShowArabicKeyboard(false);

      // Open the accordion when entering edit mode
      if (toggleSection && !activeSections) {
        toggleSection("keywords");
      }
    }
  };

  // Focus search input when entering edit mode
  useEffect(() => {
    if (isKeywordsEditMode && searchInputRef.current) {
      setTimeout(() => {
        searchInputRef.current.focus();
      }, 300);
    }
  }, [isKeywordsEditMode]);

  return (
    <Card
      variant="outlined"
      sx={{
        mb: 2,
        boxShadow: "0 4px 6px rgba(0,0,0,0.05)",
        borderColor: isValid() ? "success.light" : "error.light",
        borderWidth: 1,
        overflow: "hidden",
        position: "relative",
        transition: "all 0.3s ease",
        "&:hover": {
          boxShadow: "0 6px 10px rgba(0,0,0,0.1)",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          p: 2,
          borderBottom: "1px solid",
          borderColor: "divider",
          cursor: "pointer",
          bgcolor:
            theme.palette.mode === "dark"
              ? "rgba(255,255,255,0.05)"
              : "rgba(0,0,0,0.03)",
          transition: "background-color 0.2s ease",
          "&:hover": {
            bgcolor:
              theme.palette.mode === "dark"
                ? "rgba(255,255,255,0.08)"
                : "rgba(0,0,0,0.05)",
          },
        }}
        onClick={() => (toggleSection ? toggleSection("keywords") : null)}
      >
        <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
          {isValid() ? (
            <CheckCircleOutlineIcon color="success" sx={{ mr: 1 }} />
          ) : (
            <ErrorOutlineIcon color="error" sx={{ mr: 1 }} />
          )}
          <Typography variant="h6">Quran Root Words</Typography>

          {isKeywordsEditMode && (
            <Chip
              size="small"
              label="Editing Mode"
              color="primary"
              variant="outlined"
              sx={{ ml: 2 }}
            />
          )}

          {saveSuccess && (
            <Fade in={saveSuccess} timeout={500}>
              <Chip
                label="Changes saved"
                color="success"
                size="small"
                icon={<CheckCircleOutlineIcon />}
                sx={{ ml: 2, animation: "fadeOut 3s forwards" }}
              />
            </Fade>
          )}
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                disabled={!isKeywordsEditMode}
                checked={noRelativeKeywords}
                onChange={(event) =>
                  setNoRelativeKeywords(event.target.checked)
                }
                size={isMobile ? "small" : "medium"}
              />
            }
            label={
              <Typography variant={isMobile ? "caption" : "body2"}>
                No relative root words
              </Typography>
            }
            sx={{ mr: 2 }}
            onClick={(e) => e.stopPropagation()}
          />

          {isKeywordsEditMode && (
            <Tooltip title="Arabic Keyboard">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setShowArabicKeyboard((prev) => !prev);
                }}
                size={isMobile ? "small" : "medium"}
                color={showArabicKeyboard ? "secondary" : "primary"}
                sx={{ mr: 1 }}
              >
                <KeyboardIcon />
              </IconButton>
            </Tooltip>
          )}

          {isKeywordsEditMode ? (
            <ButtonGroup
              variant="contained"
              size={isMobile ? "small" : "medium"}
              sx={{ ml: 1 }}
            >
              <Button
                color="secondary"
                variant="outlined"
                onClick={(e) => {
                  e.stopPropagation();
                  cancelEditing();
                }}
              >
                Done
              </Button>
            </ButtonGroup>
          ) : (
            // Edit button (when not in edit mode)
            <Button
              variant="outlined"
              onClick={(e) => {
                e.stopPropagation();
                toggleEditMode();
              }}
              color="primary"
              disabled={isAnyEditModeActive && !isKeywordsEditMode}
              size={isMobile ? "small" : "medium"}
              startIcon={<EditIcon />}
            >
              Edit
            </Button>
          )}

          {toggleSection && (
            <Box component="span" sx={{ ml: 1 }}>
              {activeSections ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Box>
          )}
        </Box>
      </Box>

      <Collapse
        in={activeSections || activeSections === undefined}
        timeout={300}
      >
        <CardContent>
          {!isValid() && (
            <Alert
              severity="warning"
              sx={{
                mb: 3,
                display: "flex",
                alignItems: "center",
              }}
              icon={<WarningAmberIcon />}
            >
              Either link at least one root word or check "No relative root
              words"
            </Alert>
          )}

          {isKeywordsEditMode && (
            <Box sx={{ mb: 3 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  alignItems: isMobile ? "stretch" : "center",
                  gap: 2,
                }}
              >
                <FormControl sx={{ flexGrow: 1 }}>
                  <Autocomplete
                    id="keywords-autocomplete"
                    freeSolo
                    disablePortal
                    options={allKeywords}
                    getOptionLabel={(option) => {
                      // Handle both string and option object cases
                      if (typeof option === "string") {
                        return option;
                      }
                      return option.label || "";
                    }}
                    onChange={addKeyword}
                    inputValue={inputValue}
                    onInputChange={handleInputChange}
                    loading={isFetching}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    filterOptions={(options, state) => options}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Search Quran Root Words"
                        variant="outlined"
                        fullWidth
                        inputRef={searchInputRef}
                        dir="rtl"
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <SearchIcon
                              color="action"
                              sx={{ ml: 1, mr: 0.5 }}
                            />
                          ),
                          endAdornment: (
                            <>
                              {isFetching ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                          sx: { fontWeight: "medium" },
                        }}
                        onKeyDown={(e) => {
                          // Ensure typing is allowed without replacing existing text
                          if (e.key === "Enter") {
                            fetchKeywords(inputValue);
                          }
                        }}
                      />
                    )}
                    noOptionsText={
                      searchTerm.length > 0
                        ? "No matching root words found"
                        : "Type to search for root words"
                    }
                  />
                </FormControl>

                <Tooltip title="Toggle Arabic Keyboard">
                  <IconButton
                    onClick={() => setShowArabicKeyboard((prev) => !prev)}
                    color={showArabicKeyboard ? "secondary" : "primary"}
                    variant="contained"
                    sx={{
                      bgcolor:
                        theme.palette.mode === "dark"
                          ? "rgba(255,255,255,0.05)"
                          : "rgba(0,0,0,0.03)",
                      p: 1.5,
                      borderRadius: 1,
                      display: isMobile ? "none" : "flex",
                      border: "1px solid",
                      borderColor: "divider",
                    }}
                  >
                    <KeyboardIcon />
                    <Typography variant="caption" sx={{ ml: 1 }}>
                      Arabic Keyboard
                    </Typography>
                  </IconButton>
                </Tooltip>
              </Box>

              <Collapse in={showArabicKeyboard} timeout={300}>
                <Paper
                  elevation={2}
                  sx={{
                    mt: 2,
                    p: 1.5,
                    borderRadius: 1,
                    bgcolor:
                      theme.palette.mode === "dark"
                        ? "rgba(255,255,255,0.03)"
                        : "rgba(0,0,0,0.02)",
                    border: "1px solid",
                    borderColor: "divider",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mb: 1,
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{ color: "text.secondary" }}
                    >
                      Arabic Keyboard
                    </Typography>

                    <IconButton
                      size="small"
                      onClick={() => setShowArabicKeyboard(false)}
                      sx={{ padding: 0.5 }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </Box>

                  {/* Character keyboard - keeps adding to current input */}
                  <Typography
                    variant="caption"
                    sx={{ display: "block", mb: 0.5, color: "text.secondary" }}
                  >
                    Click on characters to add to search field
                  </Typography>
                  {arabicKeyboardLayout.map((row, rowIndex) => (
                    <Box
                      key={rowIndex}
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        mb: rowIndex < arabicKeyboardLayout.length - 1 ? 1 : 0,
                      }}
                    >
                      {row.map((letter, index) => (
                        <Button
                          key={index}
                          onClick={() => handleArabicLetterClick(letter)}
                          variant="outlined"
                          size="small"
                          sx={{
                            minWidth: "36px",
                            height: "36px",
                            m: 0.25,
                            p: 0.5,
                            fontWeight: "bold",
                            fontSize: "0.9rem",
                            transition: "all 0.15s ease",
                            "&:hover": {
                              transform: "scale(1.05)",
                            },
                            "&:active": {
                              transform: "scale(0.95)",
                            },
                          }}
                        >
                          {letter}
                        </Button>
                      ))}
                    </Box>
                  ))}
                </Paper>
              </Collapse>
            </Box>
          )}

          <Box sx={{ mt: 3 }}>
            <Typography
              variant="subtitle1"
              sx={{
                mb: 1.5,
                display: "flex",
                alignItems: "center",
              }}
            >
              Linked Root Words
              <Badge
                badgeContent={linkedKeywords.length}
                color={linkedKeywords.length > 0 ? "success" : "error"}
                sx={{ ml: 1.5 }}
              />
              {noRelativeKeywords && (
                <Chip
                  label="No relative words needed"
                  color="info"
                  size="small"
                  sx={{ ml: 2 }}
                />
              )}
            </Typography>

            {initialLoading ? (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                {[1, 2, 3, 4].map((item) => (
                  <Skeleton
                    key={item}
                    variant="rounded"
                    width={80}
                    height={32}
                    animation="wave"
                  />
                ))}
              </Box>
            ) : linkedKeywords.length === 0 && !noRelativeKeywords ? (
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  py: 2,
                  px: 2,
                  bgcolor:
                    theme.palette.mode === "dark"
                      ? "rgba(255,255,255,0.03)"
                      : "rgba(0,0,0,0.02)",
                  borderRadius: 1,
                  border: "1px dashed",
                  borderColor: "divider",
                  textAlign: "center",
                }}
              >
                No root words linked yet. Either link keywords or check "No
                relative root words".
              </Typography>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 1,
                  p: linkedKeywords.length > 0 ? 1.5 : 0,
                  border: linkedKeywords.length > 0 ? "1px solid" : "none",
                  borderColor: "divider",
                  borderRadius: 1,
                  bgcolor:
                    theme.palette.mode === "dark"
                      ? "rgba(255,255,255,0.03)"
                      : "rgba(0,0,0,0.02)",
                  minHeight: linkedKeywords.length > 0 ? "50px" : "auto",
                }}
              >
                {linkedKeywords.map((keyword) => (
                  <Chip
                    key={keyword.id}
                    label={keyword.label}
                    onDelete={
                      isKeywordsEditMode
                        ? () => handleDeleteKeyword(keyword)
                        : undefined
                    }
                    deleteIcon={isKeywordsEditMode ? <DeleteIcon /> : undefined}
                    sx={{
                      p: 0.5,
                      bgcolor:
                        theme.palette.mode === "dark"
                          ? "rgba(255,255,255,0.1)"
                          : "rgba(0,0,0,0.05)",
                      "&:hover": {
                        bgcolor:
                          theme.palette.mode === "dark"
                            ? "rgba(255,255,255,0.15)"
                            : "rgba(0,0,0,0.07)",
                      },
                      transition: "all 0.2s ease",
                    }}
                  />
                ))}
              </Box>
            )}
          </Box>
        </CardContent>
      </Collapse>

      {/* Overlay Loader for operations */}
      {isOperationLoading && (
        <Fade in={isOperationLoading}>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "rgba(0,0,0,0.3)",
              zIndex: 1000,
              backdropFilter: "blur(2px)",
            }}
          >
            <Paper
              elevation={3}
              sx={{
                p: 2,
                display: "flex",
                alignItems: "center",
                borderRadius: 2,
              }}
            >
              <CircularProgress size={24} sx={{ mr: 1 }} />
              <Typography variant="body2">Processing...</Typography>
            </Paper>
          </Box>
        </Fade>
      )}

      <Dialog
        open={confirmationDialog.open}
        onClose={!isOperationLoading ? cancelAction : undefined}
        PaperProps={{
          sx: {
            borderRadius: 2,
            boxShadow: "0 8px 32px rgba(0,0,0,0.2)",
            maxWidth: "450px",
            width: "100%",
          },
        }}
        TransitionComponent={Fade}
        transitionDuration={200}
      >
        <DialogTitle
          sx={{
            pb: 1,
            borderBottom: "1px solid",
            borderColor: "divider",
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          {confirmationDialog.type === "link" ? (
            <>
              <SaveIcon color="primary" fontSize="small" />
              Confirm Link
            </>
          ) : (
            <>
              <DeleteIcon color="error" fontSize="small" />
              Confirm Delete
            </>
          )}
        </DialogTitle>

        <DialogContent sx={{ pt: 2 }}>
          <DialogContentText>
            {confirmationDialog.type === "link"
              ? `Are you sure you want to link the Root Word "${confirmationDialog.keyword?.label}" to this Hadith?`
              : `Are you sure you want to delete the link for the Root Word "${confirmationDialog.keyword?.label}"?`}
          </DialogContentText>
        </DialogContent>

        <DialogActions sx={{ p: 2, pt: 1, gap: 1 }}>
          <Button
            onClick={cancelAction}
            color="inherit"
            variant="outlined"
            startIcon={<CloseIcon />}
            disabled={isOperationLoading}
          >
            Cancel
          </Button>
          <Button
            onClick={confirmAction}
            variant="contained"
            color={confirmationDialog.type === "link" ? "primary" : "error"}
            startIcon={
              isOperationLoading ? (
                <CircularProgress size={16} color="inherit" />
              ) : confirmationDialog.type === "link" ? (
                <SaveIcon />
              ) : (
                <DeleteIcon />
              )
            }
            disabled={isOperationLoading}
            autoFocus
          >
            {isOperationLoading
              ? "Processing..."
              : confirmationDialog.type === "link"
              ? "Link"
              : "Delete"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Alerts */}
      <AlertM ref={alertRef} />

      {/* Edit mode indicator */}
      {isKeywordsEditMode && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "4px",
            height: "100%",
            bgcolor: "primary.main",
            borderTopLeftRadius: "4px",
            borderBottomLeftRadius: "4px",
          }}
        />
      )}
    </Card>
  );
};

export default KeywordsManager;
