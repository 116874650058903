// @ts-nocheck
import { SESSION_EXPIRED, SESSION_RESTORED } from "./Types";

export const setSessionExpired = () => {
  console.log("Session expired action dispatched");
  return {
    type: SESSION_EXPIRED,
  };
};

export const setSessionRestored = () => {
  console.log("Session restored action dispatched");
  return {
    type: SESSION_RESTORED,
  };
};
