// @ts-nocheck
import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Box,
  Slider,
  Typography,
} from "@mui/material";
import { post } from "../../../../../Services/GenericApiService"; // Assuming you have the post method in your API service
import AlertM from "../../../../Helpers/AlertM/AlertM"; // Assuming you have this component for notifications
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPause, faStop } from "@fortawesome/free-solid-svg-icons"; // Import necessary icons
import { log } from "../../../../../Services/LoggerService";

const AudioPlayer = ({ hadithTranslationId }) => {
  const [isGeneratingAudio, setIsGeneratingAudio] = useState(false);
  const [audio, setAudio] = useState(null); // Store the audio element
  const [isPlaying, setIsPlaying] = useState(false); // Track if audio is playing
  const [progress, setProgress] = useState(0); // Track the progress of the audio
  const [audioUrl, setAudioUrl] = useState(null); // Store the audio URL
  const notifications = useRef();

  // Handle the progress of the audio
  useEffect(() => {
    if (audio) {
      const updateProgress = () => {
        setProgress((audio.currentTime / audio.duration) * 100);
      };

      audio.addEventListener("timeupdate", updateProgress);

      return () => {
        audio.removeEventListener("timeupdate", updateProgress);
      };
    }
  }, [audio]);

  const handleGenerateAndPlayAudio = async () => {
    if (audioUrl && audio) {
      // Resume the audio if it was paused
      audio.play();
      setIsPlaying(true);
    } else {
      setIsGeneratingAudio(true);
      try {
        const body = { hadithTranslationId };
        post("hadith/generate-audio-temp", body, true)
          .then((response: any) => {
            // Create a Blob URL from the response data (MP3 binary)
            const audioUrl = URL.createObjectURL(response); // Blob URL
            setAudioUrl(audioUrl);

            // Create a new Audio object
            const newAudio = new Audio(audioUrl);
            setAudio(newAudio); // Save the audio instance in state

            newAudio.play();
            setIsPlaying(true);

            setIsGeneratingAudio(false);
            notifications.current.successAlert("Audio generated and playing.");
          })
          .catch((error) => {
            log(error);
            setIsGeneratingAudio(false);
            notifications.current.errorAlert("Error generating audio.");
          });
      } catch (error) {
        log(error);
        setIsGeneratingAudio(false);
        notifications.current.errorAlert("Error generating audio.");
      }
    }
  };

  // Pause audio
  const handlePauseAudio = () => {
    if (audio) {
      audio.pause();
      setIsPlaying(false);
    }
  };

  // Stop audio (Pause and reset to start)
  const handleStopAudio = () => {
    if (audio) {
      audio.pause();
      audio.currentTime = 0; // Reset audio to start
      setIsPlaying(false);
    }
  };

  // Handle seeking through the audio
  const handleSeek = (event, newValue) => {
    if (audio) {
      const seekTime = (newValue / 100) * audio.duration;
      audio.currentTime = seekTime;
      setProgress(newValue);
    }
  };

  return (
    <Box
      sx={{
        border: "1px solid #ccc",
        borderRadius: "8px",
        padding: "16px",
        display: "inline-flex",
        alignItems: "center",
        gap: "10px", // Add space between the controls
        flexDirection: "column", // Align controls vertically
      }}
    >
      <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
        {/* Play or Generate Audio Button */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleGenerateAndPlayAudio}
          disabled={isGeneratingAudio || isPlaying}
          endIcon={<FontAwesomeIcon icon={faPlay} />}
        >
          {isGeneratingAudio ? (
            <>
              Generating Audio...
              <CircularProgress size={24} sx={{ ml: 1 }} />
            </>
          ) : (
            "Play Audio"
          )}
        </Button>

        {/* Pause Button */}
        <Button
          variant="outlined"
          color="secondary"
          onClick={handlePauseAudio}
          disabled={!isPlaying}
          endIcon={<FontAwesomeIcon icon={faPause} />}
        >
          Pause
        </Button>

        {/* Stop Button */}
        <Button
          variant="outlined"
          color="error"
          onClick={handleStopAudio}
          disabled={!isPlaying}
          endIcon={<FontAwesomeIcon icon={faStop} />}
        >
          Stop
        </Button>
      </Box>

      {/* Audio Progress Slider */}
      {audio && (
        <Slider
          value={progress}
          onChange={handleSeek}
          aria-labelledby="audio-progress"
          min={0}
          max={100}
          sx={{ width: "100%" }} // Full-width slider
        />
      )}

      {/* Alerts */}
      <AlertM ref={notifications} />
    </Box>
  );
};

export default AudioPlayer;
