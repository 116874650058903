//@ts-nocheck
import React, { useCallback, useRef, useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CircularProgress,
  Box,
  Fade,
  Backdrop,
  Modal,
} from "@mui/material";
import { Camera } from "react-camera-pro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStyles } from "./Styles";
import AlertM from "../../Helpers/AlertM/AlertM";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { postImage } from "../../../Services/TextExtractionService";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
  convertToPixelCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

function ImageCapture({ hadithIndex = null, scanCompleteEvent = null }) {
  const notifications: any = useRef();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);
  const [img, setImg] = useState<any>(null);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [isTextConverting, setIsTextConverting] = useState(false);
  const [isPictureTaken, setIsPictureTaken] = useState(false);
  const camera: any = useRef(null);
  const classes = useStyles();
  const [numberOfCameras, setNumberOfCameras] = useState(0);
  const [croppedImage, setCroppedImage] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [showCropper, setShowCropper] = useState(false);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);

  const [crop, setCrop] = useState<ReactCrop.Crop>({ unit: "px", width: 30 });

  const capture = useCallback(() => {
    if (isCameraOpen && camera.current) {
      const photo = camera.current.takePhoto();
      // downloadImage(photo)
      setImg(photo);
      setIsCameraOpen(false);
      setIsPictureTaken(true);
      setShowCropper(false);
    }
  }, [isCameraOpen]);

  const openCamera = () => {
    setModalOpen(true);
    setImg(null);
    setIsCameraOpen(true);
    setIsPictureTaken(false);
  };

  const handleCloseCamera = () => {
    setImg(null);
    setIsCameraOpen(false);
    setModalOpen(false);
    setIsPictureTaken(false);
    setShowCropper(false);
    setIsTextConverting(false);
  };

  const retakePhoto = () => {
    setImg(null);
    setIsCameraOpen(true);
    setIsPictureTaken(false);
    setCroppedImage(null);
  };

  const uploadForScan = () => {
    if (img) {
      let file = null;
      if (croppedImage) {
        file = dataURItoBlob(croppedImage);
      } else {
        file = dataURItoBlob(img);
      }

      setIsTextConverting(true);

      const formData = new FormData();
      formData.append("file", file, "ocrImage.jpg");

      if (formData) {
        postImage("hadith/imagetotext", formData)
          .then((_res: any) => {
            // notifications.current.successAlert(t('common.createSuccess'), 'Text Converted Successfully');

            /* get extracted text from image and route to script confirmation */
            const { text } = _res;

            const data = { hadithIndex, text };
            scanCompleteEvent(data);
            handleCloseCamera();
            setModalOpen(false);
          })
          .catch((_err: any) => {
            setIsTextConverting(false);
            notifications.current.errorAlert(
              t("common.somethingWentWrong"),
              t("common.tryAgain")
            );
          });
      }
    }
  };

  const dataURItoBlob = (dataURI: string) => {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  };

  const onImageLoaded = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget;
    const aspect = 1;

    setCrop(centerAspectCrop(width, height, aspect));

    // const width = Math.min(50, image.width);
    // const height = (width / aspect) * 100;
    // const x = (image.width - width) / 2;
    // const y = (image.height - height) / 2;

    // setCrop({ unit: "%", width, height, x, y });
  };

  const handleImageCropChange = (newCrop: any) => {
    setCrop(newCrop);
  };

  const setCompletedCrop = async (crop: ReactCrop.Crop) => {
    const croppedImg = await getCroppedImg(img, crop);
    setCroppedImage(croppedImg);
  };

  const getCroppedImg = (imageSrc: any, crop: any) => {
    const image = new Image();
    image.src = imageSrc;

    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx: any = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const base64Image = canvas.toDataURL("image/jpeg");

    return base64Image;
  };

  const downloadImage = (dataUrl: any, filename = "testfile.jpg") => {
    const a = document.createElement("a"); // Create an anchor element
    a.href = dataUrl; // Set the href to the data URL
    a.download = filename; // Set the filename for download
    document.body.appendChild(a); // Append the anchor to the document
    a.click(); // Trigger a click on the anchor
    document.body.removeChild(a); // Remove the anchor from the document
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImg(reader.result);
        setModalOpen(true);
        setIsCameraOpen(false);
        setIsPictureTaken(true);
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <Box>
      <div className="row d-flex flex-row-reverse">
        {/* <div className="col-md-6 ">
          <Button
            variant="text"
            onClick={openCamera}
            className={"py-2"}
            startIcon={<FontAwesomeIcon icon="camera" />}
          >
            <div>Scan Text</div>
          </Button>
          <div className="text-muted" style={{ fontSize: "0.7em" }}>
            Extract text from image
          </div>
        </div> */}
        <div className="col-md-6">
          <Button
            variant="text"
            component="label"
            htmlFor="uploadImageInput"
            className={"py-2"}
            startIcon={<FontAwesomeIcon icon="upload" />}
          >
            Upload Image
          </Button>
          <input
            type="file"
            accept="image/*"
            id="uploadImageInput"
            style={{ display: "none" }}
            onChange={handleImageUpload}
          />
          <div className="text-muted" style={{ fontSize: "0.7em" }}>
            Upload an image
          </div>
        </div>
      </div>

      <Modal open={modalOpen} onClose={handleCloseCamera} closeAfterTransition>
        {/* <Fade in={isCameraOpen}> */}
        <Box className={classes.imageViewModal} sx={{ overflowY: "auto" }}>
          <Card variant="outlined">
            {!loading && (
              <>
                {/* Camera component */}
                {isCameraOpen && (
                  <>
                    <Camera
                      ref={camera}
                      // aspectRatio={16 / 9}
                      errorMessages={{
                        noCameraAccessible:
                          "No camera device accessible. Please connect your camera or try a different browser.",
                        permissionDenied:
                          "Permission denied. Please refresh and give camera permission.",
                        switchCamera:
                          "It is not possible to switch camera to a different one because there is only one video device accessible.",
                        canvas: "Canvas is not supported.",
                      }}
                      numberOfCamerasCallback={setNumberOfCameras}
                    />

                    <Button
                      variant="text"
                      className={"m-2"}
                      hidden={numberOfCameras <= 1}
                      onClick={() => {
                        camera.current.switchCamera();
                      }}
                    >
                      <FontAwesomeIcon size="1x" icon="rotate-left" />
                      <span className="ps-2">Switch Camera</span>
                    </Button>

                    <div className="fixed-bottom mb-3 d-flex justify-content-center">
                      <Button
                        variant="text"
                        onClick={handleCloseCamera}
                        color="inherit"
                        className={"mx-auto my-3 py-3"}
                      >
                        {/* <FontAwesomeIcon className="mx-2" size="2x" icon='camera' /> */}
                        <div className="mx-2">Cancel</div>
                      </Button>

                      <Button
                        variant="text"
                        onClick={capture}
                        color="inherit"
                        className={"mx-auto my-3 py-3"}
                      >
                        <FontAwesomeIcon
                          className="mx-2"
                          size="2x"
                          icon="camera"
                        />
                        <div className="mx-2">Capture</div>
                      </Button>
                    </div>
                  </>
                )}

                <div className="row">
                  {img && (
                    <div
                      className={"col-md-12 " + classes.croppedImageContainer}
                    >
                      {/* <img src={img} className={classes.image} alt="Image" /> */}

                      {croppedImage && (
                        <img
                          src={croppedImage}
                          className={classes.image}
                          alt=""
                        />
                      )}

                      <ReactCrop
                        crop={crop}
                        ruleOfThirds
                        onChange={(c) => setCrop(c)}
                        onComplete={setCompletedCrop}
                        // onImageLoaded={onImageLoaded}
                      >
                        <img src={img} className={classes.image} alt="" />
                      </ReactCrop>
                    </div>
                  )}
                </div>

                {/*Buttons for retaking and saving/uploading */}
                {img && (
                  <div className="mt-2 d-flex justify-content-center">
                    <Button
                      variant="outlined"
                      disabled={isTextConverting}
                      onClick={handleCloseCamera}
                      className="btn btn-lg mx-2 mb-3 p-3"
                    >
                      <span>Cancel</span>
                    </Button>

                    <Button
                      variant="contained"
                      disabled={isTextConverting}
                      color="success"
                      onClick={uploadForScan}
                      className="btn btn-lg mx-2 mb-3 p-3"
                    >
                      {isTextConverting && (
                        <>
                          <span>
                            {"Extracting"}
                            <CircularProgress className="ps-2" size="0.9em" />
                          </span>
                        </>
                      )}

                      {!isTextConverting && (
                        <span>
                          {"Extract Text"}
                          <FontAwesomeIcon
                            size="1x"
                            className="mx-2"
                            icon="check"
                          />
                        </span>
                      )}
                    </Button>
                  </div>
                )}
              </>
            )}

            {loading && (
              <Box
                sx={{
                  display: "flex",
                  backgroundColor: "white",
                  justifyContent: "center", // Center horizontally
                  alignItems: "center",
                  height: "100%", // Center vertically
                  width: "100%",
                }}
              >
                <CircularProgress />
              </Box>
            )}

            {/* Alerts */}
            <AlertM ref={notifications} />
          </Card>
        </Box>

        {/* </Fade> */}
      </Modal>
    </Box>
  );
}

export default ImageCapture;
