// @ts-nocheck
// HadithReviewForm.tsx (Updated Parent Component with Extract Child Components)
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useStyles } from "./Styles";
import {
  post,
  getAll,
  getById,
  patch,
  getAllFiltered,
  get,
} from "../../../../../Services/GenericApiService";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { log } from "../../../../../Services/LoggerService";
import AudioPlayer from "../AudioPlayer/AudioPlayer";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import InputLabel from "@mui/material/InputLabel";
import {
  Button,
  FormControl,
  TextField,
  FormHelperText,
  CircularProgress,
  Typography,
  Drawer,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  useMediaQuery,
  useTheme,
  Fab,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Select,
  MenuItem,
} from "@mui/material";
import { getUserData } from "../../../../../Services/LocalStorageService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AlertM from "../../../../Helpers/AlertM/AlertM";
import VersesReadView from "../../QuranTranslation/VersesReadView/VersesReadView";
import KeywordsManager from "../KeywordsManager/KeywordsManager";
import VerseReferenceManager from "../VerseReferenceManager/VerseReferenceManager";
import HadithTranslationLogs from "../HadithTranslationLogs/HadithTranslationLogs";
import LabelsManager from "../LabelsManager/LabelsManager";
import HadithAttributesManager from "../../HadithAttributesManager/HadithAttributesManager";
import NarratorsManager from "../../NarratorsManager/NarratorsManager";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import HadithTranslationText from "../HadithTranslationText/HadithTranslationText";
import HadithComments from "../HadithComments/HadithComments";

// @ts-ignore
const HadithReviewForm = ({
  handleAddEvent,
  handleUpdateHadith,
  handleCancelEvent,
  hadithBookData = [],
  isLoading,
  isConfirmEvent,
  recordToUpdate,
  handleEditEvent,
  currentTabValue,
  isRejectEvent,
  isResolveEvent,
  userRole,
}) => {
  // css for module
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));

  // router object
  const navigate = useNavigate();

  // child ref for alert
  const notifications = useRef();

  // translation
  const { t } = useTranslation();

  // UI methods
  const [loader, setLoader] = useState({
    modal: false,
    attributes: false,
    narrators: false,
    keywords: false,
    topics: false,
    verseReferences: false,
    translation: false,
  });

  const [linkedKeywords, setLinkedKeywords] = useState([]);
  const [narratorChain, setNarratorChain] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [noRelativeKeywords, setNoRelativeKeywords] = useState(false);
  const [linkedLabels, setLinkedLabels] = useState([]);
  const [displayValidationSummary, setDisplayValidationSummary] =
    useState(false);
  const [activeSections, setActiveSections] = useState({
    translation: true,
    attributes: true,
    narrators: true,
    keywords: true,
    labels: true,
    verseReferences: true,
    comments: true,
  });
  const [linkVerseToHadith, setLinkVerseToHadith] = useState(false);
  const [isLinkVerseOpen, setIsLinkVerseOpen] = useState(false);
  const [openChapters, setOpenChapters] = useState(false);
  const [allChapters, setAllChapters] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState(null);
  const [openLogs, setOpenLogs] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(recordToUpdate.status);
  const [linkedVerses, setLinkedVerses] = useState([]);

  // Centralized edit mode state
  const [editModes, setEditModes] = useState({
    attributes: false,
    keywords: false,
    narrators: false,
    comments: false,
    translation: false,
    verseReference: false,
    labels: false,
  });

  const isAnyEditModeActive = Object.values(editModes).some((mode) => mode);

  // Toggle section visibility
  const toggleSection = useCallback((section) => {
    setActiveSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  }, []);

  // Toggle edit mode for a specific section
  const toggleEditMode = useCallback((key) => {
    setEditModes((prev) => {
      // If already in the correct state, don't update
      if (key && prev[key] === !prev[key]) {
        return prev;
      }

      const newModes = Object.keys(prev).reduce((acc, k) => {
        acc[k] = k === key ? !prev[k] : false;
        return acc;
      }, {});
      return newModes;
    });
  }, []);

  // form data
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  // Validation status tracking
  const [validationStatus, setValidationStatus] = useState({
    translation: false,
    attributes: false,
    narrators: false,
    keywords: false,
    labels: false,
    verseReferences: false, // Will be updated based on actual verse links
  });

  // Updated isFormValid function
  const calculateValidationStatus = useCallback(() => {
    const hadithTranslation =
      watch("translation") && watch("translation").trim() !== "";
    const attributesValid =
      selectedStatus &&
      selectedStatus !== "" &&
      watch("conventionalId") &&
      watch("conventionalId") !== "" &&
      watch("hadithbookId") &&
      watch("hadithbookId") !== "";
    const narratorsValid = narratorChain.length > 0;
    const keywordsValid = noRelativeKeywords || linkedKeywords.length > 0;
    const labelsValid = linkedLabels.length > 0;
    const versesValid = linkedVerses.length > 0;

    return {
      translation: hadithTranslation,
      attributes: attributesValid,
      narrators: narratorsValid,
      keywords: keywordsValid,
      labels: labelsValid,
      verseReferences: versesValid,
    };
  }, [
    watch,
    selectedStatus,
    narratorChain.length,
    noRelativeKeywords,
    linkedKeywords.length,
    linkedLabels.length,
    linkedVerses.length,
  ]);

  const isFormValid = useCallback(() => {
    const status = calculateValidationStatus();
    const isValid = Object.values(status).every((value) => value === true);
    return isValid;
  }, [calculateValidationStatus]);

  // Update the effect to only set validation status when dependencies change
  useEffect(() => {
    setValidationStatus(calculateValidationStatus());
  }, [
    watch("translation"),
    watch("conventionalId"),
    watch("hadithbookId"),
    selectedStatus,
    narratorChain,
    linkedKeywords,
    linkedLabels,
    linkedVerses,
    noRelativeKeywords,
    calculateValidationStatus,
  ]);

  // hooks
  useEffect(() => {
    fetch();

    if (isConfirmEvent || isRejectEvent || isResolveEvent) {
      reset(recordToUpdate);
    }
  }, [isConfirmEvent, isRejectEvent, isResolveEvent, recordToUpdate, reset]);

  // Fetch verse references
  useEffect(() => {
    if (recordToUpdate && recordToUpdate.hadithId) {
      fetchVerseReferences();
    }
  }, [recordToUpdate]);

  // Fetch linked verses for validation
  const fetchVerseReferences = async () => {
    try {
      setLoader((prevState) => ({ ...prevState, verseReferences: true }));
      const url = `verse/linked?linkedToHadithId=${recordToUpdate.hadithId}`;
      const response = await getAllFiltered(url);
      setLinkedVerses(response || []);
    } catch (error) {
      console.error("Error fetching linked verses:", error);
      setLinkedVerses([]);
    } finally {
      setLoader((prevState) => ({ ...prevState, verseReferences: false }));
    }
  };

  // fetch dependent data
  const fetch = () => {
    fetchNarratorsChain();
    fetchQuranChapters();

    // Also fetch labels if needed and not already done
    if (
      linkedLabels.length === 0 &&
      recordToUpdate &&
      recordToUpdate.hadithId
    ) {
      fetchLinkedLabels();
    }
  };

  // Fetch linked labels
  const fetchLinkedLabels = async () => {
    try {
      setLoader((prevState) => ({ ...prevState, keywords: true }));
      const url = `label?linkedHadithId=${recordToUpdate.hadithId}`;
      const response = await getAllFiltered(url);
      setLinkedLabels(response || []);
    } catch (error) {
      console.error("Error fetching linked labels:", error);
    } finally {
      setLoader((prevState) => ({ ...prevState, keywords: false }));
    }
  };

  const fetchNarratorsChain = () => {
    if (recordToUpdate) {
      setLoader((prevState) => ({ ...prevState, modal: true }));
      getById("narratordetail", recordToUpdate.hadithId)
        .then((_res) => {
          const data = _res.map((narrator) => ({
            name: narrator.name,
            id: narrator.id,
          }));

          setNarratorChain(data);
          setLoader((prevState) => ({ ...prevState, modal: false }));
        })
        .catch((_err) => {
          log(_err);
          setLoader((prevState) => ({ ...prevState, modal: false }));
        });
    }
  };

  // form methods
  const onSubmit = (_data) => {
    // Verify all sections are valid before submission
    if (!isFormValid()) {
      setDisplayValidationSummary(true);
      notifications.current.errorAlert(
        "Missing Required Information",
        "Please complete all required sections before submitting."
      );
      return;
    }

    setLoader((prevState) => ({ ...prevState, modal: true }));

    // Hadith Translation Updated Data
    let updatedData = {
      hadithId: recordToUpdate.hadithId,
      id: _data.id,
      conventionalId: _data.conventionalId,
      toBeReviewed: _data.toBeReviewed,
      toBeQC: _data.toBeQC,
      reviewRejected: _data.reviewRejected,
      qcRejected: _data.qcRejected,
      addedByUserId: _data.addedByUserId,
      approvedByUserId: _data.approvedByUserId,
      reviewedByUserId: _data.reviewedByUserId,
      qcByUserId: _data.qcByUserId,
      addedDate: _data.addedDate,
      approvedDate: _data.approvedDate,
      reviewedDate: _data.reviewedDate,
      qcDate: _data.qcDate,
      isDeleted: _data.isDeleted,
      verified: _data.verified,
      toBeApproved: _data.toBeApproved,
    };

    if (isRejectEvent) {
      const prevComments = recordToUpdate?.comments;
      if (prevComments) {
        updatedData.comments = `${prevComments}\n${newComment}`;
      } else {
        updatedData.comments = `${newComment}`;
      }
      setNewComment("");
    }

    const currentUser = getUserData();

    switch (currentTabValue) {
      case "verified":
        updatedData = {
          ...updatedData,
          toBeQC: isConfirmEvent
            ? false
            : isRejectEvent
            ? true
            : updatedData.toBeQC,
          verified: isConfirmEvent
            ? true
            : isRejectEvent
            ? false
            : updatedData.verified,
          comments: isConfirmEvent ? "" : updatedData.comments,
          status: selectedStatus,
        };
        break;

      case "toBeApproved":
        updatedData = {
          ...updatedData,
          toBeApproved: isConfirmEvent ? false : updatedData.toBeApproved,
          toBeReviewed: isConfirmEvent ? true : updatedData.toBeReviewed,
          approvedByUserId: isConfirmEvent
            ? currentUser.id
            : updatedData.approvedByUserId,
          comments: isResolveEvent ? "" : updatedData.comments,
          approvedDate: isConfirmEvent ? new Date() : updatedData.approvedDate,
          status: selectedStatus,
        };
        break;

      case "toBeReviewed":
        updatedData = {
          ...updatedData,
          toBeReviewed: isConfirmEvent
            ? false
            : isRejectEvent
            ? false
            : updatedData.toBeReviewed,
          toBeQC: isConfirmEvent ? true : updatedData.toBeQC,
          reviewRejected: isRejectEvent ? true : updatedData.reviewRejected,
          reviewedByUserId: isConfirmEvent
            ? currentUser.id
            : updatedData.reviewedByUserId,
          comments: updatedData.comments,
          reviewedDate: isConfirmEvent ? new Date() : updatedData.reviewedDate,
          status: selectedStatus,
        };
        break;

      case "toBeQc":
        updatedData = {
          ...updatedData,
          verified: isConfirmEvent
            ? true
            : isRejectEvent
            ? false
            : updatedData.verified,
          toBeQC: isConfirmEvent
            ? false
            : isRejectEvent
            ? false
            : updatedData.toBeQC,
          qcRejected: isRejectEvent ? true : updatedData.qcRejected,
          qcByUserId: isConfirmEvent ? currentUser.id : updatedData.qcByUserId,
          comments: updatedData.comments,
          qcDate: isConfirmEvent ? new Date() : updatedData.qcDate,
          status: selectedStatus,
        };
        break;

      case "qcRejected":
        updatedData = {
          ...updatedData,
          toBeQC: isResolveEvent ? true : updatedData.toBeQC,
          qcRejected: isResolveEvent ? false : updatedData.qcRejected,
          comments: isResolveEvent ? "" : updatedData.comments,
          reviewedDate: isResolveEvent ? new Date() : updatedData.reviewedDate,
          status: selectedStatus,
        };
        break;

      case "reviewRejected":
        updatedData = {
          ...updatedData,
          toBeReviewed: isResolveEvent ? true : updatedData.toBeReviewed,
          reviewRejected: isResolveEvent ? false : updatedData.reviewRejected,
          comments: isResolveEvent ? "" : updatedData.comments,
          status: selectedStatus,
          addedDate: isResolveEvent ? new Date() : updatedData.addedDate,
        };
        break;

      case "zaeefHadith":
        updatedData = {
          ...updatedData,
          toBeApproved: isConfirmEvent ? true : updatedData.toBeApproved,
          status: selectedStatus,
        };
        break;

      case "last24HoursHadith":
        updatedData = {
          ...updatedData,
          toBeApproved: isResolveEvent ? true : updatedData.toBeApproved,
        };
        break;

      default:
        break;
    }

    handleUpdateHadith(updatedData);
  };

  // ui handlers
  const onCancel = () => {
    handleCancelEvent();
  };

  const fetchQuranChapters = () => {
    setLoader((prevState) => ({ ...prevState, modal: true }));
    getAll("chapter")
      .then((_res) => {
        setAllChapters(_res);
        setLoader((prevState) => ({ ...prevState, modal: false }));
      })
      .catch((_err) => {
        log(_err);
        setLoader((prevState) => ({ ...prevState, modal: false }));
      });
  };

  const handleLinkVerse = (verseId) => {
    let verseReference = {
      verseId: verseId,
      hadithId: recordToUpdate.hadithId,
    };

    setLoader((prevState) => ({ ...prevState, modal: true }));
    post("versehadith/link", verseReference)
      .then((_res) => {
        setLoader((prevState) => ({ ...prevState, modal: false }));
        if (_res.message == "Link already exists") {
          notifications.current.errorAlert("Link already exists");
        } else {
          notifications.current.successAlert("Verse Linked Successfully");
          getHadithVerses();
          toggleEditMode("verseReference");
        }
      })
      .catch((_err) => {
        log(_err);
        notifications.current.errorAlert(
          t("common.somethingWentWrong"),
          t("common.tryAgain")
        );
        setLoader((prevState) => ({ ...prevState, modal: false }));
      });
  };

  const getHadithVerses = () => {
    let params = recordToUpdate.hadithId
      ? "hadithId=" + recordToUpdate.hadithId
      : "";
    const url = "versehadith/links?" + params;

    getAllFiltered(url)
      .then((response) => {
        if (response) {
          const allVerseHadiths = response.flatMap(
            (record) => record.versehadiths
          );
          recordToUpdate.Hadiths.verses = allVerseHadiths;
          fetchVerseReferences(); // Update linked verses for validation
        }
      })
      .catch((error) => {
        console.error("Error fetching verse hadiths:", error);
      });
  };

  const handleChapterClick = (chapter) => {
    setIsLinkVerseOpen(true);
    setSelectedChapter(chapter);
  };

  // Validation helper functions
  const getMissingItems = () => {
    const missing = [];
    if (!validationStatus.translation) missing.push("Translation text");
    if (!validationStatus.attributes) missing.push("Hadith attributes");
    if (!validationStatus.narrators) missing.push("Narrators chain");
    if (!validationStatus.keywords) missing.push("Quran Keywords");
    if (!validationStatus.labels) missing.push("Hadith Keywords");
    if (!validationStatus.verseReferences) missing.push("Verse references");

    return missing;
  };

  // Render validation summary
  const renderValidationSummary = () => {
    const missingItems = getMissingItems();
    const isValid = missingItems.length === 0;

    return (
      <Paper
        elevation={3}
        sx={{
          p: 2,
          mt: 2,
          mb: 2,
          border: "1px solid",
          borderColor: isValid ? "success.light" : "error.light",
          bgcolor:
            theme.palette.mode === "dark"
              ? isValid
                ? "rgba(76, 175, 80, 0.1)"
                : "rgba(244, 67, 54, 0.1)"
              : isValid
              ? "rgba(76, 175, 80, 0.05)"
              : "rgba(244, 67, 54, 0.05)",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          {isValid ? (
            <CheckCircleOutlineIcon color="success" sx={{ mr: 1 }} />
          ) : (
            <ErrorOutlineIcon color="error" sx={{ mr: 1 }} />
          )}
          <Typography variant="h6">
            {isValid ? "Ready to Submit" : "Required Fields Missing"}
          </Typography>
        </Box>

        {!isValid ? (
          <>
            <Typography variant="body2" sx={{ mb: 1 }}>
              Please complete the following sections before submitting:
            </Typography>
            <List dense disablePadding>
              {missingItems.map((item, index) => (
                <ListItem key={index} sx={{ py: 0.5 }}>
                  <ListItemIcon sx={{ minWidth: 32 }}>
                    <ErrorOutlineIcon color="error" fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary={item} />
                </ListItem>
              ))}
            </List>
          </>
        ) : (
          <Typography variant="body2">
            All required information has been provided. You can now submit the
            form.
          </Typography>
        )}
      </Paper>
    );
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          maxWidth: { xs: "100%", md: "92%" }, // Slightly wider on desktop
          margin: { xs: 0, md: "0 auto" }, // Center on desktop
          overflow: "hidden",
          position: "relative",
        }}
      >
        {/* Overlay loading indicator when submitting the form */}
        {loader?.modal && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 9999,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backdropFilter: "blur(5px)",
              backgroundColor: (theme) =>
                theme.palette.mode === "dark"
                  ? "rgba(0, 0, 0, 0.7)"
                  : "rgba(255, 255, 255, 0.7)",
            }}
          >
            <Paper
              elevation={4}
              sx={{
                p: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: (theme) =>
                  theme.palette.mode === "dark"
                    ? "rgba(66, 66, 66, 0.9)"
                    : "rgba(255, 255, 255, 0.9)",
                borderRadius: 2,
                maxWidth: 300,
              }}
            >
              <CircularProgress size={60} color="primary" />
              <Typography
                variant="h6"
                sx={{
                  mt: 2,
                  fontWeight: 500,
                  color: (theme) => theme.palette.text.primary,
                }}
              >
                Processing...
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  mt: 1,
                  textAlign: "center",
                  color: (theme) => theme.palette.text.secondary,
                }}
              >
                Please wait while we save your changes
              </Typography>
            </Paper>
          </Box>
        )}

        {/* Form start */}
        {(isConfirmEvent || isResolveEvent) && (
          <Card
            className={classes.fullScrnCard}
            sx={{
              px: 0,
              borderRadius: 2,
              boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
              overflow: "hidden",
            }}
          >
            <form
              className={classes.fullScrnCard}
              onSubmit={handleSubmit(onSubmit)}
              style={{ height: "100%", width: "100%" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  p: 2,
                  borderBottom: "1px solid",
                  borderColor: "divider",
                  bgcolor: (theme) =>
                    theme.palette.mode === "dark"
                      ? "rgba(255,255,255,0.03)"
                      : "rgba(0,0,0,0.02)",
                }}
              >
                <Typography variant="h5" sx={{ fontWeight: 600 }}>
                  Review Hadith{" "}
                  <Typography
                    variant="h5"
                    component="span"
                    sx={{
                      fontWeight: 400,
                      color: "text.secondary",
                      ml: 1,
                    }}
                  >
                    #{recordToUpdate?.id}
                  </Typography>
                </Typography>

                <Button
                  onClick={() => setOpenLogs(true)}
                  variant="outlined"
                  startIcon={<FontAwesomeIcon icon="history" />}
                  size={isMobile ? "small" : "medium"}
                  disabled={loader?.modal}
                >
                  View Logs
                </Button>
              </Box>

              <CardContent
                sx={{
                  overflowY: "auto",
                  flexGrow: 1,
                  px: { xs: 1, sm: 2, md: 3 },
                  py: 2,
                }}
              >
                {/* Render the translation section using the extracted component */}
                <HadithTranslationText
                  recordToUpdate={recordToUpdate}
                  isEditMode={editModes.translation}
                  toggleEditMode={toggleEditMode}
                  isAnyEditModeActive={isAnyEditModeActive}
                  validationStatus={validationStatus}
                  setValidationStatus={setValidationStatus}
                  register={register}
                  errors={errors}
                  watch={watch}
                  setValue={setValue}
                  getValues={getValues}
                  setLoader={setLoader}
                  notifications={notifications}
                  t={t}
                  theme={theme}
                  toggleSection={toggleSection}
                  isActive={activeSections.translation}
                  isMobile={isMobile}
                  currentTabValue={currentTabValue}
                  AudioPlayerComponent={AudioPlayer}
                />

                {/* Render the attributes section */}
                <HadithAttributesManager
                  recordToUpdate={recordToUpdate}
                  hadithBookData={hadithBookData}
                  isAnyEditModeActive={isAnyEditModeActive}
                  editModes={editModes}
                  toggleEditMode={toggleEditMode}
                  selectedStatus={selectedStatus}
                  setSelectedStatus={setSelectedStatus}
                  validationStatus={validationStatus}
                  register={register}
                  watch={watch}
                  errors={errors}
                  getValues={getValues}
                  setValue={setValue}
                  setLoader={setLoader}
                  notifications={notifications}
                  t={t}
                  theme={theme}
                  toggleSection={toggleSection}
                  activeSections={activeSections}
                />

                {/* Render the narrators section */}
                <NarratorsManager
                  recordToUpdate={recordToUpdate}
                  narratorChain={narratorChain}
                  setNarratorChain={setNarratorChain}
                  isAnyEditModeActive={isAnyEditModeActive}
                  editModes={editModes}
                  toggleEditMode={toggleEditMode}
                  validationStatus={validationStatus}
                  setLoader={setLoader}
                  notifications={notifications}
                  t={t}
                  theme={theme}
                  toggleSection={toggleSection}
                  activeSections={activeSections}
                />

                {/* Render the keywords section */}
                <KeywordsManager
                  hadithId={recordToUpdate?.hadithId}
                  isAnyEditModeActive={isAnyEditModeActive}
                  onEditModeChange={(isEditMode) => toggleEditMode("keywords")}
                  linkedKeywords={linkedKeywords}
                  setLinkedKeywords={setLinkedKeywords}
                  hadithTranslationId={recordToUpdate?.id}
                  setNoRelativeKeywords={setNoRelativeKeywords}
                  noRelativeKeywords={noRelativeKeywords}
                  validationStatus={validationStatus.keywords}
                  theme={theme}
                  toggleSection={toggleSection}
                  activeSections={activeSections.keywords}
                />

                {/* Render the labels section */}
                <LabelsManager
                  hadithId={recordToUpdate?.hadithId}
                  isAnyEditModeActive={isAnyEditModeActive}
                  onEditModeChange={(isEditMode) => toggleEditMode("labels")}
                  linkedLabels={linkedLabels}
                  setLinkedLabels={setLinkedLabels}
                  hadithTranslationId={recordToUpdate?.id}
                  validationStatus={validationStatus.labels}
                  theme={theme}
                  toggleSection={toggleSection}
                  activeSections={activeSections.labels}
                />

                {/* Render the verse references section */}
                <VerseReferenceManager
                  hadithId={recordToUpdate?.hadithId}
                  isAnyEditModeActive={isAnyEditModeActive}
                  toggleEditMode={toggleEditMode}
                  linkedKeywords={linkedKeywords}
                  hadithTranslationId={recordToUpdate?.id}
                  theme={theme}
                  toggleSection={toggleSection}
                  activeSections={activeSections}
                  linkedVerses={linkedVerses} // Pass the linked verses data
                  setLinkedVerses={setLinkedVerses} // Pass the setter function
                />

                {/* Render the comments section using the extracted component */}
                {!isResolveEvent && (
                  <HadithComments
                    recordToUpdate={recordToUpdate}
                    isEditMode={editModes.comments}
                    toggleEditMode={toggleEditMode}
                    isAnyEditModeActive={isAnyEditModeActive}
                    setLoader={setLoader}
                    notifications={notifications}
                    t={t}
                    theme={theme}
                    toggleSection={toggleSection}
                    isActive={activeSections.comments}
                    isMobile={isMobile}
                  />
                )}

                {/* Render validation summary */}
                {renderValidationSummary()}
              </CardContent>

              <Box
                component={Paper}
                elevation={3}
                sx={{
                  p: 2,
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  bgcolor: (theme) =>
                    theme.palette.mode === "dark"
                      ? "rgba(255,255,255,0.03)"
                      : "rgba(0,0,0,0.02)",
                  borderTop: "1px solid",
                  borderColor: "divider",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={onCancel}
                  sx={{ mr: 2 }}
                  startIcon={<FontAwesomeIcon icon="times" />}
                  disabled={loader?.modal} // Disable during submission
                >
                  {t("common.cancel")}
                </Button>

                <Button
                  variant="contained"
                  type="submit"
                  color="success"
                  startIcon={
                    loader?.modal ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <PlaylistAddCheckIcon />
                    )
                  }
                  disabled={!isFormValid() || loader?.modal} // Disable when invalid or during submission
                >
                  {loader?.modal ? "Submitting..." : "Confirm"}
                </Button>
              </Box>
            </form>
          </Card>
        )}

        {isRejectEvent && (
          <Card
            sx={{
              borderRadius: 2,
              boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
              overflow: "hidden",
              position: "relative", // For absolute positioning of loading overlay
            }}
          >
            <form
              className="row px-1 px-md-0"
              onSubmit={handleSubmit(onSubmit)}
            >
              <CardContent>
                <Box sx={{ p: 2 }}>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Rejection Comments
                  </Typography>

                  <Typography
                    variant="body1"
                    sx={{
                      mb: 3,
                      p: 2,
                      bgcolor: (theme) =>
                        theme.palette.mode === "dark"
                          ? "rgba(255,255,255,0.05)"
                          : "rgba(0,0,0,0.02)",
                      borderRadius: 1,
                      whiteSpace: "pre-line",
                    }}
                  >
                    {recordToUpdate?.comments || "No previous comments."}
                  </Typography>

                  <TextField
                    id="commentInput"
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    label={t("Add Comments")}
                    type="text"
                    multiline
                    minRows={4}
                    error={!newComment}
                    helperText={
                      !newComment ? "Please provide a reason for rejection" : ""
                    }
                    rows={4}
                    variant="outlined"
                    fullWidth
                    disabled={loader?.modal}
                  />
                </Box>
              </CardContent>

              <Box
                component={Paper}
                elevation={3}
                sx={{
                  p: 2,
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                  bgcolor: (theme) =>
                    theme.palette.mode === "dark"
                      ? "rgba(255,255,255,0.03)"
                      : "rgba(0,0,0,0.02)",
                  borderTop: "1px solid",
                  borderColor: "divider",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={onCancel}
                  sx={{ mr: 2 }}
                  disabled={loader?.modal}
                >
                  {t("common.cancel")}
                </Button>

                <Button
                  variant="contained"
                  type="submit"
                  color="error"
                  disabled={loader?.modal || !newComment}
                  startIcon={
                    loader?.modal ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : null
                  }
                >
                  {loader?.modal ? "Submitting..." : "Reject"}
                </Button>
              </Box>
            </form>
          </Card>
        )}

        {/* Verse linking dialog */}
        {linkVerseToHadith && (
          <Dialog
            open={openChapters}
            onClose={
              loader?.modal
                ? undefined
                : () => {
                    setOpenChapters(false);
                    setSelectedChapter("");
                  }
            }
            fullWidth
            maxWidth="lg"
          >
            <DialogTitle>Link Verse To Hadith</DialogTitle>

            <DialogContent>
              <Box sx={{ p: 2, position: "relative" }}>
                <FormControl variant="outlined" fullWidth sx={{ mb: 4 }}>
                  <InputLabel id="chapter-select-label">
                    Select Chapter
                  </InputLabel>
                  {allChapters.length > 0 && (
                    <Select
                      labelId="chapter-select-label"
                      label="Select Chapter"
                      error={errors?.chapter}
                      onChange={(e) => handleChapterClick(e.target.value)}
                      value={selectedChapter || ""}
                      disabled={loader?.modal}
                    >
                      {allChapters.map((chapter) => (
                        <MenuItem value={chapter.id} key={chapter.id}>
                          {chapter.nameArabic} {chapter.nameSimple}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  {allChapters.length == 0 && (
                    <>
                      <Select
                        labelId="chapter-select-label"
                        disabled
                        label="Select Chapter"
                      >
                        <MenuItem value="">No Data</MenuItem>
                      </Select>
                      <FormHelperText>No chapters available</FormHelperText>
                    </>
                  )}
                  {errors?.chapter && (
                    <FormHelperText>Chapter is required</FormHelperText>
                  )}
                </FormControl>

                {isLinkVerseOpen && selectedChapter && (
                  <Box>
                    <VersesReadView
                      linkVerseToHadith={linkVerseToHadith}
                      selectedChapter={selectedChapter}
                      handleLinkVerse={(verseId) => handleLinkVerse(verseId)}
                    />
                  </Box>
                )}

                {/* Loading overlay for dialog */}
                {loader?.modal && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      zIndex: 9999,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backdropFilter: "blur(5px)",
                      backgroundColor: (theme) =>
                        theme.palette.mode === "dark"
                          ? "rgba(0, 0, 0, 0.7)"
                          : "rgba(255, 255, 255, 0.7)",
                    }}
                  >
                    <CircularProgress size={40} />
                  </Box>
                )}
              </Box>
            </DialogContent>

            <DialogActions>
              <Button
                onClick={() => {
                  setOpenChapters(false);
                  setSelectedChapter("");
                }}
                variant="outlined"
                disabled={loader?.modal}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {/* Logs dialog */}
        {openLogs && (
          <Dialog
            open={openLogs}
            onClose={() => setOpenLogs(false)}
            fullWidth
            maxWidth="lg"
          >
            <DialogTitle>Hadith Translation Logs</DialogTitle>

            <DialogContent>
              <HadithTranslationLogs hadithtranslationId={recordToUpdate?.id} />
            </DialogContent>

            <DialogActions>
              <Button
                onClick={() => setOpenLogs(false)}
                variant="outlined"
                disabled={loader?.modal}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {/* Alerts */}
        <AlertM ref={notifications} />

        {/* Mobile FAB for validation summary */}
        {isMobile && (
          <Fab
            color={isFormValid() ? "success" : "error"}
            sx={{ position: "fixed", bottom: 16, right: 16 }}
            onClick={() =>
              setDisplayValidationSummary(!displayValidationSummary)
            }
            disabled={loader?.modal}
          >
            {isFormValid() ? <CheckCircleOutlineIcon /> : <ErrorOutlineIcon />}
          </Fab>
        )}

        {/* Mobile validation summary drawer */}
        {isMobile && (
          <Drawer
            anchor="bottom"
            open={displayValidationSummary}
            onClose={() => setDisplayValidationSummary(false)}
          >
            <Box sx={{ p: 2, maxHeight: "50vh", overflow: "auto" }}>
              {renderValidationSummary()}
              <Button
                fullWidth
                variant="outlined"
                onClick={() => setDisplayValidationSummary(false)}
                sx={{ mt: 2 }}
                disabled={loader?.modal}
              >
                Close
              </Button>
            </Box>
          </Drawer>
        )}
      </Box>
    </>
  );
};

export default HadithReviewForm;
