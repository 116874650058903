// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useStyles } from './Styles';
import '../../../../../index.scss';
import { getAllFiltered} from "../../../../../Services/GenericApiService";
import { useTranslation } from "react-i18next";
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import MUIDataTable from "mui-datatables";
import {TextField, Skeleton, Chip, Typography} from '@mui/material';

function HadithTranslationLogs(hadithtranslationId) {

    const [allData, setAllData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageSize, setPageSize] = useState(25);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalRowCount, setTotalRowCount] = useState(0);

    const moduleMain = 'hadithtranslationlog';

    const columns = [
        {
            name: "Action",
            options: {
                customBodyRender: (value) => (
                    <Chip 
                        label={value}
                        color="primary"
                        size="small"
                        sx={{ minWidth: '100px' }}
                    />
                )
            }
        },
        {
            name: "Basic Info",
            options: {
                customBodyRender: (value) => (
                    <Box>
                        <Typography variant="subtitle2">ID: {value.hadithId}</Typography>
                        <Typography variant="body2">Conv. ID: {value.conventionalId}</Typography>
                        <Typography variant="body2">Language: {value.language}</Typography>
                        <Typography variant="body2">Status: {value.status}</Typography>
                    </Box>
                ),
                setCellProps: () => ({ style: { minWidth: '150px' } })
            }
        },
        {
            name: "Status",
            options: {
                customBodyRender: (value) => (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        {Object.entries(value).map(([key, val]) => (
                            <Box key={key} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Typography variant="caption" noWrap sx={{ minWidth: '120px' }}>
                                    {key.replace(/([A-Z])/g, ' $1').trim()}:
                                </Typography>
                                <Chip 
                                    label={val ? "Yes" : "No"}
                                    color={val ? "success" : "default"}
                                    size="small"
                                    variant="outlined"
                                />
                            </Box>
                        ))}
                    </Box>
                ),
                setCellProps: () => ({ style: { minWidth: '250px' } })
            }
        },
        {
            name: "Users",
            options: {
                customBodyRender: (value) => (
                    <Box>
                        {Object.entries(value).map(([role, user]) => (
                            <Typography key={role} variant="body2">
                                {role.replace(/([A-Z])/g, ' $1').trim()}: {user?.firstName || 'N/A'}
                            </Typography>
                        ))}
                    </Box>
                ),
                setCellProps: () => ({ style: { minWidth: '200px' } })
            }
        },
        {
            name: "Dates",
            options: {
                customBodyRender: (value) => (
                    <Box>
                        {Object.entries(value).map(([key, date]) => (
                            <Typography key={key} variant="body2">
                                {key.replace(/([A-Z])/g, ' $1').trim()}: 
                                {date ? new Date(date).toLocaleDateString() : 'N/A'}
                            </Typography>
                        ))}
                    </Box>
                ),
                setCellProps: () => ({ style: { minWidth: '250px' } })
            }
        },
        {
            name: "References",
            options: {
                customBodyRender: (value) => (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Typography variant="caption" noWrap sx={{ minWidth: '120px' }}>
                                Narrators:
                            </Typography>
                            <Typography variant="body2">{value.narrators}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Typography variant="caption" noWrap sx={{ minWidth: '120px' }}>
                                Keywords:
                            </Typography>
                            <Typography variant="body2">{value.keywords}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Typography variant="caption" noWrap sx={{ minWidth: '120px' }}>
                                Verses:
                            </Typography>
                            <Typography variant="body2">{value.verses}</Typography>
                        </Box>
                    </Box>
                ),
                setCellProps: () => ({ style: { minWidth: '300px' } })
            }
        },
        {
            name: "Translation & Comments",
            options: {
                customBodyRender: (value) => (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
                            <Typography variant="caption" noWrap sx={{ minWidth: '120px' }}>
                                Translation:
                            </Typography>
                            <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
                                {value.translation ?? 'N/A'}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
                            <Typography variant="caption" noWrap sx={{ minWidth: '120px' }}>
                                Comments:
                            </Typography>
                            <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
                                {value.comments ?? 'N/A'}
                            </Typography>
                        </Box>
                    </Box>
                ),
                setCellProps: () => ({ style: { minWidth: '400px' } })
            }
        }
    ];

    let options = {
        search: false,
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
        filterType: "textField",
        responsive: "simple",
        selectableRowsHideCheckboxes: true,
        sort: true,
        onChangePage: (currentPage) => {
          setCurrentPage(currentPage);
        },
        onChangeRowsPerPage: (numberOfRows) => {
          setPageSize(numberOfRows);
        },
        page: currentPage,
        count: totalRowCount,
        serverSide: true,
        loading: true,
        rowsPerPageOptions: [25, 50, 75, 100],
        rowsPerPage: pageSize,
        textLabels: {
          body: {
            noMatch: loading ? (
              <Box>
                <Skeleton className="my-4" />
                <Skeleton className="my-4" />
                <Skeleton className="my-4" />
              </Box>
            ) : (
              "Sorry, there is no matching data to display"
            ),
          },
        },
        onTableChange: (action: any, state: any) => {
        },
      };

    if (allData.length === 0) {
        options = {
          textLabels: {
            body: {
              noMatch: loading ? (
                <Box>
                  <Skeleton className="my-4" />
                  <Skeleton className="my-4" />
                  <Skeleton className="my-4" />
                </Box>
              ) : (
                "Sorry, there is no matching data to display"
              ),
            },
          },
        };
    }
    
    const fetch = () => {
        setAllData([]); // reset table data
        setLoading(true);

        let url = `${moduleMain}?page=${currentPage}&size=${pageSize}`;
        url = hadithtranslationId ? url + "&hadithtranslationid=" + hadithtranslationId : url;
    
        getAllFiltered(url)
            .then((_res) => {
                setTotalRowCount(_res.count);
                setAllData(_res.rows);
    
                const rows = _res.rows.map((record) => [
                    record.action,
                    {
                        hadithId: record.hadithtranslationrecord?.hadithId,
                        conventionalId: record.hadithtranslationrecord?.conventionalId,
                        language: record.hadithtranslationrecord?.language
                    },
                    {
                        toBeApproved: record.hadithtranslationrecord?.toBeApproved,
                        toBeReviewed: record.hadithtranslationrecord?.toBeReviewed,
                        toBeQC: record.hadithtranslationrecord?.toBeQC,
                        verified: record.hadithtranslationrecord?.verified,
                        reviewRejected: record.hadithtranslationrecord?.reviewRejected,
                        qcRejected: record.hadithtranslationrecord?.qcRejected
                    },
                    {
                        addedBy: record.hadithtranslationrecord?.addedByUser,
                        approvedBy: record.hadithtranslationrecord?.approvedByUser,
                        reviewedBy: record.hadithtranslationrecord?.reviewedByUser,
                        qcBy: record.hadithtranslationrecord?.qcByUser
                    },
                    {
                        addedDate: record.hadithtranslationrecord?.addedDate,
                        approvedDate: record.hadithtranslationrecord?.approvedDate,
                        reviewedDate: record.hadithtranslationrecord?.reviewedDate,
                        qcDate: record.hadithtranslationrecord?.qcDate
                    },
                    {
                        narrators: record.hadithtranslationrecord?.hadithNarrators
                            ?.sort((a, b) => a.orderNumber - b.orderNumber)
                            ?.map(narrator => narrator.name)
                            ?.join(" → ") ?? "N/A",
                        keywords: record.hadithtranslationrecord?.hadithKeywords
                            ?.map(keyword => keyword.rootWord)
                            ?.join(", ") ?? "N/A",
                        verses: record.hadithtranslationrecord?.hadithVerses
                            ?.map(verse => verse.textImlaeiSimple)
                            ?.join(", ") ?? "N/A"
                    },
                    {
                        translation: record.hadithtranslationrecord?.translation,
                        comments: record.hadithtranslationrecord?.comments
                    }
                ]);
    
                setTableData(rows);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    };
    
    useEffect(() => {
        fetch();
    }, [currentPage, pageSize,]);
         
    return (
        <div sx={{ display: 'flex', minHeight: '99vh' }}>
            <CssBaseline />

            {/* Content */}
            <div component="main" sx={{ flexGrow: 2, p: 3 }} mt={10}>
                <div>
                    <div className="mt-2">
                        <Paper elevation={12}>
                            <MUIDataTable
                                data={tableData}
                                columns={columns}
                                options={options}
                            />
                        </Paper>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HadithTranslationLogs;
