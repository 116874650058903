// @ts-nocheck

import React, { useEffect, useState, useRef } from 'react';
import { useStyles } from './Styles';
import '../../../../../index.scss';
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import { getAll, getAllFiltered} from "../../../../../Services/GenericApiService";
import { useTranslation } from "react-i18next";
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import MUIDataTable from "mui-datatables";
import Button from "@mui/material/Button";
import Modal from '@mui/material/Modal';
import {TextField, Skeleton, FormControl, InputLabel, Select, MenuItem} from '@mui/material';
import Typography from '@mui/material/Typography';
import HadithTranslationLogsView from "./HadithTranslationLogsView";
import {getLoggedInUserRole, getUserData} from "../../../../../Services/LocalStorageService";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

function HadithTranslationLogsMain() {
    const classes = useStyles();
    const { t } = useTranslation();

    const [params, setParams] = useState({
        userId: null,
        hadithtranslationid: null,
        startDate: null,
        endDate: null
      });

    const [allData, setAllData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [logDetails, setLogDetails] = useState(null);
    const [pageSize, setPageSize] = useState(25);
    const [currentPage, setCurrentPage] = useState(0);
    const [debouncedParams, setDebouncedParams] = useState(params);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [allUsers, setAllUsers] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);

    const userRoleId = getLoggedInUserRole();
    const moduleMain = 'hadithtranslationlog';

    const columns = [
        { name: "Hadith Translation ID", options: { filterOptions: { fullWidth: true } } },
        { name: "Action", options: { filterOptions: { fullWidth: true } }},
        {
            name: "User ID",
            label: "User Name",
            options: {
                filterOptions: { fullWidth: true },
                customBodyRender: (value) => {
                    const user = allUsers.find(user => user.id === value.toString());
                    return user ? `${user.firstName} ${user.lastName}` : "Unknown User";    
                },
            },
        },
        {
            name: "Actions",
            options: {
                filter: false,
                sort: false,
                empty: true,
                setCellHeaderProps: () => {
                    return { align: "right" };
                },
                setCellProps: () => ({
                    align: "right",
                }),
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <div className="d-inline-flex">
                            <div className="px-2">
                                {allData && <HadithTranslationLogsView data={allData[dataIndex]?.hadithtranslationrecord} />}
                            </div>
                        </div>
                    );
                },
            },
        },
    ];

    let options = {
        search: false,
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
        filterType: "textField",
        responsive: "simple",
        selectableRowsHideCheckboxes: true,
        sort: true,
        onChangePage: (currentPage) => {
          setCurrentPage(currentPage);
        },
        onChangeRowsPerPage: (numberOfRows) => {
          setPageSize(numberOfRows);
        },
        page: currentPage,
        count: totalRowCount,
        serverSide: true,
        loading: true,
        rowsPerPageOptions: [25, 50, 75, 100],
        rowsPerPage: pageSize,
        textLabels: {
          body: {
            noMatch: loading ? (
              <Box>
                <Skeleton className="my-4" />
                <Skeleton className="my-4" />
                <Skeleton className="my-4" />
              </Box>
            ) : (
              "Sorry, there is no matching data to display"
            ),
          },
        },
        onTableChange: (action: any, state: any) => {
          // console.log(action);
          // console.dir(state);
        },
      };

    if (allData.length === 0) {
        options = {
          textLabels: {
            body: {
              noMatch: loading ? (
                <Box>
                  <Skeleton className="my-4" />
                  <Skeleton className="my-4" />
                  <Skeleton className="my-4" />
                </Box>
              ) : (
                "Sorry, there is no matching data to display"
              ),
            },
          },
        };
    }
    
    const fetch = () => {
        setAllData([]); // reset table data
        setLoading(true);

        const { userId, hadithtranslationid, startDate, endDate} = params;    
        let url = `${moduleMain}?page=${currentPage}&size=${pageSize}`;
        url = userId ? url + "&userId=" + userId : url;
        url = hadithtranslationid ? url + "&hadithtranslationid=" + hadithtranslationid : url;
        url = startDate ? url + `&startDate=${startDate.toISOString()}` : url;
        url = endDate ? url + `&endDate=${endDate.toISOString()}` : url;

        getAllFiltered(url)
            .then((_res) => {
                setTotalRowCount(_res.count);
                setAllData(_res.rows);

                const rows = _res.rows.map((record) => [
                    record.hadithtranslationid,
                    record.action,
                    record.userId,
                ]);
    
                setTableData(rows);
                setTableData(rows);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    };

    const fetchAllUsers = () => {
        setLoading(true); // show loader
        getAll("user")
            .then((_res: any) => {            
                setAllUsers(_res);
                setLoading(false); // hide loader
            })
            .catch((_err) => {
                log(_err);
                setLoading(false); // hide loader
        });
    }

    // Debounce params
    useEffect(() => {
        const timer = setTimeout(() => {
        setDebouncedParams(params);
        }, 200);
        return () => clearTimeout(timer);
    }, [params]);

    useEffect(() => {
        fetch();
    }, [debouncedParams, currentPage, pageSize,]);

    useEffect(() => {
        fetchAllUsers();
    }, []);

    useEffect(() => {
        const data = getUserData();
        if (data && Object.keys(data).length > 0) {
          setCurrentUser(data);
        }
    }, []);

    useEffect(() => {
        if (userRoleId === process.env.REACT_APP_STUDENT_ROLE_ID) {
          setParams((prev) => ({
            ...prev,
            userId: currentUser?.id || "",
          }));
        }
    }, [userRoleId, currentUser]);
         
    return (
        <Box sx={{ display: 'flex', minHeight: '99vh', backgroundColor: 'rgba(222, 223, 223, 0.3)' }}>
            <CssBaseline />

            {/* Header */}
            <Header />

            {/* Sider */}
            <Sider />

            {/* Content */}
            <Box component="main" sx={{ flexGrow: 2, p: 3 }} mt={10}>
                <div className="px-1 px-md-5">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="mt-3">Hadith Translation Logs</h2>
                        </div>

                        <div className="col-md-3 mt-2">
                            <FormControl variant="standard" fullWidth disabled={userRoleId == process.env.REACT_APP_STUDENT_ROLE_ID }>
                                <InputLabel id="demo-simple-select-standard-label">
                                    All Users
                                </InputLabel>
                                <Select
                                labelId="demo-simple-select-standard-label"
                                targetid="demo-simple-select-standard"
                                label={"User"}
                                value={
                                    userRoleId === process.env.REACT_APP_STUDENT_ROLE_ID
                                      ? currentUser?.id 
                                      : params?.userId 
                                  }                                
                                onChange={(event: any) =>
                                    setParams((prev) => ({
                                    ...prev,
                                    userId: event.target.value,
                                    }))
                                }
                                >
                                {allUsers.map((el: any) => (
                                    <MenuItem value={el.id} key={el.id}>
                                    {" "}
                                    {el.firstName} {" " } {el.lastName}
                                    </MenuItem>
                                ))}
                                </Select>
                            </FormControl>
                            <Button
                                disabled={userRoleId == process.env.REACT_APP_STUDENT_ROLE_ID }
                                className="m-0 my-1 p-0"
                                variant="text"
                                onClick={() => {
                                setParams((prevState) => ({
                                    ...prevState,
                                    userId: null,
                                }));
                                }}
                            >
                                Clear
                            </Button>
                        </div>

                        <div className="col-md-2 mt-2">
                            <TextField
                                label="Hadith Translation ID"
                                variant="standard"
                                fullWidth
                                value={params?.hadithtranslationid || ""}
                                onChange={(event) =>
                                    setParams((prev) => ({
                                        ...prev,
                                        hadithtranslationid: event.target.value,
                                    }))
                                }
                            />
                        </div>

                        <div className="col-md-2 mt-2">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                label="Start Date"
                                value={params.startDate}
                                onChange={(date) =>
                                    setParams((prev) => ({
                                    ...prev,
                                    startDate: date,
                                    }))
                                }
                                renderInput={(props) => <TextField {...props} fullWidth />}
                                />
                            </LocalizationProvider>
                            <Button
                                className="m-0 my-1 p-0"
                                variant="text"
                                onClick={() => {
                                setParams((prevState) => ({
                                    ...prevState,
                                    startDate: null,
                                }));
                                }}
                            >
                                Clear
                            </Button>
                        </div>
                        <div className="col-md-2 mt-2">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                label="End Date"
                                value={params.endDate}
                                onChange={(date) =>
                                    setParams((prev) => ({
                                    ...prev,
                                    endDate: date,
                                    }))
                                }
                                renderInput={(props) => <TextField {...props} fullWidth />}
                                />
                            </LocalizationProvider>
                            <Button
                                className="m-0 my-1 p-0"
                                variant="text"
                                onClick={() => {
                                setParams((prevState) => ({
                                    ...prevState,
                                    endDate: null,
                                }));
                                }}
                            >
                                Clear
                            </Button>
                        </div>   
                    </div>

                    <div className="mt-5">
                        <Paper elevation={12}>
                            <MUIDataTable
                                title="All Logs"
                                data={tableData}
                                columns={columns}
                                options={options}
                            />
                        </Paper>
                    </div>
                </div>
            </Box>
        </Box>
    );
}

export default HadithTranslationLogsMain;
