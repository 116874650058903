// import { CSSProperties } from "react";

// type Styles = {
//   container: CSSProperties;
//   image: CSSProperties;
//   instructions: CSSProperties;
// };


// @ts-nocheck
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    margin: '0 10px',
  },
  cameraContainer: {
    height: '90vh',
    width: '99vw',
    objectFit: 'cover',
  },
  instructions: {
    margin: '10px 0',
    textAlign: 'left',
    fontSize: '16px',
  },
  modal: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    display:'flex',
    justifyContent: 'center',
    backgroundColor: '#E5E5E5',
    alignItems: 'center',
    width: '100vw',
    height: '100vh'
  },

  imageViewModal: {
    // ... other styles ...
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  croppedImageContainer: {
    display: 'flex',             // Use flexbox
    justifyContent: 'center',    // Center horizontally
    alignItems: 'center',        // Center vertically
    height: '80vh',             // Take up full viewport height
    maxWidth: '100%',            // Ensure container does not exceed viewport width
  },
  image: {
    maxHeight: '80vh',           // Maximum height of image (80% of viewport height)
    maxWidth: '45vh',            // Maximum width of image calculated based on aspect ratio (9:16)
    width: 'auto',               // Width is auto to maintain aspect ratio
    height: 'auto',              // Height is auto to maintain aspect ratio
  }

});

export { useStyles }

// export const useStyles = (): Styles => {
//   return {
    
//   };
// };