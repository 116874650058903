//@ts-nocheck
import React from "react";
import { Modal, Backdrop, Fade, Box, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useStyles } from "./Styles";

function HadithDetailsModal({ open, onClose, hadith }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="hadith-details-modal-title"
      aria-describedby="hadith-details-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box className={classes.modal}>
          <Typography variant="h6" id="hadith-details-modal-title">
            {t("common.hadithDetails")}
          </Typography>
          <Typography id="hadith-details-modal-description" sx={{ mt: 2 }}>
            <strong>{t("common.id")}:</strong> {hadith.id}
          </Typography>
          <Typography id="hadith-details-modal-description" sx={{ mt: 2 }}>
            <strong>{t("common.text")}:</strong> {hadith.text}
          </Typography>
          <Typography id="hadith-details-modal-description" sx={{ mt: 2 }}>
            <strong>{t("common.conventionalId")}:</strong>{" "}
            {hadith.conventionalId}
          </Typography>
          <Button onClick={onClose} variant="contained" sx={{ mt: 3 }}>
            {t("common.close")}
          </Button>
        </Box>
      </Fade>
    </Modal>
  );
}

export default HadithDetailsModal;
