// @ts-nocheck

import React, { useEffect } from "react";
import { useStyles } from "./Styles";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { Button, Card, CardContent, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { useTranslation } from "react-i18next";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import CardActions from "@mui/material/CardActions";
import DataDisplayM from "../../../../Helpers/DataDisplayM/DataDisplayM";

// font awesome stuff
const iconList = Object.keys(Icons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon) => Icons[icon]);

library.add(...iconList);

function KeywordView({ data = null }) {
  // css for module
  const classes = useStyles();

  // translation
  const { t } = useTranslation();

  // ui handlers
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // handle cancel event
  const handleCancelEvent = () => {
    handleClose();
  };

  // hooks
  useEffect(() => {}, []);

  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <Tooltip title={t("common.view")}>
          <IconButton aria-label="delete" size="small" onClick={handleOpen}>
            <FontAwesomeIcon className="edit-btn" icon="eye" />
          </IconButton>
        </Tooltip>
      </Box>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className={classes.modal}>
            <Card sx={{ width: "75vw", maxHeight: "90vh", overflowY: "auto" }}>
              <CardContent>
                <div className="py-4 px-md-4 px-0">
                  <h1 className="mb-3">{t("common.recordDetails")}</h1>
                  <div className="row full-width">
                    <div className="col-md-6">
                      {data && (
                        <DataDisplayM
                          label={"Root Word"}
                          data={data?.rootWord}
                        />
                      )}
                    </div>

                    <div className="col-md-6">
                      {data && (
                        <DataDisplayM
                          label={"Arabic Letter"}
                          data={data?.letter}
                        />
                      )}
                    </div>

                    <div className="col-md-12">
                      {data && (
                        <DataDisplayM
                          label={"Description"}
                          data={data?.description}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </CardContent>
              <CardActions>
                <div
                  className="pb-4 pt-2 px-md-4 px-2 "
                  style={{ width: "100%", textAlign: "right" }}
                >
                  {/* Cancel Button */}
                  <Button variant="outlined" onClick={handleCancelEvent}>
                    {t("common.cancel")}
                  </Button>
                  &nbsp;&nbsp;
                </div>
              </CardActions>
            </Card>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default KeywordView;
