// AuthGuard.tsx
// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  isLoggedIn,
  getLoggedInUserRole,
  retrieveJWT,
} from "../../../Services/LocalStorageService";
import { isAuthorized } from "./AccessGroups";
import { setSessionExpired } from "../../../Components/Screens/Authentication/AuthSession/Actions";
import jwtDecode from "jwt-decode";
import Login from "../../../Components/Screens/Authentication/Login/Login"; // Import the Login component

// Function to check if token is expired
const isTokenExpired = (token: string) => {
  if (!token) return true;

  try {
    // Decode the JWT
    const decoded: any = jwtDecode(token);

    // Check if the expiration time is less than the current time
    // exp is stored in seconds, so multiply by 1000 to convert to milliseconds
    return decoded.exp * 1000 < Date.now();
  } catch (error) {
    console.error("Error decoding JWT:", error);
    return true;
  }
};

export function AuthGuard({ route = "" }) {
  const auth = isLoggedIn();
  const location = useLocation();
  const dispatch = useDispatch();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isReAuthentication, setIsReAuthentication] = useState(false);
  const [returnPath, setReturnPath] = useState("");

  useEffect(() => {
    // Check token expiration on component mount or route change
    if (auth) {
      const token = retrieveJWT();
      if (isTokenExpired(token)) {
        // Dispatch session expired action
        dispatch(setSessionExpired());
        // Set re-authentication flag to true
        setIsReAuthentication(true);
        // Store current path for return after login
        setReturnPath(location.pathname.substring(1)); // Remove leading slash
        // Show login modal
        setShowLoginModal(true);
      }
    }
  }, [auth, location.pathname, dispatch]);

  // Handle successful login
  const handleLoginSuccess = () => {
    setShowLoginModal(false);
  };

  // If not authenticated, save current path and redirect to login with return URL
  if (!auth) {
    // Extract the current path excluding the leading slash
    const currentPath = location.pathname.substring(1);
    // Include any query params
    const queryParams = location.search;
    // Construct the full path
    const fullPath = currentPath + queryParams;

    // If there's a path to return to, encode it in the redirect
    if (fullPath) {
      return <Navigate to={`/login?reroute=${encodeURIComponent(fullPath)}`} />;
    }
    return <Navigate to="/login" />;
  }

  // If session expired and we're showing login modal for re-authentication
  if (showLoginModal) {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1000,
        }}
      >
        <div style={{ width: "100%", maxWidth: "500px" }}>
          <Login
            reAuthMode={true}
            returnUrl={returnPath}
            onLoginSuccess={handleLoginSuccess}
          />
        </div>
      </div>
    );
  }

  // If no specific route is required, just render the children
  if (route === "") {
    return <Outlet />;
  }

  // Check if user is authorized for this route
  const role = isAuthorized(getLoggedInUserRole(), "routes", route);

  return role ? <Outlet /> : <Navigate to="/" />;
}
