// @ts-nocheck
// NarratorsManager.tsx
import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Card,
  CardContent,
  Box,
  Typography,
  Button,
  FormControl,
  TextField,
  Autocomplete,
  IconButton,
  Chip,
  Alert,
  Collapse,
  useMediaQuery,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Avatar,
  ButtonGroup,
  Fade,
  Tooltip,
  Paper,
  Divider,
  Skeleton,
  Badge,
  useTheme,
} from "@mui/material";
import { post, getAllFiltered } from "../../../../Services/GenericApiService";
import { log } from "../../../../Services/LoggerService";
import {
  Timeline,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AddIcon from "@mui/icons-material/Add";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import SearchIcon from "@mui/icons-material/Search";
import GroupIcon from "@mui/icons-material/Group";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import RecommendIcon from "@mui/icons-material/Recommend";
import PeopleIcon from "@mui/icons-material/People";
import SortIcon from "@mui/icons-material/Sort";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import _ from "lodash";

const NarratorsManager = ({
  recordToUpdate,
  narratorChain,
  setNarratorChain,
  isAnyEditModeActive,
  editModes,
  toggleEditMode,
  validationStatus,
  setLoader,
  notifications,
  t,
  theme,
  toggleSection,
  activeSections,
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const localTheme = useTheme();

  const [narratorsData, setNarratorsData] = useState([]);
  const [narratorSearchTerm, setNarratorSearchTerm] = useState("");
  const [suggestedNarrators, setSuggestedNarrators] = useState([]);
  const [narratorsLoading, setNarratorsLoading] = useState(false);
  const [narratorSearchLoading, setNarratorSearchLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [draggedNarratorIndex, setDraggedNarratorIndex] = useState(null);
  const [dragOverNarratorIndex, setDragOverNarratorIndex] = useState(null);

  // Store original narrator chain for change detection and cancellation
  const [originalNarratorChain, setOriginalNarratorChain] = useState([]);

  // State to track if there are unsaved changes
  const [hasChanges, setHasChanges] = useState(false);

  // Check if narrators are valid
  const areNarratorsValid = () => narratorChain.length > 0;

  // Store original values when entering edit mode
  useEffect(() => {
    if (editModes.narrators) {
      // Make a deep copy of the narrator chain
      setOriginalNarratorChain(JSON.parse(JSON.stringify(narratorChain)));
    }
  }, [editModes.narrators]);

  // Check for changes when narrator chain changes
  useEffect(() => {
    if (editModes.narrators) {
      // Simple length check first
      if (narratorChain.length !== originalNarratorChain.length) {
        setHasChanges(true);
        return;
      }

      // Check if any narrator has changed
      const changed = narratorChain.some((narrator, index) => {
        return narrator.id !== originalNarratorChain[index]?.id;
      });

      setHasChanges(changed);
    }
  }, [narratorChain, editModes.narrators]);

  // Update hadith narrators
  const updateHadithNarrators = async () => {
    let data = {
      hadithId: recordToUpdate.hadithId,
      hadithTranslationId: recordToUpdate.id,
      hadithnarrators: narratorChain.map((narrator, orderNumber) => ({
        narratorId: narrator.id,
        orderNumber: orderNumber + 1,
      })),
    };

    setIsSubmitting(true);
    setLoader((prevState) => ({ ...prevState, modal: true }));

    try {
      await post("hadith/updateHadithNarrators", data);
      setLoader((prevState) => ({ ...prevState, modal: false }));
      toggleEditMode("narrators");

      // Show success indicator
      setSaveSuccess(true);
      setTimeout(() => setSaveSuccess(false), 3000);

      notifications.current.successAlert(t("common.editSuccess"), "");

      // If accordion is open, close it
      if (activeSections.narrators) {
        toggleSection("narrators");
      }
    } catch (error) {
      log(error);
      setLoader((prevState) => ({ ...prevState, modal: false }));
      notifications.current.errorAlert(
        t("common.somethingWentWrong"),
        t("common.tryAgain")
      );
    } finally {
      setIsSubmitting(false);
      setHasChanges(false);
    }
  };

  // Cancel editing and restore original values
  const cancelEditing = () => {
    // Restore original narrator chain
    setNarratorChain(JSON.parse(JSON.stringify(originalNarratorChain)));

    // Exit edit mode
    toggleEditMode("narrators");

    // Close accordion if open
    if (activeSections.narrators) {
      toggleSection("narrators");
    }

    setHasChanges(false);
  };

  // Add narrator to the chain
  const addNarrator = (event, value) => {
    if (value) {
      setNarratorChain((prevState) => {
        // Check if the narrator is already in the list
        const isNarratorInList = prevState.some(
          (narrator) => narrator.id === value.id
        );

        // If not, add the new narrator
        if (!isNarratorInList) {
          return [
            ...prevState,
            {
              name: value.label,
              id: value.id,
              nameEnglish: value.nameEnglish,
              kuniat: value.kuniat,
              nasab: value.nasab,
            },
          ];
        }

        // If already in the list, return the previous state
        return prevState;
      });
    }
  };

  // Delete narrator from the chain
  const deleteNarrator = (index) => {
    let chain = [...narratorChain];
    chain.splice(index, 1);
    setNarratorChain(chain);
  };

  // Handle narrator search term change
  const handleNarratorsSearchTermChange = (event) => {
    setNarratorSearchTerm(event.target.value);
  };

  // Debounce narrator search
  const debounceNarratorSearch = useCallback(
    _.debounce((query) => {
      fetchNarrators(query);
    }, 500),
    []
  );

  // Fetch narrators based on search term
  const fetchNarrators = async (query) => {
    if (!query) return;

    try {
      setNarratorSearchLoading(true);
      let params = query ? "searchedNarrator=" + query : "";
      const url = "narratordetail?" + params;

      getAllFiltered(url)
        .then((_res) => {
          const narratorsData = _res.map((nar) => ({
            id: nar.id,
            label: `${nar?.nameEnglish || ""} ${nar.name || ""}`,
            nameEnglish: nar?.nameEnglish,
            kuniat: nar?.kuniat,
            nasab: nar?.nasab,
          }));
          setNarratorsData(narratorsData);
          setNarratorSearchLoading(false);
        })
        .catch((error) => {
          console.error("Error searching narrators:", error);
          setNarratorSearchLoading(false);
        });
    } catch (error) {
      console.error("Error fetching narrators:", error);
      setNarratorSearchLoading(false);
    }
  };

  // Fetch narrator suggestions based on hadith translation
  const fetchNarratorSuggestions = async () => {
    if (!recordToUpdate?.translation) {
      notifications.current.warningAlert(
        "Warning",
        "Hadith translation text is required for narrator suggestions"
      );
      return;
    }

    setNarratorsLoading(true);
    setSuggestedNarrators([]);

    try {
      let params = recordToUpdate?.translation
        ? "hadithTranslation=" + encodeURIComponent(recordToUpdate?.translation)
        : "";

      const url = "narratordetail/suggestions?" + params;

      const _res = await getAllFiltered(url);
      const narratorData = _res.map((nar) => ({
        id: nar.id,
        label: (nar?.nameEnglish || "") + " " + (nar?.name || ""),
        nameEnglish: nar?.nameEnglish,
        kuniat: nar?.kuniat,
        nasab: nar?.nasab,
      }));

      setSuggestedNarrators(narratorData);
    } catch (error) {
      console.error("Error fetching suggested narrators:", error);
      notifications.current.errorAlert(
        "Error",
        "Failed to fetch narrator suggestions"
      );
    } finally {
      setNarratorsLoading(false);
    }
  };

  // Handle drag and drop reordering
  const handleDragStart = (index) => {
    setDraggedNarratorIndex(index);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    setDragOverNarratorIndex(index);
  };

  const handleDrop = (e) => {
    e.preventDefault();

    if (draggedNarratorIndex === null || dragOverNarratorIndex === null) return;
    if (draggedNarratorIndex === dragOverNarratorIndex) return;

    const newChain = [...narratorChain];
    const draggedItem = newChain[draggedNarratorIndex];

    // Remove the dragged item
    newChain.splice(draggedNarratorIndex, 1);

    // Insert at the new position
    newChain.splice(dragOverNarratorIndex, 0, draggedItem);

    setNarratorChain(newChain);
    setDraggedNarratorIndex(null);
    setDragOverNarratorIndex(null);
  };

  const handleDragEnd = () => {
    setDraggedNarratorIndex(null);
    setDragOverNarratorIndex(null);
  };

  // Handle edit mode toggle with accordion sync
  const handleEditToggle = () => {
    const newEditMode = !editModes.narrators;

    // If turning on edit mode and accordion is closed, open it
    if (newEditMode && !activeSections.narrators) {
      toggleSection("narrators");
    }

    toggleEditMode("narrators");
  };

  // Effect to handle narrator search
  useEffect(() => {
    if (narratorSearchTerm) {
      debounceNarratorSearch(narratorSearchTerm);
    }
  }, [narratorSearchTerm, debounceNarratorSearch]);

  return (
    <Card
      variant="outlined"
      sx={{
        mb: 2,
        boxShadow: "0 4px 6px rgba(0,0,0,0.05)",
        borderColor: areNarratorsValid() ? "success.light" : "error.light",
        borderWidth: 1,
        position: "relative",
        transition: "all 0.2s ease",
        "&:hover": {
          boxShadow: "0 6px 10px rgba(0,0,0,0.1)",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          p: 2,
          borderBottom: "1px solid",
          borderColor: "divider",
          cursor: "pointer",
          bgcolor:
            localTheme.palette.mode === "dark"
              ? "rgba(255,255,255,0.05)"
              : "rgba(0,0,0,0.03)",
          transition: "background-color 0.2s ease",
          "&:hover": {
            bgcolor:
              localTheme.palette.mode === "dark"
                ? "rgba(255,255,255,0.08)"
                : "rgba(0,0,0,0.05)",
          },
        }}
        onClick={() => toggleSection("narrators")}
      >
        <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
          {areNarratorsValid() ? (
            <CheckCircleOutlineIcon color="success" sx={{ mr: 1 }} />
          ) : (
            <ErrorOutlineIcon color="error" sx={{ mr: 1 }} />
          )}
          <Typography variant="h6">Narrators</Typography>

          <Badge
            badgeContent={narratorChain.length}
            color={narratorChain.length > 0 ? "success" : "error"}
            sx={{ ml: 2 }}
          />

          {editModes.narrators && (
            <Chip
              size="small"
              label="Editing Mode"
              color="primary"
              variant="outlined"
              sx={{ ml: 2 }}
            />
          )}

          {saveSuccess && (
            <Fade in={saveSuccess} timeout={300}>
              <Chip
                label="Saved successfully"
                color="success"
                size="small"
                icon={<CheckCircleOutlineIcon />}
                sx={{ ml: 2 }}
              />
            </Fade>
          )}
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          {editModes.narrators ? (
            <ButtonGroup
              variant="contained"
              size={isMobile ? "small" : "medium"}
              sx={{ ml: 1 }}
            >
              <Button
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  updateHadithNarrators();
                }}
                disabled={isSubmitting || !areNarratorsValid() || !hasChanges}
                startIcon={
                  isSubmitting ? (
                    <CircularProgress size={16} color="inherit" />
                  ) : (
                    <SaveIcon />
                  )
                }
              >
                {isSubmitting ? "Saving..." : "Save"}
              </Button>
              <Button
                color="secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  cancelEditing();
                }}
              >
                Cancel
              </Button>
            </ButtonGroup>
          ) : (
            <Button
              variant="outlined"
              disabled={isAnyEditModeActive && !editModes.narrators}
              onClick={(e) => {
                e.stopPropagation();
                handleEditToggle();
              }}
              color="primary"
              size={isMobile ? "small" : "medium"}
              startIcon={<EditIcon />}
            >
              Edit
            </Button>
          )}

          {activeSections.narrators ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Box>
      </Box>

      <Collapse in={activeSections.narrators} timeout={300}>
        <CardContent>
          {!areNarratorsValid() && (
            <Alert
              severity="warning"
              sx={{ mb: 3 }}
              icon={<ErrorOutlineIcon />}
            >
              Please add at least one narrator to the chain
            </Alert>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              flexWrap: "wrap",
              gap: 2,
            }}
          >
            {/* Narrator search */}
            <FormControl
              sx={{
                width: isMobile ? "100%" : "70%",
                minWidth: 300,
              }}
            >
              <Autocomplete
                id="narrator-autocomplete"
                options={narratorsData}
                getOptionLabel={(option) => option.label || ""}
                disabled={!editModes.narrators}
                loading={narratorSearchLoading}
                onChange={addNarrator}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                noOptionsText={
                  narratorSearchTerm.length > 0
                    ? "No narrators found"
                    : "Type to search narrators"
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Narrators"
                    onChange={handleNarratorsSearchTermChange}
                    variant="outlined"
                    placeholder="Type narrator name to search..."
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <SearchIcon color="action" sx={{ ml: 1, mr: 0.5 }} />
                      ),
                      endAdornment: (
                        <>
                          {narratorSearchLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                      sx: {
                        bgcolor: editModes.narrators
                          ? localTheme.palette.mode === "dark"
                            ? "rgba(255,255,255,0.03)"
                            : "rgba(0,0,0,0.01)"
                          : "transparent",
                      },
                    }}
                  />
                )}
              />
            </FormControl>

            {/* Get suggestions button */}
            <Tooltip title="Suggests narrators based on the hadith translation text">
              <Button
                variant="contained"
                color="secondary"
                disabled={!editModes.narrators || narratorsLoading || true}
                onClick={fetchNarratorSuggestions}
                startIcon={
                  narratorsLoading ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <RecommendIcon />
                  )
                }
                sx={{
                  height: isMobile ? "auto" : 56,
                  alignSelf: isMobile ? "flex-start" : "center",
                }}
              >
                {narratorsLoading ? "Loading..." : "Get Suggestions"}
              </Button>
            </Tooltip>
          </Box>

          {/* Narrator chain display */}
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              mt: 3,
              gap: 3,
            }}
          >
            {/* Left side: Narrator chain */}
            <Box
              sx={{
                width: isMobile ? "100%" : "50%",
                border: "1px solid",
                borderColor: "divider",
                borderRadius: 1,
                p: 2,
                bgcolor:
                  localTheme.palette.mode === "dark"
                    ? "rgba(255,255,255,0.02)"
                    : "rgba(0,0,0,0.01)",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "medium",
                  }}
                >
                  <PeopleIcon sx={{ mr: 1 }} />
                  Narrators Chain
                  <Chip
                    label={narratorChain.length}
                    size="small"
                    color={narratorChain.length > 0 ? "success" : "error"}
                    sx={{ ml: 1 }}
                  />
                </Typography>

                {editModes.narrators && narratorChain.length > 1 && (
                  <Tooltip title="Drag and drop narrators to reorder">
                    <Chip
                      icon={<SwapVertIcon />}
                      label="Reorder"
                      size="small"
                      color="primary"
                      variant="outlined"
                    />
                  </Tooltip>
                )}
              </Box>

              {narratorChain.length > 0 ? (
                <Timeline
                  sx={{
                    [`& .${timelineItemClasses.root}:before`]: {
                      flex: 0,
                      padding: 0,
                    },
                    p: 0,
                    m: 0,
                  }}
                >
                  {narratorChain.map((narrator, narratorIndex) => (
                    <TimelineItem
                      key={`${narrator.id}-${narratorIndex}`}
                      draggable={editModes.narrators}
                      onDragStart={() => handleDragStart(narratorIndex)}
                      onDragOver={(e) => handleDragOver(e, narratorIndex)}
                      onDrop={handleDrop}
                      onDragEnd={handleDragEnd}
                      sx={{
                        cursor: editModes.narrators ? "grab" : "default",
                        p: 1,
                        border:
                          dragOverNarratorIndex === narratorIndex
                            ? "1px dashed"
                            : "1px solid transparent",
                        borderColor:
                          dragOverNarratorIndex === narratorIndex
                            ? "primary.main"
                            : "transparent",
                        borderRadius: 1,
                        bgcolor:
                          draggedNarratorIndex === narratorIndex
                            ? localTheme.palette.mode === "dark"
                              ? "rgba(255,255,255,0.1)"
                              : "rgba(0,0,0,0.05)"
                            : "transparent",
                        "&:hover": {
                          bgcolor: editModes.narrators
                            ? localTheme.palette.mode === "dark"
                              ? "rgba(255,255,255,0.05)"
                              : "rgba(0,0,0,0.02)"
                            : "transparent",
                        },
                        transition: "all 0.2s ease",
                      }}
                    >
                      <TimelineSeparator>
                        <TimelineDot
                          color={narratorIndex === 0 ? "success" : "primary"}
                          variant={narratorIndex === 0 ? "filled" : "outlined"}
                          sx={{ p: 0.5 }}
                        >
                          <Typography
                            variant="caption"
                            sx={{ fontWeight: "bold" }}
                          >
                            {narratorIndex + 1}
                          </Typography>
                        </TimelineDot>
                        {narratorIndex !== narratorChain.length - 1 && (
                          <TimelineConnector />
                        )}
                      </TimelineSeparator>
                      <TimelineContent sx={{ py: "12px", px: 2 }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                          }}
                        >
                          <Box>
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: "medium" }}
                            >
                              {narrator?.nameEnglish || ""}{" "}
                              {narrator?.name || ""}
                            </Typography>
                            {(narrator?.kuniat || narrator?.nasab) && (
                              <Typography
                                variant="caption"
                                sx={{
                                  display: "block",
                                  color: "text.secondary",
                                }}
                              >
                                {narrator?.kuniat} {narrator?.nasab}
                              </Typography>
                            )}
                          </Box>

                          {editModes.narrators && (
                            <IconButton
                              size="small"
                              color="error"
                              onClick={(e) => {
                                e.stopPropagation();
                                deleteNarrator(narratorIndex);
                              }}
                              sx={{
                                "&:hover": {
                                  bgcolor: "error.light",
                                  color: "white",
                                },
                              }}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          )}
                        </Box>
                      </TimelineContent>
                    </TimelineItem>
                  ))}
                </Timeline>
              ) : (
                <Box
                  sx={{
                    p: 3,
                    textAlign: "center",
                    border: "1px dashed",
                    borderColor: "divider",
                    borderRadius: 1,
                  }}
                >
                  <PeopleIcon color="disabled" sx={{ fontSize: 40, mb: 1 }} />
                  <Typography variant="body2" color="text.secondary">
                    No narrators added yet.
                    {editModes.narrators &&
                      " Search for narrators or use the suggestions."}
                  </Typography>
                </Box>
              )}
            </Box>

            {/* Right side: Suggested narrators */}
            <Box
              sx={{
                width: isMobile ? "100%" : "50%",
                border: "1px solid",
                borderColor: "divider",
                borderRadius: 1,
                p: 2,
                bgcolor:
                  localTheme.palette.mode === "dark"
                    ? "rgba(255,255,255,0.02)"
                    : "rgba(0,0,0,0.01)",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  mb: 2,
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "medium",
                }}
              >
                <RecommendIcon sx={{ mr: 1 }} /> Suggested Narrators
              </Typography>

              {narratorsLoading ? (
                <Box sx={{ p: 3 }}>
                  {[1, 2, 3].map((item) => (
                    <Skeleton
                      key={item}
                      variant="rounded"
                      width="100%"
                      height={40}
                      sx={{ mb: 1 }}
                    />
                  ))}
                </Box>
              ) : suggestedNarrators.length > 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 1,
                    p: 1,
                    overflowY: "auto",
                    maxHeight: "300px",
                  }}
                >
                  {suggestedNarrators?.map((narrator) => (
                    <Chip
                      key={narrator.id}
                      label={narrator.label}
                      disabled={!editModes.narrators}
                      onClick={() => addNarrator(null, narrator)}
                      onDelete={
                        editModes.narrators
                          ? () => addNarrator(null, narrator)
                          : undefined
                      }
                      deleteIcon={<AddIcon />}
                      color="primary"
                      variant="outlined"
                      sx={{
                        mb: 1,
                        transition: "all 0.2s ease",
                        "&:hover": {
                          bgcolor: editModes.narrators
                            ? "primary.light"
                            : "transparent",
                          color: editModes.narrators ? "white" : "inherit",
                        },
                      }}
                    />
                  ))}
                </Box>
              ) : (
                <Box
                  sx={{
                    p: 3,
                    textAlign: "center",
                    border: "1px dashed",
                    borderColor: "divider",
                    borderRadius: 1,
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <RecommendIcon
                    color="disabled"
                    sx={{ fontSize: 40, mb: 1 }}
                  />
                  <Typography variant="body2" color="text.secondary">
                    No suggested narrators available.
                  </Typography>
                  {editModes.narrators && (
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<AutorenewIcon />}
                      size="small"
                      onClick={fetchNarratorSuggestions}
                      sx={{ mt: 2 }}
                      disabled={narratorsLoading}
                    >
                      Get Suggestions
                    </Button>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </CardContent>
      </Collapse>

      {/* Edit mode indicator */}
      {editModes.narrators && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "4px",
            height: "100%",
            bgcolor: "primary.main",
            borderTopLeftRadius: "4px",
            borderBottomLeftRadius: "4px",
          }}
        />
      )}
    </Card>
  );
};

export default NarratorsManager;
