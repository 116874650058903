import {COLLAPSE, EXPAND, UPDATE_MENU} from "./Types";

const initialState = {
    state: {
        isExpanded: false,
        menuItems: []
    }
}

const SiderReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case COLLAPSE: return {
            ...state,
            state: {
                isExpanded: false,
                menuItems: [...state.state.menuItems],
            }
        }
        case EXPAND: return {
            ...state,
            state: {
                isExpanded: true,
                menuItems: [...state.state.menuItems],
            }
        }
        case UPDATE_MENU: return {
            ...state,
            state: {
                isExpanded: state.state.isExpanded,
                menuItems: [...action.payload]
            }
        }
        default: return state
    }
}

export default SiderReducer;
