import React, { useRef } from 'react';
import { useStyles } from './Styles';
import { patch, post } from "../../../../../Services/GenericApiService";
import { useTranslation } from "react-i18next";
import { log } from "../../../../../Services/LoggerService";

import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { Button } from "@mui/material";
import AlertM from '../../../../Helpers/AlertM/AlertM';
import KeywordForm from '../KeywordForm/KeywordForm';
import { getUserData } from '../../../../../Services/LocalStorageService';


// @ts-ignore
function KeywordUpdate({ refreshDataEvent, recordToUpdate, resetEditState, isReviewMode, EditMode, currentTabValue}) {
    // css for module
    const classes = useStyles();

    // module(s) for api
    const moduleMain = 'keyword';

    // translation
    const { t } = useTranslation();

    // child ref for alert
    const notifications = useRef();

    // ui handlers
    const [open, setOpen] = React.useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [loading, setLoading] = React.useState(false);


    // delay before closing update screen
    const sleep = (milliseconds: number) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    // handle edit record event
    const handleEditEvent = (_formData: any) => {
        setLoading(true); // show loader
        patch(moduleMain, _formData.id, _formData)
            .then(async (_res) => {
                setLoading(false); // hide loader
                refreshDataEvent(); // refresh data
                handleClose();
                // @ts-ignore
                notifications.current.successAlert(t('common.editSuccess'), '');
                // @ts-ignore
                await sleep(process.env.REACT_APP_UPDATE_NOTIFICATION_TIMEOUT_MS || 1000);
                resetEdit();
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                resetEdit();
            })
    }

    const handleReviewEvent = (_formData: any) => {
        setLoading(true); // show loader
        const currentUser: any = getUserData();
        let updatedData = { ..._formData } 

        if(currentUser.id !== updatedData.addedByUserId)
        {
            switch (currentTabValue) {
                case "unapproved":
                  updatedData = {
                    ...updatedData,
                    level1Approved: true,
                    level1ApprovedBy: currentUser?.id
                  };
                  break;
    
                  case "level1Approved":
                    updatedData = {
                      ...updatedData,
                      level2Approved: true,
                      level2ApprovedBy: currentUser?.id
                    };
                    break;
                    
                  default:
                  break;
            }
    
            patch(moduleMain, _formData.id, updatedData)
            .then(async (_res) => {
                setLoading(false); // hide loader
                refreshDataEvent(); // refresh data
                handleClose();
                // @ts-ignore
                notifications.current.successAlert(t('common.editSuccess'), '');
                // @ts-ignore
                await sleep(process.env.REACT_APP_UPDATE_NOTIFICATION_TIMEOUT_MS || 1000);
                resetEdit();
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                resetEdit();
            })
        }
        else{
            // @ts-ignore
            notifications.current.errorAlert(t("You do not have access to this operation."));
            setLoading(false); // hide loader
        }
    }
            
    // handle cancel event
    const handleCancelEvent = () => {
        handleClose();
        resetEdit();
    }


    // reset isEdit to false in main component
    const resetEdit = () => {
        resetEditState(true);
    }


    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box className={classes.modal}>
                        <KeywordForm handleCancelEvent={handleCancelEvent}
                            handleAddEvent={() => { }}
                            isEditMode={EditMode}
                            handleEditEvent={handleEditEvent}
                            recordToUpdate={recordToUpdate}
                            isLoading={loading} 
                            handleReviewEvent={handleReviewEvent}
                            isReviewMode={isReviewMode}
                            />
                    </Box>
                </Fade>
            </Modal>


            {/* Alerts */}
            <AlertM ref={notifications} />

        </div>
    );
}

export default KeywordUpdate;
