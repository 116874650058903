// @ts-nocheck

import React, {
  useEffect,
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
} from "react";
import { useStyles } from "./Styles";
import "../../../../../index.scss";
import { useTheme } from "@mui/material/styles";
import {
  getAll,
  getAllFiltered,
  getById,
  post,
  patchData,
  patch,
} from "../../../../../Services/GenericApiService";
import { useTranslation } from "react-i18next";
import { log } from "../../../../../Services/LoggerService";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import AlertM from "../../../../Helpers/AlertM/AlertM";
// import CommentIcon from "@mui/icons-material/Comment";
// import DetailsIcon from "@mui/icons-material/Details";
import Box from "@mui/material/Box";
import {
  Typography,
  Paper,
  Divider,
  Button,
  TextField,
  Tabs,
  Tab,
  FormControl
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import Switch from "@mui/material/Switch";
import {
  getLoggedInUserRole,
  getUserData,
} from "../../../../../Services/LocalStorageService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Chip from "@mui/material/Chip";
import "react-quill/dist/quill.snow.css";
// import SearchMain from "../../Search/SearchMain/SearchMain";
// import HadithSiderSearch from "../../Hadith/HadithSiderSearch/HadithSiderSearch";
import ManageTafseer from "../ManageTafseer/ManageTafseer";
import { useNavigate, useParams } from "react-router-dom";
import ManageKeywords from "../ManageKeywords/ManageKeywords";
import ManageVerseWord from "../ManageVerseWord/ManageVerseWord";
import { isAuthorized } from "../../../../../Routes/Guards/AuthGuard/AccessGroups";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

// font awesome stuff
const iconList = Object.keys(Icons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon) => Icons[icon]);

library.add(...iconList);

const Circle = styled("div")({
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  width: "20px", // Adjust based on your needs
  height: "20px", // Adjust to keep the aspect ratio
  backgroundColor: "#f0f0f0", // Circle background color
  color: "#000", // Text color
  borderRadius: "50%", // This creates the circle shape
  fontSize: "15px", // Adjust based on your needs
  direction: "rtl", // For Arabic text direction
});

const verseStyle = {
  alignItems: "center",
  justifyContent: "flex-start", // Align content to the right edge of each verse block
  marginBottom: "10px", // Space between verses, adjust as needed
  fontSize: "2em",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#f0f0f0 !important", // Adjust the hover color as needed
  },
};

const VersesReadView = forwardRef(
  (
    { openModal = null, linkVerseToHadith, selectedChapter, handleLinkVerse },
    ref
  ) => {
    const { chapterId } = useParams();
    const chapter = chapterId || selectedChapter;

    const navigate = useNavigate();

    useImperativeHandle(ref, () => ({
      openModal() {
        setOpen(true);
      },
    }));

    // const theme = useTheme();

    // css for module
    const classes = useStyles();

    // translation
    const { t } = useTranslation();

    // child ref for alert
    const notifications = useRef();
    const manageTafseerRef = useRef();
    const manageKeywordsRef = useRef();
    const manageVerseWordRef = useRef();

    // data vars
    const [verses, setAllVerses] = useState([]);

    // // ui controls
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentChapter, setCurrentChapter] = useState(null);
    const [commentOpen, setCommentOpen] = useState(false);
    const [verseLoading, setVersetLoading] = React.useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [userRole, setUserRole] = React.useState("");
    const [isVerificationModeActive, setIsVerificationModeActive] =
      React.useState(false);
    const [currentPageLogs, setCurrentPageLogs] = React.useState(0);
    const [verseIssueData, setVerseIssueData] = useState({});
    const [isVerificationControlEnabled, setIsVerificationControlEnabled] =
      React.useState(false);
    const [verseTafseerData, setVerseTafseerData] = useState({});
    const [tafseerBooks, setAllTafseerBooks] = useState([]);
    const [isTopicOpen, setIsTopicOpen] = useState(false);
    const [newTopic, setNewTopic] = useState("");
    const [tafseerTopics, setTafseerTopics] = useState([]);
    const [allBooks, setAllBooks] = useState([]);
    const [selectedBook, setSelectedBook] = useState("");
    const [selectedVerse, setSelectedVerse] = useState(null);
    const [selectedVerseWord, setSelectedVerseWord] = useState(null);
    const [verseWordOpen, setVerseWordOpen] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [verseIssues, setVerseIssues] = useState([]);
    const [existingVerseText, setExistingVerseText] = useState(null);
    const [enableResetChapter, setEnableResetChapter] = useState(false);

    const moduleMain = "pageverificationlog";
    const moduleTafseer = "verse/tafseer";
    const moduleBook = "book";

    useEffect(() => {
      if (chapter) fetchChapter(chapter);
    }, [chapter]);

    useEffect(() => {
      if (currentChapter) {
        fetchPage({ page: currentChapter?.startPage, chapter: currentChapter?.id });
      }
    }, [currentChapter]);

    // // Function to handle tooltip toggle
    // const handleTooltipToggle = () => {
    //     setTooltipOpen(!tooltipOpen);
    // };

    // // Close tooltip explicitly
    // const handleCloseTooltip = () => {
    //     setTooltipOpen(false);
    // };

    // useEffect(() => {
    //     if (open && currentChapter?.startPage) {
    //         setCurrentPage(currentChapter.startPage)
    //     }
    // }, [open, currentChapter])

    const handleClose = () => setOpen(false);

    const handlResetData = () => {
      setTafseerTopics([]);
      setAllTafseerBooks([]);
    };

    const handleTabChange = (event, newValue) => {
      setActiveTab(newValue);
    };

    const handleVerseClick = (data, index, type) => {
      if(isVerificationControlEnabled && isVerificationModeActive)
      {
        setSelectedVerse(data);
        getVerseIssues(data);
        setOpen(true);
      } 
    };

    const getVerseIssues = (verse) => {
      try {
        const url = `verseissue/byVerse/${verse.id}`;
        getAllFiltered(url)
          .then((_res) => {
            setVerseIssues(_res);

            let _verseIssueData = {
              verseId: verse?.id,
              verse: verse?.textIndoPak,
              comment: null,
              type: "verse",
              issues: _res,
            };
            setVerseIssueData(_verseIssueData);
          })
          .catch((error) => {
            log(error);
          });
      } catch (error) {
        log(error);
      }
    };

    const handleAddTafseer = (verse, verseIndex) => {
      handlResetData();
      let _verseTafseerData = {
        verseId: verse?.id,
        verse: verse?.textIndoPak,
        tafseerBook: null,
        tafseerTopic: null,
        text: "",
      };
      setVerseTafseerData(_verseTafseerData);
      fetchAllVerseBooks(verse?.id);
      setSelectedVerse(verse);
      setTafseerOpen(true);
    };

    const handleOpenManageKeywords = (verse) => {
      setSelectedVerse(verse);
      setManageKeywordsOpen(true);
    };

    const filterAvailableBooks = (_allBooks) => {
      if (_allBooks.length > 0 && tafseerBooks.length > 0) {
        // Assuming each book has a unique 'id' property
        const filteredBooks = _allBooks.filter(
          (allBook) =>
            !tafseerBooks.some((tafseerBook) => tafseerBook.id === allBook.id)
        );

        // Now 'filteredBooks' will have the books that are not in 'tafseerBooks'
        setAllBooks(filteredBooks);
        return;
      }
      setAllBooks(_allBooks);
    };

    const fetchChapter = (id) => {
      setLoading(true); // show loader
      getById("chapter", id)
        .then((_res: any) => {
          // set all data
          setCurrentChapter(_res);
          setCurrentPage(_res?.startPage);
          setLoading(false); // hide loader
        })
        .catch((_err) => {
          log(_err);
          setLoading(false); // hide loader
        });
    };

    const fetchAllBooks = () => {
      getAll("book")
        .then((_res: any) => {
          filterAvailableBooks(_res);
        })
        .catch((_err) => {
          notifications.current.errorAlert("Error", "Could not fetch Books");
          log(_err);
        });
    };

    const fetchAllVerseBooks = (verseId) => {
      getById("versebook/getByVerse", verseId)
        .then((_res: any) => {
          setAllTafseerBooks(_res);
          filterAvailableBooks(allBooks);
        })
        .catch((_err) => {
          notifications.current.errorAlert(
            "Error",
            "Could not fetch Linked Books"
          );
          log(_err);
        });
    };

    const handleTafseerBookChange = (value) => {
      const selectedBookId = value;
      setVerseTafseerData((prev) => ({
        ...prev,
        tafseerBook: selectedBookId,
        tafseerTopic: null,
      }));

      fetchTopicsByBookId(selectedBookId);
    };

    const fetchTopicsByBookId = (tafseerBook) => {
      getAllFiltered(
        "tafseertopic" +
          "?bookId=" +
          tafseerBook +
          "&verseId=" +
          verseTafseerData.verseId
      )
        .then((_res: any) => {
          setTafseerTopics(_res);
        })
        .catch((_err) => {
          log(_err);
        });
    };

    const handleTafseerTopicChange = (value) => {
      const selectedTopicId = value;
      setVerseTafseerData((prev) => ({
        ...prev,
        tafseerTopic: selectedTopicId,
      }));

      fetchTafseerByTopic(selectedTopicId);
    };

    const fetchTafseerByTopic = (selectedTopicId) => {
      getById("verse/topicTafseer", selectedTopicId)
        .then((_res: any) => {
          setVerseTafseerData((prev) => ({
            ...prev,
            text: _res.text,
          }));
        })
        .catch((_err) => {
          log(_err);
        });
    };

    const handleSubmitTopic = () => {
      const verseTopic = {
        name: newTopic,
        bookId: verseTafseerData?.tafseerBook,
        verseId: verseTafseerData?.verseId,
        tafseerText: "",
        addedByUserId: getUserData().id,
      };

      post("tafseertopic", verseTopic)
        .then((_res) => {
          setNewTopic("");
          fetchPage({ page: currentPage, chapter: currentChapter?.id });
          fetchTopicsByBookId(verseTafseerData?.tafseerBook);

          setVerseTafseerData((prev) => ({
            ...prev,
            tafseerTopic: _res.id,
          }));
          fetchTafseerByTopic(_res.id);
        })
        .catch((_err) => {
          log(_err);
          notifications.current.errorAlert(
            "Error",
            "Could not add tafseer topic"
          );
        });

      setIsTopicOpen(false);
    };

    const submitVerseTafseer = () => {
      const _verseTafseerData = {
        text: verseTafseerData?.text,
        verseId: verseTafseerData?.verseId,
        topicId: verseTafseerData?.tafseerTopic,
        tafseerBook: verseTafseerData?.tafseerBook,
        addedByUserId: getUserData().id,
      };
      setVerseTafseerData(_verseTafseerData);

      patchData(moduleTafseer, _verseTafseerData)
        .then((_res) => {
          fetchPage({ page: currentPage, chapter: currentChapter?.id});
          fetchAllVerseBooks(verseTafseerData?.verseId);
          notifications.current.successAlert(
            t("common.createSuccess"),
            "Tafseer Added Successfully"
          );
        })
        .catch((_err) => {
          log(_err);
          notifications.current.errorAlert(
            "Error",
            "Could not add tafseer for verse " + currentPage
          );
        });
    };

    const fetchPage = ({
      page = null,
      juz = null,
      chapter = null,
      cachedData = true,
    }) => {
      setVersetLoading(true); // show loader

      let params = page ? "page=" + page : "page=" + currentPage;
      params += juz ? "&juz=" + juz : "";
      params += chapter ? "&chapter=" + chapter : "";
      params += "&cachedData=" + cachedData;
      params += "&includeIssues=true";

      const url = "verse?" + params;

      getAllFiltered(url)
        .then((_res: any) => {
          // set all data
          setAllVerses(_res);
          fetchPageLogs({ pageNumber: page ? page : currentPage });
          setVersetLoading(false); // hide loader
        })
        .catch((_err) => {
          log(_err);
          setVersetLoading(false); // hide loader
        });
    };

    const fetchPageLogs = ({ pageNumber = null }) => {
      setVersetLoading(true); // show loader

      getById(moduleMain, pageNumber)
        .then((_res: any) => {
          setCurrentPageLogs(_res);
          setVersetLoading(false); // hide loader
        })
        .catch((_err) => {
          log(_err);
          setVersetLoading(false); // hide loader
        });
    };

    useEffect(() => {
      const roleId = getLoggedInUserRole();
      setUserRole(roleId);

      // Enables controls for verification mode
      if(roleId == process.env.REACT_APP_ADMIN_ROLE_ID || roleId == process.env.REACT_APP_MANAGER_ROLE_ID)
      {
        setIsVerificationControlEnabled(true);
      }
      else if (
        currentPageLogs?.stepOneVerification &&
        currentPageLogs?.stepTwoVerification &&
        currentPageLogs?.stepThreeVerification
      ) {
        setIsVerificationControlEnabled(false);
      } else if (
        roleId == process.env.REACT_APP_STUDENT_ROLE_ID &&
        !currentPageLogs.stepOneVerification
      ) {
        setIsVerificationControlEnabled(true);
      } else if (
        roleId == process.env.REACT_APP_TEACHER_ROLE_ID &&
        currentPageLogs?.stepOneVerification &&
        !currentPageLogs?.stepTwoVerification
      ) {
        setIsVerificationControlEnabled(true);
      } else if (
        roleId == process.env.REACT_APP_QC_ROLE_ID &&
        currentPageLogs?.stepTwoVerification &&
        !currentPageLogs?.stepThreeVerification
      ) {
        setIsVerificationControlEnabled(true);
      } else {
        setIsVerificationControlEnabled(false);
      }
    });

    const handleNextPage = () => {
      if(currentPage != parseInt(currentChapter?.endPage))
      {
        let nextPage = currentPage + 1;
        setCurrentPage(nextPage);
        fetchPage({ page: nextPage, chapter: currentChapter?.id });
      }
      else{
        fetchPage({ page: currentPage, chapter: currentChapter?.id });
      }
    };

    const hanldePreviousPage = () => {
      const nextPage = currentPage - 1;
      setCurrentPage(nextPage);
      fetchPage({ page: nextPage, chapter: currentChapter?.id });
    };

    const handleVerificationModeChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setIsVerificationModeActive(event.target.checked);
    };

    const handleConfirmPage = () => {
      let logsData = {
        pageNumber: currentPage,
      };

      switch (userRole) {
        case process.env.REACT_APP_STUDENT_ROLE_ID: // Student
          logsData.stepOneVerification = true;
          break;
        case process.env.REACT_APP_TEACHER_ROLE_ID: // Teacher
          logsData.stepTwoVerification = true;
          break;
        case process.env.REACT_APP_QC_ROLE_ID: // QC
          logsData.stepThreeVerification = true;
          break;
        default:
          break;
      }

      patchData(moduleMain, logsData)
        .then((_res) => {
          handleNextPage();
        })
        .catch((_err) => {
          log(_err);
          notifications.current.errorAlert(
            "Error",
            "Could not update logs for page " + currentPage
          );
        });
    };

    const submitComentIssue = () => {
      const data = {
        comment: verseIssueData?.comment,
        pageNumber: currentPage,
        addedByUserId: getUserData().id,
      };
      data.createdAt = new Date();

      if (verseIssueData?.type === "verse") {
        data.verseId = verseIssueData?.verseId;
      } else data.wordId = verseIssueData?.wordId;

      post("verseissue/add", data)
        .then((_res) => {
          notifications.current.successAlert(t("common.createSuccess"), "");
          fetchPage({ page: currentPage, chapter: currentChapter?.id, cachedData: false });

          getVerseIssues(selectedVerse);
          setVerseIssueData((prev) => ({
            ...prev,
            comment: "",
          }));
        })
        .catch((_err) => {
          log(_err);
          notifications.current.errorAlert(
            "Error",
            "Could not update logs for page " + currentPage
          );
        });
    };

    const resolveIssue = (issue = null) => {
      if (!issue?.id) return;
      patch("verseissue", issue?.id, { isResolved: true, pageNumber: currentPage})
        .then((_res) => {
          notifications.current.successAlert(
            "Comment Resolved Successfully!",
            ""
          );

          getVerseIssues(selectedVerse);
          fetchPage({ page: currentPage, chapter: currentChapter?.id, cachedData: false });
          setCommentOpen(false);
        })
        .catch((_err) => {
          log(_err);
          notifications.current.errorAlert(
            "Error",
            "Could not resolve issue " + currentPage
          );
        });
    };

    const getTotalChapterPages = () => {
      return (
        parseInt(currentChapter?.endPage) -
        parseInt(currentChapter?.startPage) +
        1
      );
    };

    const handleTopicOpen = () => {
      setIsTopicOpen(true);
    };

    const handleTopicClose = () => {
      setIsTopicOpen(false);
    };

    const handleBookChange = (event) => {
      setSelectedBook(event.target.value);
    };

    const handleLinkVerseToHadith = (verse) => {
      handleLinkVerse(verse?.id);
    };

    const handleOpenVerseWord = (word) => {
      setSelectedVerseWord(word);
      setVerseWordOpen(true);
    };

    const closeManageVerseWord = () => {
      setVerseWordOpen(false);
      fetchPage({ page: currentPage, chapter: currentChapter?.id});
    };

    const getVerseColor = (issues) => {
      if (issues?.some((issue) => !issue.isResolved)) {
        return "red";
      }
      if (issues?.length > 0) {
        return "blue";
      }
      return "inherit";
    };

    const handleVerseTextChange = (value, verse) => {

      setExistingVerseText(verse.textIndoPak);

      const updatedText = value.replace(/<\/?[^>]+(>|$)/g, "").trim();
      if (updatedText !== verse.textIndoPak)
      {
        setSelectedVerse((prevVerse) => ({
          ...prevVerse,
          textIndoPak: updatedText || value,
        }));
      } 
    };

    const handleVerseUpdate = (verse) => {
      const updatedVerse = {
        textIndoPak: verse?.textIndoPak,
        existingText: existingVerseText,
        updatedAt: new Date(),
        updatedBy: getUserData().id,
      };

      patch("verse", verse?.id, updatedVerse)
        .then((_res) => {
          notifications.current.successAlert(
            "Verse Text Updated Successfully!",
            ""
          );
          fetchPage({ page: currentPage, chapter: currentChapter?.id, cachedData: false });
        })
        .catch((_err) => {
          log(_err);
          notifications.current.errorAlert(
            "Error",
            "Could not update verse text " + currentPage
          );
        });
    };

    const handleRefreshChapterLogs = () => {
      setLoading(true); // show loader
      getById("chapter/logs", chapter)
        .then((_res: any) => {

          setEnableResetChapter(_res);
          setLoading(false); // hide loader
        })
        .catch((_err) => {
          log(_err);
          setLoading(false); // hide loader
        });
    }

    const handleResetChapterVerification = () => {
      setLoading(true); // show loader

      const updatedChapter = {
        isReviewed: true,
        isResolved: false, 
      };

      patch("chapter/reset", chapter, updatedChapter)
        .then((_res) => {
          setLoading(false); // hide loader
          notifications.current.successAlert("Chapter Reset Successfully!", "");

          fetchChapter(currentChapter?.id);
          fetchPage({ page: currentPage, chapter: currentChapter?.id, cachedData: false });
        })
        .catch((_err) => {
          setLoading(false); // hide loader
          log(_err);
          notifications.current.errorAlert(
            "Error",
            "Could not reset chapter " + currentChapter
          );
        });
    }

    return (
      <>
        <div className="m-2">
          {!linkVerseToHadith && (
            <Button
              onClick={() => navigate("/quran")}
              startIcon={<FontAwesomeIcon icon="arrow-left" />}
            >
              Back to Chapters
            </Button>
          )}
        </div>
        <Paper
          sx={{
            flexGrow: 1,
            overflow: "auto",
            border: isVerificationModeActive ? "0.8px solid yellow" : "none",
            boxShadow: isVerificationModeActive
              ? "0px 0px 12px yellow"
              : "none",
          }}
        >
          <DialogTitle id="alert-dialog-title">
            <div className="d-flex align-items-center">
              <div
                className=""
                style={{ fontSize: "1.3em", fontWeight: "bold" }}
              >
                {currentChapter?.nameArabic || ""}
              </div>
              <div className="mx-3" style={{ fontSize: "0.8em" }}>
                {currentChapter?.totalVerses} Verses • {getTotalChapterPages()}{" "}
                Pages
              </div>

              {(isVerificationControlEnabled && isVerificationModeActive && userRole == 6) && (
                <>
                  <div className="mx-3" style={{ fontSize: "0.8em" }}>
                    <Button onClick={() => handleRefreshChapterLogs()}>
                      Refresh Logs
                    </Button>
                  </div>

                  {enableResetChapter && (
                    <div className="mx-3" style={{ fontSize: "0.8em" }}>
                      <Button onClick={() => handleResetChapterVerification()}>
                        Reset Chapter
                      </Button>
                    </div>
                  )}
                </>
              )}
      
              {isVerificationControlEnabled && !linkVerseToHadith && (
                <div className="ms-auto">
                  <Switch
                    checked={isVerificationModeActive}
                    onChange={handleVerificationModeChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <span
                    style={{
                      color: isVerificationModeActive ? "yellow" : "white",
                    }}
                  >
                    Verification Mode
                  </span>
                </div>
              )}
            </div>
            <Divider />
          </DialogTitle>
          <DialogContent>
            <Box className="">
              <div className="mx-1">
                <span className={classes.chip}>
                  <Chip
                    label="Step One Verification"
                    size="small"
                    variant={
                      currentPageLogs.stepOneVerification
                        ? "contained"
                        : "outlined"
                    }
                    color={
                      currentPageLogs.stepOneVerification ? "success" : "error"
                    }
                    icon={
                      currentPageLogs.stepOneVerification ? (
                        <FontAwesomeIcon className="p-1" icon="check" />
                      ) : (
                        <FontAwesomeIcon className="p-2" icon="exclamation" />
                      )
                    }
                  />
                </span>
                <span className={classes.chip}>
                  <Chip
                    size="small"
                    variant={
                      currentPageLogs.stepTwoVerification
                        ? "contained"
                        : "outlined"
                    }
                    label="Step Two Verification"
                    color={
                      currentPageLogs.stepTwoVerification ? "success" : "error"
                    }
                    icon={
                      currentPageLogs.stepTwoVerification ? (
                        <FontAwesomeIcon className="p-1" icon="check" />
                      ) : (
                        <FontAwesomeIcon className="p-2" icon="exclamation" />
                      )
                    }
                  />
                </span>
                <span className={classes.chip}>
                  <Chip
                    size="small"
                    variant={
                      currentPageLogs.stepThreeVerification
                        ? "contained"
                        : "outlined"
                    }
                    label="Step Three Verification"
                    color={
                      currentPageLogs.stepThreeVerification
                        ? "success"
                        : "error"
                    }
                    icon={
                      currentPageLogs.stepThreeVerification ? (
                        <FontAwesomeIcon className="p-1" icon="check" />
                      ) : (
                        <FontAwesomeIcon className="p-2" icon="exclamation" />
                      )
                    }
                  />
                </span>
              </div>

              <div className="mt-2 mb-4">
                <div className="mt-2 mb-4">
                  <Typography className="text-center text-muted">
                    Verses
                  </Typography>
                  <div className={classes.container}>
                    {verses?.map((verse, index) => (
                      <>
                        {!linkVerseToHadith && (
                          <span
                            className={classes.verse + " arabic-text"}
                            onClick={() => handleVerseClick(verse)}
                            style={{ color: getVerseColor(verse?.issues) }}
                          >
                            {verse?.textIndoPak}
                            <Circle style={classes.circleStyle}>
                              <div
                                style={{
                                  fontSize: "0.6em",
                                  fontWeight: "bold",
                                }}
                              >
                                {verse.verseNumber}
                              </div>
                            </Circle>

                            {verse?.issues?.length > 0 && (
                              <span className={classes.chip}>
                                <Chip
                                  size="small"
                                  variant={"contained"}
                                  label={verse?.issues?.length}
                                  sx={{
                                    color: "black",
                                    backgroundColor: "yellow",
                                  }}
                                />
                              </span>
                            )}
                          </span>
                        )}
                        {linkVerseToHadith && (
                          <span
                            className={classes.verse + " arabic-text"}
                            onClick={() => handleLinkVerseToHadith(verse)}
                          >
                            {verse?.textIndoPak}
                            <Circle style={classes.circleStyle}>
                              <div
                                style={{
                                  fontSize: "0.6em",
                                  fontWeight: "bold",
                                }}
                              >
                                {verse.verseNumber}
                              </div>
                            </Circle>

                            {verse?.issues?.length > 0 && (
                              <span className={classes.chip}>
                                <Chip
                                  size="small"
                                  variant={"contained"}
                                  label={verse?.issues?.length}
                                  sx={{
                                    color: "black",
                                    backgroundColor: "yellow",
                                  }}
                                />
                              </span>
                            )}
                          </span>
                        )}
                      </>
                    ))}
                  </div>
                </div>
              </div>

              <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
                <DialogTitle>
                  <h2 className="mt-4 arabic-text">{selectedVerse?.textIndoPak}</h2>
                </DialogTitle>
                <DialogContent>
                  <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    aria-label="Verse Tabs"
                  >
                    <Tab label="Add Comments" />
                    <Tab label="Manage Tafseer" />
                    <Tab label="Keywords" />
                    {(currentPageLogs.stepOneVerification && currentPageLogs.stepTwoVerification && currentPageLogs.stepThreeVerification) &&
                      <Tab label="Update Verse" />
                    }
                  </Tabs>

                  {activeTab === 0 && (
                    <div className="mt-5">
                      {(isAuthorized(
                        userRole,
                        "feature",
                        "verseIssueComment"
                      ) ||
                        (isVerificationControlEnabled &&
                          isVerificationModeActive)) && (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="comment"
                          label="Comment"
                          type="text"
                          fullWidth
                          variant="outlined"
                          value={verseIssueData?.comment}
                          onChange={(e) =>
                            setVerseIssueData((prev) => ({
                              ...prev,
                              comment: e?.target?.value,
                            }))
                          }
                          multiline
                          className="mt-3"
                          minRows={3}
                          // Adjust the inputProps for right-to-left languages like Arabic
                          inputProps={{ dir: "auto" }}
                          placeholder="Write your comment here..."
                        />
                      )}

                      <div className="">
                        <Button
                          onClick={() => {
                            submitComentIssue();
                            console.log("Submit Comment");
                            setCommentOpen(false);
                          }}
                        >
                          Submit
                        </Button>
                      </div>

                      <div className="row mt-2">
                        <Typography variant="h6" className="my-2">
                          Issues Raised:
                        </Typography>
                        {verseIssueData?.issues?.map((issue, index) => (
                          <div className="mt-2">
                            {index + 1}.{" "}
                            <span style={{ fontStyle: "italic" }}>
                              {issue?.comment}
                            </span>
                            <span
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              &nbsp;({issue?.user?.email} on{" "}
                              {new Date(issue?.createdAt).toLocaleDateString()})
                              {issue.isResolved ? (
                                <CheckCircleIcon
                                  color="success"
                                  sx={{ ml: 4 }}
                                />
                              ) : (
                                <></>
                              )}
                              {userRole == 1 && !issue.isResolved && (
                                <Button
                                  variant="text"
                                  onClick={() => resolveIssue(issue)}
                                >
                                  Resolve
                                </Button>
                              )}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {activeTab === 1 && (
                    <ManageTafseer
                      verse={selectedVerse}
                      currentChapter={currentChapter}
                      ref={manageTafseerRef}
                      closeEvent={() => setTafseerOpen(false)}
                    />
                  )}
                  {activeTab === 2 && (
                    <ManageKeywords
                      verse={selectedVerse}
                      // currentChapter={currentChapter}
                      ref={manageKeywordsRef}
                      closeEvent={() => setManageKeywordsOpen(false)}
                    />
                  )}

                  {activeTab === 3 && (
                    <div className="mt-5">
                      {isAuthorized(userRole, "feature", "verseEdit") && (
                        <div>
                          <FormControl fullWidth>
                            <ReactQuill
                              className={classes.quillEditor}
                              minRows={3}
                              value={selectedVerse?.textIndoPak || ""}
                              onChange={(value) =>
                                handleVerseTextChange(value, selectedVerse)
                              }
                            />
                          </FormControl>
                          <Button
                            onClick={() => handleVerseUpdate(selectedVerse)}
                            variant="text"
                            className="mt-2"
                          >
                            Update
                          </Button>
                        </div>
                      )}
                    </div>
                  )}
                </DialogContent>
              </Dialog>

              <Divider />
              {!linkVerseToHadith && (
                <div className="my-5">
                  <Typography className="text-center text-muted">
                    Translation by words
                  </Typography>

                  <div className={classes.container}>
                    {verses?.map((verse, index) => (
                      <span key={index} style={verseStyle}>
                        {verse.words.map((word, wordIndex) => (
                          <div
                            className={
                              word?.issues?.length > 0
                                ? classes.verseIssue
                                : classes.verse
                            }
                            onClick={() =>
                              //handleVerseClick(word, index, "word")
                              handleOpenVerseWord(word)
                            }
                            key={wordIndex}
                            style={{
                              display: "inline-block",
                              textAlign: "center",
                              margin: "0 4px",
                              fontSize: "1em",
                              color: word?.keywordId ? "green" : "inherit", // Set color to green if keywordId is not null
                            }}
                          >
                            <div
                              style={{ fontSize: "1em", fontWeight: "bold" }}
                              className={" arabic-text"}
                            >
                              {word?.textUthmani}
                            </div>
                            <div style={{ fontSize: "0.5em" }}>
                              {word?.translationEn}
                            </div>
                            <div style={{ fontSize: "0.5em" }}>
                              {word?.translationUrdu}
                            </div>

                            {word?.issues?.length > 0 && (
                              <span className={classes.chip}>
                                <Chip
                                  variant="outlined"
                                  size="small"
                                  variant={"contained"}
                                  label={word?.issues?.length}
                                  sx={{
                                    color: "black",
                                    backgroundColor: "yellow",
                                  }}
                                />
                              </span>
                            )}
                          </div>
                        ))}
                      </span>
                    ))}
                  </div>
                </div>
              )}
            </Box>
          </DialogContent>
        </Paper>

        <div className="d-flex">
          {/* <DialogActions sx={{ mt: 'auto', backgroundColor: 'background.paper' }}> */}
          <div className="me-auto p-3">
            Page{" "}
            <strong>
              {currentPage + 1 - parseInt(currentChapter?.startPage)}
            </strong>{" "}
            of <strong>{getTotalChapterPages()}</strong>
          </div>
          <div className="me-auto p-3">
            Total Issues: <strong>{currentPageLogs?.totalIssues}</strong>
          </div>

          <div className="d-flex align-items-center">
            <div className="ms-auto mx-md-2 my-2">
              {currentPage - 1 >= parseInt(currentChapter?.startPage) && (
                <Button onClick={hanldePreviousPage}>Previous Page</Button>
              )}
              {currentPage + 1 <= parseInt(currentChapter?.endPage) && (
                <Button onClick={handleNextPage}>Next Page</Button>
              )}

              {(currentPage + 1 <= parseInt(currentChapter?.endPage) || currentPage == parseInt(currentChapter?.endPage)) &&
                isVerificationModeActive &&
                isVerificationControlEnabled && (
                  <Button
                    onClick={handleConfirmPage}
                    sx={{ color: isVerificationModeActive ? "yellow" : null }}
                  >
                    Confirm & Next &nbsp;
                    <FontAwesomeIcon icon="check" />
                  </Button>
                )}
            </div>
          </div>
        </div>
        {/* </DialogActions>
            </Dialog> */}

        {/* Manage Verse Modal */}
        {verseWordOpen && (
          <Dialog
            open={verseWordOpen}
            onClose={closeManageVerseWord}
            fullWidth={true}
            maxWidth="lg"
            disableEscapeKeyDown // Prevents closing when the user presses the escape key
            disableBackdropClick // Prevents closing when the user clicks outside the dialog
            sx={{
              "& .MuiDialog-paper": {
                width: "60%", // Ensures it takes the full width
                maxWidth: "100%", // Removes any max-width restriction
              },
            }}
          >
            <ManageVerseWord
              verseWord={selectedVerseWord}
              ref={manageVerseWordRef}
              closeEvent={() => setVerseWordOpen(false)}
            />
          </Dialog>
        )}

        <AlertM ref={notifications} />
      </>
    );
  }
);

export default VersesReadView;
