import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { alpha, styled } from "@mui/material";

// Import components
import Landing from "../Components/Screens/Landing/Landing";
import Login from "../Components/Screens/Authentication/Login/Login";
import Register from "../Components/Screens/Authentication/Register/Register";
import Home from "../Components/Screens/Dashboard/Home/Home";
import Logout from "../Components/Screens/Authentication/Logout/Logout";
import { RootStateOrAny, useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import { AuthGuard } from "./Guards/AuthGuard/AuthGuard";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { getTheme } from "../Services/ThemeService";
// import KeywordGroupMain from "../Components/Screens/Dashboard/Hadith/HadithGroup/KeywordGroupMain/KeywordGroupMain";
import KeywordGroups from "../Components/Screens/Dashboard/Keywords/KeywordsGroups/KeywordsGroups";

// Lazy load components
// const Landing = lazy(() => import("../Components/Screens/Landing/Landing"));
// const Login = lazy(() => import("../Components/Screens/Authentication/Login/Login"));
// const Register = lazy(() => import("../Components/Screens/Authentication/Register/Register"));
// const Home = lazy(() => import("../Components/Screens/Dashboard/Home/Home"));
// const Logout = lazy(() => import("../Components/Screens/Authentication/Logout/Logout"));
import VersesReadView from "../Components/Screens/Dashboard/QuranTranslation/VersesReadView/VersesReadView";
import HadithTranslationMain from "../Components/Screens/Dashboard/Hadith/HadithType/HadithTranslationMain/HadithTranslationMain";
import KeywordMain from "../Components/Screens/Dashboard/Keywords/KeywordMain/KeywordMain";
import HadithGroupDetails from "../Components/Screens/Dashboard/Hadith/HadithGroup/HadithGroupDetail/HadithGroupDetail";
import HadithTranslationLogsMain from "../Components/Screens/Dashboard/Hadith/Logs/HadithTranslationLogsMain";
import LabelMain from "../Components/Screens/Dashboard/Labels/LabelMain/LabelMain";
import QueryMain from "../Components/Screens/Dashboard/Query/QueryMain/QueryMain";
import HadithReviewMain from "../Components/Screens/Dashboard/Hadith/HadithReviewMain/HadithReviewMain";

const UserMain = lazy(
  () => import("../Components/Screens/Dashboard/User/UserMain/UserMain")
);
const ImageCapture = lazy(
  () => import("../Components/Screens/Camera/ImageCapture")
);
const ScriptConfirmation = lazy(
  () => import("../Components/Screens/ScriptConfirmation/ScriptConfirmation")
);
const HadithBookMain = lazy(
  () =>
    import(
      "../Components/Screens/Dashboard/Hadithbooks/HadithBookMain/HadithBookMain"
    )
);
// const Menu = lazy(() => import("../Components/Screens/Menu/Menu"));
const RoleMain = lazy(
  () => import("../Components/Screens/Dashboard/Role/RoleMain/RoleMain")
);
// const HadithTranslationMain = lazy(() => import('../Components/Screens/Dashboard/Hadith/HadithType/HadithTranslationMain/HadithTranslationMain'));
const TopicMain = lazy(
  () => import("../Components/Screens/Dashboard/Topic/TopicMain/TopicMain")
);
const NarratorMain = lazy(
  () =>
    import("../Components/Screens/Dashboard/Narrator/NarratorMain/NarratorMain")
);
const SearchMain = lazy(
  () => import("../Components/Screens/Dashboard/Search/SearchMain/SearchMain")
);
const QuraMain = lazy(
  () =>
    import(
      "../Components/Screens/Dashboard/QuranTranslation/QuranMain/QuranMain"
    )
);
const BookMain = lazy(
  () => import("../Components/Screens/Dashboard/Books/BookMain/BookMain")
);

function Router() {
  //   const { t, i18n } = useTranslation();

  // get current theme
  const theme = useSelector(
    (state: RootStateOrAny) => state.global.global.theme
  );

  return (
    <ThemeProvider theme={getTheme(theme)}>
      <CssBaseline />
      <BrowserRouter>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<AuthGuard />}>
              <Route path="/" element={<Home />} />
            </Route>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/logout" element={<Logout />} />
            <Route
              path="/dashboard"
              element={<AuthGuard route={"dashboard"} />}
            >
              <Route path="/dashboard" element={<Home />} />
            </Route>

            <Route
              path="/translate"
              element={<AuthGuard route={"translate"} />}
            >
              <Route path="/translate" element={<HadithTranslationMain />} />
            </Route>

            <Route path="/role" element={<AuthGuard route={"role"} />}>
              <Route path="/role" element={<RoleMain />} />
            </Route>

            <Route path="/user" element={<AuthGuard route={"user"} />}>
              <Route path="/user" element={<UserMain />} />
            </Route>

            <Route path="/lense" element={<AuthGuard route={"lense"} />}>
              <Route path="/lense" element={<ImageCapture />} />
            </Route>

            <Route
              path="/script-confirmation"
              element={<AuthGuard route={"/script-confirmation"} />}
            >
              <Route
                path="/script-confirmation"
                element={<ScriptConfirmation />}
              />
            </Route>

            <Route
              path="/hadithbooks"
              element={<AuthGuard route={"hadithbooks"} />}
            >
              <Route path="/hadithbooks" element={<HadithBookMain />} />
            </Route>

            <Route path="/hadith" element={<AuthGuard route={"hadith"} />}>
              <Route path="/hadith" element={<HadithReviewMain />} />
            </Route>

            {/* <Route
              path="/keywordgroup"
              element={<AuthGuard route={"keywordgroup"} />}
            >
              <Route path="/keywordgroup" element={<KeywordGroupMain />} />

              
            </Route> */}

            <Route
              path="/hadithgroups/:groupId"
              element={<AuthGuard route={"hadithgroups"} />}
            >
              <Route
                path="/hadithgroups/:groupId"
                element={<HadithGroupDetails />}
              />
            </Route>

            <Route path="/search" element={<AuthGuard route={"search"} />}>
              <Route path="/search" element={<SearchMain />} />
            </Route>

            <Route path="/topic" element={<AuthGuard route={"topic"} />}>
              <Route path="/topic" element={<TopicMain />} />
            </Route>

            <Route path="/narrator" element={<AuthGuard route={"narrator"} />}>
              <Route path="/narrator" element={<NarratorMain />} />
            </Route>

            <Route path="/quran" element={<AuthGuard route={"quran"} />}>
              <Route path="/quran" element={<QuraMain />} />
            </Route>

            <Route
              path="/quran/:chapterId"
              element={<AuthGuard route={"quran"} />}
            >
              <Route path="/quran/:chapterId" element={<VersesReadView />} />
            </Route>

            <Route path="/book" element={<AuthGuard route={"book"} />}>
              <Route path="/book" element={<BookMain />} />
            </Route>

            <Route path="/keyword" element={<AuthGuard route={"keyword"} />}>
              <Route path="/keyword" element={<KeywordMain />} />
            </Route>

            <Route path="/keyword" element={<AuthGuard route={"keyword"} />}>
              <Route
                path="/keyword/group/:groupId"
                element={<KeywordGroups />}
              />
            </Route>

            <Route path="/log" element={<AuthGuard route={"log"} />}>
              <Route path="/log" element={<HadithTranslationLogsMain />} />
            </Route>

            <Route path="/label" element={<AuthGuard route={"label"} />}>
              <Route path="/label" element={<LabelMain />} />
            </Route>

            <Route path="/chat" element={<AuthGuard route={"chat"} />}>
              <Route path="/chat" element={<QueryMain />} />
            </Route>

            {/* %TEMPLATE_INSERT_ROUTE%  */}
          </Routes>
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default Router;
