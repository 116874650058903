// @ts-nocheck
import React, { useState, useRef, useEffect } from "react";
import { useStyles } from "./Styles";
import {
  patch,
  post,
  getById,
} from "../../../../../Services/GenericApiService";
import { useTranslation } from "react-i18next";
import { log } from "../../../../../Services/LoggerService";

import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import AlertM from "../../../../Helpers/AlertM/AlertM";
import {
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  Chip,
  TextField,
  IconButton,
  Grid,
  Paper,
  Divider,
  CircularProgress,
  useTheme,
  Tab,
  Tabs,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import LoadingButton from "@mui/lab/LoadingButton";

// @ts-ignore
function LabelUpdate({ refreshDataEvent, recordToUpdate, resetEditState }) {
  // css for module
  const classes = useStyles();
  const theme = useTheme();

  // module(s) for api
  const moduleMain = "label";

  // translation
  const { t } = useTranslation();

  // child ref for alert
  const notifications = useRef();

  // ui handlers
  const [open, setOpen] = useState(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  // Data state
  const [labelData, setLabelData] = useState({
    id: recordToUpdate?.id || "",
    nameEnglish: recordToUpdate?.nameEnglish || [],
    nameUrdu: recordToUpdate?.nameUrdu || [],
    nameArabic: recordToUpdate?.nameArabic || [],
  });

  // New keyword inputs
  const [newKeywords, setNewKeywords] = useState({
    nameEnglish: "",
    nameUrdu: "",
    nameArabic: "",
  });

  // Track if form is modified
  const [isModified, setIsModified] = useState(false);

  // For refreshing data
  useEffect(() => {
    if (recordToUpdate?.id) {
      // Fetch the latest data for this label
      getById(moduleMain, recordToUpdate.id)
        .then((response) => {
          setLabelData({
            id: response.id,
            nameEnglish: response.nameEnglish || [],
            nameUrdu: response.nameUrdu || [],
            nameArabic: response.nameArabic || [],
          });
        })
        .catch((error) => {
          log(error);
          notifications.current.errorAlert(
            t("common.somethingWentWrong"),
            t("common.tryAgain")
          );
        });
    }
  }, [recordToUpdate]);

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Add a new keyword to a language array
  const handleAddKeyword = (field) => {
    if (!newKeywords[field].trim()) return;

    setLabelData((prev) => ({
      ...prev,
      [field]: [...new Set([...prev[field], newKeywords[field].trim()])],
    }));

    setNewKeywords((prev) => ({
      ...prev,
      [field]: "",
    }));

    setIsModified(true);
  };

  // Remove a keyword from a language array
  const handleRemoveKeyword = (field, index) => {
    setLabelData((prev) => ({
      ...prev,
      [field]: prev[field].filter((_, i) => i !== index),
    }));

    setIsModified(true);
  };

  // Handle input change for new keywords
  const handleInputChange = (e, field) => {
    setNewKeywords((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  // Handle key down event for inputs
  const handleKeyDown = (e, field) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddKeyword(field);
    }
  };

  // delay before closing update screen
  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  // handle edit record event
  const handleEditEvent = async () => {
    if (!isModified) {
      handleClose();
      resetEdit();
      return;
    }

    setLoading(true); // show loader

    try {
      await patch(moduleMain, labelData.id, labelData);
      setLoading(false); // hide loader
      refreshDataEvent(); // refresh data
      handleClose();
      notifications.current.successAlert(t("common.editSuccess"), "");
      await sleep(process.env.REACT_APP_UPDATE_NOTIFICATION_TIMEOUT_MS || 1000);
      resetEdit();
    } catch (error) {
      log(error);
      setLoading(false); // hide loader
      notifications.current.errorAlert(
        t("common.somethingWentWrong"),
        t("common.tryAgain")
      );
      resetEdit();
    }
  };

  // handle cancel event
  const handleCancelEvent = () => {
    handleClose();
    resetEdit();
  };

  // reset isEdit to false in main component
  const resetEdit = () => {
    resetEditState(true);
  };

  // Language field mapping
  const fieldLabels = {
    nameEnglish: "English Keywords",
    nameUrdu: "Urdu Keywords",
    nameArabic: "Arabic Keywords",
  };

  // Field array for tabs
  const fields = ["nameEnglish", "nameUrdu", "nameArabic"];

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className={classes.modal}>
            <Card
              sx={{
                width: "100%",
                maxHeight: "90vh",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <CardContent sx={{ flexGrow: 1, overflowY: "auto", p: 3 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 3,
                  }}
                >
                  <Typography variant="h5" component="h2">
                    Edit Keyword Group
                  </Typography>
                  <IconButton onClick={handleCancelEvent} size="small">
                    <CloseIcon />
                  </IconButton>
                </Box>

                <Paper sx={{ mb: 3 }}>
                  <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                  >
                    <Tab label="English" />
                    <Tab label="Urdu" />
                    <Tab label="Arabic" />
                  </Tabs>
                </Paper>

                {fields.map((field, index) => (
                  <Box
                    key={field}
                    role="tabpanel"
                    hidden={activeTab !== index}
                    id={`tabpanel-${index}`}
                    aria-labelledby={`tab-${index}`}
                  >
                    {activeTab === index && (
                      <Box>
                        <Typography variant="h6" gutterBottom>
                          {fieldLabels[field]}
                        </Typography>

                        <Box sx={{ mb: 3, display: "flex" }}>
                          <TextField
                            fullWidth
                            label={`Add new ${field
                              .replace("name", "")
                              .toLowerCase()} keyword`}
                            value={newKeywords[field]}
                            onChange={(e) => handleInputChange(e, field)}
                            onKeyDown={(e) => handleKeyDown(e, field)}
                            variant="outlined"
                            size="small"
                          />
                          <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={() => handleAddKeyword(field)}
                            disabled={!newKeywords[field].trim()}
                            sx={{ ml: 1, minWidth: "120px" }}
                          >
                            Add
                          </Button>
                        </Box>

                        <Paper
                          variant="outlined"
                          sx={{
                            p: 2,
                            bgcolor:
                              theme.palette.mode === "dark"
                                ? "rgba(255,255,255,0.05)"
                                : "rgba(0,0,0,0.02)",
                            minHeight: "200px",
                            maxHeight: "350px",
                            overflowY: "auto",
                          }}
                        >
                          <Typography variant="subtitle2" gutterBottom>
                            {labelData[field].length} keywords
                          </Typography>

                          {labelData[field].length > 0 ? (
                            <Box
                              sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}
                            >
                              {labelData[field].map((keyword, keywordIndex) => (
                                <Chip
                                  key={keywordIndex}
                                  label={keyword}
                                  onDelete={() =>
                                    handleRemoveKeyword(field, keywordIndex)
                                  }
                                  color="primary"
                                  variant="outlined"
                                  size="medium"
                                />
                              ))}
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "150px",
                              }}
                            >
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                No keywords added yet. Add some keywords above.
                              </Typography>
                            </Box>
                          )}
                        </Paper>
                      </Box>
                    )}
                  </Box>
                ))}
              </CardContent>

              <Divider />

              <CardActions sx={{ justifyContent: "flex-end", p: 2 }}>
                <Button
                  variant="outlined"
                  onClick={handleCancelEvent}
                  sx={{ mr: 1 }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  onClick={handleEditEvent}
                  disabled={!isModified}
                >
                  Save Changes
                </LoadingButton>
              </CardActions>
            </Card>
          </Box>
        </Fade>
      </Modal>

      {/* Alerts */}
      <AlertM ref={notifications} />
    </div>
  );
}

export default LabelUpdate;
