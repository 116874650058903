// @ts-nocheck
import React, {
    useCallback,
    useEffect,
    useState,
    forwardRef,
    useRef,
    useImperativeHandle,
  } from "react";
  import { useStyles } from "./Styles";
  import "../../../../../index.scss";
  import { useTheme } from "@mui/material/styles";
  import {
    getAllFiltered,
    post,
    deleteById,
    hardDelete,
    patch,
    getById
  } from "../../../../../Services/GenericApiService";
  import { useTranslation } from "react-i18next";
  import { log } from "../../../../../Services/LoggerService";
  import _ from "lodash";
  import AlertM from "../../../../Helpers/AlertM/AlertM";
  import Box from "@mui/material/Box";
  import {
    Typography,
    Button,
    Autocomplete,
    TextField,
    Grid,
    Card,
    CardContent,
    Divider,
    CircularProgress,
    IconButton,
    InputLabel,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Select,
    MenuItem
  } from "@mui/material";
  import { library } from "@fortawesome/fontawesome-svg-core";
  import * as Icons from "@fortawesome/free-solid-svg-icons";
  import DeleteIcon from "@mui/icons-material/Delete";
  import { getUserData } from "../../../../../Services/LocalStorageService";

  // font awesome stuff
  const iconList = Object.keys(Icons)
    .filter((key) => key !== "fas" && key !== "prefix")
    .map((icon) => Icons[icon]);
  
  library.add(...iconList);
  
  const ManageVerseWord = forwardRef(({ verseWord = null, closeEvent = null }, ref) => {
    useImperativeHandle(ref, () => ({}));
  
    const theme = useTheme();
  
    // css for module
    const classes = useStyles();
  
    // translation
    const { t } = useTranslation();
  
    // child ref for alert
    const notifications = useRef();
  
    // data vars
    const [wordData, setWordData] = useState(verseWord);
    const [keywordOpen, setKeywordOpen] = useState(false);
    const [selectedKeyword, setSelectedKeyword] = useState('');
    const [allKeywords, setAllKeywords] = useState<string[]>([]);
    const [userRole, setUserRole] = React.useState("");

    // ui controls
    const [loading, setLoading] = React.useState(false);
  
    useEffect(() => {
        const currentUser: any = getUserData()
        setUserRole(currentUser.roleKey)
    }, [])


    useEffect(() => {
    }, [wordData]);

    useEffect(() => {
        if (verseWord && verseWord.id) {
          fetchVerseWord();
        }
    }, [verseWord]);
      
    const fetchVerseWord = () => {
        try {
            getById("verse/word", verseWord.id)
              .then((_res) => {
                setWordData(_res);
              })
              .catch((error) => {
                log(error);
              });
        } 
        catch (error) {
            log(error);
        }
    }
  
    const handleKeywordOpen = () => 
    {
        try {
            getAllFiltered("keyword")
              .then((_res) => {
                const keywordData = _res.map((keyword) => ({
                  id: keyword.id,
                  label: keyword.rootWord,
                }));
                setAllKeywords(keywordData);
              })
              .catch((error) => {
                log(error);
              });
        } 
        catch (error) {
            log(error);
        }

        setKeywordOpen(true);
    }

    const handleKeywordClose = () => setKeywordOpen(false);

    const handleKeywordChange = (event) => {
        setSelectedKeyword(event.target.value);
    };

    const handleAssociateKeyword = () => {
        const updatedData = {
            keywordId: selectedKeyword
        }
        handleUpdateVerseWord(updatedData)
        handleKeywordClose();
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setWordData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    
    const handleSaveWordData = (field) => {
        if(field == 'translationEn')
        {
            const updatedData = {
                translationEn: wordData?.translationEn
            }
            handleUpdateVerseWord(updatedData);
        }
        else if(field == 'grammaticalNotation')
        {
            const updatedData = {
                grammaticalNotation: wordData?.grammaticalNotation
            }
            handleUpdateVerseWord(updatedData);
        }
    };

    const handleDeleteRootWord = () => {
        const updatedData = {
            keywordId: null
        }
        setSelectedKeyword('');
        handleUpdateVerseWord(updatedData)
    }

    const handleUpdateVerseWord = (updatedData) => {
        try {
            patch("verse/word", wordData?.id, updatedData)
            .then(async (_res) => {
                fetchVerseWord();
                notifications.current.successAlert(t("common.editSuccess"), "");
            })
            .catch((_err) => {
                log(_err);
                notifications.current.errorAlert(
                t("common.somethingWentWrong"),
                t("common.tryAgain")
                );
            });
        } 
        catch (error) {
            log(error);
        }
    }

    return (
      <Grid container spacing={1} sx={{ padding: 3 }}>
        <Grid item xs={12} style={{ position: "relative" }}>
          <div className="d-flex mb-1" id="comment-dialog-title">
            <Typography variant="h6">Manage Verse Word</Typography>
            <Divider />
          </div>
  
          <Card variant="outlined" className="p-4">
            <CardContent>
            {(userRole === "admin" || userRole === "teacher") ? (
                <div>
                    <div className="row">
                        <div className="col-md-5 d-flex align-items-center">
                            <TextField
                                label="TranslationEn"
                                name="translationEn"
                                value={wordData?.translationEn}
                                onChange={handleInputChange}
                                fullWidth
                                margin="normal"
                            />
                        </div>
                        <div className="col-md-3 d-flex align-items-center">
                            <Button
                                variant="outlined"
                                onClick={() => handleSaveWordData('translationEn')}
                                color="primary"
                                size="small"
                            >
                                Save Changes    
                            </Button>
                        </div>
                    </div>

                    <div className="row">         
                        <div className="col-md-5 d-flex align-items-center">
                            <TextField
                                label="Grammatical Notation"
                                name="grammaticalNotation"
                                value={wordData?.grammaticalNotation}
                                onChange={handleInputChange}
                                fullWidth
                                margin="normal"
                            />
                        </div>   
                        <div className="col-md-3 d-flex align-items-center">
                            <Button
                                variant="outlined"
                                onClick={() => handleSaveWordData('grammaticalNotation')}
                                color="primary"
                                size="small"
                            >
                                Save Changes    
                            </Button>
                        </div>
                    </div>  
                </div>
            ) : (
                <div>
                    <div className="row">
                        <div className="col-md-5 d-flex align-items-center">
                            <TextField
                                label="TranslationEn"
                                name="translationEn"
                                value={wordData?.translationEn}
                                InputProps={{
                                    readOnly: true,
                                }}
                                fullWidth
                                margin="normal"
                            />
                        </div>
                    </div>

                    <div className="row">         
                        <div className="col-md-5 d-flex align-items-center">
                            <TextField
                                label="Grammatical Notation"
                                name="grammaticalNotation"
                                value={wordData?.grammaticalNotation}
                                InputProps={{
                                    readOnly: true,
                                }}
                                fullWidth
                                margin="normal"
                            />
                        </div>
                    </div>  
                </div>
            )}

                    <div className="row">
                        {wordData?.rootKeyword != null && wordData?.rootKeyword != "" ? (
                            <div className="col-md-12 mt-5 d-flex align-items-center">
                                <InputLabel id="demo-simple-select-standard-label" className="me-3">
                                    Root Keyword:
                                </InputLabel>
                                <Typography variant="body1" gutterBottom className="me-3">
                                    {wordData?.rootKeyword?.rootWord}
                                </Typography>
                                {(userRole == "admin" || userRole == "teacher") &&

                                    <IconButton
                                        aria-label="delete"
                                        onClick={handleDeleteRootWord} 
                                        className="p-0"
                                        disabled={userRole !== 'admin'}  
                                        >
                                    <DeleteIcon />
                                    </IconButton>
                                }
                            </div>
                        ) : null}

                        {(userRole == "admin" || userRole == "teacher") &&
                            <div className="col-md-5 mt-5">
                                {wordData?.rootKeyword === null || wordData?.rootKeyword === "" ? (
                                <Button
                                    variant="contained"
                                    onClick={handleKeywordOpen}
                                    className={"bg-custom-gradient my-2 py-3"}
                                    fullWidth
                                >
                                    {"Associate Root Keyword"}
                                </Button>
                                ) : null}
                            </div>
                        }
                        
                    </div>
            </CardContent>
          </Card>
        </Grid>
  
        <AlertM ref={notifications} />

        {/* Dialog for selecting a keyword */}
        <Dialog open={keywordOpen} onClose={handleKeywordClose} maxWidth="xs" fullWidth>
            <DialogTitle>Root Keywords</DialogTitle>
            <DialogContent>
            <Select
                value={selectedKeyword}
                onChange={handleKeywordChange}
                fullWidth
            >
                {allKeywords.map((keyword) => (
                <MenuItem key={keyword.id} value={keyword.id}>
                    {keyword.label}
                </MenuItem>
                ))}
            </Select>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleKeywordClose} color="primary">
                Cancel
            </Button>
            <Button onClick={handleAssociateKeyword} color="primary">
                Associate
            </Button>
            </DialogActions>
        </Dialog>

      </Grid>
    );
  });
  
  export default ManageVerseWord;
  