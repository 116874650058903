// @ts-nocheck
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    modal: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
    },
    fullScrnCard: {
        display: 'flex',
        flexDirection: 'column',
        // // Responsive styling for the modal
        width: '', // Default width for larger screens
        height: '98svh', // Default height for larger screens
        // overflowY: 'auto',
        // // Media query for mobile devices
        [theme.breakpoints.down('sm')]: {
            width: '100vw', // Full viewport width on mobile
            height: '100vh', // Full viewport height on mobile
        }
    },
    fullScrnCardContent: { fontWeight: 'bold', fontSize: '2em' },
}));

export { useStyles }
