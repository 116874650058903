import { combineReducers } from "redux";
import AuthenticationReducer from "../Components/Screens/Authentication/Login/Reducer";
import SiderReducer from "../Components/Layout/Sider/Reducer";
import HeaderReducer from "../Components/Layout/Header/Reducer";
import GlobalReducer from "../Global/Reducer";
import AuthSessionReducer from "../Components/Screens/Authentication/AuthSession/Reducer";

const RootReducer = combineReducers({
  authentication: AuthenticationReducer,
  sider: SiderReducer,
  header: HeaderReducer,
  global: GlobalReducer,
  authSession: AuthSessionReducer,
});

export default RootReducer;
