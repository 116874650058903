//@ts-nocheck
import React, { useEffect, useState } from "react";
import { useStyles } from "./Styles";
import { getAll } from "../../../../../Services/GenericApiService";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import {
  Button,
  FormControl,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import SubKeywordCreate from "../SubKeywordCreate/SubKeywordCreate"; // Import SubKeywordCreate
import SubKeywordUpdate from "../SubKeywordUpdate/SubKeywordUpdate"; // Import SubKeywordUpdate
import KeywordView from "../KeywordView/KeywordView"; // Import KeywordView for viewing subkeywords
import SubKeywordView from "../SubKeywordView/SubKeywordView";
import { getUserData } from "../../../../../Services/LocalStorageService";

function KeywordForm({
  handleAddEvent,
  handleCancelEvent,
  isLoading,
  isEditMode,
  recordToUpdate,
  handleEditEvent,
  handleReviewEvent,
  isReviewMode,
}) {
  // module(s) for api
  const moduleMain = "keyword";

  // translation
  const { t } = useTranslation();

  // UI methods
  const [loading, setLoading] = React.useState(true);
  const [showSubKeywords, setShowSubKeywords] = useState(false);
  const [editSubKeyword, setEditSubKeyword] = useState(null);

  // form data
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm(); // watch is used to get the value e.g watch("name")

  const [subKeywords, setSubKeywords] = useState([]);

  //   const fetchRelatedVerseWords = async () => {
  //     // Fetch subKeywords from the server based on the keywordId (recordToUpdate.id)
  //     const response = await getAll(`subkeyword?keywordId=${recordToUpdate.id}`);
  //     setSubKeywords(response.data);
  //   };

  const fetchRelatedVerseWords = () => {
    setLoading(true); // show loader
    getAll(`keyword/groups/verseWords/${recordToUpdate.id}`)
      .then((_res: any) => {
        // set all data
        setSubKeywords(_res);

        setLoading(false); // hide loader
      })
      .catch((_err) => {
        log(_err);
        setLoading(false); // hide loader
      });
  };

  const toggleSubKeywords = () => {
    setShowSubKeywords(!showSubKeywords);
    if (!showSubKeywords) {
      fetchRelatedVerseWords();
    }
  };

  // form methods
  const onSubmit = (_data: any) => {
    if (isEditMode) {
      handleEditEvent(_data);
    } else if (isReviewMode) {
      handleReviewEvent(_data);
    } else {
      const currentUser = getUserData();

      _data.level1Approved = false;
      _data.level2Approved = false;
      _data.level1ApprovedBy = currentUser.id;

      handleAddEvent(_data);
    }
  };

  // ui handlers
  const onCancel = () => {
    handleCancelEvent();
  };

  const handleEditSubKeyword = (subKeyword) => {
    setEditSubKeyword(subKeyword);
  };

  const handleBackToMainForm = () => {
    setShowSubKeywords(false);
    setEditSubKeyword(null);
  };

  // hooks
  useEffect(() => {
    if ((isEditMode || isReviewMode) && recordToUpdate) {
      reset(recordToUpdate);
    }
  }, [isEditMode, isReviewMode, recordToUpdate, reset]);

  return (
    <Box sx={{ display: "flex" }}>
      <Card sx={{ width: "50vw", maxHeight: "90vh", overflowY: "auto" }}>
        <form
          className="row px-1 px-md-0"
          onSubmit={
            showSubKeywords ? handleSubmit(onSubmit) : handleSubmit(onSubmit)
          }
        >
          <CardContent>
            <div className="py-2 px-md-4 px-2">
              <div className="row mx-2">
                {!showSubKeywords && (
                  <>
                    <div className="col-md-12 my-2">
                      <h4 className="pb-2">
                        {isEditMode
                          ? t("common.edit")
                          : isReviewMode
                          ? t("Review")
                          : t("common.add")}{" "}
                        {"Keywords"}
                      </h4>
                    </div>

                    <div className="col-md-12 mt-4">
                      <FormControl fullWidth>
                        <TextField
                          id="titleInput"
                          {...register("rootWord", { required: true })}
                          label={"Root Word"}
                          type={"text"}
                          error={!!errors.rootWord}
                          variant="outlined"
                          className="full-width"
                        />
                      </FormControl>
                    </div>

                    <div className="col-md-12 mt-4">
                      <FormControl fullWidth>
                        <TextField
                          id="titleInput"
                          {...register("letter", { required: true })}
                          label={"Arabic Letter"}
                          type={"text"}
                          error={!!errors.letter}
                          variant="outlined"
                          className="full-width"
                        />
                      </FormControl>
                    </div>

                    <div className="col-md-12 mt-4">
                      <FormControl fullWidth>
                        <TextField
                          id="titleInput"
                          {...register("description", { required: true })}
                          label={"Description"}
                          type={"text"}
                          multiline
                          minRows={4}
                          error={!!errors.description}
                          variant="outlined"
                          className="full-width"
                        />
                      </FormControl>
                    </div>

                    {isEditMode && (
                      <div className="col-md-12 mt-4">
                        <Button variant="outlined" onClick={toggleSubKeywords}>
                          {showSubKeywords
                            ? "Hide Associated Words"
                            : "Show Associated Words"}
                        </Button>
                      </div>
                    )}
                  </>
                )}

                {showSubKeywords && !editSubKeyword && (
                  <>
                    <div className="col-md-12 mt-4">
                      <Button variant="outlined" onClick={handleBackToMainForm}>
                        Back to Main Form
                      </Button>
                    </div>
                    <div className="col-md-12 mt-4">
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Verse Placement</TableCell>
                              <TableCell>Transliteration</TableCell>
                              <TableCell>Meaning</TableCell>
                              <TableCell>Verse</TableCell>
                              <TableCell>Grammatical Notation</TableCell>
                              <TableCell>Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {subKeywords?.map((subKeyword) => (
                              <TableRow key={subKeyword?.id}>
                                <TableCell>{subKeyword?.location}</TableCell>
                                <TableCell>
                                  {subKeyword?.transliterationEn}
                                </TableCell>
                                <TableCell>
                                  {subKeyword?.translationEn}
                                </TableCell>
                                <TableCell>{subKeyword?.textUthmani}</TableCell>
                                <TableCell>
                                  {subKeyword?.grammaticalNotation}
                                </TableCell>
                                <TableCell>
                                  <Button
                                    onClick={() =>
                                      handleEditSubKeyword(subKeyword)
                                    }
                                  >
                                    Edit
                                  </Button>
                                  <SubKeywordView data={subKeyword} />{" "}
                                  {/* View button */}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                    <div className="col-md-12 mt-4">
                      <SubKeywordCreate
                        keywordId={recordToUpdate.id}
                        refreshSubKeywords={fetchRelatedVerseWords}
                      />
                    </div>
                  </>
                )}

                {showSubKeywords && editSubKeyword && (
                  <SubKeywordUpdate
                    recordToUpdate={editSubKeyword}
                    refreshDataEvent={fetchRelatedVerseWords}
                    resetEditState={handleBackToMainForm}
                  />
                )}
              </div>
            </div>
          </CardContent>
          <CardActions>
            <div
              className="pb-4 pt-2 px-md-4 px-2 "
              style={{ width: "100%", textAlign: "right" }}
            >
              {!showSubKeywords && (
                <>
                  <Button variant="outlined" onClick={onCancel}>
                    {t("common.cancel")}
                  </Button>
                  &nbsp;&nbsp;
                  <LoadingButton
                    loading={isLoading}
                    type="submit"
                    variant="contained"
                    className="bg-custom-gradient"
                  >
                    {isEditMode
                      ? t("common.edit")
                      : isReviewMode
                      ? t("Approve")
                      : t("common.add")}
                  </LoadingButton>
                  &nbsp;
                </>
              )}
            </div>
          </CardActions>
        </form>
      </Card>
    </Box>
  );
}

export default KeywordForm;
