// @ts-nocheck

import React, { useEffect, useState, useRef } from "react";
import { useStyles } from "./Styles";
import "../../../../../index.scss";
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import {
  getAllFiltered,
  getAll,
} from "../../../../../Services/GenericApiService";
import { useTranslation } from "react-i18next";
import AlertM from "../../../../Helpers/AlertM/AlertM";
import { log } from "../../../../../Services/LoggerService";

import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import MUIDataTable from "mui-datatables";
import {
  Button,
  Skeleton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Typography,
  TextField,
  CardActions,
  Card,
  CardContent,
  CircularProgress,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { cloneDeep } from "lodash";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { post } from "../../../../../Services/GenericApiService";

// font awesome stuff
const iconList = Object.keys(Icons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon) => Icons[icon]);

library.add(...iconList);

function SearchMain({
  modalView = false,
  hasParentControl = false,
  selectCallback = null,
}) {
  // css for module
  const classes = useStyles();

  // translation
  const { t } = useTranslation();

  // child ref for alert
  const notifications = useRef();

  const searchDiv = useRef(null);

  // module(s) for api
  const moduleMain = "hadith/filtered";
  const moduleUser = "user";
  const moduleBooks = "hadithbook";

  // data vars
  const [allData, setAllData] = React.useState([]);
  //   const [dataToEdit, setDataToEdit] = React.useState({});

  let columns = [
    { name: "Hadith ID", options: { filterOptions: { fullWidth: true } } },
    { name: "Translation", options: { filterOptions: { fullWidth: true } } },
    { name: "Added By", options: { filterOptions: { fullWidth: true } } },
    {
      name: "Actions",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => {
          return { align: "right" };
        },
        setCellProps: () => ({
          align: "right",
        }),
        customBodyRenderLite: (dataIndex) => {
          const record = allData[dataIndex];
          const {
            verified,
            audioProcessingStatus,
            audioFileName,
            audioUploadedToStorage,
          } = record;

          if (verified && audioProcessingStatus === "not_started") {
            return (
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleGenerateAudio(record.id)} // Trigger audio generation
              >
                Generate Audio
              </Button>
            );
          } else if (
            audioProcessingStatus === "completed" &&
            audioUploadedToStorage
          ) {
            return (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handlePlayAudio(audioFileName)} // Play the audio
              >
                Play Audio
              </Button>
            );
          } else if (audioProcessingStatus === "in_process") {
            return (
              <Button variant="contained" disabled>
                <CircularProgress size="1.2rem" /> Processing...
              </Button>
            );
          }
        },
      },
    },
  ];

  if (hasParentControl) {
    columns.push({
      name: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellHeaderProps: () => {
          return { align: "right" };
        },
        setCellProps: () => ({
          align: "right",
        }),
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          return (
            <div className="d-inline-flex">
              <Button onClick={() => onSelectActionClick(dataIndex, rowIndex)}>
                Link
              </Button>
            </div>
          );
        },
      },
    });
  }

  const hadithStatuses = [
    "Verified",
    "QC",
    "Approved",
    "Not Approved",
    "Review Rejected",
    "QC Rejected",
  ];

  const [tableData, setTableData] = React.useState([]);
  const [allUsers, setAllUsers] = React.useState([]);
  const [allHadithBooks, setAllHadithBooks] = useState([]);

  // ui controls
  const [loading, setLoading] = React.useState(true);
  const [isShrunk, setIsShrunk] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);

  const defaultValues = {
    verified: null,
    toBeApproved: null,
    toBeReviewed: null,
    toBeQC: null,
    reviewRejected: null,
    qcRejected: null,
    language: "",
    conventionalId: null,
    addedByUserId: null,
    approvedByUserId: null,
    reviewedByUserId: null,
    qcByUserId: null,
    addedDate: null,
    approvedDate: null,
    reviewedDate: null,
    qcDate: null,
    hadithbookId: null,
    translationString: "",
  };

  const [query, setQuery] = useState(cloneDeep(defaultValues));

  const [filter, setFilters] = useState({
    status: "",
    addedBy: null,
    reviewedBy: null,
    approvedBy: null,
    conventionalId: null,
    reviewedByUserId: null,
    addedDate: null,
    hadithbookId: null,
    translationString: "",
  });

  // table ui controls
  const [responsive, setResponsive] = useState("vertical");
  const [processingIds, setProcessingIds] = useState({});
  const [tableBodyHeight, setTableBodyHeight] = useState("400px");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [searchBtn, setSearchBtn] = useState(true);
  const [downloadBtn, setDownloadBtn] = useState(true);
  const [printBtn, setPrintBtn] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(true);
  const [filterBtn, setFilterBtn] = useState(true);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  useEffect(() => {
    fetch();
  }, [currentPage, pageSize]);

  useEffect(() => {
    fetchUsers();
    fetchBooks();
  }, []);

  const fetchUsers = () => {
    setTableData([]);
    setLoading(true); // show loader

    getAll(moduleUser)
      .then((_res: any) => {
        setAllUsers(_res);
        setLoading(false); // hide loader
      })
      .catch((_err) => {
        log(_err);
        setLoading(false); // hide loader
      });
  };

  const fetchBooks = () => {
    setLoading(true); // show loader

    getAll(moduleBooks)
      .then((_res: any) => {
        setAllHadithBooks(_res);
        setLoading(false); // hide loader
      })
      .catch((_err) => {
        log(_err);
        setLoading(false); // hide loader
      });
  };

  // event handlers
  const onSelectActionClick = (_dataIndex: any, _rowIndex: any) => {
    selectCallback(allData[_dataIndex]);
    // setDataToEdit(allData[_dataIndex]);
    // setEditMode(true);
  };

  const resetEdit = (_state) => {
    setEditMode(false);
  };

  const clearAllFilters = () => {
    setFilters({
      status: "",
      addedBy: null,
      reviewedBy: null,
      approvedBy: null,
      conventionalId: null,
      reviewedByUserId: null,
      addedDate: null,
      hadithbookId: null,
      translationString: "",
    });

    setQuery(cloneDeep(defaultValues));
  };

  let options = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: "textField",
    responsive: "simple",
    // tableBodyHeight: "500px",
    // tableBodyMaxHeight,
    selectableRowsHideCheckboxes: true,
    sort: true,
    onChangePage: (currentPage) => {
      setCurrentPage(currentPage);
    },
    onChangeRowsPerPage: (numberOfRows) => {
      setPageSize(numberOfRows);
    },
    page: currentPage,
    count: totalRowCount,
    serverSide: true,
    loading: true,
    rowsPerPageOptions: [25, 50, 75, 100],
    rowsPerPage: pageSize,
    textLabels: {
      body: {
        noMatch: loading ? (
          <Box>
            <Skeleton className="my-4" />
            <Skeleton className="my-4" />
            <Skeleton className="my-4" />
          </Box>
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    onTableChange: (action: any, state: any) => {
      // console.log(action);
      // console.dir(state);
    },
  };

  if (tableData.length === 0) {
    options = {
      textLabels: {
        body: {
          noMatch: loading ? (
            <Box>
              <Skeleton className="my-4" />
              <Skeleton className="my-4" />
              <Skeleton className="my-4" />
            </Box>
          ) : (
            "Sorry, there is no matching data to display"
          ),
        },
      },
    };
  }

  // fetch dependent data
  const fetch = () => {
    // setTableData([]);  // reset table data

    let queryParamsArray = [];

    Object.keys(query).forEach((queryKey) => {
      if (query[queryKey])
        queryParamsArray.push(`${queryKey}=${query[queryKey]}`);
    });

    queryParamsArray.push(`page=${currentPage}`);
    queryParamsArray.push(`size=${pageSize}`);

    const queryParams = queryParamsArray.join("&");
    const url = `${moduleMain}?${queryParams}`;

    setLoading(true); // show loader
    getAllFiltered(url)
      .then((_res: any) => {
        // set all data
        setAllData(_res.rows);

        // set total count
        setTotalRowCount(_res.count);

        const rows: any = [];
        _res.rows.forEach((_record: any) => {
          rows.push([
            _record?.conventionalId,
            _record?.translation,
            _record?.translationAddedBy
              ? _record?.translationAddedBy.firstName +
                " " +
                _record?.translationAddedBy.lastName
              : "",
          ]);
        });

        setTableData(rows);
        setLoading(false); // hide loader
      })
      .catch((_err) => {
        log(_err);
        setLoading(false); // hide loader
      });
  };

  const onChangeStatus = (value) => {
    let statusFilters = {
      verified: false,
      toBeApproved: false,
      toBeReviewed: false,
      toBeQC: false,
      reviewRejected: false,
      qcRejected: false,
    };

    switch (value) {
      case "Verified":
        statusFilters.verified = true;
        break;
      case "QC":
        statusFilters.toBeQC = true;
        break;
      case "Approved":
        statusFilters.toBeReviewed = true;
        break;
      case "Not Approved":
        statusFilters.toBeApproved = true;
        break;
      case "Review Rejected":
        statusFilters.reviewRejected = true;
        break;
      case "QC Rejected":
        statusFilters.qcRejected = true;
        break;
      default:
        break;
    }

    setFilters((prevState) => ({ ...prevState, status: value }));
    setQuery((prevState) => ({ ...prevState, ...statusFilters }));
  };

  const handleGenerateAudio = async (hadithTranslationId) => {
    try {
      const requestBody = { hadithTranslationId };
      const response = await post("hadith/generate-audio", requestBody);

      if (response?.ok) {
        notifications.current.showSuccess(
          "Audio generation started successfully."
        );
        fetch(); // Re-fetch data to update the table with new status
      } else {
        const errorMessage = response?.message || "Failed to generate audio.";
        notifications.current.showError(errorMessage);
      }
    } catch (error) {
      notifications.current.showError("Error generating audio.");
    }
  };

  const handlePlayAudio = async (audioFileName) => {
    try {
      const fileUrl = `${process.env.REACT_APP_S3_AUDIO_URL}/${audioFileName}`; // Use S3 URL for audio
      const audio = new Audio(fileUrl);
      audio.play();
    } catch (error) {
      console.error("Error playing audio:", error);
    }
  };

  const formatDate = (date) => {
    return date.toISOString().split("T")[0];
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "rgba(222, 223, 223, 0.3)",
        padding: { xs: 1 },
        minHeight: "100%", // Ensures it takes at least the height of its container
      }}
    >
      <CssBaseline />

      {/* Header */}
      {!modalView && <Header />}

      {/* Sider */}
      {!modalView && <Sider />}

      {/* Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          mt: modalView ? 0 : 8,
          overflowY: "auto",
          ml: modalView ? 0 : 8,
        }}
      >
        <div className="px-1 px-md-4">
          {/* Upper Section */}
          <div className="row">
            <div className="col-md-10">
              <h2 className="mt-2">{t("module.search")}</h2>
            </div>
            <div className="col-md-2">
              {/* <RoleCreate refreshDataEvent={fetch} /> */}
            </div>
          </div>

          <React.Fragment>
            <div className={`row ${!isShrunk ? "" : classes.stickyTop}`}>
              <div ref={searchDiv} className="col-md-12">
                <Card
                  variant="outlined"
                  sx={{ border: "1px solid black !important" }}
                >
                  <CardContent className="p-3">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="row">
                          {/* Field roleId */}
                          {!(isShrunk && filter.status == "") && (
                            <div className="col-md-4 mt-2">
                              <FormControl fullWidth={true} variant="filled">
                                <InputLabel id="userIdLabel">Status</InputLabel>
                                {hadithStatuses.length > 0 && (
                                  <Select
                                    id="roleIdInput"
                                    label={"Status"}
                                    value={filter.status}
                                    onChange={(event) => {
                                      onChangeStatus(event.target.value);
                                    }}
                                  >
                                    {hadithStatuses.map((el: any) => (
                                      <MenuItem value={el} key={el}>
                                        {" "}
                                        {el}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                )}
                                {hadithStatuses.length == 0 && (
                                  <>
                                    <Select
                                      labelId="roleIdInput"
                                      disabled
                                      id="roleIdInput"
                                    >
                                      <MenuItem value={""}>No Data</MenuItem>
                                    </Select>
                                    <FormHelperText>No Data</FormHelperText>
                                  </>
                                )}
                              </FormControl>
                              <Button
                                className="m-0 my-1 p-0"
                                variant="contained"
                                onClick={() => onChangeStatus(null)}
                              >
                                Clear
                              </Button>
                            </div>
                          )}

                          {!(isShrunk && !filter.conventionalId) && (
                            <div className="col-md-4 mt-2">
                              <FormControl fullWidth>
                                <TextField
                                  label="Hadith ID."
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  value={filter.conventionalId}
                                  onChange={(event) => {
                                    setFilters((prevState) => ({
                                      ...prevState,
                                      conventionalId: event.target.value,
                                    }));
                                    setQuery((prevState) => ({
                                      ...prevState,
                                      conventionalId: event.target.value,
                                    }));
                                  }}
                                  fullWidth
                                />
                              </FormControl>
                              <Button
                                className="m-0 my-1 p-0"
                                variant="contained"
                                onClick={() => {
                                  setFilters((prevState) => ({
                                    ...prevState,
                                    conventionalId: "",
                                  }));
                                  setQuery((prevState) => ({
                                    ...prevState,
                                    conventionalId: "",
                                  }));
                                }}
                              >
                                Clear
                              </Button>
                            </div>
                          )}

                          {/* Field Added By */}
                          {!(isShrunk && !filter.hadithbookId) && (
                            <div className="col-md-4 mt-2">
                              <FormControl fullWidth={true} variant="filled">
                                <InputLabel id="userIdLabel">
                                  Hadith Book
                                </InputLabel>
                                {allHadithBooks.length > 0 && (
                                  <Select
                                    id="roleIdInput"
                                    label={"Added By"}
                                    value={filter.hadithbookId}
                                    onChange={(event) => {
                                      setFilters((prevState) => ({
                                        ...prevState,
                                        hadithbookId: event.target.value,
                                      }));
                                      setQuery((prevState) => ({
                                        ...prevState,
                                        hadithbookId: event.target.value,
                                      }));
                                    }}
                                  >
                                    {allHadithBooks.map((el: any) => (
                                      <MenuItem value={el.id} key={el.id}>
                                        {" "}
                                        {el.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                )}
                                {allHadithBooks.length == 0 && (
                                  <>
                                    <Select
                                      labelId="roleIdInput"
                                      disabled
                                      id="roleIdInput"
                                    >
                                      <MenuItem value={""}>No Data</MenuItem>
                                    </Select>
                                    <FormHelperText>No Data</FormHelperText>
                                  </>
                                )}
                              </FormControl>
                              <Button
                                className="m-0 my-1 p-0"
                                variant="contained"
                                onClick={() => {
                                  setFilters((prevState) => ({
                                    ...prevState,
                                    hadithbookId: null,
                                  }));
                                  setQuery((prevState) => ({
                                    ...prevState,
                                    hadithbookId: null,
                                  }));
                                }}
                              >
                                Clear
                              </Button>
                            </div>
                          )}

                          {!modalView && (
                            <Box>
                              {!isShrunk && (
                                <Typography
                                  className="col-md-12"
                                  variant="subtitle2"
                                >
                                  Filter by User
                                </Typography>
                              )}

                              {/* Field Added By */}
                              {!(isShrunk && !filter.addedBy) && (
                                <div className="col-md-4 mt-2">
                                  <FormControl
                                    fullWidth={true}
                                    variant="filled"
                                  >
                                    <InputLabel id="userIdLabel">
                                      Added By
                                    </InputLabel>
                                    {allUsers.length > 0 && (
                                      <Select
                                        id="roleIdInput"
                                        label={"Added By"}
                                        value={filter.addedBy}
                                        onChange={(event) => {
                                          setFilters((prevState) => ({
                                            ...prevState,
                                            addedBy: event.target.value,
                                          }));
                                          setQuery((prevState) => ({
                                            ...prevState,
                                            addedByUserId: event.target.value,
                                          }));
                                        }}
                                      >
                                        {allUsers.map((el: any) => (
                                          <MenuItem value={el.id} key={el.id}>
                                            {" "}
                                            {el.firstName + " " + el.lastName}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    )}
                                    {allUsers.length == 0 && (
                                      <>
                                        <Select
                                          labelId="roleIdInput"
                                          disabled
                                          id="roleIdInput"
                                        >
                                          <MenuItem value={""}>
                                            No Data
                                          </MenuItem>
                                        </Select>
                                        <FormHelperText>No Data</FormHelperText>
                                      </>
                                    )}
                                  </FormControl>
                                  <Button
                                    className="m-0 my-1 p-0"
                                    variant="contained"
                                    onClick={() => {
                                      setFilters((prevState) => ({
                                        ...prevState,
                                        addedBy: null,
                                      }));
                                      setQuery((prevState) => ({
                                        ...prevState,
                                        addedByUserId: null,
                                      }));
                                    }}
                                  >
                                    Clear
                                  </Button>
                                </div>
                              )}

                              {/* Field Reviewed By */}
                              {!(isShrunk && !filter.approvedBy) && (
                                <div className="col-md-4 mt-2">
                                  <FormControl
                                    fullWidth={true}
                                    variant="filled"
                                  >
                                    <InputLabel id="approvedIdLabel">
                                      Approved By
                                    </InputLabel>
                                    {allUsers.length > 0 && (
                                      <Select
                                        id="approvedInput"
                                        label={"Approved By"}
                                        value={filter.approvedBy}
                                        onChange={(event) => {
                                          setFilters((prevState) => ({
                                            ...prevState,
                                            approvedBy: event.target.value,
                                          }));
                                          setQuery((prevState) => ({
                                            ...prevState,
                                            approvedByUserId:
                                              event.target.value,
                                          }));
                                        }}
                                      >
                                        {allUsers.map((el: any) => (
                                          <MenuItem value={el.id} key={el.id}>
                                            {" "}
                                            {el.firstName + " " + el.lastName}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    )}
                                    {allUsers.length == 0 && (
                                      <>
                                        <Select
                                          labelId="approvedInput"
                                          disabled
                                          id="approvedInput"
                                        >
                                          <MenuItem value={""}>
                                            No Data
                                          </MenuItem>
                                        </Select>
                                        <FormHelperText>No Data</FormHelperText>
                                      </>
                                    )}
                                  </FormControl>
                                  <Button
                                    className="m-0 my-1 p-0"
                                    variant="contained"
                                    onClick={() => {
                                      setFilters((prevState) => ({
                                        ...prevState,
                                        approvedBy: null,
                                      }));
                                      setQuery((prevState) => ({
                                        ...prevState,
                                        approvedByUserId: null,
                                      }));
                                    }}
                                  >
                                    Clear
                                  </Button>
                                </div>
                              )}

                              {/* Field Reviewed By */}
                              {!(isShrunk && !filter.reviewedByUserId) && (
                                <div className="col-md-4 mt-2">
                                  <FormControl
                                    fullWidth={true}
                                    variant="filled"
                                  >
                                    <InputLabel id="userIdLabel">
                                      Reviewed By
                                    </InputLabel>
                                    {allUsers.length > 0 && (
                                      <Select
                                        id="roleIdInput"
                                        label={"Reviewed By"}
                                        value={filter.reviewedByUserId}
                                        onChange={(event) => {
                                          setFilters((prevState) => ({
                                            ...prevState,
                                            reviewedByUserId:
                                              event.target.value,
                                          }));
                                          setQuery((prevState) => ({
                                            ...prevState,
                                            reviewedByUserId:
                                              event.target.value,
                                          }));
                                        }}
                                      >
                                        {allUsers.map((el: any) => (
                                          <MenuItem value={el.id} key={el.id}>
                                            {" "}
                                            {el.firstName + " " + el.lastName}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    )}
                                    {allUsers.length == 0 && (
                                      <>
                                        <Select
                                          labelId="roleIdInput"
                                          disabled
                                          id="roleIdInput"
                                        >
                                          <MenuItem value={""}>
                                            No Data
                                          </MenuItem>
                                        </Select>
                                        <FormHelperText>No Data</FormHelperText>
                                      </>
                                    )}
                                  </FormControl>
                                  <Button
                                    className="m-0 my-1 p-0"
                                    variant="contained"
                                    onClick={() => {
                                      setFilters((prevState) => ({
                                        ...prevState,
                                        reviewedByUserId: null,
                                      }));
                                      setQuery((prevState) => ({
                                        ...prevState,
                                        reviewedByUserId: null,
                                      }));
                                    }}
                                  >
                                    Clear
                                  </Button>
                                </div>
                              )}
                            </Box>
                          )}

                          {!isShrunk && (
                            <Typography
                              className="col-md-12"
                              variant="subtitle2"
                            >
                              Filter by Date
                            </Typography>
                          )}

                          {!(isShrunk && !filter.addedDate) && (
                            <div className="col-md-4 mt-2">
                              <FormControl fullWidth={true}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <MobileDatePicker
                                    value={filter.addedDate}
                                    onChange={(newValue: any) => {
                                      setFilters((prevState) => ({
                                        ...prevState,
                                        addedDate: newValue,
                                      }));
                                      setQuery((prevState) => ({
                                        ...prevState,
                                        addedDate: formatDate(newValue["$d"]),
                                      }));
                                    }}
                                    label="Date Added on"
                                  />
                                </LocalizationProvider>
                              </FormControl>
                              <Button
                                className="m-0 my-1 p-0"
                                variant="contained"
                                onClick={() => {
                                  setFilters((prevState) => ({
                                    ...prevState,
                                    addedDate: null,
                                  }));
                                  setQuery((prevState) => ({
                                    ...prevState,
                                    addedDate: null,
                                  }));
                                }}
                              >
                                Clear
                              </Button>
                            </div>
                          )}

                          {!(isShrunk && !filter.approvedDate) && (
                            <div className="col-md-4 mt-2">
                              <FormControl fullWidth={true}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <MobileDatePicker
                                    value={filter.approvedDate}
                                    onChange={(newValue: any) => {
                                      setFilters((prevState) => ({
                                        ...prevState,
                                        approvedDate: newValue,
                                      }));
                                      setQuery((prevState) => ({
                                        ...prevState,
                                        approvedDate: formatDate(
                                          newValue["$d"]
                                        ),
                                      }));
                                    }}
                                    label="Date Approved on"
                                  />
                                </LocalizationProvider>
                              </FormControl>
                              <Button
                                className="m-0 my-1 p-0"
                                variant="contained"
                                onClick={() => {
                                  setFilters((prevState) => ({
                                    ...prevState,
                                    approvedDate: null,
                                  }));
                                  setQuery((prevState) => ({
                                    ...prevState,
                                    approvedDate: null,
                                  }));
                                }}
                              >
                                Clear
                              </Button>
                            </div>
                          )}

                          {!(isShrunk && !filter.reviewedDate) && (
                            <div className="col-md-4 mt-2">
                              <FormControl fullWidth={true}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <MobileDatePicker
                                    value={filter.reviewedDate}
                                    onChange={(newValue: any) => {
                                      setFilters((prevState) => ({
                                        ...prevState,
                                        reviewedDate: newValue,
                                      }));
                                      setQuery((prevState) => ({
                                        ...prevState,
                                        reviewedDate: formatDate(
                                          newValue["$d"]
                                        ),
                                      }));
                                    }}
                                    label="Date Reviewed on"
                                  />
                                </LocalizationProvider>
                              </FormControl>
                              <Button
                                className="m-0 my-1 p-0"
                                variant="contained"
                                onClick={() => {
                                  setFilters((prevState) => ({
                                    ...prevState,
                                    reviewedDate: null,
                                  }));
                                  setQuery((prevState) => ({
                                    ...prevState,
                                    reviewedDate: null,
                                  }));
                                }}
                              >
                                Clear
                              </Button>
                            </div>
                          )}

                          {!isShrunk && (
                            <div className="row mt-2">
                              <div className="col-md-2 full-width ms-auto">
                                <Button
                                  className="m-0 p-1"
                                  variant="contained"
                                  onClick={clearAllFilters}
                                >
                                  Clear All Filters
                                </Button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-6">
                        {!(isShrunk && filter.translationString == "") && (
                          <div className="col-md-12 mt-2">
                            <FormControl fullWidth>
                              <TextField
                                label="Type Text to Search against any Hadith"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                multiline
                                minRows={isShrunk ? 2 : 14}
                                value={filter.translationString}
                                onChange={(event) => {
                                  setFilters((prevState) => ({
                                    ...prevState,
                                    translationString: event.target.value,
                                  }));
                                  setQuery((prevState) => ({
                                    ...prevState,
                                    translationString: event.target.value,
                                  }));
                                }}
                                fullWidth
                              />
                            </FormControl>
                            <Button
                              className="m-0 my-1 p-0"
                              variant="contained"
                              onClick={() => {
                                setFilters((prevState) => ({
                                  ...prevState,
                                  translationString: "",
                                }));
                                setQuery((prevState) => ({
                                  ...prevState,
                                  translationString: "",
                                }));
                              }}
                            >
                              Clear
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </CardContent>

                  {!isShrunk && (
                    <CardActions>
                      <Button
                        className="full-width"
                        variant="contained"
                        color="success"
                        disabled={loading}
                        onClick={fetch}
                        startIcon={
                          loading ? (
                            <CircularProgress size="0.7em" />
                          ) : (
                            <FontAwesomeIcon width="0.7em" icon="search" />
                          )
                        }
                      >
                        Search
                      </Button>
                    </CardActions>
                  )}
                </Card>
              </div>
            </div>
          </React.Fragment>

          <div className="mt-5">
            <div className="mx-2">
              <Typography className="ms-auto" variant="overline">
                Showing {pageSize * currentPage + 1} -{" "}
                {totalRowCount < pageSize * (1 + currentPage)
                  ? totalRowCount
                  : pageSize * (1 + currentPage)}{" "}
                of {totalRowCount} Results
              </Typography>
            </div>
            <Paper elevation={0}>
              <MUIDataTable
                sx={{ maxHeight: "99vh", overFlowY: "auto" }}
                // title={t('common.allRecords')}
                data={tableData}
                columns={columns}
                // @ts-ignore
                options={options}
              />
            </Paper>
          </div>
        </div>

        {/* Alerts */}
        <AlertM ref={notifications} />
      </Box>
    </Box>
  );
}

export default SearchMain;
