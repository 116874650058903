// @ts-nocheck
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useStyles } from "./Styles";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import {
  Button,
  Card,
  CardContent,
  IconButton,
  Chip,
  Typography,
} from "@mui/material";
import {
  Timeline,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { useTranslation } from "react-i18next";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import CardActions from "@mui/material/CardActions";
import DataDisplayM from "../../../../Helpers/DataDisplayM/DataDisplayM";
import { log } from "../../../../../Services/LoggerService";
import AlertM from "../../../../Helpers/AlertM/AlertM";
import {
  getById,
  getAllFiltered,
} from "../../../../../Services/GenericApiService";

// font awesome stuff
const iconList = Object.keys(Icons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon) => Icons[icon]);

library.add(...iconList);

function HadithView({ data = null }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [narratorChain, setNarratorChain] = useState([]);
  const [linkedKeywords, setLinkedKeywords] = useState([]);
  const [linkedLabels, setLinkedLabels] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);

  const notifications = useRef();
  const hadithIdRef = useRef(null);

  const handleOpen = () => {
    setOpen(true);

    // Only fetch data when modal is opened and data hasn't been loaded yet
    // or if the hadith ID has changed
    if (
      (!dataLoaded || hadithIdRef.current !== data?.hadithId) &&
      data?.hadithId
    ) {
      fetchAllData();
    }
  };

  const handleClose = () => setOpen(false);

  const handleCancelEvent = () => {
    handleClose();
  };

  // Combine all fetch operations into a single function
  const fetchAllData = useCallback(async () => {
    if (!data?.hadithId) return;

    try {
      console.log("Fetching data for hadithId:", data.hadithId);

      // Update the ref to track which hadith we've loaded data for
      hadithIdRef.current = data.hadithId;

      // Use Promise.all to fetch all data in parallel
      const [narratorsRes, keywordsRes, labelsRes] = await Promise.all([
        getById("narratordetail", data.hadithId),
        getAllFiltered(`keyword?linkedHadithId=${data.hadithId}`),
        getAllFiltered(`label?linkedHadithId=${data.hadithId}`),
      ]);

      // Process narrator data
      const narratorData = narratorsRes.map((narrator) => ({
        name: narrator.name,
        id: narrator.id,
        nameEnglish: narrator.nameEnglish,
        kuniat: narrator.kuniat,
        nasab: narrator.nasab,
      }));
      setNarratorChain(narratorData);

      // Process keyword data
      const keywordData = keywordsRes.map((keyword) => ({
        id: keyword.id,
        label: keyword.rootWord,
      }));
      setLinkedKeywords(keywordData);

      // Process label data
      const labelData = labelsRes.map((label) => ({
        id: label.id,
        label: label.nameEnglish,
      }));
      setLinkedLabels(labelData);

      // Mark data as loaded
      setDataLoaded(true);
    } catch (error) {
      console.error("Error fetching hadith data:", error);
      notifications.current?.errorAlert(
        "Error",
        "Failed to fetch hadith details."
      );
    }
  }, [data?.hadithId]);

  // Reset data loaded state when the component unmounts or when hadithId changes
  useEffect(() => {
    return () => {
      setDataLoaded(false);
    };
  }, [data?.hadithId]);

  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <Tooltip title={t("common.view")}>
          <IconButton aria-label="view" size="small" onClick={handleOpen}>
            <FontAwesomeIcon className="edit-btn" icon="eye" />
          </IconButton>
        </Tooltip>
      </Box>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className={classes.modal}>
            <Card sx={{ width: "75vw", maxHeight: "90vh", overflowY: "auto" }}>
              <CardContent>
                <div className="py-4 px-md-4 px-0">
                  <h1 className="mb-3">{t("common.recordDetails")}</h1>
                  <div className="row full-width">
                    {data && (
                      <Card className="col-md-12 mb-3">
                        <CardContent>
                          <div className="row">
                            <div className="col-md-2">
                              <DataDisplayM
                                label={t("hadith.hadithId")}
                                data={data?.hadithId}
                              />
                            </div>
                            <div className="col-md-2">
                              <DataDisplayM
                                label={"Conventional ID"}
                                data={data?.conventionalId}
                              />
                            </div>
                            <div className="col-md-2">
                              <DataDisplayM
                                label={"Language"}
                                data={data?.language}
                              />
                            </div>
                            <div className="col-md-2">
                              <DataDisplayM
                                label={"Added By"}
                                data={data?.addedByUserId}
                              />
                            </div>
                            <div className="col-md-2">
                              <DataDisplayM
                                label={"Hadith Book"}
                                data={data?.Hadiths?.book?.author}
                              />
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    )}
                  </div>
                  <div className="col-md-12">
                    {data && (
                      <Card className="mb-3">
                        <CardContent>
                          <DataDisplayM
                            label={t("hadith.translation")}
                            data={data?.translation}
                          />
                        </CardContent>
                      </Card>
                    )}
                  </div>
                  <div className="col-md-12">
                    {data && (
                      <Card className="mb-3">
                        <CardContent>
                          <DataDisplayM
                            label={"Comments"}
                            data={data?.comments}
                          />
                        </CardContent>
                      </Card>
                    )}
                  </div>
                  <div className="col-md-12">
                    {data && data?.Hadiths?.verses?.length > 0 && (
                      <Card className="mb-3">
                        <CardContent>
                          <Typography
                            variant="overline"
                            sx={{ fontSize: "0.8em" }}
                          >
                            Hadith Verses
                          </Typography>
                          {data.Hadiths.verses.map((verse, index) => (
                            <DataDisplayM
                              key={index}
                              label={`Verse ${index + 1}`}
                              data={verse?.textImlaeiSimple}
                            />
                          ))}
                        </CardContent>
                      </Card>
                    )}
                  </div>
                  <div className="col-md-12">
                    {data && (
                      <Card className="mb-3">
                        <CardContent>
                          <Typography
                            variant="overline"
                            sx={{ fontSize: "0.8em" }}
                          >
                            Hadith Root Words:{" "}
                          </Typography>
                          {linkedKeywords?.map((keyword) => (
                            <Chip key={keyword.id} label={keyword.label} />
                          ))}
                        </CardContent>
                      </Card>
                    )}
                  </div>
                  <div className="col-md-12">
                    {data && (
                      <Card className="mb-3">
                        <CardContent>
                          <Typography
                            variant="overline"
                            sx={{ fontSize: "0.8em" }}
                          >
                            Hadith Keywords:{" "}
                          </Typography>
                          {linkedLabels?.map((label) => (
                            <Chip key={label.id} label={label.label} />
                          ))}
                        </CardContent>
                      </Card>
                    )}
                  </div>
                  <div className="col-md-12 mt-4">
                    <Card className="mb-3">
                      <CardContent>
                        <Typography
                          variant="overline"
                          sx={{ fontSize: "0.8em" }}
                        >
                          Narrators Chain ({narratorChain.length})
                        </Typography>
                        {narratorChain.length > 0 && (
                          <Timeline
                            className="mx-auto"
                            sx={{
                              [`& .${timelineItemClasses.root}:before`]: {
                                flex: 0,
                                padding: 0,
                              },
                            }}
                          >
                            {narratorChain.map((narrator, narratorIndex) => (
                              <TimelineItem key={narrator.id}>
                                <TimelineSeparator>
                                  <TimelineDot color="primary" />
                                  {narratorIndex !==
                                    narratorChain.length - 1 && (
                                    <TimelineConnector />
                                  )}
                                </TimelineSeparator>
                                <TimelineContent>
                                  <div className="d-flex">
                                    <span>
                                      {narratorIndex + 1}.{" "}
                                      {narrator?.nameEnglish} {narrator?.name}{" "}
                                      {narrator?.kuniat} {narrator?.nasab}
                                    </span>
                                  </div>
                                </TimelineContent>
                              </TimelineItem>
                            ))}
                          </Timeline>
                        )}
                      </CardContent>
                    </Card>
                  </div>
                </div>
              </CardContent>
              <CardActions>
                <div
                  className="pb-4 pt-2 px-md-4 px-2"
                  style={{ width: "100%", textAlign: "right" }}
                >
                  <Button variant="outlined" onClick={handleCancelEvent}>
                    {t("common.cancel")}
                  </Button>
                  &nbsp;&nbsp;
                </div>
              </CardActions>
            </Card>
          </Box>
        </Fade>
      </Modal>
      {/* Alerts */}
      <AlertM ref={notifications} />
    </div>
  );
}

export default HadithView;
