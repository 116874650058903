import React, { useState } from 'react';
import { useStyles } from './Styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { get } from '../../../../../Services/GenericApiService';
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { getAll, getById, post, patch, deleteById } from "../../../../../Services/GenericApiService";
import { log } from "../../../../../Services/LoggerService";
import { useEffect } from 'react';
import {
    Button, IconButton, Skeleton, Card, CardContent, Typography, CardActions, Divider, CircularProgress, FormControl, InputLabel, MenuItem, Select, FormHelperText,
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper
} from "@mui/material";
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

function Logs(props: any) {

    const module = 'user'
    const analytics = 'analytics/dailyrecords'

    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const [filters, setFilters] = useState({
        date: dayjs(new Date().toISOString()),
        teacherId: null,
    })
    const [data, setData] = useState<any>({});
    const [allTeachers, setAllTeachers] = useState([]);


    useEffect(() => {
        fetchTeachers();
    }, [])

    useEffect(() => {
        if (filters.date && filters.teacherId && filters.teacherId !== '') fetchLogs();
    }, [filters])

    const fetchLogs = () => {
        setLoading(true); // show loader

        const dateString = filters.date.toISOString().split('T')[0];

        const url = `${analytics}?date=${dateString}&teacherId=${filters.teacherId}`;

        get(url)
            .then((_res: any) => {
                setData(_res);
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    const fetchTeachers = () => {
        setLoading(true); // show loader
        getAll(module + `?roleId=${process.env.REACT_APP_TEACHER_ROLE_ID}`)
            .then((_res: any) => {
                setAllTeachers(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false);
            })
    }


    return (
        <Box className="px-4 py-2" sx={{ minHeight: '99vh' }}>

            <Typography sx={{ fontSize: '1em' }} className='mb-2' variant='overline'>Logs</Typography>

            <div className="row">
                <div className="col-md-3">
                    <FormControl fullWidth={true} >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileDatePicker value={filters.date}
                                onChange={(newValue: any) => setFilters(prevState => ({ ...prevState, date: newValue }))}
                                label="Date" />
                        </LocalizationProvider>
                    </FormControl>
                </div>

                <div className="col-md-3">
                    <FormControl fullWidth={true} variant="filled">
                        <InputLabel id="userIdLabel">
                            Team
                        </InputLabel>
                        {allTeachers.length > 0 &&
                            <Select id="roleIdInput"
                                label={'Team'}
                                value={filters.teacherId}
                                onChange={(event) => setFilters((prevState: any) => ({ ...prevState, teacherId: event.target.value }))}>
                                {
                                    allTeachers.map((el: any) =>
                                        <MenuItem value={el.id}
                                            key={el.id}> {el.firstName} {el.lastName}
                                        </MenuItem>)
                                }
                            </Select>
                        }
                        {allTeachers.length == 0 &&
                            <>
                                <Select labelId="roleIdInput"
                                    disabled
                                    id="roleIdInput">
                                    <MenuItem value={''}>
                                        No Data
                                    </MenuItem>
                                </Select>
                                <FormHelperText>
                                    No Data
                                </FormHelperText>
                            </>
                        }
                    </FormControl>
                </div>

            </div>


            {loading &&
                <div className='d-flex justify-content-center mt-5'>
                    <CircularProgress />
                </div>}

            {!loading && Object.values(data).length > 0 &&
                <div className="row my-4">

                    <div style={{ fontWeight: 'bold' }} className="bg-dark text-white rounded col-md-12 py-2 mx-1">
                        {new Date(data?.date).toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })}
                    </div>

                    <div className="row mt-3 rounded">
                        <div className="col-md-12 mx-auto">
                            <Card variant="outlined" sx={{ height: '100%' }} className="">
                                <CardContent >
                                    <div className="row">
                                        <div className="col-12 my-2">
                                            Teacher: <span style={{ fontWeight: 'bold' }}>{data?.name}</span>
                                            <div>Total Reviewed: <span>{data?.totalReviewed}</span></div>
                                        </div>

                                        <div className="my-2">
                                            <Divider />
                                        </div>



                                        {Object.values(data?.students).map((studentRecord: any, itemIndex: any) =>
                                            <div className="col-md-12">
                                                <div className="my-2">
                                                    Student: <span style={{ fontWeight: 'bold' }}>{studentRecord.name}</span>
                                                    <div>
                                                        Total Approved: {studentRecord.totalApproved}
                                                    </div>
                                                </div>

                                                {Object.values(studentRecord.clerks).length == 0 &&
                                                    <div style={{ fontSize: '0.7em' }}>
                                                        No Data Entry Clerks Associated
                                                    </div>
                                                }

                                                {Object.values(studentRecord.clerks).length > 0 &&

                                                    <TableContainer className="my-3" component={Paper}>
                                                        <Table stickyHeader aria-label="sticky table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell colSpan={3}>#</TableCell>
                                                                    <TableCell colSpan={4}>Name</TableCell>
                                                                    <TableCell colSpan={4}>Added</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>

                                                                {Object.values(studentRecord.clerks).map((clerkRecord: any, clerkIndex: any) =>

                                                                    <TableRow
                                                                        key={'data'}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                        <TableCell colSpan={3}>{clerkIndex + 1}</TableCell>
                                                                        <TableCell colSpan={4}>{clerkRecord.name}</TableCell>
                                                                        <TableCell colSpan={4}>{clerkRecord.totalAdded}</TableCell>
                                                                    </TableRow>

                                                                )}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>


                                                }
                                            </div>
                                        )}
                                    </div>

                                </CardContent>
                            </Card>
                        </div>
                    </div>

                </div>
            }
        </Box>
    );
}

export default Logs;
