// @ts-nocheck

import React, {
  useEffect,
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
  useCallback,
} from "react";
import { useStyles } from "./Styles";
import "../../../../../index.scss";
import { useTheme } from "@mui/material/styles";
import {
  getAll,
  getAllFiltered,
  getById,
  post,
  patchData,
  patch,
} from "../../../../../Services/GenericApiService";
import { useTranslation } from "react-i18next";
import { log } from "../../../../../Services/LoggerService";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AlertM from "../../../../Helpers/AlertM/AlertM";
// import CommentIcon from "@mui/icons-material/Comment";
// import DetailsIcon from "@mui/icons-material/Details";
import Box from "@mui/material/Box";
import {
  Typography,
  Paper,
  Divider,
  Button,
  TextField,
  MenuItem,
  Select,
  FormHelperText,
  IconButton,
  InputLabel,
  FormControl,
  Modal,
  CircularProgress,
  Grid,
  Card,
  CardContent,
  Autocomplete,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import Switch from "@mui/material/Switch";
import {
  getLoggedInUserRole,
  getUserData,
} from "../../../../../Services/LocalStorageService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Chip from "@mui/material/Chip";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import SearchMain from "../../Search/SearchMain/SearchMain";
// import HadithSiderSearch from "../../Hadith/HadithSiderSearch/HadithSiderSearch";
// import CreateHadithReference from "../CreateHadithReference/CreateHadithReference";
// import ManageBook from "../../Books/ManageBook/ManageBook";
import { franc } from "franc-min";

// font awesome stuff
const iconList = Object.keys(Icons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon) => Icons[icon]);

library.add(...iconList);

const ManageTafseer = forwardRef(
  ({ verse = null, currentChapter = null, closeEvent = null }, ref) => {
    useImperativeHandle(ref, () => ({}));

    const theme = useTheme();

    // css for module
    const classes = useStyles();

    // translation
    const { t } = useTranslation();

    // child ref for alert
    const notifications = useRef();

    // data vars

    // // ui controls

    const [open, setOpen] = useState(false);
    const [importContentFromBook, setImportContentFromBook] = useState(false);
    const [tafseerOpen, setTafseerOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [hadithSearchVisible, setHadithSearchVisible] = useState(false);
    const [verseLoading, setVersetLoading] = React.useState(false);
    const [userRole, setUserRole] = React.useState("");
    const [currentPageLogs, setCurrentPageLogs] = React.useState(0);
    const [isVerificationControlEnabled, setIsVerificationControlEnabled] =
      React.useState(false);
    const [verseTafseerData, setVerseTafseerData] = useState({});
    const [verseBooks, setAllVerseBooks] = useState([]);
    const [isTopicOpen, setIsTopicOpen] = useState(false);
    const [newTafseerSectionTitle, setNewTafseerSectionTitle] = useState("");
    const [bookSections, setBookSections] = useState([]);
    const [allBooks, setAllBooks] = useState([]);
    const [linkBookOpen, setLinkBookOpen] = useState(false);
    const [selectedBook, setSelectedBook] = useState("");
    const [loadingBooks, setLoadingBooks] = useState(false);

    const [selectedSection, setSelectedSection] = useState("");
    const [verseTafseerSections, setVerseTafseerSections] = useState([]);

    const moduleMain = "pageverificationlog";
    const moduleTafseer = "verse/tafseer";
    const moduleBook = "book";

    useEffect(() => {
      if (verse?.id) fetchAllVerseBooks(verse?.id);
    }, [verse]);

    const handlResetData = () => {
      setBookSections([]);
      setSelectedKeywords([]);
      setSelectedSection("");
      setSelectedBook("");

      let _verseTafseerData = {
        verseId: verse?.id,
        verse: verse?.textIndoPak,
        bookId: null,
        sectionTitle: null,
        content: null,
        author: null,
        language: null,
        keywords: {},
      };

      setVerseTafseerData(_verseTafseerData);
    };

    const handleAddTafseer = (verse) => {
      handlResetData();
      let _verseTafseerData = {
        verseId: verse?.id,
        verse: verse?.textIndoPak,
        bookId: null,
        sectionTitle: null,
        content: null,
        author: null,
        language: null,
        keywords: {},
      };

      setVerseTafseerData(_verseTafseerData);
      fetchAllVerseBooks(verse?.id);
      setTafseerOpen(true);
    };

    const filterAvailableBooks = (_allBooks) => {
      if (_allBooks.length > 0 && verseBooks.length > 0) {
        // Assuming each book has a unique 'id' property
        const filteredBooks = _allBooks.filter(
          (allBook) =>
            !verseBooks.some((verseBook) => verseBook.id === allBook.id)
        );

        // Now 'filteredBooks' will have the books that are not in 'tafseerBooks'
        setAllBooks(filteredBooks);
        return;
      }
      setAllBooks(_allBooks);
    };

    const fetchAllBooks = () => {
      getAll("book")
        .then((_res: any) => {
          filterAvailableBooks(_res);
        })
        .catch((_err) => {
          notifications.current.errorAlert("Error", "Could not fetch Books");
          log(_err);
        });
    };

    const fetchAllVerseBooks = (verseId) => {
      getById("versebook/getByVerse", verseId)
        .then((_res: any) => {
          setAllVerseBooks(_res);
          filterAvailableBooks(allBooks);
        })
        .catch((_err) => {
          notifications.current.errorAlert(
            "Error",
            "Could not fetch Linked Books"
          );
          log(_err);
        });
    };

    const handleVerseBookChange = (value) => {
      setVerseTafseerData((prev) => ({
        ...prev,
        bookId: value,
        sectionTitle: null,
      }));

      setSelectedBook(value);
      fetchAllVerseTafseerSections(value);
    };

    const fetchAllVerseTafseerSections = (bookId) => {
      let params = verse?.id ? "verseId=" + verse?.id : "";
      params += bookId ? "&bookId=" + bookId : "";

      const url = "versetafseersection?" + params;

      getAllFiltered(url)
        .then((_res: any) => {
          setVerseTafseerSections(_res);
        })
        .catch((_err) => {
          log(_err);
        });
    };

    const handleTafseerSectionChange = (value) => {
      setSelectedSection(value);

      const selectedSectionData = verseTafseerSections.find(
        (section) => section.id === value
      );
      setVerseTafseerData((prev) => ({
        ...prev,
        id: selectedSectionData?.id,
        sectionTitle: selectedSectionData?.sectionTitle,
        content: selectedSectionData?.content,
      }));
    };

    const handleTafseerSectionOpen = () => {
      setIsTopicOpen(true);
    };

    const handleCreateTafseerSection = () => {
      const tafseerSection = {
        verseId: verse?.id,
        bookId: selectedBook,
        sectionTitle: newTafseerSectionTitle,
        content: "",
        author: "",
        language: "",
        keywords: null,
        isDeleted: false,
      };

      post("versetafseersection", tafseerSection)
        .then((_res) => {
          setNewTafseerSectionTitle("");
          fetchPage({ page: currentPage });
          fetchAllVerseTafseerSections(verseTafseerData?.bookId);
        })
        .catch((_err) => {
          log(_err);
          notifications.current.errorAlert(
            "Error",
            "Could not add tafseer topic"
          );
        });

      setIsTopicOpen(false);
    };

    const submitVerseTafseer = () => {
      const _verseTafseerData = {
        content: verseTafseerData?.content,
        tafseerKeywords: selectedKeywords?.map((keyword) => ({
          keywordId: keyword.id,
          verseTafseerSectionId: verseTafseerData?.id,
        })),
      };

      patch("versetafseersection", verseTafseerData?.id, _verseTafseerData)
        .then((_res) => {
          fetchPage({ page: currentPage });
          fetchAllVerseTafseerSections(selectedBook);
          handlResetData();
          notifications.current.successAlert("Tafseer Added Successfully!");
        })
        .catch((_err) => {
          log(_err);
          notifications.current.errorAlert(
            "Error",
            "Could not add tafseer for verse " + currentPage
          );
        });
    };

    const fetchPage = ({ page = null, juz = null, chapter = null }) => {
      setVersetLoading(true); // show loader

      let params = page ? "page=" + page : "page=" + currentPage;
      params += juz ? "&juz=" + juz : "";
      params += chapter ? "&chapter=" + chapter : "";

      const url = "verse?" + params;

      getAllFiltered(url)
        .then((_res: any) => {
          // set all data
          // setAllVerses(_res);
          fetchPageLogs({ pageNumber: page ? page : currentPage });
          setVersetLoading(false); // hide loader
        })
        .catch((_err) => {
          log(_err);
          setVersetLoading(false); // hide loader
        });
    };

    const fetchPageLogs = ({ pageNumber = null }) => {
      setVersetLoading(true); // show loader

      getById(moduleMain, pageNumber)
        .then((_res: any) => {
          setCurrentPageLogs(_res);
          setVersetLoading(false); // hide loader
        })
        .catch((_err) => {
          log(_err);
          setVersetLoading(false); // hide loader
        });
    };

    useEffect(() => {
      if (open && currentChapter?.startPage) {
        setCurrentPage(currentChapter.startPage);
      }
    }, [open, currentChapter]);

    useEffect(() => {
      if (open && currentPage) fetchPage({});
    }, [currentPage]);

    useEffect(() => {
      const roleId = getLoggedInUserRole();
      setUserRole(roleId);

      // Enables controls for verification mode
      if (
        roleId == process.env.REACT_APP_STUDENT_ROLE_ID ||
        roleId == process.env.REACT_APP_TEACHER_ROLE_ID ||
        roleId == process.env.REACT_APP_QC_ROLE_ID
      ) {
        setIsVerificationControlEnabled(true);
      } else {
        setIsVerificationControlEnabled(false);
      }
    });

    const handleBookChange = (event) => {
      setSelectedBook(event.target.value);
    };

    const handleTopicOpen = () => {
      setIsTopicOpen(true);
    };

    const handleTopicClose = () => {
      setIsTopicOpen(false);
    };

    const refreshTopicEvent = () => {
      fetchTopicsByBookId(verseTafseerData?.tafseerTopic);
    };

    /**
     * Links a book to a verse
     */
    const handleAddBook = () => {
      setLoadingBooks(true);
      if (selectedBook && selectedBook !== "") {
        const body = {
          bookId: selectedBook,
          verseId: verse?.id,
        };

        post("versebook", body)
          .then((_res) => {
            setLinkBookOpen(false);
            setLoadingBooks(false);
            fetchAllVerseBooks(verse?.id);
            // notifications.current.successAlert(t('common.createSuccess'), 'Book Linked Successfully');
          })
          .catch((_err) => {
            log(_err);
            notifications.current.errorAlert(
              "Error",
              "Could not add book " + currentPage
            );
            setLoadingBooks(false);
          });
      }
    };

    const linkHadithWithVerse = (data) => {
      const payload = {
        hadithId: data.hadithId,
        verseId: verse.id,
      };
      setHadithSearchVisible(false);

      post("versehadith/link", payload)
        .then((_res) => {
          notifications.current.successAlert(
            t("common.createSuccess"),
            "Hadith Linked Successfully"
          );
        })
        .catch((_err) => {
          log(_err);
          notifications.current.errorAlert(
            "Error",
            "Could not add book " + currentPage
          );
        });
    };

    const [searchTerm, setSearchTerm] = useState("");
    const [allKeywords, setAllKeywords] = useState<string[]>([]);
    const [selectedKeywords, setSelectedKeywords] = useState([]);

    const fetchTafseerKeywords = (verseTafseerId) => {
      if (verseTafseerId) {
        getById("versetafseer", verseTafseerId)
          .then((_res: any) => {
            const data = _res.map((result: any) => ({
              label: result.keyword.rootWord,
              id: result.keyword.id,
            }));
            setSelectedKeywords(data);
          })
          .catch((_err) => {
            log(_err);
          });
      }
    };

    const handleSearchTermChange = (event) => {
      setSearchTerm(event.target.value);
    };

    const debounceSearch = useCallback(
      _.debounce((query) => {
        fetchKeywords(query);
      }, 2000),
      []
    );

    const fetchKeywords = async (query) => {
      try {
        let params = query ? "searchedKeyword=" + query : "";
        const url = "keyword?" + params;

        getAllFiltered(url)
          .then((_res: any) => {
            const keywordData = _res.map((keyword) => ({
              id: keyword.id,
              label: keyword.rootWord,
            }));
            setAllKeywords(keywordData);
          })
          .catch((error: any) => {
            console.error("Error searching keywords:", error);
          });
      } catch (error) {
        console.error("Error fetching keywords:", error);
      }
    };

    const addKeyword = (event, value) => {
      setSelectedKeywords(value);
    };

    useEffect(() => {
      if (searchTerm) {
        debounceSearch(searchTerm);
      }
    }, [searchTerm, debounceSearch]);

    const [direction, setDirection] = useState("ltr");

    // Function to determine the direction based on language code
    const getDirection = (lang) => {
      const rtlLanguages = ["urd", "arb"];
      return rtlLanguages.includes(lang) ? "rtl" : "ltr";
    };

    const handleContentChange = (value) => {
      setVerseTafseerData((prev) => ({
        ...prev,
        content: value,
      }));

      const lang = franc(value);
      const newDirection = getDirection(lang);
      setDirection(newDirection);
    };

    useEffect(() => {
      // Initial detection if needed, otherwise, it will change on input change
      handleContentChange(verseTafseerData?.content);
    }, [verseTafseerData?.content]);

    return (
      <Grid container spacing={1} sx={{ padding: 4 }}>
        <Grid item xs={12} style={{ position: "relative" }}>
          <div className="d-flex" id="comment-dialog-title">
            <Typography variant="h6">Manage Tafseer</Typography>
          </div>

          <Typography className="mt-2 arabic-text" variant="h5">
            {verse?.textIndoPak}
          </Typography>

          <div>
            <Grid container spacing={2}>
              {!hadithSearchVisible && (
                <Grid item xs={12} md={6}>
                  <Box>
                    <span className={classes.verse}>
                      {verseTafseerData?.verse}
                    </span>
                    <div className="row mt-4">
                      <div className="col-md-12 mb-4">
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => {
                            setHadithSearchVisible(true);
                          }}
                          startIcon={<FontAwesomeIcon icon="plus" />}
                        >
                          Link Hadith
                        </Button>
                      </div>

                      {verse?.hadiths?.length > 0 && (
                        <Card>
                          <CardContent>
                            {verse?.hadiths?.map((hadith, hadithCount) => (
                              <div>
                                {hadithCount + 1}.{" "}
                                <Typography variant="subtitle1">
                                  Hadith # {hadith?.conventionalId}{" "}
                                  {hadith?.hadithTranslation[0]?.translation}
                                </Typography>
                              </div>
                            ))}
                          </CardContent>
                        </Card>
                      )}

                      <div className="col-md-12 mt-4">
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => {
                            setLinkBookOpen(true);
                            setSelectedBook("");
                            fetchAllBooks();
                          }}
                          startIcon={<FontAwesomeIcon icon="plus" />}
                        >
                          Link Tafseer Book
                        </Button>
                      </div>

                      <div className="col-md-12 mt-4">
                        <Typography variant="h6">Tafseer Books</Typography>
                        <FormControl fullWidth>
                          {verseBooks && verseBooks?.length > 0 ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                gap: "10px",
                                marginTop: "15px",
                              }}
                            >
                              {verseBooks?.map((book) => (
                                <div
                                  key={book.id}
                                  onClick={() => handleVerseBookChange(book.id)}
                                  style={{
                                    padding: "10px",
                                    backgroundColor:
                                      verseTafseerData?.bookId === book.id
                                        ? "black"
                                        : "grey",
                                    cursor: "pointer",
                                  }}
                                >
                                  {book.name}
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="mt-2">No Data Found</div>
                          )}
                        </FormControl>
                      </div>

                      {verseTafseerData.bookId && (
                        <div className="col-md-12 mt-4">
                          <InputLabel id="demo-simple-select-standard-label">
                            Select Tafseer Section
                          </InputLabel>
                          <FormControl fullWidth>
                            {verseTafseerSections &&
                            verseTafseerSections?.length > 0 ? (
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={selectedSection}
                                onChange={(e) =>
                                  handleTafseerSectionChange(e.target.value)
                                }
                              >
                                {verseTafseerSections.map((section) => (
                                  <MenuItem key={section.id} value={section.id}>
                                    {section.sectionTitle}
                                  </MenuItem>
                                ))}
                              </Select>
                            ) : (
                              <div className="mt-2">No Data Found</div>
                            )}
                          </FormControl>
                        </div>
                      )}

                      {verseTafseerData.sectionTitle && (
                        <div className="col-md-12 mt-3">
                          <FormControl fullWidth>
                            <ReactQuill
                              value={verseTafseerData?.content}
                              onChange={(value) => handleContentChange(value)}
                              className="mt-4"
                              placeholder="Write your tafseer here..."
                              style={{ direction }}
                            />
                          </FormControl>
                        </div>
                      )}

                      {selectedBook && (
                        <div className="col-md-4 mt-5">
                          <Button
                            onClick={handleTafseerSectionOpen}
                            startIcon={<FontAwesomeIcon icon="plus" />}
                            variant="outlined"
                            color="secondary"
                          >
                            Create New Tafseer Section
                          </Button>
                          <Modal
                            open={isTopicOpen}
                            onClose={handleTopicClose}
                            closeAfterTransition
                          >
                            <div className={classes.modal}>
                              <FormControl fullWidth>
                                <TextField
                                  label={"Section Title"}
                                  value={newTafseerSectionTitle}
                                  onChange={(e) =>
                                    setNewTafseerSectionTitle(e.target.value)
                                  }
                                  variant="outlined"
                                  fullWidth
                                  autoFocus
                                />
                              </FormControl>

                              <Button
                                onClick={handleCreateTafseerSection}
                                variant="contained"
                                color="primary"
                                style={{ marginTop: "30px" }}
                              >
                                Create Section
                              </Button>
                            </div>
                          </Modal>
                        </div>
                      )}

                      {/* Keywords */}
                      <div className="col-md-12 mt-5">
                        <Typography variant="h6">Keywords</Typography>
                        <div className="col-md-12 mt-2">
                          <FormControl variant="standard" fullWidth={true}>
                            <Autocomplete
                              id="combo-box-demo"
                              variant="filled"
                              multiple
                              disableCloseOnSelect
                              options={allKeywords || []}
                              getOptionLabel={(option) => option.label || ""}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              sx={{ width: 300 }}
                              onChange={addKeyword}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Keywords"
                                  onChange={handleSearchTermChange}
                                  variant="outlined"
                                />
                              )}
                            />
                          </FormControl>
                        </div>
                        <div className="mt-4">
                          <InputLabel id="demo-simple-select-standard-label">
                            Tafseer Keywords:
                          </InputLabel>
                          <div>
                            {selectedKeywords?.map((keyword) => (
                              <Chip key={keyword.id} label={keyword.label} />
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Box>
                </Grid>
              )}

              {hadithSearchVisible && (
                <Box>
                  <Button
                    variant="text"
                    className="mt-4 mb-2"
                    onClick={() => setHadithSearchVisible(false)}
                  >
                    Back
                  </Button>
                  <SearchMain
                    modalView={true}
                    hasParentControl={true}
                    selectCallback={linkHadithWithVerse}
                  />
                </Box>
              )}

              {/* {verseTafseerData.tafseerTopic && <Grid item xs={12} md={12}>
                            <CreateHadithReference
                                topicId={verseTafseerData.tafseerTopic}
                                disabledModalView={() => setHadithSearchVisible(true)}
                                enableModalView={() => setHadithSearchVisible(false)}
                                refreshData={refreshTopicEvent}
                            />
                        </Grid>} */}

              {/* {verseTafseerData?.bookId && (
                <Grid item xs={12} md={6}>
                  <ManageBook
                    bookId={verseTafseerData?.bookId}
                    readOnly={true}
                  />
                </Grid>
              )} */}
            </Grid>
          </div>

          <div
            className="d-flex mt-4"
            sx={{ mt: "auto", backgroundColor: "background.paper" }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                setTafseerOpen(false);
                closeEvent();
              }}
            >
              Cancel
            </Button>

            <Button
              onClick={() => {
                submitVerseTafseer();
                console.log("Submit Tafseer");
              }}
              variant="outlined"
              disabled={
                !verseTafseerData.bookId ||
                !verseTafseerData.sectionTitle ||
                !selectedKeywords.length > 0
              }
            >
              Save
            </Button>
          </div>
        </Grid>

        <Dialog
          open={linkBookOpen}
          onClose={() => {
            setLinkBookOpen(false);
            setSelectedBook("");
          }}
          fullWidth
        >
          <DialogTitle id="comment-dialog-title">Link Book</DialogTitle>

          <DialogContent className="p-5">
            <div className="row">
              <div className="col-md-12">
                {loadingBooks ? (
                  <CircularProgress />
                ) : (
                  <FormControl fullWidth>
                    <InputLabel htmlFor="book-native-simple" shrink={true}>
                      Book
                    </InputLabel>
                    <Select
                      native
                      value={selectedBook}
                      onChange={handleBookChange}
                      displayEmpty
                      inputProps={{
                        name: "book",
                        id: "book-native-simple",
                      }}
                    >
                      <option aria-label="None" value="">
                        None
                      </option>
                      {allBooks.map((book) => (
                        <option key={book?.id} value={book?.id}>
                          {book?.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </div>
            </div>
          </DialogContent>

          <DialogActions
            sx={{ mt: "auto", backgroundColor: "background.paper" }}
          >
            <Button
              onClick={() => {
                setLinkBookOpen(false);
                setSelectedBook("");
              }}
            >
              Cancel
            </Button>
            <Button onClick={handleAddBook}>Add</Button>
          </DialogActions>
        </Dialog>

        <AlertM ref={notifications} />
      </Grid>
    );
  }
);

export default ManageTafseer;
