// @ts-nocheck
// LabelsManager.tsx
import React, { useState, useCallback, useRef, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import SearchIcon from "@mui/icons-material/Search";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import _ from "lodash";
import {
  post,
  getAllFiltered,
  patchData,
} from "../../../../../Services/GenericApiService";
import AlertM from "../../../../Helpers/AlertM/AlertM";
import LabelForm from "../../Labels/LabelForm/LabelForm";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardContent,
  Button,
  Typography,
  TextField,
  Chip,
  IconButton,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Grid,
  useTheme,
  alpha,
  Tooltip,
  Skeleton,
  Badge,
  Fade,
  Zoom,
  Collapse,
  Alert,
  useMediaQuery,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

const KeywordSearchResult = ({ group, onSelect, selected }) => {
  const theme = useTheme();
  const [hovered, setHovered] = useState(false);

  return (
    <Paper
      elevation={hovered || selected ? 3 : 1}
      sx={{
        p: 2,
        mb: 2,
        cursor: "pointer",
        border: selected ? `2px solid ${theme.palette.primary.main}` : "none",
        bgcolor: selected
          ? alpha(theme.palette.primary.main, 0.05)
          : "background.paper",
        transition: "all 0.2s ease",
        position: "relative",
        overflow: "hidden",
      }}
      onClick={() => onSelect(group)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {selected && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            bgcolor: theme.palette.primary.main,
            px: 1,
            py: 0.5,
            borderBottomLeftRadius: 8,
          }}
        >
          <CheckCircleIcon sx={{ color: "white", fontSize: "1rem" }} />
        </Box>
      )}

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" fontWeight={500}>
            Keyword Group
          </Typography>
        </Grid>

        {["nameEnglish", "nameUrdu", "nameArabic"].map(
          (field, index) =>
            group[field] &&
            group[field].length > 0 && (
              <Grid item xs={12} key={index}>
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{ display: "block", mb: 0.5 }}
                >
                  {field === "nameEnglish"
                    ? "English"
                    : field === "nameUrdu"
                    ? "Urdu"
                    : "Arabic"}
                </Typography>
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {group[field].slice(0, 5).map((keyword, i) => (
                    <Chip
                      key={i}
                      label={keyword}
                      size="small"
                      variant={selected ? "filled" : "outlined"}
                      color={selected ? "primary" : "default"}
                    />
                  ))}
                  {group[field].length > 5 && (
                    <Chip
                      size="small"
                      label={`+${group[field].length - 5} more`}
                      variant="outlined"
                    />
                  )}
                </Box>
              </Grid>
            )
        )}
      </Grid>
    </Paper>
  );
};

const LinkedKeywordChip = ({ label, onDelete, isEditMode }) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);

  // Count total keywords across all languages
  const totalKeywords =
    (label.nameEnglish?.length || 0) +
    (label.nameUrdu?.length || 0) +
    (label.nameArabic?.length || 0);

  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-block",
        m: 0.5,
      }}
    >
      <Paper
        sx={{
          px: 1.5,
          py: 0.75,
          borderRadius: 4,
          display: "inline-flex",
          alignItems: "center",
          bgcolor: expanded
            ? alpha(theme.palette.primary.main, 0.1)
            : "background.paper",
          border: `1px solid ${
            expanded ? theme.palette.primary.main : theme.palette.divider
          }`,
          boxShadow: expanded ? 2 : 1,
          transition: "all 0.2s ease",
          maxWidth: expanded ? "auto" : "250px",
          overflow: "hidden",
          "&:hover": {
            boxShadow: 3,
            bgcolor: alpha(theme.palette.primary.main, 0.05),
          },
        }}
        onClick={() => setExpanded(!expanded)}
      >
        <Box sx={{ mr: 1, display: "flex", alignItems: "center" }}>
          <Badge badgeContent={totalKeywords} color="primary" sx={{ mr: 1 }}>
            <LocalOfferIcon color="action" fontSize="small" />
          </Badge>
        </Box>

        {expanded ? (
          <Grid container spacing={1} sx={{ maxWidth: 400 }}>
            {["nameEnglish", "nameUrdu", "nameArabic"].map(
              (field, index) =>
                label[field] &&
                label[field].length > 0 && (
                  <Grid item xs={12} key={index}>
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      sx={{ display: "block" }}
                    >
                      {field === "nameEnglish"
                        ? "English"
                        : field === "nameUrdu"
                        ? "Urdu"
                        : "Arabic"}
                    </Typography>
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {label[field].map((keyword, i) => (
                        <Chip
                          key={i}
                          label={keyword}
                          size="small"
                          variant="outlined"
                          color="primary"
                        />
                      ))}
                    </Box>
                  </Grid>
                )
            )}
          </Grid>
        ) : (
          <Typography
            noWrap
            variant="body2"
            sx={{
              maxWidth: isEditMode ? "180px" : "220px",
              display: "inline-block",
            }}
          >
            {label.nameEnglish?.join(", ") || "Keyword Group"}
          </Typography>
        )}

        {isEditMode && (
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              onDelete(label);
            }}
            sx={{ ml: 1 }}
          >
            <DeleteIcon fontSize="small" color="error" />
          </IconButton>
        )}
      </Paper>
    </Box>
  );
};

const LabelsManager = ({
  hadithId,
  isAnyEditModeActive,
  onEditModeChange,
  linkedLabels: initialLinkedLabels,
  setLinkedLabels: parentSetLinkedLabels,
  hadithTranslationId,
  validationStatus,
  theme,
  toggleSection,
  activeSections,
}) => {
  const themeObj = useTheme();
  const isMobile = useMediaQuery(themeObj.breakpoints.down("md"));
  const [allLabels, setAllLabels] = useState([]);
  const [isLabelsEditMode, setIsLabelsEditMode] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [searchDialogOpen, setSearchDialogOpen] = useState(false);
  const [selectedLabelGroup, setSelectedLabelGroup] = useState(null);
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [labelData, setLabelData] = useState({
    nameEnglish: [],
    nameUrdu: [],
    nameArabic: [],
  });
  const [localIsActive, setLocalIsActive] = useState(false);
  const [isLinking, setIsLinking] = useState(false);

  // Initialize React Query client
  const queryClient = useQueryClient();

  // Alert ref
  const notifications = useRef();
  const { t } = useTranslation();

  const [confirmationDialog, setConfirmationDialog] = useState({
    open: false,
    type: "",
    label: null,
  });

  // Fetch linked labels using React Query
  const {
    data: linkedLabels = [],
    isLoading: isLoadingLabels,
    error: labelsError,
  } = useQuery({
    queryKey: ["linkedLabels", hadithId],
    queryFn: async () => {
      const url = `label?linkedHadithId=${hadithId}`;
      return await getAllFiltered(url);
    },
    enabled: !!hadithId,
    staleTime: 5 * 60 * 1000, // 5 minutes
    onSuccess: (data) => {
      // Update parent state if needed
      if (parentSetLinkedLabels) {
        parentSetLinkedLabels(data);
      }
    },
    onError: (error) => {
      console.error("Error fetching linked Hadith Keywords:", error);
      notifications?.current?.errorAlert(
        "Error",
        "Failed to fetch linked Hadith Keywords."
      );
    },
  });

  // Search labels mutation
  const handleSearch = async (value) => {
    if (!value) {
      setAllLabels([]);
      setNoResults(false);
      return;
    }

    setSearchTerm(value);
    setNoResults(false);

    try {
      const response = await getAllFiltered(
        `label?searchedLabel=${encodeURIComponent(value)}`
      );

      if (response.length > 0) {
        setAllLabels(response);

        // Pre-fill form data if we're creating a new group
        if (isCreatingNew) {
          setLabelData({
            nameEnglish: [value],
            nameUrdu: [],
            nameArabic: [],
          });
        }
      } else {
        setAllLabels([]);
        setNoResults(true);
        // Pre-fill for new keyword creation
        setLabelData({
          nameEnglish: [value],
          nameUrdu: [],
          nameArabic: [],
        });
      }
    } catch (error) {
      notifications?.current?.errorAlert(
        t("common.somethingWentWrong"),
        t("common.tryAgain")
      );
    }
  };

  // Link label mutation
  const linkLabelMutation = useMutation({
    mutationFn: (labelId) => {
      return post("label/link", {
        hadithId,
        labelId,
        hadithTranslationId: hadithTranslationId,
      });
    },
    onSuccess: () => {
      notifications?.current?.successAlert(
        "Success",
        "Hadith Keyword linked successfully."
      );
      // Invalidate the query to refresh the data
      queryClient.invalidateQueries({ queryKey: ["linkedLabels", hadithId] });
      setIsLinking(false);
    },
    onError: (error) => {
      console.error("Error linking keyword:", error);
      notifications?.current?.errorAlert(
        "Error",
        "Failed to link keyword. Please try again."
      );
      setIsLinking(false);
    },
  });

  // Unlink label mutation
  const unlinkLabelMutation = useMutation({
    mutationFn: (labelId) => {
      return post("label/unlink", {
        hadithId,
        labelId,
        hadithTranslationId: hadithTranslationId,
      });
    },
    onSuccess: () => {
      notifications?.current?.successAlert(
        "Success",
        "Hadith Keyword unlinked successfully."
      );
      // Invalidate the query to refresh the data
      queryClient.invalidateQueries({ queryKey: ["linkedLabels", hadithId] });
    },
    onError: (error) => {
      console.error("Error unlinking keyword:", error);
      notifications?.current?.errorAlert(
        "Error",
        "Failed to unlink keyword. Please try again."
      );
    },
  });

  // Add new label mutation
  const addLabelMutation = useMutation({
    mutationFn: (formData) => {
      return post("label", formData);
    },
    onSuccess: async (response, formData) => {
      notifications?.current?.successAlert(t("common.createSuccess"), "");

      if (response && response.id) {
        // Get the complete label data
        try {
          const labelResponse = await getAllFiltered(
            `label?searchedLabel=${encodeURIComponent(formData.nameEnglish[0])}`
          );
          if (labelResponse && labelResponse.length > 0) {
            // Ask to link the newly created label
            setConfirmationDialog({
              open: true,
              type: "link",
              label: labelResponse[0],
            });
          }
        } catch (error) {
          console.error("Error fetching new label:", error);
        }
      }

      setIsCreatingNew(false);
      setOpenDialog(false);
    },
    onError: (error) => {
      console.error("Error creating label:", error);
      notifications?.current?.errorAlert(
        t("common.somethingWentWrong"),
        t("common.tryAgain")
      );
    },
  });

  // Edit label mutation
  const editLabelMutation = useMutation({
    mutationFn: (formData) => {
      return patchData(`label/${formData.id}`, formData);
    },
    onSuccess: () => {
      notifications?.current?.successAlert(t("common.createSuccess"), "");
      // Invalidate any queries that might contain this label
      queryClient.invalidateQueries({ queryKey: ["linkedLabels"] });
      setOpenDialog(false);
    },
    onError: (error) => {
      console.error("Error editing label:", error);
      notifications?.current?.errorAlert(
        t("common.somethingWentWrong"),
        t("common.tryAgain")
      );
    },
  });

  // Determine correct active state
  useEffect(() => {
    const isActive =
      typeof activeSections === "boolean"
        ? activeSections
        : activeSections?.labels || false;
    setLocalIsActive(isActive);
  }, [activeSections]);

  // When toggling edit mode, ensure content is visible
  useEffect(() => {
    if (isLabelsEditMode && !localIsActive) {
      // If entering edit mode, ensure content is expanded
      toggleSection("labels");
      setLocalIsActive(true);
    }
  }, [isLabelsEditMode, localIsActive, toggleSection]);

  const addLabel = (labelToAdd) => {
    setConfirmationDialog({
      open: true,
      type: "link",
      label: labelToAdd,
    });
  };

  const deleteLabel = (label) => {
    setConfirmationDialog({
      open: true,
      type: "delete",
      label,
    });
  };

  const confirmAction = async () => {
    const { type, label } = confirmationDialog;

    try {
      setIsLinking(true);
      if (type === "link") {
        await linkLabelMutation.mutate(label.id);
      } else if (type === "delete") {
        await unlinkLabelMutation.mutate(label.id);
      }
    } finally {
      setConfirmationDialog({ open: false, type: "", label: null });
      // Reset linking state when operation completes (success or error)
      if (type !== "link") {
        setIsLinking(false);
      }
      // For "link" operations, the loader will be handled by the mutation callbacks
    }
  };

  const cancelAction = () => {
    setConfirmationDialog({ open: false, type: "", label: null });
    setIsLinking(false);
  };

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    debouncedSearch(newValue);
  };

  const debouncedSearch = useCallback(
    _.debounce((value) => {
      handleSearch(value);
    }, 1000),
    []
  );

  const handleAddNewLabel = () => {
    setLabelData({
      nameEnglish: searchTerm ? [searchTerm] : [],
      nameUrdu: [],
      nameArabic: [],
    });
    setIsCreatingNew(true);
    setOpenDialog(true);
    setSearchDialogOpen(false);
  };

  const openSearchDialog = () => {
    setSearchDialogOpen(true);
    setInputValue("");
    setSearchTerm("");
    setAllLabels([]);
    setNoResults(false);
    setSelectedLabelGroup(null);
  };

  const toggleEditMode = () => {
    // If we're turning on edit mode, make sure the section is expanded
    if (!isLabelsEditMode) {
      if (!localIsActive) {
        toggleSection("labels");
      }
    } else {
      // If we're turning off edit mode, close the section
      if (localIsActive) {
        toggleSection("labels");
      }
    }

    setIsLabelsEditMode(!isLabelsEditMode);
    onEditModeChange(!isLabelsEditMode);
  };

  const apiHandlers = {
    handleAddLabel: (formData) => {
      addLabelMutation.mutate(formData);
    },

    handleEditLabel: (formData) => {
      editLabelMutation.mutate(formData);
    },
  };

  // Check if a label is already linked
  const isLabelLinked = (labelId) => {
    return linkedLabels.some((label) => label.id === labelId);
  };

  // Determine if mutations are in progress
  const isOperationLoading =
    linkLabelMutation.isPending ||
    unlinkLabelMutation.isPending ||
    addLabelMutation.isPending ||
    editLabelMutation.isPending;

  // Determine if search is in progress
  const isSearching = searchTerm && allLabels.length === 0 && !noResults;

  // Check if we have any linked labels
  const hasLinkedLabels = linkedLabels && linkedLabels.length > 0;

  return (
    <Card
      variant="outlined"
      sx={{
        mb: 2,
        boxShadow: "0 4px 6px rgba(0,0,0,0.05)",
        borderColor: hasLinkedLabels ? "success.light" : "error.light",
        borderWidth: 1,
        position: "relative",
        transition: "all 0.2s ease",
        "&:hover": {
          boxShadow: "0 6px 10px rgba(0,0,0,0.1)",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          p: 2,
          borderBottom: "1px solid",
          borderColor: "divider",
          cursor: "pointer",
          bgcolor:
            theme.palette.mode === "dark"
              ? "rgba(255,255,255,0.05)"
              : "rgba(0,0,0,0.03)",
          transition: "background-color 0.2s ease",
          "&:hover": {
            bgcolor:
              theme.palette.mode === "dark"
                ? "rgba(255,255,255,0.08)"
                : "rgba(0,0,0,0.05)",
          },
        }}
        onClick={() => !isLabelsEditMode && toggleSection("labels")} // Only toggle if not in edit mode
      >
        <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
          {hasLinkedLabels ? (
            <CheckCircleOutlineIcon color="success" sx={{ mr: 1 }} />
          ) : (
            <ErrorOutlineIcon color="error" sx={{ mr: 1 }} />
          )}
          <Typography variant="h6">Hadith Keywords</Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          {isLabelsEditMode && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<SearchIcon />}
              onClick={openSearchDialog}
              size={isMobile ? "small" : "medium"}
              sx={{ mr: 1 }}
            >
              Find & Link Keywords
            </Button>
          )}

          <Button
            variant={"outlined"}
            disabled={isAnyEditModeActive && !isLabelsEditMode}
            onClick={toggleEditMode}
            color={isLabelsEditMode ? "secondary" : "primary"}
            size={isMobile ? "small" : "medium"}
            startIcon={isLabelsEditMode ? null : <EditIcon />}
          >
            {isLabelsEditMode ? "Close" : "Edit"}
          </Button>

          {!isLabelsEditMode &&
            (localIsActive ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
        </Box>
      </Box>

      <Collapse in={localIsActive || isLabelsEditMode}>
        <CardContent sx={{ p: 3, position: "relative" }}>
          {/* Loading overlay for linking operations */}
          {isLinking && (
            <Fade in={isLinking}>
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  bgcolor: "rgba(255,255,255,0.7)",
                  zIndex: 5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backdropFilter: "blur(2px)",
                }}
              >
                <Paper
                  elevation={3}
                  sx={{
                    p: 2,
                    display: "flex",
                    alignItems: "center",
                    borderRadius: 2,
                  }}
                >
                  <CircularProgress size={24} sx={{ mr: 1 }} />
                  <Typography variant="body2">
                    Linking keyword group...
                  </Typography>
                </Paper>
              </Box>
            </Fade>
          )}

          {!hasLinkedLabels && (
            <Alert severity="warning" sx={{ mb: 3 }}>
              Please link at least one keyword group to this hadith
            </Alert>
          )}

          {isLoadingLabels ? (
            <Box sx={{ mt: 1 }}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Loading keyword groups...
              </Typography>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                <Skeleton variant="rounded" width={180} height={32} />
                <Skeleton variant="rounded" width={140} height={32} />
                <Skeleton variant="rounded" width={200} height={32} />
              </Box>
            </Box>
          ) : labelsError ? (
            <Box sx={{ p: 3, textAlign: "center", color: "error.main" }}>
              <Typography>Error loading keywords. Please try again.</Typography>
              <Button
                sx={{ mt: 2 }}
                variant="outlined"
                onClick={() =>
                  queryClient.invalidateQueries({
                    queryKey: ["linkedLabels", hadithId],
                  })
                }
              >
                Retry
              </Button>
            </Box>
          ) : hasLinkedLabels ? (
            <Fade in={true} timeout={500}>
              <Box>
                <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 500 }}>
                  Linked Keyword Groups ({linkedLabels.length})
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    mt: 1,
                    p: 2,
                    bgcolor:
                      themeObj.palette.mode === "dark"
                        ? "rgba(255,255,255,0.03)"
                        : "rgba(0,0,0,0.02)",
                    borderRadius: 1,
                  }}
                >
                  {linkedLabels.map((label) => (
                    <LinkedKeywordChip
                      key={label.id}
                      label={label}
                      onDelete={deleteLabel}
                      isEditMode={isLabelsEditMode}
                    />
                  ))}
                </Box>
              </Box>
            </Fade>
          ) : (
            <Box
              sx={{
                py: 4,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                bgcolor:
                  themeObj.palette.mode === "dark"
                    ? "rgba(255,255,255,0.03)"
                    : "rgba(0,0,0,0.02)",
                borderRadius: 1,
              }}
            >
              <LocalOfferIcon
                sx={{ fontSize: 40, color: "text.secondary", mb: 2 }}
              />
              <Typography color="text.secondary" variant="body1" mb={1}>
                No keyword groups linked to this hadith yet
              </Typography>
              {isLabelsEditMode && (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<SearchIcon />}
                  onClick={openSearchDialog}
                  sx={{ mt: 1 }}
                >
                  Find & Link Keywords
                </Button>
              )}
            </Box>
          )}
        </CardContent>
      </Collapse>

      {/* Search Dialog */}
      <Dialog
        open={searchDialogOpen}
        onClose={() => setSearchDialogOpen(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            overflow: "hidden",
          },
        }}
      >
        <DialogTitle
          sx={{
            borderBottom: `1px solid ${themeObj.palette.divider}`,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            bgcolor: themeObj.palette.primary.main,
            color: "white",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <SearchIcon sx={{ mr: 1.5 }} />
            Find & Link Keyword Groups
          </Box>
          <IconButton
            edge="end"
            onClick={() => setSearchDialogOpen(false)}
            aria-label="close"
            sx={{ color: "white" }}
            disabled={isLinking}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers sx={{ p: 3 }}>
          <Box sx={{ mb: 3 }}>
            <TextField
              fullWidth
              label="Search keywords"
              variant="outlined"
              value={inputValue}
              onChange={handleInputChange}
              placeholder="Type to search for keyword groups..."
              disabled={isLinking}
              InputProps={{
                startAdornment: (
                  <SearchIcon sx={{ mr: 1, color: "text.secondary" }} />
                ),
                endAdornment: isSearching ? (
                  <CircularProgress size={20} />
                ) : null,
              }}
            />
          </Box>

          {!searchTerm && !noResults && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                py: 6,
              }}
            >
              <SearchIcon
                sx={{ fontSize: 48, color: "text.secondary", mb: 2 }}
              />
              <Typography variant="body1" color="text.secondary">
                Enter a keyword to search
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                You can search by English, Urdu, or Arabic keywords
              </Typography>
            </Box>
          )}

          {isSearching ? (
            <Box sx={{ py: 4 }}>
              <Skeleton variant="rounded" height={100} sx={{ mb: 2 }} />
              <Skeleton variant="rounded" height={100} sx={{ mb: 2 }} />
              <Skeleton variant="rounded" height={100} />
            </Box>
          ) : (
            <>
              {noResults && (
                <Box
                  sx={{
                    p: 3,
                    textAlign: "center",
                    bgcolor: alpha(themeObj.palette.warning.light, 0.1),
                    borderRadius: 1,
                    border: `1px solid ${alpha(
                      themeObj.palette.warning.main,
                      0.2
                    )}`,
                    mb: 3,
                  }}
                >
                  <ErrorOutlineIcon
                    sx={{
                      fontSize: 40,
                      color: themeObj.palette.warning.main,
                      mb: 1,
                    }}
                  />
                  <Typography variant="h6">
                    No matching keyword groups found
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2 }}
                  >
                    Would you like to create a new keyword group with "
                    {searchTerm}"?
                  </Typography>
                  <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={handleAddNewLabel}
                    disabled={isLinking}
                  >
                    Create New Group
                  </Button>
                </Box>
              )}

              {allLabels.length > 0 && (
                <Box>
                  <Typography variant="subtitle1" gutterBottom sx={{ ml: 1 }}>
                    Found {allLabels.length} matching keyword group
                    {allLabels.length !== 1 ? "s" : ""}
                  </Typography>

                  {allLabels.map((group) => (
                    <Zoom
                      in={true}
                      key={group.id}
                      style={{ transitionDelay: "100ms" }}
                    >
                      <Box>
                        <KeywordSearchResult
                          group={group}
                          onSelect={setSelectedLabelGroup}
                          selected={selectedLabelGroup?.id === group.id}
                        />
                      </Box>
                    </Zoom>
                  ))}
                </Box>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ px: 3, py: 2 }}>
          <Button
            onClick={() => setSearchDialogOpen(false)}
            variant="outlined"
            disabled={isLinking}
          >
            Cancel
          </Button>
          {allLabels.length > 0 && selectedLabelGroup && (
            <LoadingButton
              loading={isOperationLoading || isLinking}
              loadingPosition="start"
              startIcon={<LinkIcon />}
              variant="contained"
              onClick={() => {
                addLabel(selectedLabelGroup);
                setSearchDialogOpen(false);
              }}
              disabled={isLabelLinked(selectedLabelGroup.id) || isLinking}
              color={
                isLabelLinked(selectedLabelGroup.id) ? "secondary" : "primary"
              }
            >
              {isLabelLinked(selectedLabelGroup.id)
                ? "Already Linked"
                : "Link Selected Group"}
            </LoadingButton>
          )}
        </DialogActions>
      </Dialog>

      {/* Add/Edit new keyword */}
      <Dialog
        open={openDialog}
        onClose={() => {
          if (!isLinking) {
            setOpenDialog(false);
            setIsCreatingNew(false);
          }
        }}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            overflow: "hidden",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            bgcolor: themeObj.palette.primary.main,
            color: "white",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <AddIcon sx={{ mr: 1.5 }} />
            {isCreatingNew ? "Create New Keyword Group" : "Edit Keyword Group"}
          </Box>
          <IconButton
            edge="end"
            onClick={() => {
              if (!isLinking) {
                setOpenDialog(false);
                setIsCreatingNew(false);
              }
            }}
            aria-label="close"
            sx={{ color: "white" }}
            disabled={isLinking}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <LabelForm
            handleAddEvent={apiHandlers.handleAddLabel}
            handleEditEvent={apiHandlers.handleEditLabel}
            handleCancelEvent={() => {
              if (!isLinking) {
                setOpenDialog(false);
                setIsCreatingNew(false);
              }
            }}
            isLoading={isOperationLoading || isLinking}
            isEditMode={labelData.id ? true : false}
            recordToUpdate={labelData}
          />
        </DialogContent>
      </Dialog>

      {/* Confirmation Dialog */}
      <Dialog
        open={confirmationDialog.open}
        onClose={!isLinking ? cancelAction : undefined}
        PaperProps={{
          sx: {
            borderRadius: 2,
            overflow: "hidden",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            bgcolor:
              confirmationDialog.type === "delete"
                ? alpha(themeObj.palette.error.main, 0.1)
                : alpha(themeObj.palette.primary.main, 0.1),
          }}
        >
          {confirmationDialog.type === "link" ? (
            <>
              <LinkIcon
                sx={{ mr: 1.5, color: themeObj.palette.primary.main }}
              />
              Link Keyword Group
            </>
          ) : (
            <>
              <LinkOffIcon
                sx={{ mr: 1.5, color: themeObj.palette.error.main }}
              />
              Unlink Keyword Group
            </>
          )}
        </DialogTitle>
        <DialogContent sx={{ pt: 3 }}>
          <DialogContentText>
            {confirmationDialog.type === "link"
              ? `Are you sure you want to link this keyword group to the current hadith?`
              : `Are you sure you want to unlink this keyword group from the current hadith?`}
          </DialogContentText>

          {confirmationDialog.label && (
            <Paper
              variant="outlined"
              sx={{
                mt: 2,
                p: 2,
                bgcolor: alpha(themeObj.palette.background.default, 0.5),
              }}
            >
              {["nameEnglish", "nameUrdu", "nameArabic"].map(
                (field, index) =>
                  confirmationDialog.label[field] &&
                  confirmationDialog.label[field].length > 0 && (
                    <Box key={index} sx={{ mb: index !== 2 ? 1.5 : 0 }}>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ display: "block" }}
                      >
                        {field === "nameEnglish"
                          ? "English"
                          : field === "nameUrdu"
                          ? "Urdu"
                          : "Arabic"}
                      </Typography>
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {confirmationDialog.label[field].map((keyword, i) => (
                          <Chip
                            key={i}
                            label={keyword}
                            size="small"
                            variant="outlined"
                            color={
                              confirmationDialog.type === "link"
                                ? "primary"
                                : "default"
                            }
                          />
                        ))}
                      </Box>
                    </Box>
                  )
              )}
            </Paper>
          )}
        </DialogContent>
        <DialogActions sx={{ px: 3, py: 2 }}>
          <Button
            onClick={cancelAction}
            variant="outlined"
            disabled={isLinking}
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={confirmAction}
            loading={isOperationLoading || isLinking}
            loadingPosition="start"
            startIcon={
              confirmationDialog.type === "link" ? (
                <LinkIcon />
              ) : (
                <LinkOffIcon />
              )
            }
            variant="contained"
            color={confirmationDialog.type === "link" ? "primary" : "error"}
            disabled={isLinking && confirmationDialog.type !== "link"}
          >
            {confirmationDialog.type === "link" ? "Link" : "Unlink"}
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {/* Alerts */}
      <AlertM ref={notifications} />

      {/* Edit mode indicator */}
      {isLabelsEditMode && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "4px",
            height: "100%",
            bgcolor: "primary.main",
            borderTopLeftRadius: "4px",
            borderBottomLeftRadius: "4px",
          }}
        />
      )}
    </Card>
  );
};

export default LabelsManager;
