import { ThemeProvider, createTheme } from "@mui/material/styles";
import { alpha, styled } from "@mui/material";
import { RootStateOrAny, useSelector } from "react-redux";

export const getTheme = (theme: any) => {
  const darkTheme = createTheme({
    palette: {
      mode: theme,
      primary: {
        main: "rgba(130,154,177, 0.9)",
        contrastText: "#fff", //button text white instead of black to support dark theme
      },
    },
    components: {
      // MuiTextField: {
      //     defaultProps: {
      //       variant: 'filled', // Set all TextFields to be filled by default
      //     },
      //   },
      // Override styles for InputLabel
      MuiInputLabel: {
        // styleOverrides: {
        //   root: {
        //     // Apply styles to make the label shrink by default
        //     '&.Mui-focused': {
        //       transform: 'translate(12px, 10px) scale(0.75)',
        //     },
        //   },
        // },
      },
      MuiCard: {
        styleOverrides: {},
      },
      MuiModal: {
        styleOverrides: {
          root: {
            // transitionDuration: '0ms', // Disable the transition animation
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            autoComplete: "off",
            //   'label + &': {
            //         marginTop: 3,
            //   },
            //   borderRadius: 6,
            //   position: 'relative',
            //   backgroundColor: '#F3F6F9',
            //   border: 'none',
            //   fontSize: 14,
            // overflow: 'hidden',
            fontSize: "14px", // Set the desired font size,
            marginTop: "0",
            marginBottom: "0",
            "&:hover": {
              border: "none",
              // borderColor: '#F3F6F9'
            },
            "&:focused": {
              border: "none",
              // boxShadow: `${alpha("rgba(9,9,121,0.9)", 0.25)} 0 0 0 2px`,
              // borderColor: "rgba(9,9,121,0.1)",
              // shrink: true,
            },
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true, // No more ripple, on the whole application 💣!
        },
      },

      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            padding: "10px 16px",
            fontWeight: "bold",
            transition: "background-color 0.1s ease", // Adjust transition speed
            "&:hover": {},
          },
          contained: {
            "&:hover": {
              color: "white",
            },
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {},
        },
      },
      MuiListItemText: {
        styleOverrides: {
          root: {
            fontSize: "12px", // Adjust the font size as per your requirement
            fontWeight: "bold", // Make the text bold
          },
        },
      },
    },
  });
  return darkTheme;
};
