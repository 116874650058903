// @ts-nocheck
// HadithAttributesManager.tsx
import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  CardContent,
  Box,
  Typography,
  Button,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  FormControlLabel,
  Alert,
  Collapse,
  useMediaQuery,
  CircularProgress,
  Chip,
  Fade,
  ButtonGroup,
  Tooltip,
  Paper,
  RadioGroup,
  Radio,
  useTheme,
} from "@mui/material";
import { patch } from "../../../../Services/GenericApiService";
import { log } from "../../../../Services/LoggerService";
import { languages } from "../Hadith/HadithType/HadithTranslationMain/languagesMeta";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import StatusIcon from "@mui/icons-material/Verified";
import SahihIcon from "@mui/icons-material/ThumbUp";
import ZaeefIcon from "@mui/icons-material/ThumbDown";

const HadithAttributesManager = ({
  recordToUpdate,
  hadithBookData,
  isAnyEditModeActive,
  editModes,
  toggleEditMode,
  selectedStatus,
  setSelectedStatus,
  validationStatus,
  register,
  watch,
  errors,
  getValues,
  setValue,
  setLoader,
  notifications,
  t,
  theme,
  toggleSection,
  activeSections,
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const localTheme = useTheme();

  // State to track original values for change detection
  const [originalValues, setOriginalValues] = useState({
    status: "",
    hadithbookId: "",
    conventionalId: "",
    language: "",
  });

  // State to track if there are unsaved changes
  const [hasChanges, setHasChanges] = useState(false);

  // Numbered input ref for focus management
  const conventionalIdRef = useRef(null);

  // Store original values when entering edit mode
  useEffect(() => {
    if (editModes.attributes) {
      setOriginalValues({
        status: selectedStatus,
        hadithbookId: watch("hadithbookId"),
        conventionalId: watch("conventionalId"),
        language: watch("language"),
      });
    }
  }, [editModes.attributes]);

  // Check for changes on watch values
  useEffect(() => {
    if (editModes.attributes) {
      const currentValues = {
        status: selectedStatus,
        hadithbookId: watch("hadithbookId"),
        conventionalId: watch("conventionalId"),
        language: watch("language"),
      };

      const changed =
        currentValues.status !== originalValues.status ||
        currentValues.hadithbookId !== originalValues.hadithbookId ||
        currentValues.conventionalId !== originalValues.conventionalId ||
        currentValues.language !== originalValues.language;

      setHasChanges(changed);
    }
  }, [
    selectedStatus,
    watch("hadithbookId"),
    watch("conventionalId"),
    watch("language"),
  ]);

  // Update hadith attributes
  const updateHadithAttributes = async () => {
    const data = getValues();

    let attributes = {
      hadithId: recordToUpdate.hadithId,
      status: selectedStatus,
      hadithbookId: data.hadithbookId,
      conventionalId: data.conventionalId,
      language: data.language,
    };

    setIsSubmitting(true);
    setLoader((prevState) => ({ ...prevState, modal: true }));

    try {
      await patch("hadith/attributes", data.id, attributes);
      setLoader((prevState) => ({ ...prevState, modal: false }));
      toggleEditMode("attributes");

      // Show success indicator
      setSaveSuccess(true);
      setTimeout(() => setSaveSuccess(false), 3000);

      notifications.current.successAlert(t("common.editSuccess"), "");

      // If accordion is open, close it
      if (activeSections.attributes) {
        toggleSection("attributes");
      }
    } catch (error) {
      log(error);
      setLoader((prevState) => ({ ...prevState, modal: false }));
      notifications.current.errorAlert(
        t("common.somethingWentWrong"),
        t("common.tryAgain")
      );
    } finally {
      setIsSubmitting(false);
      setHasChanges(false);
    }
  };

  // Cancel editing and restore original values
  const cancelEditing = () => {
    // Restore original values
    setSelectedStatus(originalValues.status);
    setValue("hadithbookId", originalValues.hadithbookId);
    setValue("conventionalId", originalValues.conventionalId);
    setValue("language", originalValues.language);

    // Exit edit mode
    toggleEditMode("attributes");

    // Close accordion if open
    if (activeSections.attributes) {
      toggleSection("attributes");
    }
  };

  // Check if attributes are valid
  const areAttributesValid = () => {
    return (
      selectedStatus &&
      selectedStatus !== "" &&
      watch("conventionalId") &&
      watch("conventionalId") !== "" &&
      watch("hadithbookId") &&
      watch("hadithbookId") !== ""
    );
  };

  // Handle focus for better UX
  const handleFocus = (event) => {
    event.target.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  // Handle edit mode toggle with accordion sync
  const handleEditToggle = () => {
    const newEditMode = !editModes.attributes;

    // If turning on edit mode and accordion is closed, open it
    if (newEditMode && !activeSections.attributes) {
      toggleSection("attributes");
    }

    toggleEditMode("attributes");
  };

  return (
    <Card
      variant="outlined"
      sx={{
        mb: 2,
        boxShadow: "0 4px 6px rgba(0,0,0,0.05)",
        borderColor: areAttributesValid() ? "success.light" : "error.light",
        borderWidth: 1,
        position: "relative",
        transition: "all 0.2s ease",
        "&:hover": {
          boxShadow: "0 6px 10px rgba(0,0,0,0.1)",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          p: 2,
          borderBottom: "1px solid",
          borderColor: "divider",
          cursor: "pointer",
          bgcolor:
            localTheme.palette.mode === "dark"
              ? "rgba(255,255,255,0.05)"
              : "rgba(0,0,0,0.03)",
          transition: "background-color 0.2s ease",
          "&:hover": {
            bgcolor:
              localTheme.palette.mode === "dark"
                ? "rgba(255,255,255,0.08)"
                : "rgba(0,0,0,0.05)",
          },
        }}
        onClick={() => toggleSection("attributes")}
      >
        <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
          {areAttributesValid() ? (
            <CheckCircleOutlineIcon color="success" sx={{ mr: 1 }} />
          ) : (
            <ErrorOutlineIcon color="error" sx={{ mr: 1 }} />
          )}
          <Typography variant="h6">Attributes</Typography>

          {editModes.attributes && (
            <Chip
              size="small"
              label="Editing Mode"
              color="primary"
              variant="outlined"
              sx={{ ml: 2 }}
            />
          )}

          {saveSuccess && (
            <Fade in={saveSuccess} timeout={300}>
              <Chip
                label="Saved successfully"
                color="success"
                size="small"
                icon={<CheckCircleOutlineIcon />}
                sx={{ ml: 2 }}
              />
            </Fade>
          )}

          {/* Status badge display */}
          {selectedStatus && (
            <Chip
              size="small"
              label={selectedStatus === "sahih" ? "Sahih" : "Zaeef"}
              color={selectedStatus === "sahih" ? "success" : "warning"}
              icon={selectedStatus === "sahih" ? <SahihIcon /> : <ZaeefIcon />}
              sx={{ ml: 2, fontWeight: "medium" }}
            />
          )}
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          {editModes.attributes ? (
            <ButtonGroup
              variant="contained"
              size={isMobile ? "small" : "medium"}
              sx={{ ml: 1 }}
            >
              <Button
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  updateHadithAttributes();
                }}
                disabled={isSubmitting || !areAttributesValid() || !hasChanges}
                startIcon={
                  isSubmitting ? (
                    <CircularProgress size={16} color="inherit" />
                  ) : (
                    <SaveIcon />
                  )
                }
              >
                {isSubmitting ? "Saving..." : "Save"}
              </Button>
              <Button
                color="secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  cancelEditing();
                }}
              >
                Cancel
              </Button>
            </ButtonGroup>
          ) : (
            <Button
              variant="outlined"
              disabled={isAnyEditModeActive && !editModes.attributes}
              onClick={(e) => {
                e.stopPropagation();
                handleEditToggle();
              }}
              color="primary"
              size={isMobile ? "small" : "medium"}
              startIcon={<EditIcon />}
            >
              Edit
            </Button>
          )}

          {activeSections.attributes ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Box>
      </Box>

      <Collapse in={activeSections.attributes} timeout={300}>
        <CardContent>
          {!areAttributesValid() && (
            <Alert severity="warning" sx={{ mb: 3 }}>
              Please complete all required attribute fields
            </Alert>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              flexWrap: "wrap",
              gap: 2,
            }}
          >
            {/* Language Selection */}
            <FormControl
              sx={{
                width: isMobile ? "100%" : "30%",
                minWidth: 200,
              }}
              variant="outlined"
              error={!!errors?.language}
            >
              <InputLabel id="language-select-label">
                Hadith Language
              </InputLabel>
              {languages.length > 0 ? (
                <Select
                  labelId="language-select-label"
                  id="language-select"
                  label="Hadith Language"
                  disabled={!editModes.attributes}
                  error={!!errors?.language}
                  {...register("language", { required: true })}
                  value={watch("language") || ""}
                  MenuProps={{
                    PaperProps: {
                      sx: { maxHeight: 300 },
                    },
                  }}
                >
                  {languages.map((language) => (
                    <MenuItem key={language.key} value={language.key}>
                      {language.label}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                <>
                  <Select disabled label="Hadith Language">
                    <MenuItem value="">No Data</MenuItem>
                  </Select>
                  <FormHelperText>No languages available</FormHelperText>
                </>
              )}
              {errors?.language && (
                <FormHelperText>Language is required</FormHelperText>
              )}
            </FormControl>

            {/* Hadith Book Selection */}
            <FormControl
              sx={{
                width: isMobile ? "100%" : "30%",
                minWidth: 200,
              }}
              variant="outlined"
              error={!!errors?.hadithbookId}
            >
              <InputLabel id="hadith-book-select-label">Hadith Book</InputLabel>
              {hadithBookData.length > 0 ? (
                <Select
                  labelId="hadith-book-select-label"
                  id="hadith-book-select"
                  label="Hadith Book"
                  disabled={!editModes.attributes}
                  error={!!errors?.hadithbookId}
                  {...register("hadithbookId", { required: true })}
                  value={watch("hadithbookId") || ""}
                  MenuProps={{
                    PaperProps: {
                      sx: { maxHeight: 300 },
                    },
                  }}
                >
                  {hadithBookData.map((book) => (
                    <MenuItem key={book.id} value={book.id}>
                      {book.name}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                <>
                  <Select disabled label="Hadith Book">
                    <MenuItem value="">No Data</MenuItem>
                  </Select>
                  <FormHelperText>No books available</FormHelperText>
                </>
              )}
              {(errors?.hadithbookId || !watch("hadithbookId")) && (
                <FormHelperText error>Hadith Book is required</FormHelperText>
              )}
            </FormControl>

            {/* Hadith Number */}
            <FormControl
              sx={{
                width: isMobile ? "100%" : "15%",
                minWidth: 120,
              }}
            >
              <TextField
                label="Hadith No."
                disabled={!editModes.attributes}
                type="number"
                variant="outlined"
                error={!!errors?.conventionalId}
                onWheel={(event) => event.target.blur()}
                onFocus={handleFocus}
                inputRef={conventionalIdRef}
                {...register("conventionalId", { required: true })}
                value={watch("conventionalId") || ""}
                helperText={
                  errors?.conventionalId ? "Hadith No. is required" : ""
                }
                InputProps={{
                  sx: {
                    bgcolor: editModes.attributes
                      ? localTheme.palette.mode === "dark"
                        ? "rgba(255,255,255,0.03)"
                        : "rgba(0,0,0,0.01)"
                      : "transparent",
                  },
                }}
              />
            </FormControl>

            {/* System ID */}
            <FormControl
              sx={{
                width: isMobile ? "100%" : "15%",
                minWidth: 120,
              }}
            >
              <TextField
                label="System ID"
                disabled
                type="number"
                variant="outlined"
                {...register("id", { required: true })}
                value={watch("id") || ""}
              />
            </FormControl>
          </Box>

          {/* Hadith Status */}
          <Box
            sx={{
              mt: 3,
              p: 2,
              borderRadius: 1,
              bgcolor:
                localTheme.palette.mode === "dark"
                  ? "rgba(255,255,255,0.03)"
                  : "rgba(0,0,0,0.01)",
              border: "1px solid",
              borderColor: "divider",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                mb: 2,
                display: "flex",
                alignItems: "center",
                fontWeight: "medium",
              }}
            >
              <StatusIcon sx={{ mr: 1 }} /> Hadith Status
            </Typography>

            <RadioGroup
              row
              value={selectedStatus || ""}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              <Paper
                variant="outlined"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  p: 1,
                  borderRadius: 2,
                  mb: 1,
                  mr: 2,
                  bgcolor:
                    selectedStatus === "sahih"
                      ? "success.light"
                      : "transparent",
                  borderColor:
                    selectedStatus === "sahih" ? "success.main" : "divider",
                  transition: "all 0.2s ease",
                  cursor: editModes.attributes ? "pointer" : "default",
                  opacity: editModes.attributes ? 1 : 0.8,
                }}
                onClick={() =>
                  editModes.attributes && setSelectedStatus("sahih")
                }
              >
                <FormControlLabel
                  value="sahih"
                  control={
                    <Radio
                      disabled={!editModes.attributes}
                      checked={selectedStatus === "sahih"}
                      color="success"
                    />
                  }
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <SahihIcon color="success" sx={{ mr: 1 }} />
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight:
                            selectedStatus === "sahih" ? "bold" : "normal",
                          color:
                            selectedStatus === "sahih"
                              ? "text.primary"
                              : "text.secondary",
                        }}
                      >
                        Sahih
                      </Typography>
                    </Box>
                  }
                />
              </Paper>

              <Paper
                variant="outlined"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  p: 1,
                  borderRadius: 2,
                  mb: 1,
                  bgcolor:
                    selectedStatus === "zaeef"
                      ? "warning.light"
                      : "transparent",
                  borderColor:
                    selectedStatus === "zaeef" ? "warning.main" : "divider",
                  transition: "all 0.2s ease",
                  cursor: editModes.attributes ? "pointer" : "default",
                  opacity: editModes.attributes ? 1 : 0.8,
                }}
                onClick={() =>
                  editModes.attributes && setSelectedStatus("zaeef")
                }
              >
                <FormControlLabel
                  value="zaeef"
                  control={
                    <Radio
                      disabled={!editModes.attributes}
                      checked={selectedStatus === "zaeef"}
                      color="warning"
                    />
                  }
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <ZaeefIcon color="warning" sx={{ mr: 1 }} />
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight:
                            selectedStatus === "zaeef" ? "bold" : "normal",
                          color:
                            selectedStatus === "zaeef"
                              ? "text.primary"
                              : "text.secondary",
                        }}
                      >
                        Zaeef
                      </Typography>
                    </Box>
                  }
                />
              </Paper>
            </RadioGroup>

            {(!selectedStatus || selectedStatus === "") && (
              <FormHelperText error>Status is required</FormHelperText>
            )}
          </Box>
        </CardContent>
      </Collapse>

      {/* Edit mode indicator */}
      {editModes.attributes && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "4px",
            height: "100%",
            bgcolor: "primary.main",
            borderTopLeftRadius: "4px",
            borderBottomLeftRadius: "4px",
          }}
        />
      )}
    </Card>
  );
};

export default HadithAttributesManager;
