// @ts-nocheck

import React from 'react';
import { useStyles } from "./Styles";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { Button, Card, CardContent, IconButton, Typography, Paper } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { useTranslation } from "react-i18next";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import CardActions from "@mui/material/CardActions";
import DataDisplayM from "../../../../Helpers/DataDisplayM/DataDisplayM";

// font awesome stuff
const iconList = Object.keys(Icons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon) => Icons[icon]);

library.add(...iconList);

function HadithTranslationLogsView({ data = null }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const sections = [
    {
      title: "Basic Information",
      fields: [
        { label: "Hadith Id", value: data?.hadithId },
        { label: t("hadithbook.author"), value: data?.author },
        { label: "Conventional Id", value: data?.conventionalId },
        { label: "Language", value: data?.language },
        { label: "Status", value: data?.status },
      ]
    },
    {
      title: "Hadith References",
      fields: [
        { 
          label: "Keywords",
          value: data?.hadithKeywords?.length > 0 
            ? data.hadithKeywords.map(kw => kw.rootWord).join(", ")
            : "No keywords"
        },
        {
          label: "Narrators",
          value: data?.hadithNarrators?.length > 0
            ? data.hadithNarrators.map(n => n.name).join(", ")
            : "No narrators"
        },
        {
          label: "Verses",
          value: data?.hadithVerses?.length > 0
            ? data.hadithVerses.map(v => v.textImlaeiSimple).join(", ")
            : "No Verses"
        },
      ]
    },
    {
      title: "Status",
      fields: [
        { label: "To Be Reviewed", value: data?.toBeReviewed ? "Yes" : "No" },
        { label: "To Be QC", value: data?.toBeQC ? "Yes" : "No" },
        { label: "Review Rejected", value: data?.reviewRejected ? "Yes" : "No" },
        { label: "QC Rejected", value: data?.qcRejected ? "Yes" : "No" },
        { label: "To Be Approved", value: data?.toBeApproved ? "Yes" : "No" },
        { label: "Verified", value: data?.verified ? "Yes" : "No" },
      ]
    },
    {
      title: "User Information",
      fields: [
        { label: "Added By User ID", value: data?.addedByUserId },
        { label: "Approved By User ID", value: data?.approvedByUserId },
        { label: "Reviewed By User ID", value: data?.reviewedByUserId },
        { label: "QC By User ID", value: data?.qcByUserId },
      ]
    },
    {
      title: "Dates",
      fields: [
        { label: "Added Date", value: data?.addedDate ? new Date(data.addedDate).toLocaleDateString() : "N/A" },
        { label: "Approved Date", value: data?.approvedDate ? new Date(data.approvedDate).toLocaleDateString() : "N/A" },
        { label: "Reviewed Date", value: data?.reviewedDate ? new Date(data.reviewedDate).toLocaleDateString() : "N/A" },
        { label: "QC Date", value: data?.qcDate ? new Date(data.qcDate).toLocaleDateString() : "N/A" },
      ]
    },
    {
      title: "Translation",
      fields: [
        { value: data?.translation },
      ]
    },
    {
      title: "Comments",
      fields: [
        { 
          value: data?.comments ? data.comments.split('\n').join(', ') : "No comments" 
        },
      ]
    }
  ];

  return (
    <div>
      <Tooltip title={t("common.view")}>
        <IconButton aria-label="view" size="small" onClick={handleOpen}>
          <FontAwesomeIcon icon="eye" />
        </IconButton>
      </Tooltip>

      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
      >
        <Fade in={open}>
          <Box className={classes.modal}>
            <Card sx={{ width: "75vw", maxHeight: "90vh", overflowY: "auto" }}>
              <CardContent>
                <div className="py-4 px-md-4">
                  <Typography variant="h5" className="mb-4">
                    {t("common.recordDetails")}
                  </Typography>

                  {sections.map((section, index) => (
                    <Card key={index} variant="outlined" className="mb-4">
                      <CardContent>
                        <Typography variant="overline" className="mb-3 d-block" sx={{ fontWeight: 'bold' }}>
                          {section.title}
                        </Typography>
                        <div className="row">
                          {section.fields.map((field, fieldIndex) => (
                            <div 
                              key={fieldIndex} 
                              className={
                                section.title === "Translation" || section.title === "Comments" 
                                  ? "col-md-12 mb-3"
                                  : "col-md-3 mb-3"
                              }
                            >
                              <DataDisplayM 
                                label={field.label} 
                                data={field.value} 
                              />
                            </div>
                          ))}
                        </div>
                      </CardContent>
                    </Card>
                  ))}
                </div>
              </CardContent>
              <CardActions>
                <div className="pb-3 px-md-4" style={{ width: "100%", textAlign: "right" }}>
                  <Button variant="outlined" onClick={handleClose}>
                    {'Cancle'}
                  </Button>
                </div>
              </CardActions>
            </Card>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default HadithTranslationLogsView;
