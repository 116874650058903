import React, { useEffect } from "react";
import {
  Toolbar,
  IconButton,
  Typography,
  styled,
  useTheme,
  Box,
  FormControlLabel,
  Switch,
  Chip,
  useScrollTrigger,
  Slide,
  Avatar,
  Divider,
  useMediaQuery,
  Badge,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { collapse } from "./Actions";
import { expand } from "../Sider/Actions";
import { switchTheme } from "../../../Global/Actions";
import "../../../index.scss";
import { getUserData } from "../../../Services/LocalStorageService";

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

// Function to hide header on scroll
function HideOnScroll(props: any) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    threshold: 10, // Added threshold for smoother transition
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

// Styled AppBar with smooth transitions
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin", "box-shadow"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.standard,
  }),
  boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 2px 0px",
  backdropFilter: "blur(8px)",
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(18, 18, 18, 0.9)"
      : "rgba(255, 255, 255, 0.8)",
  color: theme.palette.mode === "dark" ? "#fff" : "#333",
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard,
    }),
  }),
}));

// Modern theme toggle switch
const ThemeToggleSwitch = styled(Switch)(({ theme }) => ({
  width: 42,
  height: 24,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(18px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#4f6bed" : "#1976d2",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 20,
    height: 20,
  },
  "& .MuiSwitch-track": {
    borderRadius: 24 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

// User avatar with first letter
const UserAvatar = styled(Avatar)(({ theme }) => ({
  width: 36,
  height: 36,
  fontSize: "1rem",
  fontWeight: 600,
  backgroundColor: theme.palette.mode === "dark" ? "#4f6bed" : "#1976d2",
  transition: "all 0.3s ease",
  "&:hover": {
    transform: "scale(1.05)",
    boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
  },
}));

// Email chip with modern styling
const StyledChip = styled(Chip)(({ theme }) => ({
  borderRadius: 20,
  fontWeight: 500,
  fontSize: "0.75rem",
  height: 24,
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, 0.12)"
      : "rgba(0, 0, 0, 0.08)",
  color: theme.palette.mode === "dark" ? "#fff" : "#333",
  transition: "all 0.3s ease",
  "&:hover": {
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, 0.2)"
        : "rgba(0, 0, 0, 0.12)",
  },
}));

function Header({ mobileView = false }) {
  const navigate = useNavigate();
  const currentUser: any = getUserData();
  const materialTheme = useTheme();
  const isMobile = useMediaQuery(materialTheme.breakpoints.down("sm"));

  // Redux
  const header = useSelector((state: RootStateOrAny) => state.header);

  const sider = useSelector((state: RootStateOrAny) => state.sider);

  const theme = useSelector(
    (state: RootStateOrAny) => state.global.global.theme
  );

  // Dispatcher
  const dispatch = useDispatch();

  // UI vars
  const [open, setOpen] = React.useState(header.state.isExpanded);
  const [activeTheme, setActiveTheme] = React.useState(theme);

  // Get user initials for avatar
  const getUserInitials = () => {
    const firstName = currentUser?.firstName || "";
    const lastName = currentUser?.lastName || "";

    const firstInitial =
      firstName.length > 0 ? firstName.charAt(0).toUpperCase() : "";
    const lastInitial =
      lastName.length > 0 ? lastName.charAt(0).toUpperCase() : "";

    return firstInitial + lastInitial;
  };

  // Hooks
  useEffect(() => {
    setOpen(!header.state.isExpanded);
  }, [header]);

  useEffect(() => {
    setOpen(sider.state.isExpanded);
  }, [sider]);

  useEffect(() => {
    dispatch(switchTheme(activeTheme));
  }, [activeTheme]);

  // UI toggles
  const handleDrawerOpen = () => {
    dispatch(collapse()); //header collapse state change
    dispatch(expand()); //sider expand state change
  };

  const toggleTheme = () => {
    setActiveTheme(activeTheme === "light" ? "dark" : "light");
  };

  return (
    <React.Fragment>
      <HideOnScroll>
        <AppBar position="fixed" open={mobileView ? false : open} elevation={0}>
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
              minHeight: "64px",
            }}
          >
            {/* Left section with menu and title */}
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 2,
                  ...(open && { display: "none" }),
                  transition: "all 0.2s",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.08)",
                  },
                }}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                variant="h6"
                component="div"
                sx={{
                  fontWeight: 600,
                  letterSpacing: "0.5px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {process.env.REACT_APP_PROJECT_NAME}
              </Typography>
            </Box>

            {/* Right section with theme toggle and user info */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: isMobile ? 1 : 2,
              }}
            >
              {/* Theme toggle */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor:
                    materialTheme.palette.mode === "dark"
                      ? "rgba(255,255,255,0.05)"
                      : "rgba(0,0,0,0.03)",
                  borderRadius: "24px",
                  padding: "4px 12px",
                  marginRight: isMobile ? 0 : 1,
                }}
              >
                {!isMobile && (
                  <Typography
                    variant="caption"
                    sx={{ marginRight: 1, fontWeight: 500 }}
                  >
                    {activeTheme === "dark" ? "Dark" : "Light"}
                  </Typography>
                )}
                <IconButton
                  size="small"
                  onClick={toggleTheme}
                  color="inherit"
                  sx={{
                    padding: 0.5,
                    backgroundColor: "transparent",
                    "&:hover": { backgroundColor: "rgba(0,0,0,0.08)" },
                  }}
                >
                  {activeTheme === "dark" ? (
                    <DarkModeOutlinedIcon fontSize="small" />
                  ) : (
                    <LightModeOutlinedIcon fontSize="small" />
                  )}
                </IconButton>
              </Box>

              {/* Divider between theme toggle and user info */}
              {!isMobile && (
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ height: 24, my: "auto" }}
                />
              )}

              {/* User info with avatar and name */}
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                {!isMobile && (
                  <Box sx={{ textAlign: "right", marginRight: 1 }}>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontWeight: 600,
                        lineHeight: 1.2,
                      }}
                    >
                      {currentUser?.firstName || ""}{" "}
                      {currentUser?.lastName || ""}
                    </Typography>
                    <StyledChip size="small" label={currentUser.email} />
                  </Box>
                )}
                <UserAvatar>{getUserInitials()}</UserAvatar>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </React.Fragment>
  );
}

export default Header;
