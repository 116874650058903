//@ts-nocheck
import React, { useRef, useEffect } from "react";
import { useStyles } from "./Styles";
import { patch } from "../../../../../Services/GenericApiService";
import { useTranslation } from "react-i18next";
import { log } from "../../../../../Services/LoggerService";
import {
  Card,
  CardContent,
  CardActions,
  FormControl,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Button } from "@mui/material";
import AlertM from "../../../../Helpers/AlertM/AlertM";
import { useForm } from "react-hook-form";

function SubKeywordUpdate({
  refreshDataEvent,
  recordToUpdate,
  resetEditState,
}) {
  // css for module
  const classes = useStyles();

  // module(s) for api
  const moduleMain = "subkeyword";

  // translation
  const { t } = useTranslation();

  // child ref for alert
  const notifications = useRef();

  // ui handlers
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isLoading, setLoading] = React.useState(false);

  // form data
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (_data: any) => {
    setLoading(true); // show loader
    patch(moduleMain, recordToUpdate.id, _data)
      .then((_res) => {
        setLoading(false); // hide loader
        refreshDataEvent(); // refresh data
        handleClose();
        resetEditState();
        // @ts-ignore
        notifications.current.successAlert(t("common.editSuccess"), "");
      })
      .catch((_err) => {
        log(_err);
        setLoading(false); // hide loader
        // @ts-ignore
        notifications.current.errorAlert(
          t("common.somethingWentWrong"),
          t("common.tryAgain")
        );
      });
  };

  // handle cancel event
  const handleCancelEvent = () => {
    handleClose();
    resetEditState();
  };

  // hooks
  useEffect(() => {
    if (recordToUpdate) {
      reset(recordToUpdate);
    }
  }, [recordToUpdate, reset]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className={classes.modal}>
            <form
              className="row px-1 px-md-0"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Card>
                <CardContent>
                  <div className="py-2 px-md-4 px-2">
                    <div className="row mx-2">
                      <div className="col-md-12 my-2">
                        <h4 className="pb-2">{t("common.edit")} SubKeyword</h4>
                      </div>

                      <div className="col-md-12 mt-4">
                        <FormControl fullWidth>
                          <TextField
                            id="versePlacementInput"
                            {...register("versePlacement", { required: true })}
                            label={"Verse Placement"}
                            type={"text"}
                            error={!!errors.versePlacement}
                            variant="outlined"
                            className="full-width"
                          />
                        </FormControl>
                      </div>

                      <div className="col-md-12 mt-4">
                        <FormControl fullWidth>
                          <TextField
                            id="transliterationInput"
                            {...register("transliteration", { required: true })}
                            label={"Transliteration"}
                            type={"text"}
                            error={!!errors.transliteration}
                            variant="outlined"
                            className="full-width"
                          />
                        </FormControl>
                      </div>

                      <div className="col-md-12 mt-4">
                        <FormControl fullWidth>
                          <TextField
                            id="meaningInput"
                            {...register("meaning", { required: true })}
                            label={"Meaning"}
                            type={"text"}
                            error={!!errors.meaning}
                            variant="outlined"
                            className="full-width"
                          />
                        </FormControl>
                      </div>

                      <div className="col-md-12 mt-4">
                        <FormControl fullWidth>
                          <TextField
                            id="verseInput"
                            {...register("verse", { required: true })}
                            label={"Verse"}
                            type={"text"}
                            multiline
                            minRows={4}
                            error={!!errors.verse}
                            variant="outlined"
                            className="full-width"
                          />
                        </FormControl>
                      </div>

                      <div className="col-md-12 mt-4">
                        <FormControl fullWidth>
                          <TextField
                            id="grammaticalNotationInput"
                            {...register("grammaticalNotation", {
                              required: true,
                            })}
                            label={"Grammatical Notation"}
                            type={"text"}
                            error={!!errors.grammaticalNotation}
                            variant="outlined"
                            className="full-width"
                          />
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </CardContent>
                <CardActions>
                  <div
                    className="pb-4 pt-2 px-md-4 px-2 "
                    style={{ width: "100%", textAlign: "right" }}
                  >
                    <Button variant="outlined" onClick={handleCancelEvent}>
                      {t("common.cancel")}
                    </Button>
                    &nbsp;&nbsp;
                    <LoadingButton
                      loading={isLoading}
                      type="submit"
                      variant="contained"
                      className="bg-custom-gradient"
                    >
                      {t("common.edit")}
                    </LoadingButton>
                    &nbsp;
                  </div>
                </CardActions>
              </Card>
            </form>
          </Box>
        </Fade>
      </Modal>

      {/* Alerts */}
      <AlertM ref={notifications} />
    </div>
  );
}

export default SubKeywordUpdate;
