import React, { useRef } from 'react';
import { useStyles } from './Styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { get } from '../../../../Services/GenericApiService';

import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Header from "../../../Layout/Header/Header";
import Sider from "../../../Layout/Sider/Sider";

import { getAll, getById, post, patch, deleteById, getAllHadith } from "../../../../Services/GenericApiService";
import { log } from "../../../../Services/LoggerService";
import { getUserData } from "../../../../Services/LocalStorageService";
import { useEffect } from 'react';
import { CSVLink } from "react-csv";

import {
    Button, IconButton, Skeleton, Card, CardContent, Typography, CardActions, CircularProgress,
} from "@mui/material";
import Logs from './Logs/Logs';


function Home() {

    const analytics = 'analytics/dailyrecords'

    const dateFormat: any = { day: 'numeric', month: 'short', year: 'numeric' }

    const classes = useStyles();
    const [loading, setLoading] = React.useState(false)
    const [logsLoading, setLogsLoading] = React.useState(false)
    const [csvData, setCsvData] = React.useState([]);
    const [userRole, setUserRole] = React.useState("");
    const moduleMain = 'hadith';

    const [data, setData] = React.useState({})

    useEffect(() => {
        const currentUser: any = getUserData()
        setUserRole(currentUser.roleKey)
        // handleDownload();
    }, [])

    // const handleDownload = () => {
    //     setLoading(true); // show loader
    //     getAllHadith(moduleMain, 0, 0, false, false, false, false, true, false, "sahih", 0, null)
    //         .then((_res: any) => {
    //             // set all data
    //             const selectedColumns = _res.map(({ conventionalId, translation, language, addedDate }: any) =>
    //             ({
    //                 conventionalId,
    //                 translation: translation.replace(/"/g, '""'),
    //                 language,
    //                 addedDate
    //             }));

    //             setCsvData(selectedColumns);
    //             setLoading(false); // hide loader
    //         })
    //         .catch(_err => {
    //             log(_err)
    //             setLoading(false); // hide loader
    //         })
    // }


    return (
        <>
            <Box sx={{ display: 'flex', minHeight: '99vh', backgroundColor: 'rgba(222, 223, 223, 0.3)' }}>
                <CssBaseline />

                <Header />

                <Sider />


                <Box component="main" sx={{ flexGrow: 2, p: 3 }} mt={8}>

                    <h4 className="mx-2 px-3">Dashboard</h4>

                    {(userRole === 'admin' || userRole === 'manager' || userRole === 'teacher' || userRole === 'student') && <>
                        <div className="d-flex flex-row-reverse px-3">
                            {!loading && csvData.length > 0 && (
                                <CSVLink
                                    data={csvData}
                                    headers={['Hadith ID', 'Translation', 'Language Code', 'Date Added']}
                                    filename={'Hadith Records ' + new Date().toLocaleDateString('en-US', dateFormat)}
                                    style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <Button variant='text' startIcon={<FontAwesomeIcon icon='cloud-download' />}>
                                        Download Data
                                    </Button>
                                </CSVLink>
                            )}
                            {/* {loading &&
                                <Button variant='text'
                                    disabled={true}
                                    startIcon={<CircularProgress size="0.8em" />}
                                >
                                    Download Data
                                </Button>} */}
                        </div>

                        <Logs loading={logsLoading} data={data} />
                    </>
                    }
                </Box>
            </Box>
        </>
    );
}

export default Home;
