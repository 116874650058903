import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MUIDataTable from "mui-datatables";
import { getAllFiltered } from "../../../../../Services/GenericApiService";
import AlertM from "../../../../Helpers/AlertM/AlertM";
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import CssBaseline from "@mui/material/CssBaseline";
import Button from "@mui/material/Button";
import { log } from "../../../../../Services/LoggerService";
import { Link } from "react-router-dom";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function KeywordGroups() {
  const notifications = React.useRef(null);
  const { groupId } = useParams<{ groupId: string }>();
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const [hadiths, setHadiths] = useState([]);
  const [verses, setVerses] = useState([]);
  const [tafseer, setTafseer] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    fetchData(newValue);
  };

  const fetchData = (tabIndex: number) => {
    setLoading(true);
    let url = "";
    if (tabIndex === 0) {
      url = `/keyword/groups/hadith/${groupId}`;
    } else if (tabIndex === 1) {
      url = `/keyword/groups/verses/${groupId}`;
    } else if (tabIndex === 2) {
      url = `/keyword/groups/tafseer/${groupId}`;
    }

    getAllFiltered(url)
      .then((response: any) => {
        if (tabIndex === 0) {
          setHadiths(response.rows);
        } else if (tabIndex === 1) {
          setVerses(response.rows);
        } else if (tabIndex === 2) {
          setTafseer(response.rows);
        }
        setLoading(false);
      })
      .catch((error) => {
        log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData(0);
  }, [groupId]);

  const columnsHadith = [
    { name: "id", label: "Resource ID" },
    { name: "conventionalId", label: "ID" },
    { name: "status", label: "Status" },
  ];

  const columnsVerses = [
    { name: "id", label: "ID" },
    { name: "juzNumber", label: "Juzz" },
    { name: "verseNumber", label: "Verse No." },
    { name: "textUthmani", label: "Text" },
  ];

  const columnsTafseer = [
    { name: "id", label: "ID" },
    { name: "sectionTitle", label: "Tafseer Section" },
  ];

  const options: any = {
    search: true,
    download: true,
    print: true,
    viewColumns: true,
    filter: true,
    responsive: "simple",
    selectableRowsHideCheckboxes: true,
    sort: true,
    textLabels: {
      body: {
        noMatch: loading ? t("common.loading") : t("common.noData"),
      },
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "99vh",
        backgroundColor: "rgba(222, 223, 223, 0.3)",
      }}
    >
      <CssBaseline />
      <Header />
      <Sider />

      <Box component="main" sx={{ flexGrow: 1, p: 3 }} mt={10}>
        <div className="px-1 px-md-5">
          <div className="row mb-3">
            <div className="col-md-10">
              <Typography variant="h4">{t("module.keywordGroups")}</Typography>
            </div>
            <div className="col-md-2 d-flex justify-content-end">
              <Button component={Link} to="/keyword" variant="contained">
                {t("common.back")}
              </Button>
            </div>
          </div>

          <Box sx={{ width: "100%" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label={t("common.hadiths")} {...a11yProps(0)} />
              <Tab label={t("common.verses")} {...a11yProps(1)} />
              <Tab label={t("common.tafseer")} {...a11yProps(2)} />
            </Tabs>
            <TabPanel value={value} index={0}>
              {hadiths.length > 0 ? (
                <MUIDataTable
                  title={t("common.hadiths")}
                  data={hadiths}
                  columns={columnsHadith}
                  options={options}
                />
              ) : (
                <Typography variant="body1">{t("common.noData")}</Typography>
              )}
            </TabPanel>
            <TabPanel value={value} index={1}>
              {verses.length > 0 ? (
                <MUIDataTable
                  title={t("common.verses")}
                  data={verses}
                  columns={columnsVerses}
                  options={options}
                />
              ) : (
                <Typography variant="body1">{t("common.noData")}</Typography>
              )}
            </TabPanel>
            <TabPanel value={value} index={2}>
              {tafseer.length > 0 ? (
                <MUIDataTable
                  title={t("common.tafseer")}
                  data={tafseer}
                  columns={columnsTafseer}
                  options={options}
                />
              ) : (
                <Typography variant="body1">{t("common.noData")}</Typography>
              )}
            </TabPanel>
          </Box>

          <AlertM ref={notifications} />
        </div>
      </Box>
    </Box>
  );
}
