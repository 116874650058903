//@ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import "../../../../../../index.scss";
import Header from "../../../../../Layout/Header/Header";
import Sider from "../../../../../Layout/Sider/Sider";
import { getById, post } from "../../../../../../Services/GenericApiService";
import { useTranslation } from "react-i18next";
import AlertM from "../../../../../Helpers/AlertM/AlertM";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import MUIDataTable from "mui-datatables";
import { useNavigate, useParams } from "react-router-dom";
import HadithDetailsModal from "../HadithDetailModal/HadithDetailModal";
import SearchMain from "../../../Search/SearchMain/SearchMain";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import { log } from "../../../../../../Services/LoggerService";

function HadithGroupDetails() {
  const { t } = useTranslation();
  const notifications = useRef();
  const navigate = useNavigate();
  const { groupId } = useParams<{ groupId: string }>();

  const [groupDetails, setGroupDetails] = useState<any>({});
  const [hadiths, setHadiths] = useState<any[]>([]);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [loading, setLoading] = useState(true);
  const [selectedHadith, setSelectedHadith] = useState<any>(null);
  const [openHadithModal, setOpenHadithModal] = useState(false);
  const [openSearchModal, setOpenSearchModal] = useState(false); // State to control the search modal

  const fetchGroupDetails = () => {
    if (!groupId) {
      return;
    }
    setLoading(true);
    getById("hadithGroup", groupId)
      .then((response: any) => {
        setGroupDetails(response);
        setHadiths(response);
        setTotalRowCount(response?.length);
        setLoading(false);
      })
      .catch((error) => {
        log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchGroupDetails();
  }, [groupId, currentPage, pageSize]);

  const handleHadithClick = (dataIndex: number) => {
    setSelectedHadith(hadiths[dataIndex]);
    setOpenHadithModal(true);
  };

  const handleLinkHadith = (hadith) => {
    // Link the hadith to the group
    post("hadithgroup/addHadith", {
      hadithGroupId: groupId,
      hadithId: hadith.id,
    })
      .then(() => {
        fetchGroupDetails();
        setOpenSearchModal(false);
        notifications.current.successAlert(t("common.linkSuccess"), "");
      })
      .catch((error) => {
        log(error);
        notifications.current.errorAlert(
          t("hadithgroups.linkFail"),
          t("common.tryAgain")
        );
      });
  };

  const columns = [
    { name: "id", label: "ID" },
    { name: "conventionalId", label: "Conventional ID" },
    { name: "status", label: "Status" },
    { name: "createdAt", label: "Created At" },
    { name: "updatedAt", label: "Updated At" },
    {
      name: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellHeaderProps: () => ({ align: "right" }),
        setCellProps: () => ({ align: "right" }),
        customBodyRenderLite: (dataIndex: any) => (
          <Button
            variant="contained"
            onClick={() => handleHadithClick(dataIndex)}
          >
            {t("common.view")}
          </Button>
        ),
      },
    },
  ];

  const options: any = {
    search: true,
    download: true,
    print: true,
    viewColumns: true,
    filter: true,
    filterType: "textField",
    responsive: "simple",
    selectableRowsHideCheckboxes: true,
    sort: true,
    onChangePage: (currentPage: any) => setCurrentPage(currentPage),
    onChangeRowsPerPage: (numberOfRows: any) => setPageSize(numberOfRows),
    page: currentPage,
    count: totalRowCount,
    rowsPerPageOptions: [25, 50, 75, 100],
    rowsPerPage: pageSize,
    textLabels: {
      body: {
        noMatch: loading ? "Loading..." : t("common.noData"),
      },
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "99vh",
        backgroundColor: "rgba(222, 223, 223, 0.3)",
      }}
    >
      <CssBaseline />

      {/* Header */}
      <Header />

      {/* Sider */}
      <Sider />

      {/* Content */}
      <Box component="main" sx={{ flexGrow: 2, p: 3 }} mt={10}>
        <div className="px-1 px-md-5">
          <div className="row mb-3">
            <div className="col-md-10">
              <Typography variant="h4">{groupDetails.name}</Typography>
            </div>
            <div className="col-md-2 d-flex justify-content-end">
              <Button
                variant="contained"
                onClick={() => navigate("/hadithGroups")}
                sx={{ mr: 1 }}
              >
                {t("common.back")}
              </Button>
              <Button
                variant="contained"
                onClick={() => setOpenSearchModal(true)} // Open the search modal
              >
                {t("common.addHadith")}
              </Button>
            </div>
          </div>

          <Paper elevation={12}>
            {hadiths?.length > 0 ? (
              <MUIDataTable
                title={t("common.hadiths")}
                data={hadiths.map((hadith) => ({
                  id: hadith.id,
                  conventionalId: hadith.conventionalId,
                  status: hadith.status,
                  createdAt: new Date(hadith.createdAt).toLocaleString(),
                  updatedAt: new Date(hadith.updatedAt).toLocaleString(),
                }))}
                columns={columns}
                options={options}
              />
            ) : (
              <Typography variant="body1" sx={{ p: 3 }}>
                {t("common.noHadithsAttached")}
              </Typography>
            )}
          </Paper>

          {selectedHadith && (
            <HadithDetailsModal
              open={openHadithModal}
              onClose={() => setOpenHadithModal(false)}
              hadith={selectedHadith}
            />
          )}

          <Modal
            open={openSearchModal}
            onClose={() => setOpenSearchModal(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
          >
            <Fade in={openSearchModal}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: { xs: "90%", sm: "90%", md: "80%" },
                  maxHeight: "90vh",
                  overflowY: "auto",
                  bgcolor: "background.paper",
                  border: "2px solid #000",
                  boxShadow: 24,
                  p: 4,
                }}
              >
                <div className="d-flex justify-content-between mb-3">
                  <Typography variant="h6">
                    {t("common.searchHadith")}
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setOpenSearchModal(false)}
                  >
                    {t("common.cancel")}
                  </Button>
                </div>
                <SearchMain
                  modalView={true}
                  hasParentControl={true}
                  selectCallback={handleLinkHadith}
                />
              </Box>
            </Fade>
          </Modal>

          <AlertM ref={notifications} />
        </div>
      </Box>
    </Box>
  );
}

export default HadithGroupDetails;
