// VerseReferenceManager.jsx - Patched Component
// @ts-nocheck
import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  CardContent,
  Button,
  Typography,
  CircularProgress,
  Chip,
  Box,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Collapse,
  Alert,
  useTheme,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AlertM from "../../../../Helpers/AlertM/AlertM";
import {
  post,
  getAllFiltered,
  patchData,
  getAll,
} from "../../../../../Services/GenericApiService";
import VersesReadView from "../../QuranTranslation/VersesReadView/VersesReadView";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import LinkIcon from "@mui/icons-material/Link";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import DeleteIcon from "@mui/icons-material/Delete";

const VerseReferenceManager = ({
  hadithId,
  isAnyEditModeActive,
  toggleEditMode,
  linkedKeywords,
  hadithTranslationId,
  theme,
  toggleSection,
  activeSections,
  // New props for better data flow
  linkedVerses = [],
  setLinkedVerses = () => {}, // Default to no-op function if not provided
}) => {
  const [editMode, setEditMode] = useState(false);
  const [verses, setVerses] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [isFetchingKeywords, setIsFetchingKeywords] = useState(false);
  const [loader, setLoader] = useState(false);
  const [keywordVerses, setKeywordVerses] = useState([]);
  const [selectedVerses, setSelectedVerses] = useState([]);
  const [showVerseDialog, setShowVerseDialog] = useState(false);
  const [activeKeyword, setActiveKeyword] = useState(null);
  const [openManualLinkDialog, setOpenManualLinkDialog] = useState(false);
  const [allChapters, setAllChapters] = useState([]);
  const [chaptersLoading, setChaptersLoading] = useState(false);
  const [selectedChapter, setSelectedChapter] = useState("");
  const [hasInitialized, setHasInitialized] = useState(false);

  const themeObj = useTheme();
  const isMobile = useMediaQuery(themeObj.breakpoints.down("md"));
  const notifications = useRef(null);

  // On initial mount, set local state from parent if available
  useEffect(() => {
    if (!hasInitialized && linkedVerses && linkedVerses.length > 0) {
      setVerses(linkedVerses);
      setHasInitialized(true);
    }
  }, [linkedVerses, hasInitialized]);

  // Fetch linked verses for the specified Hadith ID
  const fetchLinkedVerses = async () => {
    try {
      setLoader(true);
      const url = `verse/linked?linkedToHadithId=${hadithId}`;
      const response = await getAllFiltered(url);

      // Update local state
      setVerses(response || []);

      // Always update parent state for validation
      setLinkedVerses(response || []);
    } catch (error) {
      console.error("Error fetching linked verses:", error);
      notifications.current?.errorAlert(
        "Error",
        "Failed to fetch linked verses."
      );
    } finally {
      setLoader(false);
    }
  };

  // Fetch verses linked to a specific keyword
  const fetchKeywordVerses = async (keywordId, keywordLabel) => {
    try {
      setLoader(true);
      const url = `verse/linkedToKeyword/${keywordId}`;
      const response = await getAllFiltered(url);
      setKeywordVerses(response || []);
      setActiveKeyword(keywordLabel);
      setShowVerseDialog(true);
    } catch (error) {
      console.error("Error fetching keyword verses:", error);
      notifications.current?.errorAlert(
        "Error",
        "Failed to fetch verses linked to the keyword."
      );
    } finally {
      setLoader(false);
    }
  };

  // Handle selecting a verse
  const handleSelectVerse = (verseId) => {
    setSelectedVerses((prev) =>
      prev.includes(verseId)
        ? prev.filter((id) => id !== verseId)
        : [...prev, verseId]
    );
  };

  // Handle selecting all verses
  const handleSelectAllVerses = () => {
    if (selectedVerses.length === keywordVerses.length) {
      setSelectedVerses([]);
    } else {
      setSelectedVerses(keywordVerses.map((verse) => verse.id));
    }
  };

  // Handle API call to link selected verses to the Hadith (bulk)
  const processSelectedVerses = async () => {
    try {
      setLoader(true);

      const payload = {
        verseIds: selectedVerses,
        hadithId,
        hadithTranslationId: hadithTranslationId,
      };
      await post(`versehadith/bulk-link`, payload);

      notifications.current?.successAlert(
        "Success",
        "Selected verses linked to the Hadith successfully."
      );

      // Fetch updated verses and update parent state
      await fetchLinkedVerses();

      setShowVerseDialog(false);
      setSelectedVerses([]);
    } catch (error) {
      console.error("Error linking selected verses:", error);
      notifications.current?.errorAlert(
        "Error",
        "Failed to link selected verses."
      );
    } finally {
      setLoader(false);
    }
  };

  // Handle single verse linking from VerseReadView
  const linkQuranVerseToHadith = async (verseId) => {
    try {
      setOpenManualLinkDialog(false);
      setLoader(true);
      const payload = {
        verseId,
        hadithId,
        hadithTranslationId: hadithTranslationId,
      };
      await post(`versehadith/link`, payload);

      notifications.current?.successAlert(
        "Success",
        "Verse linked to Hadith successfully."
      );

      // Fetch updated verses and update parent state
      await fetchLinkedVerses();
    } catch (error) {
      console.error("Error linking single verse:", error);
      notifications.current?.errorAlert(
        "Error",
        "Failed to link verse to the Hadith."
      );
    } finally {
      setLoader(false);
    }
  };

  // Remove a single verse reference
  const RemoveVerseReference = async (verseId) => {
    const verseReference = {
      verseId,
      hadithId,
      hadithTranslationId: hadithTranslationId,
    };

    setLoader(true);
    patchData("versehadith/link", verseReference)
      .then((_res) => {
        setLoader(false);
        if (_res.code === 200) {
          notifications.current?.successAlert(
            "Verse Link Removed Successfully"
          );

          // Fetch updated verses and update parent state
          fetchLinkedVerses();
        } else {
          notifications.current?.errorAlert("Verse Link Not Removed");
        }
      })
      .catch((_err) => {
        console.log(_err);
        notifications.current?.errorAlert("Something went wrong", "Try again");
        setLoader(false);
      });
  };

  // Fetch list of chapters
  const fetchQuranChapters = async () => {
    try {
      setChaptersLoading(true);
      const chapters = await getAll("chapter");
      setAllChapters(chapters || []);
    } catch (error) {
      console.error("Error fetching chapters:", error);
      notifications.current?.errorAlert(
        "Error",
        "Failed to fetch Quran chapters."
      );
    } finally {
      setChaptersLoading(false);
    }
  };

  // Handle edit mode toggle with syncing between local and parent state
  const handleEditToggle = () => {
    const newEditMode = !editMode;
    setEditMode(newEditMode);
    toggleEditMode("verseReference");

    // If turning on edit mode and accordion is closed, open it
    if (newEditMode && !activeSections?.verseReferences) {
      toggleSection("verseReferences");
    }
  };

  // Fetch data when component mounts or hadithId changes
  useEffect(() => {
    if (hadithId) {
      // Only fetch if we don't already have data from parent
      if (linkedVerses.length === 0) {
        fetchLinkedVerses();
      } else {
        // Use parent data if available
        setVerses(linkedVerses);
      }
    }
  }, [hadithId]);

  // Check if there are verses linked to this hadith (either locally or from parent)
  const hasLinkedVerses =
    (verses && verses.length > 0) || (linkedVerses && linkedVerses.length > 0);

  return (
    <Card
      variant="outlined"
      sx={{
        mb: 2,
        boxShadow: "0 4px 6px rgba(0,0,0,0.05)",
        borderColor: hasLinkedVerses ? "success.light" : "error.light",
        borderWidth: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          p: 2,
          borderBottom: "1px solid",
          borderColor: "divider",
          cursor: "pointer",
          bgcolor:
            theme.palette.mode === "dark"
              ? "rgba(255,255,255,0.05)"
              : "rgba(0,0,0,0.03)",
        }}
        onClick={() => toggleSection("verseReferences")}
      >
        <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
          {hasLinkedVerses ? (
            <CheckCircleOutlineIcon color="success" sx={{ mr: 1 }} />
          ) : (
            <ErrorOutlineIcon color="error" sx={{ mr: 1 }} />
          )}
          <Typography variant="h6">Verse References</Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Button
            variant={"outlined"}
            disabled={isAnyEditModeActive && !editMode}
            onClick={(e) => {
              e.stopPropagation(); // Prevent accordion toggle when clicking the button
              handleEditToggle();
            }}
            color={editMode ? "success" : "primary"}
            size={isMobile ? "small" : "medium"}
          >
            {editMode ? "Done" : "Edit"}
          </Button>

          {activeSections?.verseReferences ? (
            <ExpandLessIcon />
          ) : (
            <ExpandMoreIcon />
          )}
        </Box>
      </Box>

      <Collapse in={activeSections?.verseReferences}>
        <CardContent>
          {!hasLinkedVerses && (
            <Alert severity="warning" sx={{ mb: 3 }}>
              Please link at least one verse to this hadith
            </Alert>
          )}

          {/* Button to open manual linking dialog */}
          {editMode && (
            <Box sx={{ mb: 3 }}>
              <Button
                variant="outlined"
                startIcon={<LinkIcon />}
                onClick={() => {
                  setOpenManualLinkDialog(true);
                  setSelectedChapter("");
                  fetchQuranChapters();
                }}
              >
                Link Verses
              </Button>
            </Box>
          )}

          {/* Display linked verses */}
          {loader ? (
            <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
              <CircularProgress />
            </Box>
          ) : hasLinkedVerses ? (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 500 }}>
                Linked Verses ({verses.length || linkedVerses.length})
              </Typography>
              <Box
                sx={{
                  p: 2,
                  bgcolor:
                    themeObj.palette.mode === "dark"
                      ? "rgba(255,255,255,0.03)"
                      : "rgba(0,0,0,0.02)",
                  borderRadius: 1,
                }}
              >
                {/* Use verses from local state or parent prop, whichever has data */}
                {(verses.length > 0 ? verses : linkedVerses).map(
                  (verse, index) => (
                    <Paper
                      key={index}
                      elevation={1}
                      sx={{
                        p: 2,
                        mb: 2,
                        position: "relative",
                        borderLeft: `4px solid ${themeObj.palette.primary.main}`,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          mb: 1,
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          color="primary"
                          sx={{ fontWeight: "bold" }}
                        >
                          {verse?.chapter?.nameSimple || ""} - Verse #
                          {verse?.verseNumber}
                        </Typography>

                        {editMode && (
                          <Tooltip title="Unlink verse">
                            <IconButton
                              color="error"
                              size="small"
                              onClick={() => RemoveVerseReference(verse?.id)}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>

                      <Typography
                        variant="subtitle1"
                        className="arabic-text"
                        sx={{
                          fontFamily: "Noto Naskh Arabic, serif",
                          fontSize: "1.2rem",
                          mb: 1,
                          direction: "rtl",
                        }}
                      >
                        {verse?.textIndoPak || ""}
                      </Typography>

                      <Typography variant="body2" color="text.secondary">
                        {verse?.translationEnglish || ""}
                      </Typography>
                    </Paper>
                  )
                )}
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                py: 4,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                bgcolor:
                  themeObj.palette.mode === "dark"
                    ? "rgba(255,255,255,0.03)"
                    : "rgba(0,0,0,0.02)",
                borderRadius: 1,
              }}
            >
              <ImportContactsIcon
                sx={{ fontSize: 40, color: "text.secondary", mb: 2 }}
              />
              <Typography color="text.secondary" variant="body1" mb={1}>
                No verses linked to this hadith yet
              </Typography>
              {editMode && (
                <Button
                  variant="outlined"
                  startIcon={<LinkIcon />}
                  onClick={() => {
                    setOpenManualLinkDialog(true);
                    setSelectedChapter("");
                    fetchQuranChapters();
                  }}
                  sx={{ mt: 1 }}
                >
                  Link Verses
                </Button>
              )}
            </Box>
          )}

          {/* Display linked keywords */}
          {linkedKeywords?.length > 0 && (
            <Box sx={{ mt: 4 }}>
              <Divider sx={{ mb: 2 }} />
              <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 500 }}>
                Find Verses by Keywords
              </Typography>
              {isFetchingKeywords ? (
                <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
                  <CircularProgress size={20} />
                </Box>
              ) : (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                  {linkedKeywords.map((keyword) => (
                    <Chip
                      disabled={!editMode}
                      key={keyword.id}
                      label={keyword.label}
                      icon={<LocalOfferIcon />}
                      onClick={() =>
                        fetchKeywordVerses(keyword.id, keyword.label)
                      }
                      color="primary"
                      variant={editMode ? "filled" : "outlined"}
                      clickable={editMode}
                    />
                  ))}
                </Box>
              )}
            </Box>
          )}
        </CardContent>
      </Collapse>

      {/* Dialog showing verses linked to the clicked keyword */}
      <Dialog
        open={showVerseDialog}
        onClose={() => {
          setShowVerseDialog(false);
          setSelectedVerses([]);
        }}
        fullWidth
        maxWidth="lg"
        PaperProps={{
          sx: {
            borderRadius: 2,
            overflow: "hidden",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            bgcolor:
              themeObj.palette.mode === "dark"
                ? "rgba(255,255,255,0.05)"
                : "rgba(0,0,0,0.03)",
          }}
        >
          <LocalOfferIcon sx={{ mr: 1.5 }} />
          {`Verses linked to "${activeKeyword}"`}
        </DialogTitle>
        <DialogContent dividers>
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={
                        selectedVerses.length === keywordVerses.length &&
                        keywordVerses.length > 0
                      }
                      onChange={handleSelectAllVerses}
                    />
                  </TableCell>
                  <TableCell>Verse #</TableCell>
                  <TableCell>Surah</TableCell>
                  <TableCell>Text</TableCell>
                  <TableCell>Translation</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {keywordVerses.length > 0 ? (
                  keywordVerses.map((verse) => (
                    <TableRow
                      key={verse.id}
                      hover
                      selected={selectedVerses.includes(verse.id)}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedVerses.includes(verse.id)}
                          onChange={() => handleSelectVerse(verse.id)}
                        />
                      </TableCell>
                      <TableCell>{verse.verseNumber}</TableCell>
                      <TableCell>{verse.chapter?.nameSimple || ""}</TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "Noto Naskh Arabic, serif",
                          direction: "rtl",
                        }}
                      >
                        {verse.textIndoPak}
                      </TableCell>
                      <TableCell>{verse.translationEnglish}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <Typography variant="body2" sx={{ py: 2 }}>
                        No verses found for this keyword
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions sx={{ px: 3, py: 2 }}>
          <Button
            variant="outlined"
            onClick={() => {
              setShowVerseDialog(false);
              setSelectedVerses([]);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={processSelectedVerses}
            color="primary"
            disabled={selectedVerses.length === 0}
            startIcon={<LinkIcon />}
          >
            Link Selected Verses
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for Manually Linking Verses */}
      <Dialog
        open={openManualLinkDialog}
        onClose={() => setOpenManualLinkDialog(false)}
        fullWidth
        maxWidth="lg"
        PaperProps={{
          sx: {
            borderRadius: 2,
            overflow: "hidden",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            bgcolor:
              themeObj.palette.mode === "dark"
                ? "rgba(255,255,255,0.05)"
                : "rgba(0,0,0,0.03)",
          }}
        >
          <ImportContactsIcon sx={{ mr: 1.5 }} />
          Link Verse to Hadith
        </DialogTitle>
        <DialogContent dividers>
          {/* Loader while fetching chapters */}
          {chaptersLoading ? (
            <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              {/* Chapter Selection Dropdown */}
              <FormControl fullWidth variant="outlined" sx={{ mt: 2, mb: 3 }}>
                <InputLabel>Select Chapter (Surah)</InputLabel>
                <Select
                  value={selectedChapter}
                  onChange={(e) => setSelectedChapter(e.target.value)}
                  label="Select Chapter (Surah)"
                >
                  {allChapters.map((ch) => (
                    <MenuItem key={ch.id} value={ch.id}>
                      {ch.nameSimple} - {ch.nameArabic}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Prompt if no chapter is selected */}
              {!selectedChapter && (
                <Box
                  sx={{
                    py: 4,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    bgcolor:
                      themeObj.palette.mode === "dark"
                        ? "rgba(255,255,255,0.03)"
                        : "rgba(0,0,0,0.02)",
                    borderRadius: 1,
                  }}
                >
                  <ImportContactsIcon
                    sx={{ fontSize: 40, color: "text.secondary", mb: 2 }}
                  />
                  <Typography variant="body1" color="text.secondary">
                    Please select a chapter to view its verses
                  </Typography>
                </Box>
              )}

              {/* If a chapter is selected, display VersesReadView */}
              {selectedChapter && (
                <Box sx={{ mt: 2 }}>
                  <VersesReadView
                    verseLinkingModeEnabled={true}
                    selectedChapter={selectedChapter}
                    linkVerseToHadithEvent={linkQuranVerseToHadith}
                    modalCloseEvent={() => setOpenManualLinkDialog(false)}
                  />
                </Box>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ px: 3, py: 2 }}>
          <Button
            variant="outlined"
            onClick={() => setOpenManualLinkDialog(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <AlertM ref={notifications} />
    </Card>
  );
};

export default VerseReferenceManager;
