/** Maps user against their IDs */
export const user = {
  admin: process.env.REACT_APP_ADMIN_ROLE_ID,
  student: process.env.REACT_APP_STUDENT_ROLE_ID,
  teacher: process.env.REACT_APP_TEACHER_ROLE_ID,
  qc: process.env.REACT_APP_QC_ROLE_ID,
  dataentryclerk: process.env.REACT_APP_DATAENTRY_ROLE_ID,
  manager: process.env.REACT_APP_MANAGER_ROLE_ID,
};

export const isAuthorized = (roleId: any, module: any, path: any) => {
  try {
    if (accessGroups[module][path].includes(roleId.toString())) return true;
    return false;
  } catch (error) {
    return false;
  }
};

export const accessGroups: any = {
  routes: {
    /** Access Group to view All Assessments */
    dashboard: [
      user.admin,
      user.teacher,
      user.manager,
      user.student,
      user.qc,
      user.dataentryclerk,
    ],
    logout: [
      user.admin,
      user.teacher,
      user.qc,
      user.student,
      user.dataentryclerk,
      user.manager,
    ],
    role: [user.admin],
    user: [user.admin],
    hadithbooks: [user.admin, user.manager],
    hadith: [
      user.admin,
      user.qc,
      user.teacher,
      user.student,
      user.manager,
      user.dataentryclerk,
    ],
    keywordgroup: [
      user.admin,
      user.qc,
      user.teacher,
      user.student,
      user.manager,
    ],
    translate: [
      user.admin,
      user.teacher,
      user.qc,
      user.student,
      user.dataentryclerk,
      user.manager,
    ],
    search: [
      user.admin,
      user.teacher,
      user.qc,
      user.student,
      user.dataentryclerk,
      user.manager,
    ],
    menu: [
      user.admin,
      user.teacher,
      user.qc,
      user.student,
      user.dataentryclerk,
      user.manager,
    ],
    lense: [
      user.admin,
      user.teacher,
      user.qc,
      user.student,
      user.dataentryclerk,
      user.manager,
    ],
    topic: [user.admin, user.teacher, user.manager, user.qc],
    narrator: [user.admin, user.teacher, user.manager, user.qc],
    quran: [user.admin, user.teacher, user.student, user.manager, user.qc],
    book: [user.admin, user.teacher, user.manager, user.qc],
    keyword: [user.admin, user.teacher, user.manager, user.qc],
  },
  feature: {
    verseEdit: [user.admin, user.manager],
    verseIssueComment: [
      user.admin,
      user.teacher,
      user.qc,
      user.student,
      user.manager,
    ],
  },
};
