// @ts-nocheck
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    card: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '3vw',

        '&:hover': {
            //boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)', // Highlight on hover
            // backgroundColor: 'rgba(245, 245, 245, 0.5)',
            transition: '0.2s ease-in-out',
        },
    },
    cardContent: {
        borderRadius: '15px !important'

    },
    cardTransition: {
        transition: 'all 0.5s ease-in-out', /* Customize duration and easing as needed */
    }

});

export { useStyles }
