// @ts-nocheck
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    stickyTop: {
        position: 'sticky',
        top: 0,
        backgroundColor: 'white', // Use camelCase for multi-word CSS properties
        zIndex: 1000, // Material-UI uses zIndex in its theme, you might want to align with that
        // Add any other styles you need here
    },
});

export { useStyles }
