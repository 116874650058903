// @ts-nocheck

import React, { useEffect, useState, useRef } from "react";
import { useStyles } from "./Styles";
import "../../../../../index.scss";
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  getAll,
  getById,
  post,
  patch,
  deleteById,
} from "../../../../../Services/GenericApiService";
import { useTranslation } from "react-i18next";
import AlertM from "../../../../Helpers/AlertM/AlertM";
import LabelUpdate from "../LabelUpdate/LabelUpdate";
// import LabelView from "../LabelView/LabelView";
import LabelCreate from "../LabelCreate/LabelCreate";
import { log } from "../../../../../Services/LoggerService";

import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import MUIDataTable from "mui-datatables";
import {
  IconButton,
  Skeleton,
  Tooltip,
  Chip,
  Typography,
  Popover,
  Badge,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import ConfirmationDialog from "../../../../Helpers/ConfirmationDialog/ConfirmationDialog";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SearchIcon from "@mui/icons-material/Search";

// font awesome stuff
const iconList = Object.keys(Icons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon) => Icons[icon]);

library.add(...iconList);

// Component to display keyword chips with truncation
const KeywordGroup = ({ keywords, maxInitialDisplay = 3 }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Display fewer chips on mobile
  const displayCount = isMobile ? 2 : maxInitialDisplay;

  if (!keywords || keywords.length === 0) {
    return (
      <Typography variant="body2" color="text.secondary">
        No keywords
      </Typography>
    );
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 0.5,
          alignItems: "center",
        }}
      >
        {keywords.slice(0, displayCount).map((keyword, index) => (
          <Chip
            key={index}
            label={keyword}
            size="small"
            sx={{
              maxWidth: "150px",
              "& .MuiChip-label": {
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
            }}
          />
        ))}

        {keywords.length > displayCount && (
          <Tooltip title="View all keywords">
            <Badge
              badgeContent={keywords.length - displayCount}
              color="primary"
            >
              <Chip
                icon={<MoreHorizIcon />}
                size="small"
                onClick={handleClick}
                sx={{ cursor: "pointer" }}
                label="More"
              />
            </Badge>
          </Tooltip>
        )}
      </Box>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            p: 2,
            maxWidth: "400px",
            maxHeight: "400px",
            overflowY: "auto",
          },
        }}
      >
        <Typography variant="subtitle2" sx={{ mb: 1 }}>
          All Keywords ({keywords.length})
        </Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
          {keywords.map((keyword, index) => (
            <Chip key={index} label={keyword} size="small" />
          ))}
        </Box>
      </Popover>
    </>
  );
};

function LabelMain() {
  // css for module
  const classes = useStyles();

  // translation
  const { t } = useTranslation();

  // child ref for alert
  const notifications = useRef();

  // module(s) for api
  const moduleMain = "label";

  // data vars
  const [allData, setAllData] = React.useState([]);
  const [dataToEdit, setDataToEdit] = React.useState({});
  const [searchText, setSearchText] = React.useState("");

  // Define columns with custom components
  let columns = [
    {
      name: "Name English",
      label: "English Keywords",
      options: {
        filterOptions: { fullWidth: true },
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = tableMeta.rowIndex;
          const keywords = allData[rowIndex]?.nameEnglish || [];
          return <KeywordGroup keywords={keywords} />;
        },
      },
    },
    {
      name: "Name Urdu",
      label: "Urdu Keywords",
      options: {
        filterOptions: { fullWidth: true },
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = tableMeta.rowIndex;
          const keywords = allData[rowIndex]?.nameUrdu || [];
          return <KeywordGroup keywords={keywords} />;
        },
      },
    },
    {
      name: "Name Arabic",
      label: "Arabic Keywords",
      options: {
        filterOptions: { fullWidth: true },
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = tableMeta.rowIndex;
          const keywords = allData[rowIndex]?.nameArabic || [];
          return <KeywordGroup keywords={keywords} />;
        },
      },
    },
    {
      name: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellHeaderProps: () => {
          return { align: "right" };
        },
        setCellProps: () => ({
          align: "right",
        }),
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div className="d-inline-flex">
              <div className="px-2">
                <Tooltip title={t("common.edit")}>
                  <IconButton
                    aria-label="edit"
                    size="small"
                    onClick={() => {
                      onEditClick(dataIndex, rowIndex);
                    }}
                  >
                    <FontAwesomeIcon className="edit-btn" icon="edit" />
                  </IconButton>
                </Tooltip>
              </div>

              <div className="px-2">
                {/*  delete confirmation dialog  */}
                <ConfirmationDialog
                  title={"Delete Confirmation"}
                  body={"Are you sure you want to delete this keyword group?"}
                  type={"delete"}
                  isLoading={loading}
                  dataItemIdx={dataIndex}
                  confirmEvent={handleDelete}
                />
              </div>

              {/* <div className="px-2">
                {allData && <LabelView data={allData[rowIndex]} />}
              </div> */}
            </div>
          );
        },
      },
    },
  ];

  // ui controls
  const [loading, setLoading] = React.useState(true);
  const [editMode, setEditMode] = React.useState(false);

  // table ui controls
  const [tableData, setTableData] = React.useState([]);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  // Custom search function to search across all keywords
  const customSearch = (searchText, currentRow, columns) => {
    const rowData = allData[currentRow.dataIndex];

    // Combine all keywords into a single array for searching
    const allKeywords = [
      ...(rowData.nameEnglish || []),
      ...(rowData.nameUrdu || []),
      ...(rowData.nameArabic || []),
    ];

    // Check if any keyword includes the search text (case insensitive)
    return allKeywords.some((keyword) =>
      keyword.toLowerCase().includes(searchText.toLowerCase())
    );
  };

  // fetch dependent data
  const fetch = () => {
    setTableData([]); // reset table data
    setLoading(true); // show loader
    getAll(moduleMain, currentPage, pageSize)
      .then((_res) => {
        // set all data
        setAllData(_res.rows);

        // set total count
        setTotalRowCount(_res.count);

        // For MUIDataTable, we still need a dummy array for each row
        // The actual rendering is done in customBodyRender
        const rows = _res.rows.map((record) => {
          return [
            "English Keywords", // These values don't matter, they're replaced by customBodyRender
            "Urdu Keywords",
            "Arabic Keywords",
          ];
        });

        setTableData(rows);
        setLoading(false); // hide loader
      })
      .catch((_err) => {
        log(_err);
        setLoading(false); // hide loader
      });
  };

  useEffect(() => {
    fetch();
  }, [currentPage, pageSize]);

  // event handlers
  const onEditClick = (_dataIndex, _rowIndex) => {
    setDataToEdit(allData[_dataIndex]);
    setEditMode(true);
  };

  const resetEdit = (_state) => {
    setEditMode(false);
  };

  const handleDelete = (_dataItemIndex) => {
    setLoading(true); // show spinner
    deleteById(moduleMain, allData[_dataItemIndex].id)
      .then(async (_res) => {
        setLoading(false); // hide loader
        notifications.current.successAlert(t("common.deleteSuccess"), "");
        fetch(); // refresh data
      })
      .catch((_err) => {
        log(_err);
        notifications.current.errorAlert(
          t("common.somethingWentWrong"),
          t("common.tryAgain")
        );
        setLoading(false); // hide loader
      });
  };

  let options = {
    search: true,
    download: true,
    print: true,
    viewColumns: true,
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    selectableRowsHideCheckboxes: true,
    sort: true,
    onChangePage: (currentPage) => {
      setCurrentPage(currentPage);
    },
    onChangeRowsPerPage: (numberOfRows) => {
      setPageSize(numberOfRows);
    },
    page: currentPage,
    count: totalRowCount,
    serverSide: true,
    rowsPerPageOptions: [25, 50, 75, 100],
    rowsPerPage: pageSize,
    searchPlaceholder: "Search for any keyword...",
    customSearch: customSearch,
    onTableChange: (action, state) => {
      // Handle search here if needed
      if (action === "search" && state.searchText !== searchText) {
        setSearchText(state.searchText);
        // You can implement server-side search here if needed
      }
    },
  };

  if (tableData.length === 0) {
    options = {
      ...options,
      textLabels: {
        body: {
          noMatch: loading ? (
            <Box>
              <Skeleton className="my-4" />
              <Skeleton className="my-4" />
              <Skeleton className="my-4" />
            </Box>
          ) : (
            "Sorry, there is no matching data to display"
          ),
        },
      },
    };
  }

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "99vh",
        backgroundColor: "rgba(222, 223, 223, 0.3)",
      }}
    >
      <CssBaseline />

      {/* Header */}
      <Header />

      {/* Sider */}
      <Sider />

      {/* Content */}
      <Box component="main" sx={{ flexGrow: 2, p: 3 }} mt={10}>
        <div className="px-1 px-md-5">
          {/* Upper Section */}
          <div className="row">
            <div className="col-md-10">
              <h2 className="mt-3">{"Hadith Keyword Groups"}</h2>
              <Typography variant="subtitle1" color="text.secondary">
                Manage keyword groups for hadith categorization
              </Typography>
            </div>
            <div className="col-md-2">
              <LabelCreate refreshDataEvent={fetch} />
            </div>
          </div>

          <div className="mt-4">
            <Paper elevation={3} sx={{ borderRadius: 2, overflow: "hidden" }}>
              <MUIDataTable
                title={
                  <Typography variant="h6">
                    <SearchIcon sx={{ mr: 1, verticalAlign: "middle" }} />
                    Keyword Groups
                  </Typography>
                }
                data={tableData}
                columns={columns}
                options={options}
              />
            </Paper>
          </div>
        </div>

        {/* display edit modal */}
        {editMode && (
          <div>
            <LabelUpdate
              refreshDataEvent={fetch}
              resetEditState={resetEdit}
              recordToUpdate={dataToEdit}
            />
          </div>
        )}

        {/* Alerts */}
        <AlertM ref={notifications} />
      </Box>
    </Box>
  );
}

export default LabelMain;
