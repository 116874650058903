import {COLLAPSE, EXPAND, UPDATE_MENU} from "./Types";

export const collapse = () => {
    return {
        type: COLLAPSE
    }
}
export const expand = () => {
    return {
        type: EXPAND
    }
}

export const updateMenuItems = (menuItems: []) => {
    return {
        type: UPDATE_MENU,
        payload: menuItems
    }
}