const languages = [
  { label: "Arabic", key: "ar" },
  { label: "Urdu", key: "ur" },
  { label: "English", key: "en" },
  // { label: "Spanish", key: "es" },
  // { label: "French", key: "fr" },
  // { label: "German", key: "de" },
  // { label: "Italian", key: "it" },
  // { label: "Portuguese", key: "pt" },
  // { label: "Dutch", key: "nl" },
  // { label: "Russian", key: "ru" },
  // { label: "Chinese (Simplified)", key: "zh-CN" },
  // { label: "Japanese", key: "ja" },
  // { label: "Korean", key: "ko" },
  // { label: "Turkish", key: "tr" },
  // { label: "Hindi", key: "hi" },
  // { label: "Indonesian", key: "id" },
  // { label: "Malay", key: "ms" },
  // { label: "Vietnamese", key: "vi" },
  // { label: "Thai", key: "th" },
  // { label: "Hebrew", key: "he" },
  // { label: "Greek", key: "el" },
  // { label: "Swedish", key: "sv" },
  // { label: "Danish", key: "da" },
  // { label: "Norwegian", key: "no" },
  // { label: "Finnish", key: "fi" },
  // { label: "Polish", key: "pl" },
  // { label: "Czech", key: "cs" },
  // { label: "Hungarian", key: "hu" },
  // { label: "Romanian", key: "ro" },
  // { label: "Bulgarian", key: "bg" },
  // { label: "Slovak", key: "sk" },
];

const getLanguageLabel = (key: string) => {
  const language = languages.find((lang) => lang.key === key);
  return language ? language.label : key;
};

export { languages, getLanguageLabel };
