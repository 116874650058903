// @ts-nocheck
import React, {
  useCallback,
  useEffect,
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
} from "react";
import { useStyles } from "./Styles";
import "../../../../../index.scss";
import { useTheme } from "@mui/material/styles";
import {
  getAllFiltered,
  post,
  deleteById,
  hardDelete,
} from "../../../../../Services/GenericApiService";
import { useTranslation } from "react-i18next";
import { log } from "../../../../../Services/LoggerService";
import _ from "lodash";
import AlertM from "../../../../Helpers/AlertM/AlertM";
import Box from "@mui/material/Box";
import {
  Typography,
  Button,
  Autocomplete,
  TextField,
  Grid,
  Card,
  CardContent,
  Divider,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { library } from "@fortawesome/fontawesome-svg-core";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import DeleteIcon from "@mui/icons-material/Delete";

// font awesome stuff
const iconList = Object.keys(Icons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon) => Icons[icon]);

library.add(...iconList);

const ManageKeywords = forwardRef(
  ({ verse = null, closeEvent = null }, ref) => {
    useImperativeHandle(ref, () => ({}));

    const theme = useTheme();

    // css for module
    const classes = useStyles();

    // translation
    const { t } = useTranslation();

    // child ref for alert
    const notifications = useRef();

    // data vars
    const [linkedKeywords, setLinkedKeywords] = useState([]);

    // ui controls
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
      if (verse) {
        fetchLinkedKeywords();
      }
    }, [verse]);

    const fetchLinkedKeywords = async () => {
      try {
        const url = `keyword/byVerse/${verse.id}`;
        getAllFiltered(url)
          .then((_res) => {
            setLinkedKeywords(_res);
          })
          .catch((error) => {
            log(error);
          });
      } catch (error) {
        log(error);
      }
    };

    return (
      <Grid container spacing={1} sx={{ padding: 2 }}>
        <Grid item xs={12} style={{ position: "relative" }}>
          <div className="d-flex" id="comment-dialog-title">
            <Divider />
          </div>

          <div className="p-2">
            <Box mt={2}>
              <Typography variant="h6" className="mb-4">
                Linked Keywords
              </Typography>
              {linkedKeywords.length > 0 ? (
                <Grid container spacing={2}>
                  {linkedKeywords.map((keyword) => (
                    <Grid item xs={12} sm={6} md={2} key={keyword.id}>
                      <Card variant="outlined" sx={{ padding: 2 }}>
                        <Typography variant="h6">
                          {keyword?.rootWord}
                        </Typography>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Typography variant="body1">
                  No keywords linked to this verse.
                </Typography>
              )}
            </Box>
          </div>
        </Grid>

        <AlertM ref={notifications} />
      </Grid>
    );
  }
);

export default ManageKeywords;
